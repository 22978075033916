"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _user = _interopRequireDefault(require("./user"));
var _holder = _interopRequireDefault(require("./holder"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    User: _user.default,
    Holder: _holder.default
  },
  data: function data() {
    return {
      types: 'blind',
      tableCli: -1,
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        status: null,
        type: 3
      },
      snapshotList: [],
      visibleUser: false,
      productId: null,
      visibleHolder: false
    };
  },
  created: function created() {
    var _this = this;
    this.getList(1);
    (0, _marketing.marketGetSnapshotApi)().then(function (res) {
      _this.snapshotList = res.data;
    });
  },
  filters: {
    formatStatus: function formatStatus(val) {
      var res = '';
      switch (val) {
        case true:
          res = '已开启';
          break;
        case false:
          res = '未开启';
          break;
        default:
          break;
      }
      return res;
    }
  },
  methods: {
    handleClick: function handleClick(row) {
      this.tableCli = row.id;
    },
    updateUserCount: function updateUserCount(row) {
      var _this2 = this;
      (0, _marketing.changeRecommendOrCommisionApi)({
        id: row.id,
        artificialCount: row.artificialCount ? row.artificialCount : null
      }).then(function (res) {
        _this2.tableCli = -1;
        _this2.getList(1);
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === 'works') {
        this.$router.push('/merchant/order/consignmentOrder/works');
        return false;
      } else if (tab.name === 'album') {
        this.$router.push('/merchant/order/consignmentOrder/album');
        return false;
      } else if (tab.name === 'blind') {
        this.$router.push('/merchant/order/consignmentOrder/blind');
        return false;
      }
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _marketing.marketProductListApi)(this.queryParams).then(function (res) {
        res.rows.forEach(function (item) {
          if (item.marketProductOpens) {
            var newarr = [];
            item.marketProductOpens.forEach(function (it) {
              if (it.hasOpenMarket) {
                newarr.push(it.getType);
              }
            });
            item.marketSelectType = newarr;
          } else {
            item.marketSelectType = [];
          }
          item.pirceLimits.forEach(function (m) {
            if (!m.getType) {
              m.getType = '';
            }
          });
        });
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.tableData.statistical = res.statistical;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    chargeDeleteAction: function chargeDeleteAction(val, index, rowIndex) {
      var _this4 = this;
      if (val.id) {
        (0, _marketing.marketChargeRemoveApi)(val.id).then(function (res) {
          _this4.getList(1);
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      } else {
        this.tableData.data[rowIndex].charges.splice(index, 1);
      }
    },
    chargeRateAction: function chargeRateAction(val) {
      var obj = {
        id: val.id,
        productId: val.productId,
        snapshotId: null,
        chargeRate: null,
        type: null
      };
      if (val.type == 0) {
        obj.chargeRate = Number(val.chargeRate);
        obj.type = val.type;
      } else {
        obj.snapshotId = val.snapshotId;
        obj.chargeRate = Number(val.chargeRate);
        obj.type = val.type;
      }
      if (val.id) {
        this.marketChargeUpdateApiMethod(obj);
      } else {
        this.marketChargeSaveApiMethod(obj);
      }
    },
    marketChargeUpdateApiMethod: function marketChargeUpdateApiMethod(obj) {
      var _this5 = this;
      (0, _marketing.marketChargeUpdateApi)(obj.id, obj).then(function (res) {
        _this5.getList('');
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    marketChargeSaveApiMethod: function marketChargeSaveApiMethod(obj) {
      var _this6 = this;
      (0, _marketing.marketChargeSaveApi)(obj).then(function (res) {
        _this6.getList('');
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    addChargeRate: function addChargeRate(index, row) {
      var obj = {
        id: null,
        productId: row.productId,
        snapshotId: null,
        chargeRate: null,
        type: 1
      };
      this.tableData.data[index].charges.push(obj);
      // console.log('this.tableData.data', this.tableData.data)
    },
    limitsAction: function limitsAction(val) {
      var obj = {
        id: val.id,
        productId: val.productId,
        startDate: val.startDate,
        lowestLimit: Number(val.lowestLimit),
        highestLimit: Number(val.highestLimit),
        getType: val.getType || ''
      };
      if (obj.id) {
        this.marketPirceLimitUpdateApiMethod(obj);
      } else {
        this.marketPirceLimitSaveApiMethod(obj);
      }
    },
    marketPirceLimitUpdateApiMethod: function marketPirceLimitUpdateApiMethod(obj) {
      var _this7 = this;
      (0, _marketing.marketPirceLimitUpdateApi)(obj.id, obj).then(function (res) {
        _this7.getList('');
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    marketPirceLimitSaveApiMethod: function marketPirceLimitSaveApiMethod(obj) {
      var _this8 = this;
      (0, _marketing.marketPirceLimitSaveApi)(obj).then(function (res) {
        _this8.getList('');
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    addPriceLimits: function addPriceLimits(index, row) {
      var obj = {
        id: null,
        productId: row.productId,
        startDate: null,
        lowestLimit: null,
        highestLimit: null,
        getType: 1
      };
      this.tableData.data[index].pirceLimits.push(obj);
    },
    statusAction: function statusAction(row) {
      var _this9 = this;
      (0, _marketing.marketProductIsOpenApi)(row.productId, {
        isOpen: !row.status
      }).then(function (res) {
        _this9.$message.success('操作成功');
        _this9.getList('');
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    downAction: function downAction(row) {
      var _this10 = this;
      this.$confirm('确定下架所有藏品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.marketProductTakeApi)(row.productId).then(function (res) {
          _this10.$message.success('操作成功');
          _this10.getList('');
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      }).catch(function () {});
    },
    limitsDeleteAction: function limitsDeleteAction(val, index, rowIndex) {
      var _this11 = this;
      if (val.id) {
        (0, _marketing.marketLimitRemoveApi)(val.id).then(function (res) {
          _this11.getList(1);
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      } else {
        this.tableData.data[rowIndex].pirceLimits.splice(index, 1);
      }
    },
    changeListTypeAction: function changeListTypeAction(e) {
      var typearr = [1, 2, 3, 4, 5, 6, 7, 8];
      var marketselectArr = JSON.parse(JSON.stringify(e.marketSelectType));
      var marketArrJson = JSON.parse(JSON.stringify(e.marketProductOpens));
      var marketchangeJSON = {};
      var that = this;
      marketArrJson.forEach(function (item) {
        marketchangeJSON[item.getType] = item;
      });
      typearr.forEach(function (item) {
        if (marketchangeJSON[item] && marketchangeJSON[item].hasOpenMarket && marketselectArr.indexOf(item) == -1) {
          (0, _marketing.changeopneMarketTypeApi)(e.id, {
            type: item,
            isOpen: false
          }).then(function () {
            that.getList();
          });
        } else if (marketchangeJSON[item] && !marketchangeJSON[item].hasOpenMarket && marketselectArr.indexOf(item) > -1) {
          (0, _marketing.changeopneMarketTypeApi)(e.id, {
            type: item,
            isOpen: true
          }).then(function () {
            that.getList();
          });
        } else if (!marketchangeJSON[item] && marketselectArr.indexOf(item) > -1) {
          (0, _marketing.changeopneMarketTypeApi)(e.id, {
            type: item,
            isOpen: true
          }).then(function () {
            that.getList();
          });
        }
      });
      // changeopneMarketTypeApi
    },
    chargeCommisionRateAction: function chargeCommisionRateAction(val) {
      var _this12 = this;
      (0, _marketing.changeRecommendOrCommisionApi)({
        id: val.id,
        productId: val.productId,
        hasRecommend: val.hasRecommend,
        recommendSort: val.recommendSort,
        commissionRatio: val.commissionRatio
      }).then(function (res) {
        _this12.getList();
      });
    },
    userAction: function userAction(row) {
      this.visibleUser = true;
      this.productId = row.productId;
    },
    userClose: function userClose() {
      this.visibleUser = false;
    },
    holderAction: function holderAction(row) {
      this.visibleHolder = true;
      this.productId = row.productId;
    },
    holderClose: function holderClose() {
      this.visibleHolder = false;
    }
  }
};
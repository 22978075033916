"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _marketing = require("@/api/marketing");
var _automaticOrder = require("@/api/automaticOrder");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "SweepGoods",
  data: function data() {
    return {
      payUrl: "",
      iframeDialog: false,
      activeName: "",
      collectionDialog: false,
      name: "",
      collectionLoading: false,
      collectionConfig: {
        pageNum: 1,
        pageSize: 10
      },
      collectionData: {
        data: [],
        total: 0
      },
      orderTotal: 0,
      listPageConfig: {
        pageNum: 1,
        pageSize: 10
      },
      collection: [],
      sweepGoodsDialog: false,
      account: [],
      transactionMemberIds: [],
      transactionRangeMemberIds: [],
      type: 1,
      userList: [],
      userLoading: false,
      marketProducts: [],
      minPrice: 0,
      maxPrice: 0,
      buyCount: 0,
      tabs: [],
      //共有几种支付方式
      orderList: [],
      //总订单
      selectOrderList: [],
      //选中的订单
      countDown: "",
      payMethod: "",
      //支付方式
      timer: "",
      payOrderNo: "" //订单号
    };
  },
  mounted: function mounted() {
    this.getUseList();
    this.getPayByOrder();
  },
  computed: {
    allPrice: function allPrice() {
      var _this = this;
      var a = 0;
      if (this.selectOrderList.length) {
        var item = this.orderList.filter(function (i) {
          return _this.selectOrderList.includes(i.id);
        });
        a = item.reduce(function (total, current) {
          return total + parseFloat(Number(current.totalPrice).toFixed(2));
        }, 0);
      }
      return a;
    }
  },
  watch: {
    iframeDialog: function iframeDialog(val) {
      console.log(val);
      if (val) {
        this.loopPayStatus();
      } else {
        if (this.timer) clearInterval(this.timer);
      }
    }
  },
  methods: {
    getUseList: function getUseList() {
      var _this2 = this;
      (0, _automaticOrder.autoOrderListApi)().then(function (res) {
        _this2.account = res.rows;
      });
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.collectionConfig.pageSize = val;
      this.getList();
    },
    getList: function getList(page) {
      var _this3 = this;
      var data = {
        type: 1,
        status: true,
        name: this.name,
        pageNum: page || this.collectionConfig.pageNum,
        pageSize: this.collectionConfig.pageSize
      };
      this.collectionLoading = true;
      (0, _marketing.marketProductListApi)(data).then(function (res) {
        _this3.collectionLoading = false;
        if (!_this3.collectionDialog) {
          _this3.collectionDialog = true;
        }
        _this3.collectionData.data = res.rows;
        _this3.collectionData.total = res.total;
      });
    },
    selectCollection: function selectCollection(item, type) {
      if (type) {
        this.collection.push(item);
      } else {
        var idx = this.collection.findIndex(function (i) {
          return i.id === item.id;
        });
        this.collection.splice(idx, 1);
      }
    },
    addCollection: function addCollection() {
      var _this4 = this;
      this.collection.forEach(function (item) {
        _this4.marketProducts.push((0, _objectSpread2.default)({
          minPrice: 0,
          maxPrice: 0,
          buyCount: 0
        }, item));
      });
      this.closeDialog("collectionDialog");
    },
    searchUserAction: function searchUserAction(data) {
      var _this5 = this;
      this.userLoading = true;
      var params = {
        keyword: data
      };
      (0, _automaticOrder.listByClearApi)(params).then(function (res) {
        _this5.userLoading = false;
        _this5.userList = res.rows;
      });
    },
    showCollection: function showCollection() {
      this.collection = (0, _toConsumableArray2.default)(this.marketProducts);
      this.getList(1);
    },
    deleteCollection: function deleteCollection(_ref) {
      var id = _ref.id;
      this.marketProducts = this.marketProducts.filter(function (item) {
        return item.id != id;
      });
    },
    closeDialog: function closeDialog(ele) {
      if (ele) {
        this[ele] = false;
        this.collectionConfig.pageNum = 1;
      }
      if (ele === "sweepGoodsDialog") {
        this.collection = [];
        this.transactionMemberIds = [];
        this.transactionRangeMemberIds = [];
        this.type = 1;
        this.clearProducts();
      }
    },
    clearProducts: function clearProducts() {
      this.minPrice = 0;
      this.maxPrice = 0;
      this.buyCount = 0;
      this.marketProducts = [];
    },
    checkProducts: function checkProducts() {
      var _this6 = this;
      return new Promise(function (resolve, reject) {
        var list = (0, _toConsumableArray2.default)(_this6.marketProducts);
        var pass = "";
        if (!_this6.transactionMemberIds.length) {
          // this.$message.warning("请选择交易账户");
          reject("请选择交易账户");
          return;
        }
        if (_this6.type == 2) {
          for (var i = 0; i < list.length; i++) {
            // list[i].minPrice == 0 ||
            if (list[i].maxPrice == 0) {
              pass = "请输入价格区间";
              break;
            }
            if (list[i].buyCount == 0) {
              pass = "请输入购买数量";
              break;
            }
          }
        } else {
          // this.minPrice == 0 ||
          if (_this6.maxPrice == 0) {
            pass = "请输入价格区间";
          }
          if (_this6.buyCount == 0) {
            pass = "请输入购买数量";
          }
        }
        if (pass) {
          reject(pass);
          return;
        } else {
          resolve();
        }
      });
    },
    batchSubmit: function batchSubmit() {
      var _this7 = this;
      this.checkProducts().then(function (res) {
        var data = {
          transactionMemberIds: _this7.transactionMemberIds,
          transactionRangeMemberIds: _this7.transactionRangeMemberIds,
          type: _this7.type
        };
        if (_this7.type == 1) {
          data.minPrice = _this7.minPrice;
          data.maxPrice = _this7.maxPrice;
          data.buyCount = _this7.buyCount;
        } else {
          var marketProducts = [];
          _this7.marketProducts.forEach(function (item) {
            marketProducts.push({
              minPrice: item.minPrice,
              maxPrice: item.maxPrice,
              buyCount: item.buyCount,
              productId: item.productId
            });
          });
          data.marketProducts = marketProducts;
        }
        var loadingInstance = _elementUi.Loading.service();
        (0, _automaticOrder.batchSubmitApi)(data).then(function (res) {
          _this7.$message.success("执行成功");
          _this7.$nextTick(function () {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
          _this7.closeDialog("sweepGoodsDialog");
          _this7.getPayByOrder();
        }).catch(function (err) {
          _this7.$nextTick(function () {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        });
      }).catch(function (err) {
        _this7.$message.warning(err);
      });
    },
    getPayByOrder: function getPayByOrder() {
      var _this8 = this;
      (0, _automaticOrder.getPayByOrderApi)().then(function (res) {
        _this8.tabs = res.data;
        if (res.data.length) {
          _this8.batchSubmitList(res.data[0].id);
          _this8.payMethod = res.data[0].id;
          _this8.activeName = res.data[0].name;
        }
      });
    },
    batchSubmitList: function batchSubmitList() {
      var _this9 = this;
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.payMethod;
      (0, _automaticOrder.batchSubmitListApi)({
        payType: id,
        pageNum: this.listPageConfig.pageNum,
        pageSize: this.listPageConfig.pageSize
      }).then(function (res) {
        _this9.orderList = res.rows;
        _this9.orderTotal = res.total;
        if (res.rows.length) {
          _this9.countDown = res.rows[0].closeOrderTime;
        }
        _this9.selectOrderList = res.rows.map(function (item) {
          return item.id;
        });
        _this9.toggle(res.rows);
      });
    },
    handleNumChange: function handleNumChange(val) {
      this.listPageConfig.pageNum = val;
      this.batchSubmitList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPageConfig.pageSize = val;
      this.batchSubmitList();
    },
    handleClick: function handleClick(item) {
      console.log(item);
      this.payMethod = item.id;
      this.batchSubmitList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectOrderList = val.map(function (item) {
        return item.id;
      });
    },
    initiatePay: function initiatePay() {
      var _this10 = this;
      if (!this.selectOrderList.length) {
        return this.$message.warning("没有未支付的订单");
      }
      var loadingInstance = _elementUi.Loading.service();
      var data = {
        orderIds: this.selectOrderList,
        payMethod: this.payMethod
      };
      (0, _automaticOrder.initiatePayApi)(data).then(function (res) {
        // window.open(res.data.url);
        _this10.payUrl = res.data.url;
        _this10.payOrderNo = res.data.payOrderNo;
        _this10.iframeDialog = true;
        _this10.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (err) {
        _this10.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    toggle: function toggle(data) {
      var _this11 = this;
      if (data.length) {
        this.$nextTick(function () {
          data.forEach(function (item) {
            _this11.$refs.multipleTable.toggleRowSelection(item, true);
          });
        });
      }
    },
    hilarity: function hilarity() {
      console.log("倒计时结束");
      this.iframeDialog = false;
      this.payUrl = "";
      this.batchSubmitList();
      this.getPayByOrder();
    },
    loopPayStatus: function loopPayStatus() {
      var _this12 = this;
      this.timer = setInterval(function () {
        (0, _automaticOrder.payOrderStatusApi)(_this12.payOrderNo).then(function (res) {
          console.log(res);
          if (res.data.payResult) {
            _this12.hilarity();
            clearInterval(_this12.timer);
          }
        });
      }, 3000);
    },
    cancelOrder: function cancelOrder() {
      var _this13 = this;
      this.$confirm("确认取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        (0, _automaticOrder.cancelOrderApi)({
          ids: _this13.selectOrderList
        }).then(function (res) {
          _this13.$message.success("取消成功");
          _this13.$nextTick(function () {
            loadingInstance.close();
          });
          _this13.hilarity();
        }).catch(function (_) {
          _this13.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    }
  }
};
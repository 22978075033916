"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
var _tosplit = require("@/api/tosplit");
var _user = require("@/api/user");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      searchForm: {
        phone: '',
        startTime: '',
        endTime: ''
      },
      initSearchForm: {},
      dialogForm: {
        user: '',
        requestMoney: '',
        password: ''
      },
      contentVisible: false,
      userList: [],
      time: ['', ''],
      statusList: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '求购中'
      }, {
        value: '2',
        label: '已完成'
      }, {
        value: '-1',
        label: '已取消'
      }],
      dialogVisible: false,
      passwordVisible: false,
      currentId: '',
      topList: [],
      topLoading: false,
      listLoading: false,
      list: [],
      listQuery: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      btnLoading: false,
      searchLoading: false,
      statistical: {}
    };
  },
  computed: {
    currentUser: function currentUser() {
      var user = {};
      if (this.dialogForm.user && this.userList && this.userList.length) {
        var currentUserId = this.dialogForm.user.split('_')[0];
        if (currentUserId) {
          user = this.userList.find(function (e) {
            return e.userId === currentUserId;
          }) || {};
        }
      }
      return user;
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getList(1);
    },
    getList: function getList(reset) {
      var _this = this;
      if (reset) {
        this.listQuery.pageNum = 1;
      }
      this.listLoading = true;
      (0, _tosplit.payTosplitListApi)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), {}, {
        pageNum: this.listQuery.pageNum,
        pageSize: this.listQuery.pageSize
      })).then(function (res) {
        _this.list = res.rows;
        _this.listQuery.total = res.total;
        _this.listLoading = false;
        _this.statistical = res.statistical;
      }).catch(function (err) {
        console.log(err);
        _this.listLoading = false;
      });
    },
    search: function search() {
      this.searchForm = (0, _objectSpread2.default)({}, this.initSearchForm);
      this.getList(true);
    },
    // searchInput: debounce(function() {
    //   this.getList();
    // }, 200, false),
    exportExcel: function exportExcel() {
      var _this2 = this;
      if (this.btnLoading) {
        return;
      }
      this.btnLoading = true;
      (0, _tosplit.payTosplitExportApi)(this.searchForm).then(function (res) {
        _this2.btnLoading = false;
        if (res.code === 200) {
          _this2.$message.success(res.msg);
        } else {
          _this2.$message.error(res.msg);
        }
      }).catch(function (err) {
        _this2.$message.error(err.message);
      });
    },
    changeTime: function changeTime(val) {
      if (val && val.length) {
        this.initSearchForm.startTime = val[0] || '';
        this.initSearchForm.endTime = val[1] || '';
      }
      this.getList(1);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getList(1);
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(48);
    },
    searchUserAction: function searchUserAction() {
      var _this3 = this;
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var params = {
        source: 2,
        hasVerified: 2,
        userStatus: 2,
        nameOrPhone: data,
        pageNum: 1,
        pageSize: 20
      };
      this.searchLoading = true;
      (0, _user.userLstApi)(params).then(function (res) {
        _this3.userList = res.rows;
        _this3.searchLoading = false;
      }).catch(function () {
        _this3.searchLoading = false;
      });
    },
    openDialog: function openDialog() {
      this.searchUserAction();
      this.dialogVisible = true;
    },
    openContentDialog: function openContentDialog() {
      var _this4 = this;
      this.$refs['ruleFormRef'].validate(function (valid) {
        if (valid) {
          _this4.contentVisible = true;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.setpayTosplitToSplit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    setpayTosplitToSplit: function setpayTosplitToSplit() {
      var _this6 = this;
      var form = (0, _objectSpread2.default)({}, this.dialogForm);
      var userId = this.dialogForm.user.split('_')[0];
      var phone = this.dialogForm.user.split('_')[1];
      delete form.user;
      (0, _tosplit.payTosplitToSplitApi)((0, _objectSpread2.default)({
        userId: userId,
        phone: phone
      }, form)).then(function (res) {
        if (res.code === 200) {
          _this6.$message({
            type: 'success',
            message: '操作成功'
          });
          _this6.dialogVisible = false;
          _this6.contentVisible = false;
          _this6.passwordVisible = false;
          _this6.getList();
        } else {
          _this6.$message({
            type: 'error',
            message: res.mag
          });
        }
      });
    },
    numInput: function numInput(name, point, min, max) {
      this.dialogForm[name] = (0, _index.numInput)(this.dialogForm[name], point, min, max);
    },
    goRouter: function goRouter(name) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.$router.push({
        name: name,
        query: query,
        params: params
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 0" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { path: _vm.roterPre + "/marketing/compose/create" },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("创建合成活动")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "合成活动名称",
              "min-width": "150",
              prop: "activeName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动时间", "min-width": "200", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v("开始时间：" + _vm._s(scope.row.startTime)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("结束时间：" + _vm._s(scope.row.endTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "合成活动状态",
              "min-width": "100",
              prop: "isStart",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm._f("formatIsStart")(scope.row.isStart))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "玩法数量", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#1890ff", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.clickNum(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.ruleSetCount))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "创建时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "180", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path:
                              _vm.roterPre +
                              "/marketing/compose/create?id=" +
                              scope.row.id,
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                          },
                          [_vm._v("编辑")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    scope.row.isStart != 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.priorityType == 2
                      ? _c(
                          "el-upload",
                          {
                            ref: "uploadFile",
                            staticClass: "upload-demo mr10",
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              action: "#",
                              limit: 1,
                              multiple: "",
                              "http-request": function (file) {
                                return _vm.dExcels(file, scope.row.id)
                              },
                              "show-file-list": false,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { type: "text", size: "small" } },
                              [_vm._v("替换VIP名单")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.pageSize,
              "current-page": _vm.tableFrom.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "合成详情", visible: _vm.visible, width: "800px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "mt20",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dialogTable, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "ruleSetName",
                  label: "玩法名称",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "消耗材料/条件/已消耗数量",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.conditionsList,
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  item.materialName +
                                    "/" +
                                    (item.conditionsNum
                                      ? item.conditionsNum
                                      : 0) +
                                    "/" +
                                    (item.alreadyConsumeNum
                                      ? item.alreadyConsumeNum
                                      : 0)
                                )
                              ),
                            ]),
                          ])
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "已合成对象数量详情",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.contentsList, function (y, i) {
                        return _c("div", { key: i }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                y.composeType == 1 ? "【作品】" : "【盲盒】"
                              ) +
                                "-" +
                                _vm._s(y.composeTypeName) +
                                "-已合成" +
                                _vm._s(y.alreadyOpenNum) +
                                "份"
                            ),
                          ]),
                        ])
                      })
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
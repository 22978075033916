"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderMemberApi = OrderMemberApi;
exports.UpdateOrderApi = UpdateOrderApi;
exports.cancelScanProductApi = cancelScanProductApi;
exports.createLockOrderv2Api = createLockOrderv2Api;
exports.createScanProductApi = createScanProductApi;
exports.deleteLockOrderv2Api = deleteLockOrderv2Api;
exports.deleteOrderApi = deleteOrderApi;
exports.deleteScanProductApi = deleteScanProductApi;
exports.getLockOrderv2ListApi = getLockOrderv2ListApi;
exports.getScanConfigApi = getScanConfigApi;
exports.getScanProductApi = getScanProductApi;
exports.listApi = listApi;
exports.orderApi = orderApi;
exports.setScanConfigApi = setScanConfigApi;
exports.setlockOrderWhiteListApi = setlockOrderWhiteListApi;
exports.startApi = startApi;
exports.updateScanProductApi = updateScanProductApi;
var _request = _interopRequireDefault(require("./request"));
function OrderMemberApi(id) {
  return _request.default.post("member/sys/member/info/isLockOrderMember/".concat(id));
}
function listApi(data) {
  return _request.default.get("market/sys/lock/order/list", data);
}
function orderApi(data) {
  return _request.default.post("market/sys/lock/order", data);
}
function UpdateOrderApi(data) {
  return _request.default.put("market/sys/lock/order", data);
}
function deleteOrderApi(id) {
  return _request.default.delete("market/sys/lock/order/".concat(id));
}
function startApi(data) {
  return _request.default.post("market/sys/lock/order/start", data);
}

// 获取扫描寄售藏品列表
function getScanProductApi(data) {
  return _request.default.get("/market/sys/scan/product", data);
}
// 添加扫描寄售藏品
function createScanProductApi(data) {
  return _request.default.post("/market/sys/scan/product", data);
}
// 修改扫描寄售藏品
function updateScanProductApi(data) {
  return _request.default.put("/market/sys/scan/product", data);
}
// 下架扫描寄售商品列表
function cancelScanProductApi(id) {
  return _request.default.post("market/sys/scan/product/cancelMarket/".concat(id));
}
// 删除扫描寄售藏品
function deleteScanProductApi(id) {
  return _request.default.delete("/market/sys/scan/product/".concat(id));
}

// 修改扫描寄售设置
function setScanConfigApi(data) {
  return _request.default.post("/market/sys/scan/config", data);
}
// 添加扫描寄售藏品
function getScanConfigApi(data) {
  return _request.default.get("/market/sys/scan/config", data);
}

// 添加锁定订单
function createLockOrderv2Api(data) {
  return _request.default.post("/market/sys/lock/orderv2", data);
}
// 添加锁定订单
// export function updateLockOrderv2Api(data) {
//   return request.put(`/market/sys/lock/orderv2`, data);
// }
// 分页显示锁定订单
function getLockOrderv2ListApi(data) {
  return _request.default.get("/market/sys/lock/orderv2/list", data);
}
// 删除锁定订单
function deleteLockOrderv2Api(id) {
  return _request.default.delete("/market/sys/lock/orderv2/".concat(id));
}
// 锁定名单白名单
function setlockOrderWhiteListApi(data) {
  return _request.default.post("/market/sys/lock/order/setlockOrderWhiteList", data);
}
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _automaticOrder = require("@/api/automaticOrder");
var _timing = require("@/api/timing");
var _marketing = require("@/api/marketing");
var _elementUi = require("element-ui");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "TimingOrder",
  data: function data() {
    return {
      selectUser: {},
      curStatus: {},
      name: "",
      listTableData: {
        data: [],
        total: 0
      },
      listPageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      listLoading: "",
      userDialog: false,
      userPageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      userTableData: {
        data: [],
        total: 0
      },
      userName: "",
      userLoading: false,
      addCollectionDialog: false,
      marketProducts: [],
      collection: [],
      collectionDialog: false,
      collectionName: "",
      collectionLoading: false,
      collectionConfig: {
        pageNum: 1,
        pageSize: 10
      },
      collectionData: {
        data: [],
        total: 0
      },
      searchPages: {
        pageNum: 1,
        pageSize: 10
      },
      searchLoading: false,
      snapshotOptions: [],
      lockordermember: '',
      snapshotOptionsPages: {
        pageNum: 1,
        pageSize: 500
      },
      downPrice: '',
      upPrice: '',
      consignVisible: false,
      formData: {
        marketPriceType: 1
      },
      lockOrderForm: {}
    };
  },
  created: function created() {
    this.loadSnapshot();
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    editOneDialog: function editOneDialog(row) {
      this.downPrice = '';
      this.upPrice = '';
      this.formData = {
        marketPriceType: 1,
        marketDownPrice: '',
        marketUpPrice: ''
      };
      Object.assign(this.formData, (0, _objectSpread2.default)({}, row));
      if (row.marketPriceType == 1) {
        this.formData.marketDownPrice = row.marketDownPrice ? Number(row.marketDownPrice) : '';
        this.formData.marketUpPrice = '';
      }
      if (row.marketPriceType == 2) {
        this.formData.marketUpPrice = row.marketUpPrice ? Number(row.marketUpPrice) : '';
        this.formData.marketDownPrice = '';
      }
      if (row.marketPriceType == 3) {
        this.downPrice = Number(row.marketDownPrice);
        this.upPrice = Number(row.marketUpPrice);
        this.formData.marketDownPrice = '';
        this.formData.marketUpPrice = '';
      }

      // console.log(this.formData);
      this.consignVisible = true;
    },
    addLockUser: function addLockUser() {
      if (this.selectUser.id) {
        // console.log(this.selectUser,this.lockOrderForm);
        this.lockOrderForm.lockOrderMemberId = this.selectUser.id;
        this.lockOrderForm.lockOrderMember = this.selectUser.userPhone;
        this.onUpdate(this.lockOrderForm);
      }
    },
    onSwitchChange: function onSwitchChange(e, row) {
      // console.log(row);
      row.hasWhiteList = row.hasWhiteList == true ? 1 : 0;
      this.onUpdate(row);
    },
    onWhiteListChange: function onWhiteListChange(e, row, type) {
      if (!e || type == '1') this.onUpdate(row);
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this.formData.marketPriceType == 3) {
            _this.formData.marketDownPrice = Number(_this.downPrice);
            _this.formData.marketUpPrice = Number(_this.upPrice);
          }
          _this.onUpdate(_this.formData);
        }
      });
    },
    onUpdate: function onUpdate(params) {
      var _this2 = this;
      // console.log(params);
      params.lockOrderWhiteList = !params.lockOrderWhiteList || params.lockOrderWhiteList.length == 0 ? '' : params.lockOrderWhiteList.join(',');
      (0, _timing.UpdateOrderApi)(params).then(function (res) {
        if (res.code === 200) {
          _this2.consignVisible = false;
          _this2.closeDialog("userDialog");
          _this2.getList();
        }
      });
    },
    onHandStatus: function onHandStatus(row) {
      var _this3 = this;
      var model = (0, _objectSpread2.default)({}, row);
      model.lockOrderWhiteList = model.lockOrderWhiteList.length == 0 ? '' : model.lockOrderWhiteList.join(',');
      (0, _timing.UpdateOrderApi)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, model), {}, {
        status: model.status == 1 ? 0 : 1
      })).then(function (res) {
        // console.log(res);
        if (res.code === 200) {
          _this3.getList();
        }
      });
    },
    handleClose: function handleClose(formEl) {
      this.$refs[formEl].resetFields();
    },
    handle: function handle() {
      var _this4 = this;
      var loadingInstance = _elementUi.Loading.service();
      (0, _timing.startApi)().then(function (res) {
        _this4.getList();
        _this4.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this4.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    addAccount: function addAccount(row) {
      this.lockOrderForm = row;
      this.getUserList(1);
    },
    closeDialog: function closeDialog(ele, needApi) {
      console.log(ele);
      if (ele) {
        this[ele] = false;
        this.userName = "";
        this.userPageConfig.pageNum = 1;
        this.collectionConfig.pageNum = 1;
        this.selectUser = {};
      }
      if (ele === "addCollectionDialog") {
        this.marketProducts = [];
      }
      this.collectionName = "";
      if (needApi) {
        this.getList(1);
      }
    },
    getList: function getList(page) {
      var _this5 = this;
      var data = {
        name: this.name,
        pageNum: page ? page : this.listPageConfig.pageNum,
        pageSize: this.listPageConfig.pageSize
      };
      this.listLoading = true;
      (0, _timing.listApi)(data).then(function (res) {
        res.rows.map(function (item) {
          if (item.lockOrderWhiteList) item.lockOrderWhiteList = item.lockOrderWhiteList.split(',');
        });
        _this5.listTableData.total = res.total;
        _this5.listTableData.data = res.rows;
        _this5.curStatus = res.statistical || {};
        // if (this.curStatus.lockOrderWhiteList) {
        //   this.curStatus.lockOrderWhiteList = this.curStatus.lockOrderWhiteList.split(',');
        // }
        _this5.listLoading = false;
      });
    },
    getSnapshot: function getSnapshot(reset) {
      var _this6 = this;
      if (reset) {
        this.searchPages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)({}, this.searchPages);
      (0, _product.snapshotListApi)(params).then(function (res) {
        _this6.snapshotOptions = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    },
    deleteOrder: function deleteOrder(id) {
      var _this7 = this;
      this.$confirm("确认删除吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var loadingInstance = _elementUi.Loading.service();
        (0, _timing.deleteOrderApi)(id).then(function (res) {
          _this7.$message.success("删除成功");
          _this7.closeDialog();
          _this7.getList();
          _this7.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this7.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }).catch(function () {});
    },
    // setlockOrderWhiteList(list) {
    //   const lockOrderWhiteList = list.length > 0 ? list.join(',') : '';
    //   setlockOrderWhiteListApi({ lockOrderWhiteList }).then(res => {
    //     if (res.code === 200) {
    //       this.$message.success(res.msg);
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   }).catch(err => {
    //     this.$message.error(err.message);
    //   });
    // },
    getUserList: function getUserList(page) {
      var _this8 = this;
      var data = {
        keyword: this.userName,
        pageNum: page ? page : this.userPageConfig.pageNum,
        pageSize: this.userPageConfig.pageSize
      };
      this.userLoading = true;
      (0, _automaticOrder.listByClearApi)(data).then(function (res) {
        _this8.userLoading = false;
        _this8.userTableData.total = res.total;
        _this8.userTableData.data = res.rows;
        if (!_this8.userDialog) {
          _this8.userDialog = true;
        }
      });
    },
    loadSnapshot: function loadSnapshot(reset) {
      var _this9 = this;
      if (reset) {
        this.searchPages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)({}, this.snapshotOptionsPages);
      (0, _product.snapshotListApi)(params).then(function (res) {
        _this9.snapshotOptions = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPageConfig.pageSize = val;
      this.getList();
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.userPageConfig.pageSize = val;
      this.getUserList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.userTable.clearSelection();
        this.$refs.userTable.toggleRowSelection(val.pop());
        return;
      }
      if (val[0]) {
        this.selectUser = val[0];
      } else {
        this.selectUser = {};
      }
    },
    addUser: function addUser() {
      var _this10 = this;
      if (this.selectUser.id) {
        var loadingInstance = _elementUi.Loading.service();
        (0, _timing.OrderMemberApi)(this.selectUser.id).then(function (res) {
          _this10.$message.success("添加成功");
          _this10.curStatus.lockordermember = _this10.selectUser.userPhone;
          _this10.closeDialog("userDialog");
          _this10.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this10.$nextTick(function () {
            loadingInstance.close();
          });
        });
      } else {
        this.$message.error("请选择用户");
      }
    },
    getCollectionList: function getCollectionList(page) {
      var _this11 = this;
      var data = {
        type: 1,
        // status: true,
        name: this.collectionName,
        pageNum: page || this.collectionConfig.pageNum,
        pageSize: this.collectionConfig.pageSize
      };
      this.collectionLoading = true;
      (0, _marketing.marketProductListApi)(data).then(function (res) {
        _this11.collectionLoading = false;
        if (!_this11.collectionDialog) {
          _this11.collectionDialog = true;
        }
        _this11.collectionData.data = res.rows;
        _this11.collectionData.total = res.total;
      });
    },
    showCollection: function showCollection() {
      this.collection = (0, _toConsumableArray2.default)(this.marketProducts);
      this.getCollectionList(1);
    },
    addCollection: function addCollection() {
      var _this12 = this;
      this.collection.forEach(function (item) {
        _this12.marketProducts.push((0, _objectSpread2.default)({
          maxPrice: 0
        }, item));
      });
      this.closeDialog("collectionDialog");
    },
    selectCollection: function selectCollection(item, type) {
      if (type) {
        this.collection.push(item);
      } else {
        var idx = this.collection.findIndex(function (i) {
          return i.id === item.id;
        });
        this.collection.splice(idx, 1);
      }
    },
    deleteCollection: function deleteCollection(_ref) {
      var id = _ref.id;
      this.marketProducts = this.marketProducts.filter(function (item) {
        return item.id != id;
      });
    },
    batchSubmit: function batchSubmit() {
      var _this13 = this;
      var marketProducts = [];
      this.marketProducts.forEach(function (item) {
        marketProducts.push({
          maxPrice: item.maxPrice,
          productId: item.productId,
          productName: item.name,
          image: item.image
        });
      });
      (0, _timing.orderApi)(marketProducts).then(function (res) {
        _this13.$message.success("提交成功");
        _this13.closeDialog("addCollectionDialog");
        _this13.getList(1);
      });
      console.log(marketProducts);
    }
  }
};
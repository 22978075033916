"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var automaticOrderPlanBRouter = {
  path: "".concat(_settings.roterPre, "/automaticOrderV2"),
  name: "automaticOrderPlanB",
  meta: {
    icon: "",
    title: "自动下单"
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: "index",
    name: "Index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/automaticOrderPlanB/index"));
      });
    },
    children: [{
      path: "accountManage",
      name: "UserManagePlanB",
      meta: {
        title: "B账户管理"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/automaticOrderPlanB/accountManage"));
        });
      }
    }, {
      path: "sweepGoods",
      name: "BatchScan",
      meta: {
        title: "B批量扫货"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/automaticOrderPlanB/sweepGoods"));
        });
      }
    }, {
      path: "orderList",
      name: "PurchasedCollection",
      meta: {
        title: "B已购藏品"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/automaticOrderPlanB/orderList"));
        });
      }
    }]
  }]
};
var _default = exports.default = automaticOrderPlanBRouter;
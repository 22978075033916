"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    colour: _colour.default
  },
  props: {
    form: {
      type: Object,
      default: function _default() {}
    },
    initForm: {
      type: Object,
      default: function _default() {}
    },
    timeType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      formData: {},
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      currentTime: '',
      first: true,
      formRules: {
        name: [{
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请选择活动封面图',
          trigger: 'blur'
        }],
        activities: [{
          required: true,
          message: '请输入活动规则',
          trigger: 'blur'
        }],
        backgroundImage: [{
          required: true,
          message: '请选择页面背景图',
          trigger: 'blur'
        }],
        backGroundColor: [{
          required: true,
          message: '请选择版块背景',
          trigger: 'blur'
        }],
        titleMasterColor: [{
          required: true,
          message: '请选择版块主文字',
          trigger: 'blur'
        }],
        titleAssistantColor: [{
          required: true,
          message: '请选择版块副文本',
          trigger: 'blur'
        }],
        timingBackgroundColor: [{
          required: true,
          message: '请选择计时背景',
          trigger: 'blur'
        }],
        timingTitleColor: [{
          required: true,
          message: '请选择计时文字',
          trigger: 'blur'
        }],
        cardRadius: [{
          required: true,
          message: '请输入版块圆角',
          trigger: 'blur'
        }],
        buttonImage: [{
          required: true,
          message: '请选择按钮背景图',
          trigger: 'blur'
        }],
        buttonTitleColor: [{
          required: true,
          message: '请选择按钮文字',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    form: {
      handler: function handler(val) {
        var _this2 = this;
        Object.keys(val).forEach(function (e) {
          _this2.$set(_this2.formData, e, val[e]);
        });
      },
      deep: true,
      immediate: true
    },
    formData: {
      handler: function handler(val) {
        this.$emit('change', val);
      },
      deep: true
    },
    'formData.startTime': function formDataStartTime(val) {
      if (this.timeType === 'daterange') {
        this.currentTime = [this.formData.startTime, this.formData.endTime];
      }
    },
    'formData.endTime': function formDataEndTime() {
      if (this.timeType === 'daterange') {
        this.currentTime = [this.formData.startTime, this.formData.endTime];
      }
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.$emit('submit', _this3.formData);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, key) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.$set(_this.formData, key, img[0]);
      }, tit);
    },
    handleChangeTime: function handleChangeTime(val) {
      if (val[0]) {
        this.formData.startTime = (0, _index.parseTime)(val[0]);
      }
      if (val[1]) {
        this.formData.endTime = (0, _index.parseTime)(val[1]);
      }
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _index = require("@/utils/index");
var _user = _interopRequireDefault(require("./user"));
var _holder = _interopRequireDefault(require("./holder"));
var _product = require("@/api/product");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { } from '@/api/wantBuy';
var _default = exports.default = {
  components: {
    User: _user.default,
    Holder: _holder.default,
    Nft: _nft.default
  },
  data: function data() {
    return {
      id: '',
      tableCli: -1,
      types: "works",
      listLoading: false,
      isHasWantBuy: false,
      isHasAutoWantBuy: false,
      currentInfo: {},
      wantBuyLowPrice: "",
      wantBuyHighPrice: "",
      autoWantBuyLowPrice: "",
      autoWantBuyHighPrice: "",
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        status: null,
        type: 1,
        starLevel: ""
      },
      categoryList: [],
      fileTypeList: [{
        id: 1,
        label: "图片"
      }, {
        id: 2,
        label: "3D"
      }, {
        id: 3,
        label: "视频"
      }, {
        id: 4,
        label: "音频"
      }],
      snapshotList: [],
      visibleBannedStatus: false,
      bannedStatus: {
        productId: "",
        banned: ""
      },
      //设置禁售弹出框内禁售编号
      visibleUser: false,
      productId: null,
      visibleHolder: false,
      typeList: [],
      buySettingData: {
        show: false,
        btnLoading: false,
        marketProductId: '',
        type: '',
        isOpen: false,
        data: [{
          day: '',
          openPoint: false,
          openProduct: false,
          scoreTypeId: '',
          pointNum: '',
          product: []
        }]
      },
      showChangeIndex: 0,
      currData: []
    };
  },
  created: function created() {
    var _this = this;
    this.getList(1);
    this.getType();
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this.categoryList = res.data;
    });
    (0, _marketing.marketGetSnapshotApi)().then(function (res) {
      _this.snapshotList = res.data;
    });
  },
  filters: {
    formatStatus: function formatStatus(val) {
      var res = "";
      switch (val) {
        case true:
          res = "已开启";
          break;
        case false:
          res = "未开启";
          break;
        default:
          break;
      }
      return res;
    }
  },
  methods: {
    removeProduct: function removeProduct(i, index) {
      this.buySettingData.data[i].product.splice(index, 1);
    },
    onSubmitSetting: function onSubmitSetting() {
      var _this2 = this;
      var saveData = {};
      if (this.buySettingData.isOpen) {
        try {
          this.buySettingData.data.forEach(function (m) {
            if (!m.day) {
              throw new TypeError('请设置套餐有效天数');
            } else if (m.openPoint && (!m.scoreTypeId || !m.pointNum)) {
              throw new TypeError('请完整设置积分类型与数量');
            } else if (m.openProduct && (m.product.length === 0 || m.product.filter(function (item) {
              return !item.count || isNaN(item.count);
            }).length > 0)) {
              throw new TypeError('请完整设置藏品数量');
            }
          });
        } catch (e) {
          return this.$message.error(e.message);
        }
        saveData = Object.assign({}, this.buySettingData);
      } else {
        saveData = {
          marketProductId: this.buySettingData.marketProductId,
          type: this.buySettingData.type,
          isOpen: false
        };
      }
      this.buySettingData.btnLoading = true;
      (0, _marketing.addConsumptionApi)(saveData).then(function () {
        _this2.buySettingData.btnLoading = false;
        _this2.buySettingData.show = false;
        _this2.getList("");
        _this2.$message.success('提交成功');
      }).catch(function () {
        _this2.buySettingData.btnLoading = false;
      });
    },
    addDataAction: function addDataAction() {
      this.buySettingData.data.push({
        day: '',
        openPoint: false,
        openProduct: false,
        scoreTypeId: '',
        pointNum: '',
        product: []
      });
    },
    removeDataAction: function removeDataAction(i) {
      this.buySettingData.data.splice(i, 1);
    },
    showChangeProduct: function showChangeProduct(i) {
      var _this3 = this;
      this.showChangeIndex = i;
      this.currData = this.buySettingData.data[i].product;
      this.$nextTick(function () {
        _this3.$refs.nft.changeshow();
      });
    },
    selectMessage: function selectMessage(e) {
      this.buySettingData.data[this.showChangeIndex].product = e.data.map(function (m) {
        return {
          name: m.name,
          id: m.id,
          productId: m.productId,
          count: m.count || 1,
          image: m.image
        };
      });
    },
    setBuySetting: function setBuySetting(id, type) {
      var _this4 = this;
      (0, _marketing.consumptionApi)(id, type).then(function (res) {
        if (!res.data.isOpen) {
          _this4.buySettingData = {
            show: true,
            btnLoading: false,
            marketProductId: id,
            type: type,
            isOpen: false,
            data: [{
              day: '',
              openPoint: false,
              openProduct: false,
              scoreTypeId: '',
              pointNum: '',
              product: []
            }]
          };
        } else {
          _this4.buySettingData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), {}, {
            show: true,
            btnLoading: false
          });
        }
      }).catch(function () {
        _this4.buySettingData = {
          show: true,
          btnLoading: false,
          marketProductId: id,
          type: type,
          isOpen: false,
          data: [{
            day: '',
            openPoint: false,
            openProduct: false,
            scoreTypeId: '',
            pointNum: '',
            product: []
          }]
        };
      });
      // this.buySettingData.type = type;
      // this.buySettingData.show = true;
    },
    getType: function getType() {
      var _this5 = this;
      (0, _marketing.integralTypeListApi)().then(function (res) {
        _this5.typeList = res.data;
      }).catch(function (res) {
        _this5.$message.error(res.message);
      });
    },
    handleClick: function handleClick(row) {
      this.tableCli = row.id;
    },
    updateUserCount: function updateUserCount(row) {
      var _this6 = this;
      (0, _marketing.changeRecommendOrCommisionApi)({
        id: row.id,
        artificialCount: row.artificialCount ? row.artificialCount : null
      }).then(function (res) {
        _this6.tableCli = -1;
        _this6.getList(1);
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "works") {
        this.$router.push("/merchant/order/consignmentOrder/works");
        return false;
      } else if (tab.name === "album") {
        this.$router.push("/merchant/order/consignmentOrder/album");
        return false;
      } else if (tab.name === "blind") {
        this.$router.push("/merchant/order/consignmentOrder/blind");
        return false;
      }
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.setTypeAction();
      (0, _marketing.marketProductListApi)(this.queryParams).then(function (res) {
        res.rows.forEach(function (item) {
          if (item.marketProductOpens) {
            var newarr = [];
            item.marketProductOpens.forEach(function (it) {
              if (it.hasOpenMarket) {
                newarr.push(it.getType);
              }
            });
            item.marketSelectType = newarr;
          } else {
            item.marketSelectType = [];
          }
          item.pirceLimits.forEach(function (m) {
            if (!m.getType) {
              m.getType = "";
            }
          });
        });
        _this7.tableData.data = res.rows;
        _this7.tableData.total = res.total;
        _this7.tableData.statistical = res.statistical;
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    chargeDeleteAction: function chargeDeleteAction(val, index, rowIndex) {
      var _this8 = this;
      if (val.id) {
        (0, _marketing.marketChargeRemoveApi)(val.id).then(function (res) {
          _this8.getList("");
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      } else {
        this.tableData.data[rowIndex].charges.splice(index, 1);
      }
    },
    chargeRateAction: function chargeRateAction(val) {
      var obj = {
        id: val.id,
        productId: val.productId,
        snapshotId: null,
        chargeRate: null,
        type: null
      };
      if (val.type == 0) {
        obj.chargeRate = Number(val.chargeRate);
        obj.type = val.type;
      } else {
        obj.snapshotId = val.snapshotId;
        obj.chargeRate = Number(val.chargeRate);
        obj.type = val.type;
      }
      if (obj.chargeRate < 0 || obj.chargeRate > 100) {
        this.$message.error("请输入0~100的数值");
        return false;
      }
      if (val.id) {
        this.marketChargeUpdateApiMethod(obj);
      } else {
        this.marketChargeSaveApiMethod(obj);
      }
    },
    marketChargeUpdateApiMethod: function marketChargeUpdateApiMethod(obj) {
      var _this9 = this;
      (0, _marketing.marketChargeUpdateApi)(obj.id, obj).then(function (res) {
        _this9.getList("");
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    marketChargeSaveApiMethod: function marketChargeSaveApiMethod(obj) {
      var _this10 = this;
      (0, _marketing.marketChargeSaveApi)(obj).then(function (res) {
        _this10.getList("");
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    addChargeRate: function addChargeRate(index, row) {
      var obj = {
        id: null,
        productId: row.productId,
        snapshotId: null,
        chargeRate: null,
        type: 1
      };
      this.tableData.data[index].charges.push(obj);
      // console.log('this.tableData.data', this.tableData.data)
    },
    limitsAction: function limitsAction(val) {
      if (Number(val.highestLimit) && Number(val.lowestLimit) > Number(val.highestLimit)) {
        this.$message({
          type: "warning",
          message: "限价输入不符合规则"
        });
        return;
      }
      var obj = {
        id: val.id,
        productId: val.productId,
        startDate: val.startDate,
        getType: val.getType,
        lowestLimit: Number(val.lowestLimit),
        highestLimit: Number(val.highestLimit)
      };
      if (obj.id) {
        this.marketPirceLimitUpdateApiMethod(obj);
      } else {
        this.marketPirceLimitSaveApiMethod(obj);
      }
    },
    marketPirceLimitUpdateApiMethod: function marketPirceLimitUpdateApiMethod(obj) {
      var _this11 = this;
      (0, _marketing.marketPirceLimitUpdateApi)(obj.id, obj).then(function (res) {
        _this11.getList("");
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    marketPirceLimitSaveApiMethod: function marketPirceLimitSaveApiMethod(obj) {
      var _this12 = this;
      (0, _marketing.marketPirceLimitSaveApi)(obj).then(function (res) {
        _this12.getList("");
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    addPriceLimits: function addPriceLimits(index, row) {
      var obj = {
        id: null,
        productId: row.productId,
        startDate: null,
        lowestLimit: null,
        highestLimit: null,
        getType: ""
      };
      this.tableData.data[index].pirceLimits.push(obj);
    },
    statusAction: function statusAction(row) {
      var _this13 = this;
      (0, _marketing.marketProductIsOpenApi)(row.productId, {
        isOpen: !row.status
      }).then(function (res) {
        _this13.$message.success("操作成功");
        _this13.getList("");
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    downAction: function downAction(row) {
      var _this14 = this;
      this.$confirm("确定下架所有藏品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _marketing.marketProductTakeApi)(row.productId).then(function (res) {
          _this14.$message.success("操作成功");
          _this14.getList("");
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      }).catch(function () {});
    },
    setAction: function setAction(row) {
      this.visibleBannedStatus = true;
      this.bannedStatus.productId = row.productId;
    },
    confirmBannedStatusAction: function confirmBannedStatusAction() {
      var _this15 = this;
      (0, _marketing.marketProductBannedApi)(this.bannedStatus.productId, {
        banned: this.bannedStatus.banned
      }).then(function (res) {
        _this15.$message.success("操作成功");
        _this15.bannedStatus.productId = "";
        _this15.bannedStatus.banned = "";
        _this15.visibleBannedStatus = false;
        _this15.getList("");
      }).catch(function (res) {
        _this15.visibleBannedStatus = false;
        // this.$message.error(res.message);
      });
    },
    limitsDeleteAction: function limitsDeleteAction(val, index, rowIndex) {
      var _this16 = this;
      if (val.id) {
        (0, _marketing.marketLimitRemoveApi)(val.id).then(function (res) {
          _this16.getList(1);
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      } else {
        this.tableData.data[rowIndex].pirceLimits.splice(index, 1);
      }
    },
    changeListTypeAction: function changeListTypeAction(e) {
      var typearr = [1, 2, 3, 4, 5, 6, 7, 8];
      var marketselectArr = JSON.parse(JSON.stringify(e.marketSelectType));
      var marketArrJson = JSON.parse(JSON.stringify(e.marketProductOpens));
      var marketchangeJSON = {};
      var that = this;
      marketArrJson.forEach(function (item) {
        marketchangeJSON[item.getType] = item;
      });
      typearr.forEach(function (item) {
        if (marketchangeJSON[item] && marketchangeJSON[item].hasOpenMarket && marketselectArr.indexOf(item) == -1) {
          (0, _marketing.changeopneMarketTypeApi)(e.id, {
            type: item,
            isOpen: false
          }).then(function () {
            that.getList();
          });
        } else if (marketchangeJSON[item] && !marketchangeJSON[item].hasOpenMarket && marketselectArr.indexOf(item) > -1) {
          (0, _marketing.changeopneMarketTypeApi)(e.id, {
            type: item,
            isOpen: true
          }).then(function () {
            that.getList();
          });
        } else if (!marketchangeJSON[item] && marketselectArr.indexOf(item) > -1) {
          (0, _marketing.changeopneMarketTypeApi)(e.id, {
            type: item,
            isOpen: true
          }).then(function () {
            that.getList();
          });
        }
      });
      // changeopneMarketTypeApi
    },
    onCloseHasWantBuy: function onCloseHasWantBuy() {
      this.isHasWantBuy = false;
      this.currentInfo = {};
      this.wantBuyLowPrice = "";
      this.wantBuyHighPrice = "";
    },
    onCloseHasAutoWantBuy: function onCloseHasAutoWantBuy() {
      this.isHasAutoWantBuy = false;
      this.autoWantBuyLowPrice = "";
      this.autoWantBuyHighPrice = "";
    },
    onChangeHasWantbuy: function onChangeHasWantbuy(row) {
      if (!row.hasWantBuy) return;
      this.isHasWantBuy = true;
      this.wantBuyLowPrice = row.wantBuyLowPrice || "";
      this.wantBuyHighPrice = row.wantBuyHighPrice || "";
      this.currentInfo = (0, _index.deepClone)(row);
    },
    onHasWantbuy: function onHasWantbuy(row) {
      var _this17 = this;
      if (row.hasWantBuy) {
        this.isHasWantBuy = true;
        row.hasWantBuy = false;
        this.wantBuyLowPrice = row.wantBuyLowPrice || "";
        this.wantBuyHighPrice = row.wantBuyHighPrice || "";
        this.currentInfo = (0, _index.deepClone)(row);
      } else {
        var loadingInstance = _elementUi.Loading.service();
        (0, _marketing.changeRecommendOrCommisionApi)({
          id: row.id,
          // productId: row.productId,
          // hasRecommend: row.hasRecommend,
          // recommendSort: row.recommendSort,
          // commissionRatio: row.commissionRatio,
          // artificialCount: row.artificialCount,
          // starLevel: row.starLevel,
          hasWantBuy: row.hasWantBuy
        }).then(function (res) {
          _this17.getList();
          _this17.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this17.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }
    },
    onHasAutoWantbuy: function onHasAutoWantbuy(row) {
      var _this18 = this;
      if (row.hasAutoWantBuy) {
        this.id = row.id, this.isHasAutoWantBuy = true;
        row.hasAutoWantBuy = false;
        this.autoWantBuyLowPrice = row.autoWantBuyLowPrice || "";
        this.autoWantBuyHighPrice = row.autoWantBuyHighPrice || "";
      } else {
        var loadingInstance = _elementUi.Loading.service();
        (0, _marketing.changeRecommendOrCommisionApi)({
          id: row.id,
          hasAutoWantBuy: false
        }).then(function (res) {
          _this18.getList();
          _this18.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (err) {
          _this18.$nextTick(function () {
            loadingInstance.close();
          });
        });
      }
    },
    chargeCommisionRateAction: function chargeCommisionRateAction(val, name) {
      var _this19 = this;
      if (name === "hasWantBuy") {
        if (!this.wantBuyLowPrice) return this.$message.warning("请输入求购最低价");
        if (!this.wantBuyHighPrice) return this.$message.warning("请输入求购最高价");
        if (Number(this.wantBuyLowPrice) >= Number(this.wantBuyHighPrice)) return this.$message.warning("最低价不能大于最高价");
        val.hasWantBuy = true;
        val.wantBuyLowPrice = Number(this.wantBuyLowPrice);
        val.wantBuyHighPrice = Number(this.wantBuyHighPrice);
      }
      var loadingInstance = _elementUi.Loading.service();
      (0, _marketing.changeRecommendOrCommisionApi)({
        id: val.id,
        productId: val.productId,
        hasRecommend: val.hasRecommend,
        recommendSort: val.recommendSort,
        commissionRatio: val.commissionRatio,
        artificialCount: val.artificialCount,
        starLevel: val.starLevel,
        hasWantBuy: val.hasWantBuy,
        wantBuyLowPrice: Number(val.wantBuyLowPrice),
        wantBuyHighPrice: Number(val.wantBuyHighPrice)
      }).then(function (res) {
        _this19.getList();
        _this19.onCloseHasWantBuy();
        _this19.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (err) {
        _this19.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    onSubmit: function onSubmit() {
      var _this20 = this;
      if (!this.autoWantBuyLowPrice) return this.$message.warning("请输入求购最低价");
      if (!this.autoWantBuyHighPrice) return this.$message.warning("请输入求购最高价");
      if (Number(this.autoWantBuyLowPrice) >= Number(this.autoWantBuyHighPrice)) return this.$message.warning("最低价不能大于最高价");
      var loadingInstance = _elementUi.Loading.service();
      (0, _marketing.changeRecommendOrCommisionApi)({
        id: this.id,
        hasAutoWantBuy: true,
        autoWantBuyLowPrice: Number(this.autoWantBuyLowPrice),
        autoWantBuyHighPrice: Number(this.autoWantBuyHighPrice)
      }).then(function (res) {
        _this20.getList();
        _this20.onCloseHasAutoWantBuy();
        _this20.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (err) {
        _this20.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    userAction: function userAction(row) {
      this.productId = row.productId;
      this.visibleUser = true;
    },
    userClose: function userClose() {
      this.visibleUser = false;
    },
    holderAction: function holderAction(row) {
      this.productId = row.productId;
      this.visibleHolder = true;
    },
    holderClose: function holderClose() {
      this.visibleHolder = false;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.userType,
                callback: function ($$v) {
                  _vm.userType = $$v
                },
                expression: "userType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "用户列表", name: "all" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "实名认证", name: "authentication" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "用户标签", name: "label" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注销申请", name: "canel" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注册配置", name: "regiest_set" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "设置", name: "new_user_set" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", "label-width": "80px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.userFrom.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFrom, "status", $$v)
                            },
                            expression: "userFrom.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("认证成功"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("审核中"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "-1" } }, [
                            _vm._v("已拒绝"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200 ml20",
                          attrs: {
                            placeholder: "客户名称/手机号",
                            size: "small",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.userFrom.key,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFrom, "key", $$v)
                            },
                            expression: "userFrom.key",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addAuthAction },
                        },
                        [_vm._v("新增实名认证")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.avatar
                                  ? scope.row.avatar
                                  : _vm.moren,
                                "preview-src-list": [
                                  scope.row.avatar || _vm.moren,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "昵称", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", {
                            domProps: { textContent: _vm._s(row.nickName) },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "实名信息", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(row.name) + " " + _vm._s(row.idCard)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", prop: "phone", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 2
                          ? _c("div", [_vm._v("已认证")])
                          : row.status == 1
                          ? _c("div", [_vm._v("审核中")])
                          : row.status == -1
                          ? _c("div", [_vm._v("已拒绝")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == -1 && row.reason
                          ? _c("div", [
                              _vm._v(
                                "\n            拒绝理由：" +
                                  _vm._s(row.reason) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showRealNameAuthentication(
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("实名审核")]
                            )
                          : scope.row.status == 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAuthAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.userFrom.pageSize,
                  "current-page": _vm.userFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.realNameAuthentication.show,
            title: "审核信息",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.realNameAuthentication, "show", $event)
            },
          },
        },
        [
          _c("div", [
            _vm._v("姓名：" + _vm._s(_vm.realNameAuthentication.name)),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      证件类型："),
            _vm.realNameAuthentication.type == 1
              ? _c("span", [_vm._v("大陆身份证")])
              : _vm.realNameAuthentication.type == 2
              ? _c("span", [_vm._v("香港身份证")])
              : _vm.realNameAuthentication.type == 3
              ? _c("span", [_vm._v("澳门身份证")])
              : _vm.realNameAuthentication.type == 4
              ? _c("span", [_vm._v("台湾身份证")])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("证件号码：" + _vm._s(_vm.realNameAuthentication.idCard)),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("证件照片：")]),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt10" }, [
            _c("div", { staticClass: "l flex-one" }, [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.realNameAuthentication.idCardFacadeUrl },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r flex-one ml20" }, [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.realNameAuthentication.idCardReverseUrl },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt10" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.realNameAuthentication.result,
                    callback: function ($$v) {
                      _vm.$set(_vm.realNameAuthentication, "result", $$v)
                    },
                    expression: "realNameAuthentication.result",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("同意")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("拒绝")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one ml20" },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.realNameAuthentication.result == 2,
                      placeholder: "请输入拒绝理由",
                    },
                    model: {
                      value: _vm.realNameAuthentication.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.realNameAuthentication, "reason", $$v)
                      },
                      expression: "realNameAuthentication.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex align-items-c mt20",
              staticStyle: { "padding-bottom": "20px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveVerifyAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.closeAction } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.authenticationData.show,
            title: "实名认证",
            "close-on-click-modal": false,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.authenticationData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("绑定用户：")]),
              _vm._v(" "),
              _vm.authenticationData.id
                ? _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.authenticationData.nickName) +
                        "\n      "
                    ),
                  ])
                : _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "搜索客户昵称/手机号/真实姓名",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.authenticationData.memberId,
                        callback: function ($$v) {
                          _vm.$set(_vm.authenticationData, "memberId", $$v)
                        },
                        expression: "authenticationData.memberId",
                      },
                    },
                    _vm._l(_vm.userList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.userId,
                          attrs: { value: item.userId, label: item.nickName },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", {
                                attrs: { src: item.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-r flex-one" }, [
                                _c("div", { staticClass: "line1" }, [
                                  _vm._v(_vm._s(item.nickName)),
                                ]),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(item.userPhone))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }, [_vm._v("身份证类型：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.authenticationData.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.authenticationData, "type", $$v)
                      },
                      expression: "authenticationData.type",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("大陆身份证"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("香港身份证"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 3 } }, [
                      _vm._v("澳门身份证"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 4 } }, [
                      _vm._v("台湾身份证"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }, [_vm._v("真实姓名：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c("el-input", {
                  model: {
                    value: _vm.authenticationData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.authenticationData, "name", $$v)
                    },
                    expression: "authenticationData.name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }, [_vm._v("身份证：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c("el-input", {
                  model: {
                    value: _vm.authenticationData.idCard,
                    callback: function ($$v) {
                      _vm.$set(_vm.authenticationData, "idCard", $$v)
                    },
                    expression: "authenticationData.idCard",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.authenticationData.show = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.saveAuthAction },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var cdAuctionRouter = {
  path: "".concat(_settings.roterPre, "/cdAuction"),
  name: 'cdAuction',
  meta: {
    icon: '',
    title: '积分抽奖'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'list',
    name: 'cdAuctionList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cdAuction/list'));
      });
    },
    meta: {
      title: '积分抽奖列表'
    }
  }, {
    path: 'countdown',
    name: 'AuctionCountdownAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cdAuction/countdown'));
      });
    },
    meta: {
      title: '创建倒计时抽奖'
    }
  }, {
    path: 'countdown/:id',
    name: 'AuctionCountdownInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cdAuction/countdown'));
      });
    },
    meta: {
      title: '编辑倒计时抽奖'
    }
  }, {
    path: 'random',
    name: 'AuctionRandomAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cdAuction/random'));
      });
    },
    meta: {
      title: '创建随机抽奖'
    }
  }, {
    path: 'random/:id',
    name: 'AuctionRandomInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cdAuction/random'));
      });
    },
    meta: {
      title: '编辑随机抽奖'
    }
  }]
};
var _default = exports.default = cdAuctionRouter;
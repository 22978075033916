"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var systemFormRouter = {
  path: "".concat(_settings.roterPre, "/systemForm"),
  name: 'system',
  meta: {
    icon: 'dashboard',
    title: '设置'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'Basics/:key?',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemForm/setSystem/index'));
      });
    },
    name: 'Basics',
    meta: {
      title: '店铺配置',
      noCache: true
    }
  }, {
    path: 'modifyStoreInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemForm/setSystem/modifyStoreInfo'));
      });
    },
    name: 'ModifyStoreInfo',
    meta: {
      title: ' 商户基本信息',
      noCache: true
    }
  }, {
    path: 'systemStore',
    name: 'setting_systemStore',
    meta: {
      title: '提货点设置'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemStore/index'));
      });
    }
  }, {
    path: 'systemBank/list',
    name: 'setting_systemBankList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemBank/index'));
      });
    },
    meta: {
      title: '账户列表',
      activeMenu: "".concat(_settings.roterPre, "/systemForm/systemBank/list")
    }
  }, {
    path: 'classify',
    name: 'ProductClassify',
    meta: {
      title: '分类管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/productClassify'));
      });
    }
  }, {
    path: 'tag',
    name: 'ProductTag',
    meta: {
      title: '标签管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemForm/tag/lable'));
      });
    }
  }, {
    path: 'article',
    name: 'article',
    meta: {
      title: '文章管理',
      noCache: true
    },
    children: [{
      path: 'list',
      name: 'article',
      meta: {
        title: '文章管理',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/cms/article/index'));
        });
      }
    }, {
      path: 'cate',
      name: 'articleCategory',
      meta: {
        title: '文章分类',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/cms/article/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/cms/articleCategory/index'));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cms/index'));
      });
    }
  }, {
    path: 'article/addArticle/:id?',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cms/addArticle/index'));
      });
    },
    name: 'EditArticle',
    meta: {
      title: '文章添加',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/cms/article/list")
    },
    hidden: true
  }, {
    path: 'config',
    name: 'Sysconfig',
    meta: {
      title: '通知配置',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/config/index'));
      });
    }
  }, {
    path: 'systemAgreement/list',
    name: 'setting_systemAgreementList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAgreement/list'));
      });
    },
    meta: {
      title: '协议配置',
      activeMenu: "".concat(_settings.roterPre, "/systemForm/systemAgreement/list")
    }
  }, {
    path: 'systemAgreement/detail',
    name: 'setting_systemAgreementDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAgreement/detail'));
      });
    },
    meta: {
      title: '协议详情',
      activeMenu: "".concat(_settings.roterPre, "/systemForm/systemAgreement/list")
    }
  }, {
    path: 'clientSettings',
    name: 'clientSettings',
    meta: {
      title: '客户端管理',
      activeMenu: "".concat(_settings.roterPre, "/systemForm/clientSettings/index")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemForm/clientSettings/index'));
      });
    }
  }]
};
var _default = exports.default = systemFormRouter;
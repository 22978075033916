"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHallfameConfigApi = getHallfameConfigApi;
exports.getHallfameExportApi = getHallfameExportApi;
exports.getHallfameListApi = getHallfameListApi;
exports.updateHallfameConfigApi = updateHallfameConfigApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 修改名人榜设置
 */
function updateHallfameConfigApi(data) {
  return _request.default.post('/activity/sys/hall/fame/config/update', data);
}
/**
 * @description 获取名人榜设置
 */
function getHallfameConfigApi(data) {
  return _request.default.get('/activity/sys/hall/fame/config/info', data);
}
/**
 * @description 获取名人榜列表
 */
function getHallfameListApi(data) {
  return _request.default.get('/activity/sys/hall/fame/list', data);
}
/**
 * @description 导出名人榜设置
 */
function getHallfameExportApi(data) {
  return _request.default.get('/activity/sys/hall/fame/export', data);
}
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _animal = require("@/api/animal");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Produce",
  data: function data() {
    return {
      listData: [],
      addProduceDialog: false,
      ruleForm: {
        image: "",
        name: "",
        commission: 1,
        maxAccount: 1,
        minPrice: 0,
        maxPrice: 0
      },
      rules: {
        name: [{
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }],
        maxAccount: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        commission: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "请输入排序",
          trigger: "blur"
        }],
        minNumber: [{
          required: true,
          message: "请输入最低挂售数量",
          trigger: "blur"
        }],
        image: [{
          required: true,
          message: "请选择内丹图片",
          trigger: "change"
        }]
      }
    };
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.produceListApi)().then(function (res) {
        _this2.listData = res.rows;
        _this2.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this2.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    addProduce: function addProduce() {
      this.addProduceDialog = true;
    },
    editProduce: function editProduce(id) {
      var _this3 = this;
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.produceInfoApi)(id).then(function (res) {
        _this3.ruleForm = res.data;
        _this3.$nextTick(function () {
          loadingInstance.close();
          _this3.addProduceDialog = true;
        });
      });
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.ruleForm.image = img[0];
      }, tit);
    },
    closeDialog: function closeDialog(formName) {
      this.$refs[formName].resetFields();
      this.$data.ruleForm = JSON.parse(JSON.stringify(this.$options.data().ruleForm) //解决关闭弹框 数据没有清空
      );
      this.addProduceDialog = false;
    },
    blurNumber: function blurNumber(e, name) {
      if (!e.target.value) {
        this.ruleForm[name] = 0;
      }
    },
    submitForm: function submitForm() {
      var _this4 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var loadingInstance = _elementUi.Loading.service();
          (0, _animal.produceCreateApi)((0, _objectSpread2.default)({}, _this4.ruleForm)).then(function (res) {
            _this4.$message.success("添加成功");
            _this4.closeDialog("ruleForm");
            _this4.getList();
            _this4.$nextTick(function () {
              loadingInstance.close();
            });
          }).catch(function (_) {
            _this4.$nextTick(function () {
              loadingInstance.close();
            });
          });
        }
      });
    },
    goUser: function goUser() {
      this.$router.push("produceUser");
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      remark: '',
      isReason: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      listLoading: true,
      queryParams: {
        type: '',
        withdrawalStatus: '',
        approvalStatus: '',
        pageNum: 1,
        pageSize: 20
      },
      timeType: [],
      chkName: '',
      checkedIds: [],
      checkedPage: [],
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      timeList: [{
        text: '全部',
        val: ''
      }, {
        text: '今天',
        val: 'today'
      }, {
        text: '昨天',
        val: 'yesterday'
      }, {
        text: '最近7天',
        val: 'lately7'
      }, {
        text: '最近30天',
        val: 'lately30'
      }, {
        text: '本月',
        val: 'month'
      }, {
        text: '本年',
        val: 'year'
      }],
      params: {} // 拒绝穿参
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = '';
      switch (val) {
        case 1:
          res = '作品';
          break;
        case 2:
          res = '盲盒';
          break;
        default:
          break;
      }
      return res;
    },
    formatStatus: function formatStatus(val) {
      var res = '';
      switch (val) {
        case 0:
          res = '退款中';
          break;
        case 1:
          res = '已退款';
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    handleCheckRefuse: function handleCheckRefuse(val, type) {
      var params = {
        id: val.id,
        type: type,
        remark: this.remark
      };
      this.params = params;
      this.isReason = true;
    },
    // 拒绝理由
    sureClick: function sureClick() {
      var _this = this;
      var param = this.params;
      (0, _finance.withdrawalApproval)(param).then(function (res) {
        _this.isReason = false;
        _this.$message({
          type: 'success',
          message: res.msg
        });
      });
    },
    // 提交同意还是拒绝
    handleCheck: function handleCheck(val, type) {
      var _this2 = this;
      var params = {
        id: val.id,
        type: type
      };
      this.$confirm("\u786E\u8BA4\u540C\u610F?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _finance.withdrawalApproval)(params).then(function () {
          _this2.$message.success('操作成功');
          _this2.getList(1);
        }).catch(function (message) {});
      }).catch(function () {});
    },
    exportOrder: function exportOrder() {
      var _this3 = this;
      // alert('暂未开发')
      (0, _finance.getwithdrawal)(this.queryParams).then(function (res) {
        _this3.$message.success('操作成功');
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(27);
    },
    selectChange: function selectChange(tab) {
      this.timeType = [];
      this.queryParams.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.date = e ? this.timeType.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _finance.getWithdrawalList)(this.queryParams).then(function (res) {
        _this4.tableData.data = res.rows;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList('');
    },
    changeOne: function changeOne(v, user) {
      console.log(v, user, 'v, user...');
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    // 选择
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.pageNum);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.queryParams.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this5 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this5.checkedIds.indexOf(id);
          if (index === -1) {
            _this5.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this5.checkedIds.indexOf(id);
          if (index > -1) {
            _this5.checkedIds.splice(index, 1);
          }
        });
      }
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "control",
              { attrs: { title: "数藏顶部", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "展示形式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.showType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "showType", $$v)
                                },
                                expression: "facade.showType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("舞台"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("图片静态展示")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex-one fontW400 color-gray ft12",
                              staticStyle: { margin: "0 12px" },
                            },
                            [_vm._v("开启后，只展示盒子图片，盒子不在冒出藏品")]
                          ),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.facade.imgStatic,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgStatic", $$v)
                              },
                              expression: "facade.imgStatic",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "share"
        ? [
            _c(
              "control",
              { attrs: { title: "分享设置", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("分享图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "share_icon")
                              },
                            },
                          },
                          [
                            _vm.facade.share_icon
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("share_icon")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.share_icon
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.share_icon + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "name"
        ? [
            _c(
              "control",
              { attrs: { title: "盲盒名称", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.facade.showStock
                                    ? "剩余 " + _vm.item.product.stock
                                    : "抢购中"
                                ) + "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "price"
        ? [
            _c(
              "control",
              { attrs: { title: "盲盒价格", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "符号字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.symbol_font_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "symbol_font_size", $$v)
                              },
                              expression: "facade.symbol_font_size",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "stock"
        ? [
            _c(
              "control",
              { attrs: { title: "盲盒库存", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "coupon"
        ? [
            _c(
              "control",
              { attrs: { title: "优惠券", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black mr10" },
                          [_vm._v("优惠券图标")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("couponImg")
                              },
                            },
                          },
                          [
                            _vm.facade.couponImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("couponImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.couponImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.couponImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "领取颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.receiveColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "receiveColor", $$v)
                              },
                              expression: "facade.receiveColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black mr10" },
                          [_vm._v("领取图标")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("receiveImg")
                              },
                            },
                          },
                          [
                            _vm.facade.receiveImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("receiveImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.receiveImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.receiveImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "control",
              { attrs: { title: "商品详情", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.title_image + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 30, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h3" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("我的藏品详情"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("区块链图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "blockchain_image")
                            },
                          },
                        },
                        [
                          _vm.facade.blockchain_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("blockchain_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.blockchain_image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.blockchain_image + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.blockchain_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "blockchain_color", $$v)
                            },
                            expression: "facade.blockchain_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "control",
              { attrs: { title: "盲盒内藏品展示", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: " " } }, [
                      _c("span", { staticClass: "color-gray ft12" }, [
                        _vm._v("${num} 为变量，会自动替换为盲盒下藏品数量"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.title_image + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容距上" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.item_margin,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "item_margin", $$v)
                            },
                            expression: "facade.item_margin",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容底色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.status_background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "status_background", $$v)
                            },
                            expression: "facade.status_background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容背景" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "status_bg_image")
                              },
                            },
                          },
                          [
                            _vm.facade.status_bg_image
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("status_bg_image")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.status_bg_image
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.status_bg_image + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.title_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_color", $$v)
                            },
                            expression: "facade.title_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.title_font_size,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_font_size", $$v)
                            },
                            expression: "facade.title_font_size",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.title_font_weight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "title_font_weight", $$v)
                              },
                              expression: "facade.title_font_weight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标签字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.btn_font_size,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btn_font_size", $$v)
                            },
                            expression: "facade.btn_font_size",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标签圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.btn_radius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btn_radius", $$v)
                            },
                            expression: "facade.btn_radius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "主标签背景" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.main_btn_bg_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "main_btn_bg_color", $$v)
                            },
                            expression: "facade.main_btn_bg_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "主标签文字" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.main_btn_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "main_btn_color", $$v)
                            },
                            expression: "facade.main_btn_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "副标签背景" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.sub_btn_bg_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "sub_btn_bg_color", $$v)
                            },
                            expression: "facade.sub_btn_bg_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "副标签文字" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.sub_btn_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "sub_btn_color", $$v)
                            },
                            expression: "facade.sub_btn_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "notice"
        ? [
            _c(
              "control",
              { attrs: { title: "购买须知", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.title_image + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 30, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "nameArea"
        ? [
            _c(
              "control",
              { attrs: { title: "名称区域", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "名称布局" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.layout,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "layout", $$v)
                              },
                              expression: "facade.layout",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "left" } }, [
                              _vm._v("居左"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "center" } }, [
                              _vm._v("居中"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "上圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.topRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "topRadius", $$v)
                            },
                            expression: "facade.topRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "下圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.bottomRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bottomRadius", $$v)
                            },
                            expression: "facade.bottomRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: -50, max: 300 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距左右距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.marginLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginLeft", $$v)
                            },
                            expression: "facade.marginLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容左距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.paddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingLeft", $$v)
                            },
                            expression: "facade.paddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容下距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.paddingbottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingbottom", $$v)
                            },
                            expression: "facade.paddingbottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("盲盒名称配置"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.nameTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "nameTop", $$v)
                            },
                            expression: "facade.nameTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.nameColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "nameColor", $$v)
                            },
                            expression: "facade.nameColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号大小" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.nameFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "nameFontSize", $$v)
                            },
                            expression: "facade.nameFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.nameFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nameFontWeight", $$v)
                              },
                              expression: "facade.nameFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("藏品标签配置"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.tabTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabTop", $$v)
                            },
                            expression: "facade.tabTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "圆角大小" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.stock_radius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "stock_radius", $$v)
                            },
                            expression: "facade.stock_radius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.tabBgColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabBgColor", $$v)
                            },
                            expression: "facade.tabBgColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景渐变颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.tabBgColorGradient,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabBgColorGradient", $$v)
                            },
                            expression: "facade.tabBgColorGradient",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "边框颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.tabBorder,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabBorder", $$v)
                            },
                            expression: "facade.tabBorder",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "边框渐变颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.tabBorderGradient,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabBorderGradient", $$v)
                            },
                            expression: "facade.tabBorderGradient",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.tabColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabColor", $$v)
                            },
                            expression: "facade.tabColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号大小" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.tabFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabFontSize", $$v)
                            },
                            expression: "facade.tabFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.tabFongWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "tabFongWeight", $$v)
                              },
                              expression: "facade.tabFongWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("寄售市场按钮配置"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "按钮文字" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.btnText,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnText", $$v)
                            },
                            expression: "facade.btnText",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("按钮图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "btnImg")
                            },
                          },
                        },
                        [
                          _vm.facade.btnImg
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("btnImg")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.btnImg
                            ? _c("img", {
                                attrs: { src: _vm.facade.btnImg + "!120a" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.btnTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnTop", $$v)
                            },
                            expression: "facade.btnTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "按钮高度" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.btnHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnHeight", $$v)
                            },
                            expression: "facade.btnHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "按钮圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.btnRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnRadius", $$v)
                            },
                            expression: "facade.btnRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.btnBgColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnBgColor", $$v)
                            },
                            expression: "facade.btnBgColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "边框颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.btnBorder,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnBorder", $$v)
                            },
                            expression: "facade.btnBorder",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.btnColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnColor", $$v)
                            },
                            expression: "facade.btnColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号大小" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.btnFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "btnFontSize", $$v)
                            },
                            expression: "facade.btnFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.btnFongWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "btnFongWeight", $$v)
                              },
                              expression: "facade.btnFongWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("盲盒简介配置"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.descTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descTop", $$v)
                            },
                            expression: "facade.descTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "上下间距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.descLineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descLineHeight", $$v)
                            },
                            expression: "facade.descLineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.descColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descColor", $$v)
                            },
                            expression: "facade.descColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号大小" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.descFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descFontSize", $$v)
                            },
                            expression: "facade.descFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.descFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "descFontWeight", $$v)
                              },
                              expression: "facade.descFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "syntheticConditions"
        ? [
            _c(
              "control",
              { attrs: { title: "藏品列表区域", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.title_image + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("el-form-item", {
                      staticClass: "mt20",
                      attrs: { label: "内容区域" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图片圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.imgRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadius", $$v)
                            },
                            expression: "facade.imgRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "名称上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.nameMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "nameMarginTop", $$v)
                            },
                            expression: "facade.nameMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "名称颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.nameColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "nameColor", $$v)
                            },
                            expression: "facade.nameColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.nameFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "nameFontSize", $$v)
                            },
                            expression: "facade.nameFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.nameFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nameFontWeight", $$v)
                              },
                              expression: "facade.nameFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "条件上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.needMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "needMarginTop", $$v)
                            },
                            expression: "facade.needMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "边框颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.needBorderColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "needBorderColor", $$v)
                            },
                            expression: "facade.needBorderColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.needBgColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "needBgColor", $$v)
                            },
                            expression: "facade.needBgColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "openresault"
        ? [
            _c(
              "control",
              { attrs: { title: "开奖记录", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.title_image + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.titleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleColor", $$v)
                            },
                            expression: "facade.titleColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.titleFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleFontSize", $$v)
                            },
                            expression: "facade.titleFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("el-form-item", {
                      staticClass: "mt20",
                      attrs: { label: "内容区域" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "列表间距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.listMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listMarginTop", $$v)
                            },
                            expression: "facade.listMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "列表标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.listTitleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listTitleColor", $$v)
                            },
                            expression: "facade.listTitleColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "列表标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.listTitleFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listTitleFontSize", $$v)
                            },
                            expression: "facade.listTitleFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: { label: "列表标题上下间距" },
                      },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.listTitleLineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listTitleLineHeight", $$v)
                            },
                            expression: "facade.listTitleLineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "列表时间颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.listTimeColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listTimeColor", $$v)
                            },
                            expression: "facade.listTimeColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "列表时间字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.listTimeFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listTimeFontSize", $$v)
                            },
                            expression: "facade.listTimeFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: { label: "列表时间上下间距" },
                      },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.listTimeLineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listTimeLineHeight", $$v)
                            },
                            expression: "facade.listTimeLineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: { label: "列表右文字颜色" },
                      },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.listRightColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listRightColor", $$v)
                            },
                            expression: "facade.listRightColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: { label: "列表右文字字号" },
                      },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.listRightFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listRightFontSize", $$v)
                            },
                            expression: "facade.listRightFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: { label: "列表右文字上下间距" },
                      },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.listRightLineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listRightLineHeight", $$v)
                            },
                            expression: "facade.listRightLineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _choose = _interopRequireDefault(require("@/components/customPage/choose.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _selectClass = _interopRequireDefault(require("@/components/customChildren/selectClass"));
var _select = _interopRequireDefault(require("@/components/hyperlinkSelect/select.vue"));
var _panels = _interopRequireDefault(require("../../../../libs/panels.js"));
var _modules = _interopRequireDefault(require("../../modules"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    choose: _choose.default,
    colour: _colour.default,
    slider: _slider.default,
    selectView: _selectClass.default,
    hyperlinkSelect: _select.default
  },
  data: function data() {
    return {
      fullHeight: document.documentElement.clientHeight,
      showDataType: false,
      modules: _modules.default,
      panels: _panels.default,
      styleData: [{
        value: 1,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/b2b72c44fca1b113071328fcf9247bf0.png",
        active: true
      }, {
        value: 2,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/9d8a65912aea9ad305ddf8b25c0af19d.png",
        active: true
      }, {
        value: 3,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/a1ed8515c4c1e306187276cf908104ee.png",
        active: true
      }, {
        value: 4,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/f1804c0972137bb5941213a464c2b966.png",
        active: true
      }, {
        value: 5,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/23294ee4a8c84e4938455e8eff74f433.png",
        active: true
      }],
      drawer: false
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: "content",
          value: value
        };
        this.$store.commit("finish/setAttribute", data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: "facade",
          value: value
        };
        this.$store.commit("finish/setAttribute", data);
      }
    },
    scrollHeight: function scrollHeight() {
      return {
        height: parseInt(this.fullHeight) - 70 + "px"
      };
    },
    drawerControl: function drawerControl() {
      return this.drawer && this.content.data[this.content.active].link.name;
    }
  },
  methods: {
    goUp: function goUp(index) {
      if (index === 0) {
        return;
      }
      this.content.data.splice(index - 1, 0, this.content.data[index]);
      this.content.data.splice(index + 1, 1);
    },
    goDown: function goDown(index) {
      if (index === this.content.data - 1) {
        return;
      }
      this.content.data.splice(index + 2, 0, this.content.data[index]);
      this.content.data.splice(index, 1);
    },
    /**
     * 删除元素
     * @return {[type]} [description]
     */
    handleDelete: function handleDelete(index) {
      this.content.data.splice(index, 1);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      this.content.data.push({
        name: "",
        title: "文字",
        link: {
          title: ""
        }
      });
    },
    handleAddCategoryitem: function handleAddCategoryitem() {
      this.$refs.selectView.changeshow();
    },
    mouseOver: function mouseOver(index) {
      this.content.active = index;
    },
    changeActive: function changeActive(index) {
      // this.content.active = index;
      this.drawer = true;
    },
    selectMessage: function selectMessage(data) {
      var _this = this;
      if (data.data && data.data.length > 0) {
        var defaultData = {};
        var name = "";
        if (data.type === "productClass") {
          defaultData = {
            content: {
              style: 1,
              // 风格模板选择 1 单列模式 2 日历模式
              img_style: 2,
              // 显示方式 2 对其-完整图片 3 对其-截取正方形
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 全部 2 可使用积分 3 不可使用积分
              system_sort: 1,
              // 系统自动抓取排序 1 按最新发布排序  2 按销售时间排序
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              background_color: "#1E1E1E",
              page_margin: 30,
              img_radius: 8,
              status_radius: 8,
              item_margin: 30,
              status_background: "#333333",
              status_bg_image: "",
              font_weight: 400,
              title_color: "#ffffff",
              title_font_size: 32,
              title_font_weight: 500,
              stock_color: "#00eeff",
              stock_font_size: 24,
              price_font_size: 48,
              price_color: "#e6cb95",
              img_style: 2,
              status_btn_bg_color: "#000000",
              status_btn_color: "#00eeff",
              status_font_size: 24,
              btn_font_size: 22,
              btn_radius: 2,
              main_btn_bg_color: "#00eeff",
              main_btn_color: "#000000",
              sub_btn_bg_color: "#5f5f5f",
              sub_btn_color: "#ffffff",
              sub_font_size: 24,
              sub_color: "#999999",
              month_color: "#FFFFFF",
              month_font_size: 28,
              month_font_weight: 400,
              time_color: "#999999",
              time_font_size: 24,
              time_font_weight: 400,
              dian_color: "#00eeff",
              xian_color: "#333",
              showStock: false
            }
          };
          name = "material";
        } else if (data.type === "articleClass") {
          defaultData = {
            content: {
              style: 2,
              // 风格模板选择 1 单列模式 2 双列模式 3 三列模式  4 列表模式  5 左右滑动 6 多图模式
              img_style: 2,
              // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 全部 （右侧不修改）
              system_sort: 0,
              // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              background_color: "",
              view_radius: 8,
              // 顶部圆角 多图模式
              img_radius: 8,
              // 图片圆角
              page_padding: 30,
              // 页面边距
              item_padding: 30,
              // 容器边距
              status_background: "#FFFFFF",
              status_high: 60,
              status_radius: 8,
              item_bottom_padding: 30,
              price_color: "#003D9C",
              img_width: 120,
              img_height: 120
            }
          };
          name = "word";
        } else if (data.type === "nftClass") {
          defaultData = {
            content: {
              style: 1,
              // 风格模板选择 1 单列模式 2 日历模式
              img_style: 2,
              // 显示方式 2 对其-完整图片 3 对其-截取正方形
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 全部 2 可使用积分 3 不可使用积分
              system_sort: 1,
              // 系统自动抓取排序 1 按最新发布排序  2 按销售时间排序
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              background_color: "#1E1E1E",
              page_margin: 30,
              img_radius: 8,
              status_radius: 8,
              item_margin: 30,
              status_background: "#333333",
              status_bg_image: "",
              font_weight: 400,
              title_color: "#ffffff",
              title_font_size: 32,
              title_font_weight: 500,
              stock_color: "#00eeff",
              stock_font_size: 24,
              price_font_size: 48,
              price_color: "#e6cb95",
              img_style: 2,
              status_btn_bg_color: "#000000",
              status_btn_color: "#00eeff",
              status_font_size: 24,
              btn_font_size: 22,
              btn_radius: 2,
              main_btn_bg_color: "#00eeff",
              main_btn_color: "#000000",
              sub_btn_bg_color: "#5f5f5f",
              sub_btn_color: "#ffffff",
              sub_font_size: 24,
              sub_color: "#999999",
              month_color: "#FFFFFF",
              month_font_size: 28,
              month_font_weight: 400,
              time_color: "#999999",
              time_font_size: 24,
              time_font_weight: 400,
              dian_color: "#00eeff",
              xian_color: "#333",
              showStock: false
            }
          };
          name = "nftCollection";
        } else if (data.type === "nftBlindClass") {
          defaultData = {
            content: {
              style: 1,
              // 风格模板选择 1 单列模式 2 日历模式
              img_style: 2,
              // 显示方式 2 对其-完整图片 3 对其-截取正方形
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 全部 （右侧不修改）
              system_sort: 1,
              // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              background_color: "#1E1E1E",
              page_margin: 30,
              img_radius: 8,
              status_radius: 8,
              item_margin: 30,
              status_background: "#333333",
              status_bg_image: "",
              font_weight: 400,
              title_color: "#ffffff",
              title_font_size: 32,
              title_font_weight: 500,
              stock_color: "#00eeff",
              stock_font_size: 24,
              price_font_size: 48,
              price_color: "#e6cb95",
              img_style: 2,
              status_btn_bg_color: "#000000",
              status_btn_color: "#00eeff",
              status_font_size: 24,
              btn_font_size: 22,
              btn_radius: 2,
              main_btn_bg_color: "#00eeff",
              main_btn_color: "#000000",
              sub_btn_bg_color: "#5f5f5f",
              sub_btn_color: "#ffffff",
              sub_font_size: 24,
              sub_color: "#999999",
              month_color: "#FFFFFF",
              month_font_size: 28,
              month_font_weight: 400,
              time_color: "#999999",
              time_font_size: 24,
              time_font_weight: 400,
              dian_color: "#00eeff",
              xian_color: "#333",
              showStock: false
            }
          };
          name = "nftBlindBox";
        }
        data.data.forEach(function (m) {
          _this.content.data.push({
            title: m.cate_name,
            link: {
              name: name,
              dataType: "category",
              categoryType: data.type,
              categoryTypeTxt: data.typeTxt,
              category_id: m.store_category_id,
              content: JSON.parse(JSON.stringify(defaultData.content)),
              facade: JSON.parse(JSON.stringify(defaultData.facade))
            }
          });
        });
      }
    },
    setData: function setData(index) {
      this.content.active = index;
      this.showDataType = true;
    },
    changeDataType: function changeDataType(item) {
      this.content.data[this.content.active].link = {
        name: item.name,
        dataType: item.name,
        icon: item.icon,
        title: item.title,
        content: JSON.parse(JSON.stringify(item.content)),
        facade: JSON.parse(JSON.stringify(item.facade))
      };
      this.showDataType = false;
      this.drawer = true;
    }
  }
};
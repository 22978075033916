var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", [_vm._v("通知管理")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.notice,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "场景", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendSms",
                  label: "发送短信",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendSms !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendSms,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendSms", $$v)
                                    },
                                    expression: "scope.row.hasSendSms",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendMessage",
                  label: "站内通知",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendMessage !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendMessage,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendMessage", $$v)
                                    },
                                    expression: "scope.row.hasSendMessage",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendAndroid",
                  label: "Android客户端",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendAndroid !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendAndroid,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendAndroid", $$v)
                                    },
                                    expression: "scope.row.hasSendAndroid",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendIos",
                  label: "iOS客户端",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendIos !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendIos,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendIos", $$v)
                                    },
                                    expression: "scope.row.hasSendIos",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "模板", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.template))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt20" }, [_vm._v("订阅管理")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.subscribe,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "场景", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendSms",
                  label: "发送短信",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendSms !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendSms,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendSms", $$v)
                                    },
                                    expression: "scope.row.hasSendSms",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendMessage",
                  label: "站内通知",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendMessage !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendMessage,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendMessage", $$v)
                                    },
                                    expression: "scope.row.hasSendMessage",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendAndroid",
                  label: "Android客户端",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendAndroid !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendAndroid,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendAndroid", $$v)
                                    },
                                    expression: "scope.row.hasSendAndroid",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasSendIos",
                  label: "iOS客户端",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.hasSendIos !== undefined
                          ? _c(
                              "div",
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.hasSendSmsChange(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasSendIos,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasSendIos", $$v)
                                    },
                                    expression: "scope.row.hasSendIos",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "模板", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.template))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
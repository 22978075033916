"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _user = require("@/api/user");
var _nft = require("@/api/nft");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      activeTab: '1',
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20
      },
      visible: false,
      visibleOne: false,
      searchLoading: false,
      userList: [],
      formState: {
        splitRate: ''
      },
      selectForm: {},
      subForm: {},
      rules: {
        splitMemberId: [{
          required: true,
          message: '请选择用户',
          trigger: 'blur'
        }],
        splitRate: [{
          required: true,
          message: '请输入分账比例',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList(this.queryParams.pageNum);
  },
  methods: {
    onTabChange: function onTabChange() {
      this.getList(1);
    },
    getList: function getList(num) {
      var _this = this;
      console.log(this.activeTab);
      var func = this.activeTab == '1' ? _nft.nftCollectionListApi : _nft.productBoxListApi;
      // return
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      // this.setTypeAction();
      // console.log(this.queryParams);
      // return
      func(this.queryParams).then(function (res) {
        console.log(res);
        _this.$nextTick(function () {
          _this.tableData.data = res.rows;
          _this.tableData.total = res.total;
          _this.listLoading = false;
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    onAdd: function onAdd(row, type) {
      this.formState = {
        id: row.productId,
        splitRate: '',
        type: type,
        oneSplitMemberId: row.splitMemberId,
        oneSplitRate: row.splitRate,
        twoSplitMemberId: row.twoSplitMemberId,
        twoSplitRate: row.twoSplitRate
      };
      this.visible = true;
    },
    onDele: function onDele(row, type) {
      var _this2 = this;
      var model;
      if (type == 1) model = {
        id: row.productId,
        splitRate: null,
        splitMemberId: null,
        twoSplitMemberId: row.twoSplitMemberId,
        twoSplitRate: row.twoSplitRate
      };else model = {
        id: row.productId,
        splitRate: row.splitRate,
        splitMemberId: row.splitMemberId,
        twoSplitMemberId: null,
        twoSplitRate: null
      };
      // return
      (0, _nft.splitUpdateApi)(model).then(function (res) {
        _this2.getList();
        _this2.$message.success('操作成功');
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this3 = this;
      var params = {
        nameOrPhone: data,
        pageNum: 1,
        pageSize: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this3.userList = res.rows;
      });
    },
    onChange: function onChange(e) {
      console.log(e);
      this.selectForm = e;
    },
    onSubmit: function onSubmit(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // console.log('1',this.formState);//
          // return
          var model;
          if (_this4.formState.type == 1) {
            model = {
              // ...this.formState,
              id: _this4.formState.id,
              twoSplitMemberId: _this4.formState.twoSplitMemberId,
              twoSplitRate: _this4.formState.twoSplitRate,
              splitMemberId: _this4.formState.splitMemberId,
              splitRate: _this4.formState.splitRate,
              userPhone: _this4.selectForm.userPhone,
              realName: _this4.selectForm.realName,
              type: _this4.formState.type
            };
            // if ()
          } else {
            model = {
              id: _this4.formState.id,
              twoSplitMemberId: _this4.formState.splitMemberId,
              twoSplitRate: _this4.formState.splitRate,
              splitMemberId: _this4.formState.oneSplitMemberId,
              splitRate: _this4.formState.oneSplitRate,
              userPhone: _this4.selectForm.userPhone,
              realName: _this4.selectForm.realName,
              type: _this4.formState.type
            };
          }
          console.log('2', model);
          _this4.subForm = Object.assign({}, model);
          _this4.visible = false;
          _this4.visibleOne = true;
        }
      });
    },
    onNext: function onNext() {
      var _this5 = this;
      // console.log(this.subForm)
      (0, _nft.splitUpdateApi)(this.subForm).then(function (res) {
        _this5.$message.success('操作成功');
        _this5.visibleOne = false;
        _this5.getList();
      });
    },
    oniput: function oniput() {
      this.formState.splitRate = this.formState.splitRate.replace(/[^0-9]/g, "");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    }
  }
};
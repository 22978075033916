"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _hallFame = require("@/api/hallFame");
var _utils = require("@/utils");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      list: [],
      listLoading: false,
      config: {},
      searchForm: {
        type: 1,
        time: ''
      },
      currentTime: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
      fileVisible: false
    };
  },
  watch: {
    'searchForm.type': function searchFormType(val) {
      this.getList(1);
    }
  },
  created: function created() {
    this.getConfig();
    this.getList();
  },
  methods: {
    // 获取配置
    getConfig: function getConfig() {
      var _this = this;
      (0, _hallFame.getHallfameConfigApi)().then(function (res) {
        if (res.code === 200) {
          _this.config = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 获取列表
    getList: function getList(reset) {
      var _this2 = this;
      var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (this.listLoading) {
        return;
      }
      this.listLoading = true;
      if (reset) {
        this.pages.pageNum = 1;
      }
      if (JSON.stringify(search) !== '{}') {
        Object.assign(this.searchForm, search);
      }
      (0, _hallFame.getHallfameListApi)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pages), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this2.list = res.rows;
          _this2.pages.total = res.total;
        }
        _this2.listLoading = false;
      }).catch(function (err) {
        _this2.$message(err.massage);
        _this2.listLoading = false;
      });
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.pages.pageSize = val;
      this.getList(1);
    },
    exportExcel: function exportExcel() {
      var _this3 = this;
      (0, _hallFame.getHallfameExportApi)((0, _objectSpread2.default)({}, this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.msg);
        }
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      console.log(this.$refs.exportList);
      this.$refs.exportList.exportFileList(46);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container gradeDetails",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.gradeDetail,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "等级名称：",
                            prop: "memberLevelName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入等级名称" },
                            model: {
                              value: _vm.gradeDetail.memberLevelName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.gradeDetail,
                                  "memberLevelName",
                                  $$v
                                )
                              },
                              expression: "gradeDetail.memberLevelName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "成长值达到：", prop: "levelGrowth" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入成长值",
                              disabled: _vm.id === "0",
                            },
                            model: {
                              value: _vm.gradeDetail.levelGrowth,
                              callback: function ($$v) {
                                _vm.$set(_vm.gradeDetail, "levelGrowth", $$v)
                              },
                              expression: "gradeDetail.levelGrowth",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "ft13 ml20",
                        staticStyle: {
                          position: "relative",
                          top: "-10px",
                          display: "inline-block",
                          color: "#606266",
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("等级标识："),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item-align-center",
                        staticStyle: {
                          display: "inline-block",
                          color: "#606266",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "image")
                              },
                            },
                          },
                          [
                            _vm.gradeDetail.image
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("image")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gradeDetail.image
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: _vm.gradeDetail.image + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "积分倍数：", prop: "integralMultiple" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w300",
                      attrs: {
                        type: "number",
                        placeholder: "请输入积分倍数",
                        disabled: _vm.id === "0",
                      },
                      model: {
                        value: _vm.gradeDetail.integralMultiple,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.gradeDetail,
                            "integralMultiple",
                            _vm._n($$v)
                          )
                        },
                        expression: "gradeDetail.integralMultiple",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("倍")])],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ml20", staticStyle: { color: "#606266" } },
                    [_vm._v("例：输入1.3，用户获得积分时“积分*1.3”")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数字藏品折扣：",
                    prop: "memberLevelPrivilegeNftDiscount",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w300",
                      attrs: {
                        type: "number",
                        placeholder: "请输入数字藏品折扣",
                        disabled: _vm.id === "0",
                      },
                      model: {
                        value: _vm.gradeDetail.memberLevelPrivilegeNftDiscount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.gradeDetail,
                            "memberLevelPrivilegeNftDiscount",
                            _vm._n($$v)
                          )
                        },
                        expression:
                          "gradeDetail.memberLevelPrivilegeNftDiscount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ml20", staticStyle: { color: "#606266" } },
                    [_vm._v("例：输入98，用户购买数字藏品时“价格*0.98”")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数字盲盒折扣：",
                    prop: "memberLevelPrivilegeBoxDiscount",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w300",
                      attrs: {
                        type: "number",
                        placeholder: "请输入数字盲盒折扣",
                        disabled: _vm.id === "0",
                      },
                      model: {
                        value: _vm.gradeDetail.memberLevelPrivilegeBoxDiscount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.gradeDetail,
                            "memberLevelPrivilegeBoxDiscount",
                            _vm._n($$v)
                          )
                        },
                        expression:
                          "gradeDetail.memberLevelPrivilegeBoxDiscount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ml20", staticStyle: { color: "#606266" } },
                    [_vm._v("例：输入98，用户购买数字盲盒时“价格*0.98”")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "赠送积分：", prop: "memberLevelAwardScore" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "div",
                        { staticClass: "w100" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w100",
                              attrs: {
                                placeholder: "积分类型",
                                disabled: _vm.id === "0",
                              },
                              model: {
                                value: _vm.gradeDetail.scoreTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.gradeDetail, "scoreTypeId", $$v)
                                },
                                expression: "gradeDetail.scoreTypeId",
                              },
                            },
                            _vm._l(_vm.typeList, function (m, i) {
                              return _c("el-option", {
                                key: "type" + i,
                                attrs: { value: m.id, label: m.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticStyle: {
                            width: "190px",
                            "margin-left": "10px",
                          },
                          attrs: {
                            type: "number",
                            placeholder: "请输入赠送积分",
                            disabled: _vm.id === "0",
                          },
                          model: {
                            value: _vm.gradeDetail.memberLevelAwardScore,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.gradeDetail,
                                "memberLevelAwardScore",
                                _vm._n($$v)
                              )
                            },
                            expression: "gradeDetail.memberLevelAwardScore",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("分")])],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: { color: "#606266" },
                        },
                        [_vm._v("例：输入6，用户达到等级后，可领取6积分")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "赠送优惠券：",
                    prop: "memberLevelAwardCouponIds",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        multiple: "",
                        placeholder: "请选择优惠券",
                        disabled: _vm.id === "0",
                      },
                      model: {
                        value: _vm.gradeDetail.memberLevelAwardCouponIds,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.gradeDetail,
                            "memberLevelAwardCouponIds",
                            $$v
                          )
                        },
                        expression: "gradeDetail.memberLevelAwardCouponIds",
                      },
                    },
                    _vm._l(_vm.couponsArr, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "其他文字描述权益：",
                    prop: "textDescriptionEquities",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.id === "0" },
                    model: {
                      value: _vm.gradeDetail.textDescriptionEquities,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.gradeDetail,
                          "textDescriptionEquities",
                          $$v
                        )
                      },
                      expression: "gradeDetail.textDescriptionEquities",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("请输入其他文字描述权益，多个用“ , ”逗号隔开"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "等级权益描述：",
                    prop: "definitionDescriptionHierarchicalRights",
                  },
                },
                [
                  _c("ueditorFrom", {
                    staticStyle: {},
                    attrs: {
                      content:
                        _vm.gradeDetail.definitionDescriptionHierarchicalRights,
                    },
                    model: {
                      value:
                        _vm.gradeDetail.definitionDescriptionHierarchicalRights,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.gradeDetail,
                          "definitionDescriptionHierarchicalRights",
                          $$v
                        )
                      },
                      expression:
                        "gradeDetail.definitionDescriptionHierarchicalRights",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
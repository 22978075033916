var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.userType,
                callback: function ($$v) {
                  _vm.userType = $$v
                },
                expression: "userType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "用户列表", name: "all" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "实名认证", name: "authentication" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "用户标签", name: "label" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注销申请", name: "canel" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注册配置", name: "regiest_set" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "设置", name: "new_user_set" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt20 ft14 fWeight500" }, [
            _vm._v("新用户设置"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("未下单即为新用户"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("按注册时间"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt20" }, [
            _c("div", { staticClass: "flex align-items-c" }, [
              _c("span", [_vm._v("注册时间小于")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w100 ml10" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      controls: false,
                      precision: 0,
                      disabled: _vm.type == 1,
                    },
                    model: {
                      value: _vm.day,
                      callback: function ($$v) {
                        _vm.day = $$v
                      },
                      expression: "day",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml10" }, [_vm._v("天")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt20 ft14 fWeight500" }, [
            _vm._v("用户锁定设置"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("el-input-number", {
                staticStyle: { width: "100px" },
                attrs: { controls: false, precision: 0 },
                model: {
                  value: _vm.lockHours,
                  callback: function ($$v) {
                    _vm.lockHours = $$v
                  },
                  expression: "lockHours",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml5 mr5" }, [_vm._v("小时内，")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticStyle: { width: "100px" },
                attrs: { controls: false, precision: 0 },
                model: {
                  value: _vm.lockCount,
                  callback: function ($$v) {
                    _vm.lockCount = $$v
                  },
                  expression: "lockCount",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml5 mr5" }, [
                _vm._v("次取消订单，锁定"),
              ]),
              _vm._v(" "),
              _c("el-input-number", {
                staticStyle: { width: "100px" },
                attrs: { controls: false, precision: 0 },
                model: {
                  value: _vm.needLockHours,
                  callback: function ($$v) {
                    _vm.needLockHours = $$v
                  },
                  expression: "needLockHours",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml5 mr5" }, [_vm._v("小时")]),
              _vm._v(" "),
              _c("el-switch", {
                attrs: { "inactive-value": 0, "active-color": "#13ce66" },
                model: {
                  value: _vm.isUse,
                  callback: function ($$v) {
                    _vm.isUse = $$v
                  },
                  expression: "isUse",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml5 mr5" }, [_vm._v("白名单")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "选择关联快照名单",
                  },
                  model: {
                    value: _vm.snapshotArr,
                    callback: function ($$v) {
                      _vm.snapshotArr = $$v
                    },
                    expression: "snapshotArr",
                  },
                },
                _vm._l(_vm.snapshotOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMe
            ? [
                _c("div", { staticClass: "mt20 ft14 fWeight500" }, [
                  _vm._v("滑块验证设置"),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "flex align-items-c" }, [
                  _c(
                    "li",
                    [
                      _vm._v("\n          登录验证滑块"),
                      _c("el-switch", {
                        staticClass: "ml20",
                        model: {
                          value: _vm.hasLoginHuakuai,
                          callback: function ($$v) {
                            _vm.hasLoginHuakuai = $$v
                          },
                          expression: "hasLoginHuakuai",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "ml20 mr20" },
                    [
                      _vm._v("\n          一级购买验证滑块"),
                      _c("el-switch", {
                        staticClass: "ml20",
                        model: {
                          value: _vm.hasOneHuakuai,
                          callback: function ($$v) {
                            _vm.hasOneHuakuai = $$v
                          },
                          expression: "hasOneHuakuai",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _vm._v("\n          二级购买验证滑块\n          "),
                      _c("el-switch", {
                        staticClass: "ml20",
                        model: {
                          value: _vm.hasTwoHuakuai,
                          callback: function ($$v) {
                            _vm.hasTwoHuakuai = $$v
                          },
                          expression: "hasTwoHuakuai",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _vm._v("\n          合成验证滑块\n          "),
                      _c("el-switch", {
                        staticClass: "ml20",
                        model: {
                          value: _vm.composeHuakuai,
                          callback: function ($$v) {
                            _vm.composeHuakuai = $$v
                          },
                          expression: "composeHuakuai",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.userType,
                callback: function ($$v) {
                  _vm.userType = $$v
                },
                expression: "userType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "用户列表", name: "all" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "实名认证", name: "authentication" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "用户标签", name: "label" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注销申请", name: "canel" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注册配置", name: "regiest_set" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "设置", name: "new_user_set" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "clearfix" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("添加用户标签")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标签名称", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleLabel,
                "close-on-click-modal": false,
                center: "",
                title: "添加用户标签",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addLabelForm",
                  attrs: {
                    size: "small",
                    model: _vm.addLabelForm,
                    rules: _vm.labelRules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户标签名称", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addLabelForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.addLabelForm, "name", $$v)
                          },
                          expression: "addLabelForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmLabelAction("addLabelForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
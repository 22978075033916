var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "mt20" }, [
          _c("div", { staticClass: "flex align-items-c mb10" }, [
            _c("div", { staticClass: "flex-one t ft14 fWeight500" }, [
              _vm._v("交易设置"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ft14 fWeight500" }, [_vm._v("操作")]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            不展示哈希值\n            "),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v("打开后寄售市场详情页不展示哈希值"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.isNotShowHash,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "isNotShowHash", $$v)
                      },
                      expression: "ruleData.isNotShowHash",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            不展示生成时间\n            "),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v("打开后不展示生成时间"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.isNotShowDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "isNotShowDate", $$v)
                      },
                      expression: "ruleData.isNotShowDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            不展示当前归属\n            "),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v("打开后不展示当前归属"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.isNotCurrentOwn,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "isNotCurrentOwn", $$v)
                      },
                      expression: "ruleData.isNotCurrentOwn",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            不展示寄售记录\n            "),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v("打开后寄售详情页不展示寄售记录"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.isNotShowRecord,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "isNotShowRecord", $$v)
                      },
                      expression: "ruleData.isNotShowRecord",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(
                  "\n            不展示寄售市场首页列表中的发行作者\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.isNotShowAuthor,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "isNotShowAuthor", $$v)
                      },
                      expression: "ruleData.isNotShowAuthor",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            寄售首页不展示搜索\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.isNotShowSearch,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "isNotShowSearch", $$v)
                      },
                      expression: "ruleData.isNotShowSearch",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            列表统计数字设置\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "color-black ft14",
                      staticStyle: { color: "#000" },
                    },
                    [_vm._v("限量：")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasLimit", $$v)
                      },
                      expression: "ruleData.hasLimit",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "110px" },
                    attrs: {
                      controls: false,
                      maxlength: "3",
                      placeholder: "自定义名称",
                      disabled: !_vm.ruleData.hasLimit,
                    },
                    model: {
                      value: _vm.ruleData.limitName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "limitName", $$v)
                      },
                      expression: "ruleData.limitName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "color-black ft14 ml20",
                      staticStyle: { color: "#000" },
                    },
                    [_vm._v("流通：")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasCirculate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasCirculate", $$v)
                      },
                      expression: "ruleData.hasCirculate",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "110px" },
                    attrs: {
                      controls: false,
                      maxlength: "3",
                      placeholder: "自定义名称",
                      disabled: !_vm.ruleData.hasCirculate,
                    },
                    model: {
                      value: _vm.ruleData.circulateName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "circulateName", $$v)
                      },
                      expression: "ruleData.circulateName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "color-black ft14 ml20",
                      staticStyle: { color: "#000" },
                    },
                    [_vm._v("持仓：")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasCirculateIsCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasCirculateIsCount", $$v)
                      },
                      expression: "ruleData.hasCirculateIsCount",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "110px" },
                    attrs: {
                      controls: false,
                      maxlength: "3",
                      placeholder: "自定义名称",
                      disabled: !_vm.ruleData.hasCirculateIsCount,
                    },
                    model: {
                      value: _vm.ruleData.circulateIsCountName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "circulateIsCountName", $$v)
                      },
                      expression: "ruleData.circulateIsCountName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            首页标签展示\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.checkboxChange },
                      model: {
                        value: _vm.ruleData.homeLableShow,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData, "homeLableShow", $$v)
                        },
                        expression: "ruleData.homeLableShow",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        { attrs: { label: "1", disabled: "" } },
                        [_vm._v("藏品")]
                      ),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("盲盒"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "3" } }, [
                        _vm._v("系列"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            首页选项卡排序"),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v("拖拽排序"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "board-column-content flex",
                        attrs: { list: _vm.sort },
                      },
                      "draggable",
                      _vm.$attrs,
                      false
                    ),
                    _vm._l(_vm.sort, function (m, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "ml20",
                          staticStyle: { cursor: "pointer" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(m) +
                              "\n              "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            寄售冻结时间\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mr10 color-black ft14",
                      staticStyle: { color: "#000" },
                    },
                    [_vm._v("用户取消寄售后")]
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: { controls: false },
                    model: {
                      value: _vm.ruleData.marketFreezeTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "marketFreezeTime", $$v)
                      },
                      expression: "ruleData.marketFreezeTime",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml10 color-black ft14",
                      staticStyle: { color: "#000" },
                    },
                    [_vm._v("分钟后可再次寄售上架")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            展示首次标签\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasFirstMarket,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasFirstMarket", $$v)
                      },
                      expression: "ruleData.hasFirstMarket",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            显示退市藏品"),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v("退市藏品显示在寄售市场"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.noBuyHas,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "noBuyHas", $$v)
                      },
                      expression: "ruleData.noBuyHas",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item flex align-items-c",
                staticStyle: { "padding-top": "20px" },
              },
              [
                _c("div", { staticClass: "flex-one" }, [
                  _vm._v("\n            设置退市藏品寄售列表页展示图标"),
                  _c("span", { staticClass: "ml10 color-gray ft12" }, [
                    _vm._v("（格式支持：JPG、JPEG、PNG、GIF）"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex align-items-c" }, [
                  _c(
                    "div",
                    { staticClass: "img-list" },
                    [
                      _vm.ruleData.noBuyImage
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "del",
                                on: {
                                  click: function ($event) {
                                    return _vm.delImgAction("noBuyImage")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              attrs: { src: _vm.ruleData.noBuyImage + "!120a" },
                              on: {
                                click: function ($event) {
                                  return _vm.showImageAction(1, "noBuyImage")
                                },
                              },
                            }),
                          ]
                        : _c(
                            "div",
                            {
                              staticClass: "item-flex-center",
                              staticStyle: { height: "50px" },
                              on: {
                                click: function ($event) {
                                  return _vm.showImageAction(1, "noBuyImage")
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  height: "30px",
                                  display: "block",
                                },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item flex align-items-c",
                staticStyle: { "padding-top": "20px" },
              },
              [
                _c("div", { staticClass: "flex-one" }, [
                  _vm._v("\n            寄售市场星级icon"),
                  _c("span", { staticClass: "ml10 color-gray ft12" }, [
                    _vm._v("（格式支持：JPG、JPEG、PNG、GIF）"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex align-items-c" }, [
                  _c(
                    "div",
                    { staticClass: "img-list" },
                    [
                      _vm.ruleData.starLevelImage
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "del",
                                on: {
                                  click: function ($event) {
                                    return _vm.delImgAction("starLevelImage")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: _vm.ruleData.starLevelImage + "!120a",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showImageAction(
                                    1,
                                    "starLevelImage"
                                  )
                                },
                              },
                            }),
                          ]
                        : _c(
                            "div",
                            {
                              staticClass: "item-flex-center",
                              staticStyle: { height: "50px" },
                              on: {
                                click: function ($event) {
                                  return _vm.showImageAction(
                                    1,
                                    "starLevelImage"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  height: "30px",
                                  display: "block",
                                },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            寄售记录昵称样式\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleData.nickNameStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData, "nickNameStyle", $$v)
                        },
                        expression: "ruleData.nickNameStyle",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("完整昵称"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("*****y"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            挂单时间间隔"),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v(
                    "设置后,每次挂单寄售的间隔时间,设置后可防止外挂批量挂单"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mr10 color-black ft14",
                      staticStyle: { color: "#000" },
                    },
                    [_vm._v("挂单间隔时间")]
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: { controls: false, min: 0, precision: 0 },
                    model: {
                      value: _vm.ruleData.registrationInterval,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "registrationInterval", $$v)
                      },
                      expression: "ruleData.registrationInterval",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml10 color-black ft14",
                      staticStyle: { color: "#000" },
                    },
                    [_vm._v("秒")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one " }, [
                _vm._v("\n            批量下单白名单\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    staticClass: "mr10",
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasBatch,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasBatch", $$v)
                      },
                      expression: "ruleData.hasBatch",
                    },
                  }),
                  _vm._v(" "),
                  _vm.ruleData.hasBatch
                    ? [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            on: { change: _vm.changeRestriction },
                            model: {
                              value: _vm.restriction,
                              callback: function ($$v) {
                                _vm.restriction = $$v
                              },
                              expression: "restriction",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("不限"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("指定白名单"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.restriction == "2"
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "选择关联快照名单",
                                },
                                model: {
                                  value: _vm.ruleData.snapshotIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleData, "snapshotIds", $$v)
                                  },
                                  expression: "ruleData.snapshotIds",
                                },
                              },
                              _vm._l(_vm.whitelist, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            快捷下单白名单\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    staticClass: "mr10",
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasQuick,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasQuick", $$v)
                      },
                      expression: "ruleData.hasQuick",
                    },
                  }),
                  _vm._v(" "),
                  _vm.ruleData.hasQuick
                    ? [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            on: { change: _vm.changeFastBuy },
                            model: {
                              value: _vm.fastBuy,
                              callback: function ($$v) {
                                _vm.fastBuy = $$v
                              },
                              expression: "fastBuy",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("不限"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("指定白名单"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.fastBuy == "2"
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "选择关联快照名单",
                                },
                                model: {
                                  value: _vm.ruleData.quickSnapshotIds,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleData,
                                      "quickSnapshotIds",
                                      $$v
                                    )
                                  },
                                  expression: "ruleData.quickSnapshotIds",
                                },
                              },
                              _vm._l(_vm.whitelist, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("\n            寄售挂单白名单\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: { change: _vm.changeMarketWhite },
                      model: {
                        value: _vm.ruleData.hasMarketWhiteList,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData, "hasMarketWhiteList", $$v)
                        },
                        expression: "ruleData.hasMarketWhiteList",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("指定白名单"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleData.hasMarketWhiteList
                    ? _c(
                        "el-select",
                        {
                          staticClass: "ml10",
                          staticStyle: { width: "300px" },
                          attrs: {
                            multiple: "",
                            placeholder: "选择关联快照名单",
                          },
                          model: {
                            value: _vm.ruleData.marketWhiteList,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleData, "marketWhiteList", $$v)
                            },
                            expression: "ruleData.marketWhiteList",
                          },
                        },
                        _vm._l(_vm.whitelist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleData.hasMarketWhiteList
                    ? _c("el-input", {
                        staticClass: "ml10",
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "输入提示语；例如市场火爆，请稍后再试",
                        },
                        model: {
                          value: _vm.ruleData.restrictivePrompts,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "restrictivePrompts", $$v)
                          },
                          expression: "ruleData.restrictivePrompts",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c(
                "div",
                { staticClass: "flex-one   flex align-items-c " },
                [
                  _vm._v("\n            需签署寄售协议"),
                  _c("span", { staticClass: "ml10 color-gray ft12" }, [
                    _vm._v("关闭时无需签署。打开后将弹出强制签署"),
                  ]),
                  _c(
                    "el-link",
                    {
                      staticClass: "ft12",
                      attrs: { underline: false, type: "primary" },
                      on: { click: _vm.settingContent },
                    },
                    [_vm._v("去设置协议内容")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasSignAgree,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasSignAgree", $$v)
                      },
                      expression: "ruleData.hasSignAgree",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml30" },
                    [
                      _c("span", { staticClass: "ml10 color-gray ft12" }, [
                        _vm._v("寄售协议有更新时重新签署"),
                      ]),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                        },
                        model: {
                          value: _vm.ruleData.hasRetrySignAgree,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "hasRetrySignAgree", $$v)
                          },
                          expression: "ruleData.hasRetrySignAgree",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one   flex align-items-c " }, [
                _vm._v("\n            显示买卖详情入口\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasTransaction,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "hasTransaction", $$v)
                      },
                      expression: "ruleData.hasTransaction",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one   flex align-items-c " }, [
                _vm._v("\n            显示成交滚动记录\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.hasTransactionRollingRecord,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleData,
                          "hasTransactionRollingRecord",
                          $$v
                        )
                      },
                      expression: "ruleData.hasTransactionRollingRecord",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one   flex align-items-c " }, [
                _vm._v("\n            显示当前行情\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.ruleData.currentMarket,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "currentMarket", $$v)
                      },
                      expression: "ruleData.currentMarket",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.saveAction },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemForm = require("@/api/systemForm");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserGroup',
  data: function data() {
    return {
      notice: [],
      subscribe: [],
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _systemForm.noticeConfigApi)().then(function (res) {
        _this.notice = res.data.notice;
        _this.subscribe = res.data.subscribe;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    hasSendSmsChange: function hasSendSmsChange(scope) {
      var _this2 = this;
      var rjson = {
        id: scope.row.id,
        hasSendSms: scope.row.hasSendSms,
        type: scope.row.type,
        hasSendMessage: scope.row.hasSendMessage,
        hasSendAndroid: scope.row.hasSendAndroid,
        hasSendIos: scope.row.hasSendIos
      };
      (0, _systemForm.noticeConfigUpdateApi)(rjson).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.getList();
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-form",
        { attrs: { size: "small", inline: "", "label-position": "left" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "场景名称" } },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: { size: "small", placeholder: "" },
                model: {
                  value: _vm.infoData.battleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.infoData, "battleName", $$v)
                  },
                  expression: "infoData.battleName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.infoData.roomList, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "房间名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          model: {
                            value: row.roomName,
                            callback: function ($$v) {
                              _vm.$set(row, "roomName", $$v)
                            },
                            expression: "row.roomName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", prop: "updateTime" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gameTit" }, [_vm._v("筹码设置")]),
      _vm._v(" "),
      _vm.infoData.tableData
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.infoData.tableData, size: "small" },
            },
            [
              _c("el-table-column", { attrs: { label: "筹码类型" } }, [
                _vm._v(" 积分 "),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: row.chipImage },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1105482741
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "chipName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "可投放数量设置" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._l(row.chipNum, function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: { size: "small", placeholder: "" },
                                  model: {
                                    value: item.label,
                                    callback: function ($$v) {
                                      _vm.$set(item, "label", $$v)
                                    },
                                    expression: "item.label",
                                  },
                                }),
                                _vm._v("个\n          "),
                                index != 0
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          underline: false,
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDeleNum(row, index)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                underline: false,
                                type: "primary",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onAddNum(row)
                                },
                              },
                            },
                            [_vm._v("添加选项")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3394510892
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item flex align-items-c h50",
          staticStyle: { "margin-top": "20px" },
        },
        [
          _c("div", { staticClass: "flex-one" }, [_vm._v("开启游戏")]),
          _vm._v(" "),
          _c("el-switch", {
            model: {
              value: _vm.infoData.isOpen,
              callback: function ($$v) {
                _vm.$set(_vm.infoData, "isOpen", $$v)
              },
              expression: "infoData.isOpen",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item flex align-items-c h50" },
        [
          _c("div", { staticClass: "flex-one" }, [_vm._v("开局人数")]),
          _vm._v("\n    参与人数满"),
          _c("el-input", {
            staticClass: "inpStyle",
            attrs: { placeholder: "请输入数量" },
            model: {
              value: _vm.infoData.openNum,
              callback: function ($$v) {
                _vm.$set(_vm.infoData, "openNum", $$v)
              },
              expression: "infoData.openNum",
            },
          }),
          _vm._v("人开局\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item flex align-items-c h50" },
        [
          _c("div", { staticClass: "flex-one" }, [_vm._v("每轮时间")]),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "inpStyle",
            attrs: { placeholder: "请输入数量" },
            model: {
              value: _vm.infoData.gameTime,
              callback: function ($$v) {
                _vm.$set(_vm.infoData, "gameTime", $$v)
              },
              expression: "infoData.gameTime",
            },
          }),
          _vm._v("秒\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _vm.infoData.unInjury
        ? _c("div", { staticClass: "item flex align-items-c h50" }, [
            _c("div", { staticClass: "flex-one" }, [_vm._v("免伤设置")]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-right": "20px" } },
              [
                _vm._v("\n      第"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[0].min,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[0], "min", $$v)
                    },
                    expression: "infoData.unInjury[0].min",
                  },
                }),
                _vm._v("\n      至"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[0].max,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[0], "max", $$v)
                    },
                    expression: "infoData.unInjury[0].max",
                  },
                }),
                _vm._v("\n      免伤"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[0].scale,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[0], "scale", $$v)
                    },
                    expression: "infoData.unInjury[0].scale",
                  },
                }),
                _vm._v("%\n    "),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-right": "20px" } },
              [
                _vm._v("\n      第"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[1].min,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[1], "min", $$v)
                    },
                    expression: "infoData.unInjury[1].min",
                  },
                }),
                _vm._v("\n      至"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[1].max,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[1], "max", $$v)
                    },
                    expression: "infoData.unInjury[1].max",
                  },
                }),
                _vm._v("\n      免伤"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[1].scale,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[1], "scale", $$v)
                    },
                    expression: "infoData.unInjury[1].scale",
                  },
                }),
                _vm._v("%\n    "),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n      第"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[2].min,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[2], "min", $$v)
                    },
                    expression: "infoData.unInjury[2].min",
                  },
                }),
                _vm._v("\n      至"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[2].max,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[2], "max", $$v)
                    },
                    expression: "infoData.unInjury[2].max",
                  },
                }),
                _vm._v("\n      免伤"),
                _c("el-input", {
                  staticClass: "minWid",
                  model: {
                    value: _vm.infoData.unInjury[2].scale,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData.unInjury[2], "scale", $$v)
                    },
                    expression: "infoData.unInjury[2].scale",
                  },
                }),
                _vm._v("%\n    "),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "submit-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onSubmit()
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.createdTypeAction },
            },
            [_vm._v("创建积分类型")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.Loading,
              expression: "Loading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.issueData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "图标", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "30px" },
                      attrs: { src: scope.row.image + "!120a" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "类型名称", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "最后修改人", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.updateBy))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "是否开启转赠" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.isTransfer ? "是" : "否") + " "),
                      scope.row.isTransfer
                        ? _c("span", [
                            _vm._v(
                              "转增手续费" + _vm._s(scope.row.transferScale)
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "最后修改时间", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.updateTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.editAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    scope.row.id != 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delAction(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "el-dialog": "",
            width: "400px",
            "close-on-click-modal": false,
            visible: _vm.showDetailData.show,
            title: "创建积分类型",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showDetailData, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c mt10" }, [
            _c("div", { staticClass: "l w80" }, [_vm._v("名称")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("el-input", {
                  model: {
                    value: _vm.showDetailData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.showDetailData, "name", $$v)
                    },
                    expression: "showDetailData.name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt20" }, [
            _c("div", { staticClass: "l w80" }, [_vm._v("图标")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "upload-img-view item-flex-center",
                on: {
                  click: function ($event) {
                    return _vm.modalPicTap("image")
                  },
                },
              },
              [
                _vm.showDetailData.image
                  ? _c(
                      "div",
                      {
                        staticClass: "del-upload-img",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.delImg("image")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showDetailData.image
                  ? _c("img", {
                      attrs: { src: _vm.showDetailData.image + "!120a" },
                    })
                  : _c("img", {
                      staticClass: "upload-icon",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                        alt: "",
                      },
                    }),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "ft12 color-gray ml20" }, [
              _vm._v("1:1图片"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt10" }, [
            _c("div", { staticClass: "l w80" }, [_vm._v("是否开启转赠")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.showDetailData.isTransfer,
                    callback: function ($$v) {
                      _vm.$set(_vm.showDetailData, "isTransfer", $$v)
                    },
                    expression: "showDetailData.isTransfer",
                  },
                }),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "200px", "margin-left": "20px" },
                  attrs: { placeholder: "请输入转赠手续费" },
                  model: {
                    value: _vm.showDetailData.transferScale,
                    callback: function ($$v) {
                      _vm.$set(_vm.showDetailData, "transferScale", $$v)
                    },
                    expression: "showDetailData.transferScale",
                  },
                }),
                _vm._v("%\n      "),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("div", { staticClass: "l w80" }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var timingOrderRouter = {
  path: "".concat(_settings.roterPre, "/timing"),
  name: "Timing",
  meta: {
    icon: "",
    title: "定时下单"
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/timing/index"));
      });
    },
    name: "TimingOrder",
    meta: {
      title: "C账户管理",
      noCache: true
    }
  }, {
    path: "orderv2",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/timing/orderv2.vue"));
      });
    },
    name: "TimingOrderV2",
    meta: {
      title: "账户管理",
      noCache: true
    },
    children: [{
      path: 'scan',
      name: 'orderScan',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/timing/scan'));
        });
      },
      meta: {
        title: 'D寄售列表',
        noCache: true
      }
    }, {
      path: 'lock',
      name: 'orderLock',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/timing/lock'));
        });
      },
      meta: {
        title: 'D锁单列表',
        noCache: true
      }
    }]
  }, {
    path: 'setting',
    name: 'orderSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/timing/setting'));
      });
    },
    meta: {
      title: 'D锁单和寄售设置',
      noCache: true
    }
  }]
};
var _default = exports.default = timingOrderRouter;
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _animal = require("@/api/animal");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AnimalDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      selectType: "",
      form: {
        productId: "",
        productName: "",
        productImage: "",
        initialEnergy: 0,
        maxEnergy: 1,
        consumeEnergy: 1,
        lifeEnergy: 0,
        maxLife: 1,
        consumeLife: 1
      },
      outPutData: [],
      produceDialog: false,
      produceData: [],
      energyData: [],
      lifeData: [],
      selectIndex: -1
    };
  },
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.getInfo(this.$route.query.id);
    }
  },
  methods: {
    getInfo: function getInfo(id) {
      var _this = this;
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.getInfoApi)(id).then(function (res) {
        console.log(res);
        var _res$data = res.data,
          animalsProduceOrExchanges = _res$data.animalsProduceOrExchanges,
          consumeEnergy = _res$data.consumeEnergy,
          initialEnergy = _res$data.initialEnergy,
          maxEnergy = _res$data.maxEnergy,
          productId = _res$data.productId,
          productImage = _res$data.productImage,
          productName = _res$data.productName,
          maxLife = _res$data.maxLife,
          lifeEnergy = _res$data.lifeEnergy,
          consumeLife = _res$data.consumeLife,
          id = _res$data.id;
        var outPutData = animalsProduceOrExchanges.filter(function (item) {
          return item.type == 1;
        });
        var energyData = animalsProduceOrExchanges.filter(function (item) {
          return item.type == 2;
        });
        var lifeData = animalsProduceOrExchanges.filter(function (item) {
          return item.type == 3;
        });
        _this.form.productId = productId;
        _this.form.productImage = productImage;
        _this.form.productName = productName;
        _this.form.maxEnergy = maxEnergy;
        _this.form.initialEnergy = initialEnergy;
        _this.form.consumeEnergy = consumeEnergy;
        _this.form.maxLife = maxLife;
        _this.form.lifeEnergy = lifeEnergy;
        _this.form.consumeLife = consumeLife;
        _this.form.id = id;
        _this.outPutData = outPutData;
        _this.energyData = energyData;
        _this.lifeData = lifeData;
        _this.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    goBack: function goBack() {
      this.$router.push("/merchant/animals/manageMent");
    },
    selectAnimal: function selectAnimal() {
      this.$refs["product_nft"].changeshow();
      this.selectType = "selectAnimal";
    },
    addOutPut: function addOutPut() {
      this.outPutData.push({
        productId: "",
        name: "",
        image: "",
        account: 1,
        materialType: 1,
        type: 1
      });
    },
    addEnergy: function addEnergy() {
      this.energyData.push({
        productId: "",
        name: "",
        image: "",
        account: 1,
        materialType: 1,
        type: 2
      });
    },
    addLife: function addLife() {
      this.lifeData.push({
        productId: "",
        name: "",
        image: "",
        account: 1,
        materialType: 1,
        type: 3
      });
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      var _data$data$ = data.data[0],
        productId = _data$data$.productId,
        image = _data$data$.image,
        name = _data$data$.name;
      if (this.selectType === "addOutPutCollection") {
        this.outPutData[0].productId = productId;
        this.outPutData[0].image = image;
        this.outPutData[0].name = name;
      } else if (this.selectType === "selectAnimal") {
        this.form.productId = productId;
        this.form.productImage = image;
        this.form.productName = name;
      } else if (this.selectType === "addEnergyCollection") {
        var idx = this.energyData.findIndex(function (item) {
          return item.productId == productId;
        });
        if (idx > -1) {
          return this.$message.error("已存在,请重新选择");
        } else {
          this.energyData[this.selectIndex].productId = productId;
          this.energyData[this.selectIndex].image = image;
          this.energyData[this.selectIndex].name = name;
        }
      } else if (this.selectType === "addLifeCollection") {
        var _idx = this.lifeData.findIndex(function (item) {
          return item.productId == productId;
        });
        if (_idx > -1) {
          return this.$message.error("已存在,请重新选择");
        } else {
          this.lifeData[this.selectIndex].productId = productId;
          this.lifeData[this.selectIndex].image = image;
          this.lifeData[this.selectIndex].name = name;
        }
      }
      this.selectType = "";
    },
    selectOutPut: function selectOutPut(type, addType, idx) {
      var _this2 = this;
      console.log(idx);
      if (idx > -1) {
        this.selectIndex = idx;
      }
      if (type == 1) {
        var loadingInstance = _elementUi.Loading.service();
        (0, _animal.produceListApi)().then(function (res) {
          _this2.produceData = res.rows;
          _this2.produceDialog = true;
          if (addType === "outPut") {
            _this2.selectType = "addProduce";
          } else if (addType === "energy") {
            _this2.selectType = "addEnergy";
          } else if (addType === "life") {
            _this2.selectType = "addLife";
          }
          _this2.$nextTick(function () {
            loadingInstance.close();
          });
        }).catch(function (_) {
          _this2.$nextTick(function () {
            loadingInstance.close();
          });
        });
      } else {
        if (addType === "outPut") {
          this.selectType = "addOutPutCollection";
        } else if (addType === "energy") {
          this.selectType = "addEnergyCollection";
        } else if (addType === "life") {
          this.selectType = "addLifeCollection";
        }
        this.$refs["product_nft"].changeshow();
      }
    },
    selectProduce: function selectProduce(row) {
      var id = row.id,
        image = row.image,
        name = row.name;
      if (this.selectType === "addProduce") {
        this.outPutData[0].productId = id;
        this.outPutData[0].image = image;
        this.outPutData[0].name = name;
      } else if (this.selectType === "addEnergy") {
        var idx = this.energyData.findIndex(function (item) {
          return item.productId == id;
        });
        if (idx > -1) {
          return this.$message.error("已存在,请重新选择");
        } else {
          this.energyData[this.selectIndex].productId = id;
          this.energyData[this.selectIndex].image = image;
          this.energyData[this.selectIndex].name = name;
        }
      } else if (this.selectType === "addLife") {
        var _idx2 = this.lifeData.findIndex(function (item) {
          return item.productId == id;
        });
        if (_idx2 > -1) {
          return this.$message.error("已存在,请重新选择");
        } else {
          this.lifeData[this.selectIndex].productId = id;
          this.lifeData[this.selectIndex].image = image;
          this.lifeData[this.selectIndex].name = name;
        }
      }
      this.produceDialog = false;
      this.selectType = "";
    },
    changeMaterialType: function changeMaterialType() {
      this.outPutData[0].productId = "";
      this.outPutData[0].image = "";
      this.outPutData[0].name = "";
    },
    changeEnergy: function changeEnergy(idx) {
      this.energyData[idx].productId = "";
      this.energyData[idx].image = "";
      this.energyData[idx].name = "";
    },
    changeLife: function changeLife(idx) {
      this.lifeData[idx].productId = "";
      this.lifeData[idx].image = "";
      this.lifeData[idx].name = "";
    },
    deleteEnergy: function deleteEnergy(idx) {
      this.energyData.splice(idx, 1);
    },
    deleteLife: function deleteLife(idx) {
      this.lifeData.splice(idx, 1);
    },
    deleteOutOut: function deleteOutOut() {
      this.outPutData = [];
    },
    submit: function submit() {
      var _this3 = this;
      // handleAnimalsApi
      console.log(this.form);
      var err = "";
      if (!this.form.productId) {
        err = "请选择神兽";
      }
      if (!this.outPutData.length) {
        err = "请添加产出";
      }
      if (!this.energyData.length) {
        err = "请添加补充灵力值";
      }
      var idx = this.outPutData.findIndex(function (item) {
        return item.productId != "";
      });
      var eIdx = this.energyData.findIndex(function (item) {
        return item.productId != "";
      });
      console.log(idx, eIdx);
      if (idx == -1 || eIdx == -1) {
        err = "请补全信息";
      }
      if (err) {
        return this.$message.error(err);
      }
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.form), {}, {
        animalsProduceOrExchanges: [].concat((0, _toConsumableArray2.default)(this.outPutData), (0, _toConsumableArray2.default)(this.energyData), (0, _toConsumableArray2.default)(this.lifeData))
      });
      console.log(data);
      if (this.$route.query.id) {
        data.id = this.$route.query.id;
      }
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.handleAnimalsApi)(data).then(function (res) {
        _this3.$message.success("添加成功");
        _this3.goBack();
        _this3.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this3.$nextTick(function () {
          loadingInstance.close();
        });
      });
    }
  }
};
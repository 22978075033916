var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-row",
        { staticClass: "mb15" },
        [
          _c(
            "el-col",
            { attrs: { offset: 18, span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入藏品名" },
                on: {
                  blur: function () {
                    return _vm.getList()
                  },
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.addCollectionDialog = true
                    },
                  },
                },
                [_vm._v("添加藏品")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.listTableData.data, stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticClass: "w50 h50",
                      attrs: { src: scope.row.image },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "数藏名称", prop: "productName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "扫货价格" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.marketPriceType == 1
                      ? _c("span", [
                          _vm._v(
                            "<" +
                              _vm._s(
                                scope.row.marketDownPrice
                                  ? scope.row.marketDownPrice
                                  : 0
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.marketPriceType == 2
                      ? _c("span", [
                          _vm._v(
                            ">" +
                              _vm._s(
                                scope.row.marketUpPrice
                                  ? scope.row.marketUpPrice
                                  : 0
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.marketPriceType == 3
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.marketDownPrice
                                ? scope.row.marketDownPrice
                                : 0
                            ) +
                              "-" +
                              _vm._s(
                                scope.row.marketUpPrice
                                  ? scope.row.marketUpPrice
                                  : 0
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.editOneDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "锁单账户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.lockOrderMember
                            ? scope.row.lockOrderMember
                            : "未选择"
                        ) +
                        "\n        "
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.addAccount(scope.row)
                          },
                        },
                      },
                      [_vm._v("选择")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "锁单排除名单", width: "450" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { multiple: "", "collapse-tags": "" },
                        on: {
                          "remove-tag": function (e) {
                            return _vm.onWhiteListChange(e, scope.row, 1)
                          },
                          "visible-change": function (e) {
                            return _vm.onWhiteListChange(e, scope.row, 2)
                          },
                        },
                        model: {
                          value: scope.row.lockOrderWhiteList,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "lockOrderWhiteList", $$v)
                          },
                          expression: "scope.row.lockOrderWhiteList",
                        },
                      },
                      _vm._l(_vm.snapshotOptions, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#13ce66",
                      },
                      on: {
                        change: function (e) {
                          return _vm.onSwitchChange(e, scope.row)
                        },
                      },
                      model: {
                        value: scope.row.hasWhiteList,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "hasWhiteList", $$v)
                        },
                        expression: "scope.row.hasWhiteList",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("(反选)只锁名单中的用户")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.status == 1 ? "进行中" : "暂停")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.id
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandStatus(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "暂停" : "开始")
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteOrder(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              "page-size": _vm.listPageConfig.pageSize,
              "current-page": _vm.listPageConfig.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.listTableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.consignVisible,
            title: "修改扫货价格",
            width: "400px",
            align: "center",
          },
          on: {
            "update:visible": function ($event) {
              _vm.consignVisible = $event
            },
            close: function ($event) {
              return _vm.handleClose("formData")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "form-custom",
              attrs: { model: _vm.formData },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "marketPriceType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.marketPriceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "marketPriceType", $$v)
                        },
                        expression: "formData.marketPriceType",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 3 } },
                        [
                          _vm._v("按价格区间\n            "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.downPrice,
                              callback: function ($$v) {
                                _vm.downPrice = $$v
                              },
                              expression: "downPrice",
                            },
                          }),
                          _vm._v("-\n            "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.upPrice,
                              callback: function ($$v) {
                                _vm.upPrice = $$v
                              },
                              expression: "upPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _c("span", [_vm._v("小于")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.formData.marketDownPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "marketDownPrice", $$v)
                              },
                              expression: "formData.marketDownPrice",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("元自动寄售")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: 2 } },
                        [
                          _c("span", [_vm._v("大于")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.formData.marketUpPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "marketUpPrice", $$v)
                              },
                              expression: "formData.marketUpPrice",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("元自动寄售")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      return (_vm.consignVisible = false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "搜索用户",
            visible: _vm.userDialog,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDialog = $event
            },
            close: function ($event) {
              return _vm.closeDialog("userDialog")
            },
          },
        },
        [
          _c(
            "el-input",
            {
              staticClass: "input-with-select",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "用户名/手机号", clearable: "" },
              on: {
                clear: function ($event) {
                  return _vm.getUserList(1)
                },
              },
              model: {
                value: _vm.userName,
                callback: function ($$v) {
                  _vm.userName = $$v
                },
                expression: "userName",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.getUserList(1)
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.userLoading,
                  expression: "userLoading",
                },
              ],
              ref: "userTable",
              attrs: {
                height: "400",
                data: _vm.userTableData.data,
                stripe: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [_vm._v(_vm._s(scope.row.nickName))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(scope.row.userPhone))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "w50 h50",
                          attrs: { src: scope.row.avatar },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "注册时间" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-b" },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "25px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addLockUser },
                    },
                    [_vm._v("确认添加")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.userPageConfig.pageSize,
                  "current-page": _vm.userPageConfig.pageNum,
                  total: _vm.userTableData.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.userPageConfig, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.userPageConfig, "pageNum", $event)
                  },
                  "current-change": _vm.getUserList,
                  "size-change": _vm.handleUserSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加藏品",
            visible: _vm.addCollectionDialog,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addCollectionDialog = $event
            },
            close: function ($event) {
              return _vm.closeDialog("addCollectionDialog")
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.marketProducts, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticClass: "w50 h50",
                          attrs: { src: scope.row.image, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格小于", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          staticClass: "w100",
                          attrs: { controls: false, min: 0 },
                          model: {
                            value: row.maxPrice,
                            callback: function ($$v) {
                              _vm.$set(row, "maxPrice", $$v)
                            },
                            expression: "row.maxPrice",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCollection(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticClass: "mt20 mb20",
              attrs: { underline: false, type: "primary" },
              on: { click: _vm.showCollection },
            },
            [_vm._v("添加藏品")]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.marketProducts.length == 0,
                  },
                  on: { click: _vm.batchSubmit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加藏品",
                "append-to-body": "",
                visible: _vm.collectionDialog,
                "close-on-click-modal": false,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.collectionDialog = $event
                },
                close: function ($event) {
                  return _vm.closeDialog("collectionDialog")
                },
              },
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "名称搜索" },
                  model: {
                    value: _vm.collectionName,
                    callback: function ($$v) {
                      _vm.collectionName = $$v
                    },
                    expression: "collectionName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getCollectionList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.collectionLoading,
                      expression: "collectionLoading",
                    },
                  ],
                  staticClass: "mt20",
                  attrs: {
                    data: _vm.collectionData.data,
                    height: "400",
                    stripe: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticClass: "w50 h50",
                              attrs: { src: scope.row.image, alt: "" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "名称", prop: "name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.collection
                              .map(function (item) {
                                return item.id
                              })
                              .includes(scope.row.id)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectCollection(
                                          scope.row,
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              已选择\n            "
                                    ),
                                  ]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectCollection(
                                          scope.row,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "25px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: !_vm.collection.length },
                          on: { click: _vm.addCollection },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                      layout: "total, sizes, prev, pager, next, jumper",
                      "page-size": _vm.collectionConfig.pageSize,
                      "current-page": _vm.collectionConfig.pageNum,
                      total: _vm.collectionData.total,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.collectionConfig, "pageNum", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.collectionConfig, "pageNum", $event)
                      },
                      "current-change": _vm.getList,
                      "size-change": _vm.handleUserSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
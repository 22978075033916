"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "airdropList",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      setTimeObj: {
        type: 0,
        afterTime: 0,
        isAutoGet: 0
      },
      radio1: "0",
      recive: {
        status: 1,
        type: "",
        ids: [],
        list: []
      },
      reviceData: [],
      reviceShow: false,
      // 回收弹框
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      detailData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        pageSize: 20
      },
      userflag: false,
      newlistLoading: true,
      newtableData: {
        data: [],
        total: 0
      },
      newtableFrom: {
        page: 1,
        pageSize: 20,
        status: 1,
        phone: ""
      },
      isShowAddInner: false
    };
  },
  filters: {
    formatType: function formatType(val) {
      var res = "";
      switch (val) {
        case 1:
          res = "作品";
          break;
        case 2:
          res = "盲盒";
          break;
        case 3:
          res = "积分";
          break;
        case 4:
          res = "优惠券";
          break;
        case 5:
          res = "内丹";
          break;
        case 6:
          res = "成长值";
          break;
        default:
          break;
      }
      return res;
    },
    formatAirdropStatus: function formatAirdropStatus(val) {
      var res = "";
      switch (val) {
        case 0:
          res = "待开始";
          break;
        case 1:
          res = "投放中";
          break;
        case 2:
          res = "已投放";
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    this.getAirdropSets();
    (0, _animal.isShowApi)().then(function (res) {
      _this.isShowAddInner = res.data;
    });
  },
  methods: {
    getAirdropSets: function getAirdropSets() {
      var _this2 = this;
      (0, _product.getAirdropSet)({}).then(function (res) {
        _this2.setTimeObj = res.data;
      });
    },
    updateAirdropSets: function updateAirdropSets() {
      var _this3 = this;
      if (this.setTimeObj.isAutoGet) {
        this.setTimeObj.type = 0;
        this.setTimeObj.afterTime = "";
      }
      (0, _product.updateAirdropSet)(this.setTimeObj).then(function (res) {
        _this3.$message.success("设置成功");
      });
    },
    reciveClick: function reciveClick() {
      var _this4 = this;
      var recycless = this.recive.list.map(function (item, index) {
        return {
          productId: item.productId,
          type: item.type
        };
      });
      var data = {
        status: this.recive.status,
        recycles: recycless,
        airdropId: this.recive.airdropId,
        userId: this.recive.userId
      };
      (0, _product.airdropRecycle)(data).then(function (res) {
        _this4.$message.success("已回收");
        _this4.reviceShow = false;
        _this4.userflag = false;
        _this4.getList(1);
      });
    },
    changeCon: function changeCon(event, val) {
      console.log(event, val, 307);
      var lists = this.recive.list.map(function (m) {
        return m.productName;
      });
      var index = lists.indexOf(val.productName);
      if (index == -1) {
        this.recive.list.push(val);
      } else {
        this.recive.list.splice(index, 1);
      }
    },
    radioRecive: function radioRecive(val) {},
    reviceBtn: function reviceBtn(row) {
      var _this5 = this;
      var data = {
        airdropId: row.airdropId,
        userId: row.userId
      };
      this.recive.list = [];
      this.recive.ids = [];
      this.recive.userId = row.userId;
      this.recive.status = 1;
      this.recive.airdropId = row.airdropId;
      (0, _product.getAirdropContent)(data).then(function (res) {
        console.log(res.data, 304);
        _this5.reviceData = res.data;
        _this5.reviceShow = true;
      });
    },
    // 点击获取盲盒/ 优惠券/商品的具体详情
    clickDetail: function clickDetail(row, type) {
      var _this6 = this;
      var data = {
        airdropId: row.airdropId,
        userId: row.userId,
        type: type
      };
      (0, _product.getUserAirdropRecord)(data).then(function (res) {
        _this6.detailData = res;
      });
    },
    showuser: function showuser(row) {
      this.newtableFrom.id = row.id;
      this.newtableFrom.type = row.type;
      this.userflag = true;
      this.getuserList(1);
    },
    getuserList: function getuserList(num) {
      var _this7 = this;
      this.newlistLoading = true;
      this.newtableFrom.page = num || this.tableFrom.page;
      // this.newtableFrom.id = this.sid;
      (0, _product.getAirdropRecord)(this.newtableFrom).then(function (res) {
        console.log(res, 252);
        _this7.newtableData.data = res.rows;
        _this7.newtableData.total = res.total;
        _this7.newlistLoading = false;
      }).catch(function (res) {
        _this7.newlistLoading = false;
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this8 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      (0, _product.airdropListApi)(this.tableFrom).then(function (res) {
        _this8.tableData.data = res.rows;
        _this8.tableData.total = res.total;
        _this8.listLoading = false;
      }).catch(function (res) {
        _this8.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    newpageChange: function newpageChange(page) {
      this.newtableFrom.pageNum = page;
      this.getuserList();
    },
    newhandleSizeChange: function newhandleSizeChange(val) {
      this.newtableFrom.pageSize = val;
      this.getuserList();
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push({
        path: _settings.roterPre + "/product/addAirdrop"
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      this.$router.push({
        path: _settings.roterPre + "/product/addAirdrop",
        query: {
          id: id
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this9 = this;
      // this.$modalSure().then(() => {
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664\uFF1F", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _product.airdropdel)({
          id: id
        }).then(function (_ref) {
          var message = _ref.message;
          _this9.$message.success("删除成功");
          _this9.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      }).catch(function () {});
      // });
    },
    exportRecord: function exportRecord() {
      var _this10 = this;
      (0, _product.airdropExport)(this.newtableFrom).then(function (res) {
        _this10.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList(code) {
      this.$refs.exportList.exportFileList(code);
    }
  }
};
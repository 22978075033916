"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// 请求接口地址 如果没有配置自动获取当前网址路径
var VUE_APP_API_URL = process.env.VUE_APP_BASE_API || "".concat(location.origin);
var h5Link = localStorage.getItem("h5Link");
var XCX_APP_APPURL = process.env.VUE_APP_APPURL || "".concat(location.origin);
var VUE_APP_WS_URL = process.env.VUE_APP_WS_URL || (location.protocol === "https:" ? "wss" : "ws") + ":" + location.hostname;
var login_title = _jsCookie.default.get("MerInfo") ? JSON.parse(_jsCookie.default.get("MerInfo")).login_title : "";
var url = location.hostname.split(".")[0];
var SettingMer = {
  // 服务器地址
  httpUrl: "",
  // 接口请求地址
  https: "",
  // socket连接
  wsSocketUrl: VUE_APP_WS_URL,
  appUrl: h5Link || XCX_APP_APPURL,
  // 路由标题
  // title: login_title || '商户商城'
  title: " "
};
var _default = exports.default = SettingMer;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("添加属性")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData.data, size: "mini" } },
            [
              _c("el-table-column", {
                attrs: { label: "id", prop: "attr_id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "属性名称",
                  prop: "attr_name",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "属性类型",
                  prop: "attr_type",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.attr_type == 1
                          ? _c("span", [_vm._v("输入框")])
                          : scope.row.attr_type == 2
                          ? _c("span", [_vm._v("单选")])
                          : scope.row.attr_type == 3
                          ? _c("span", [_vm._v("多选")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "关联分类",
                  prop: "cate_name",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "100%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加属性",
            visible: _vm.dialogEditAttrVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditAttrVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "属性名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "属性类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("输入框"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.type != 1
                ? _c("el-form-item", { attrs: { label: "属性值" } }, [
                    _c(
                      "div",
                      { staticClass: "attr-value-content" },
                      _vm._l(_vm.formValidate, function (m, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "attr-value" },
                          [
                            _c(
                              "div",
                              { staticClass: "attr-value-left" },
                              [
                                _c(
                                  "el-form",
                                  { attrs: { "label-width": "100px" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "属性值名称" } },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: m.value,
                                            callback: function ($$v) {
                                              _vm.$set(m, "value", $$v)
                                            },
                                            expression: "m.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mt20",
                                        attrs: { label: "选中前图片" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "upLoadPicBox",
                                            staticStyle: { background: "#fff" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.modalPicTap(
                                                  "1",
                                                  "brforeImg",
                                                  i
                                                )
                                              },
                                            },
                                          },
                                          [
                                            m.brforeImg
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "pictrue",
                                                    staticStyle: {
                                                      margin: "0",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: m.brforeImg,
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "upLoad" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-camera cameraIconfont",
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mt20",
                                        attrs: { label: "选中后图片" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "upLoadPicBox",
                                            staticStyle: { background: "#fff" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.modalPicTap(
                                                  "1",
                                                  "afterImg",
                                                  i
                                                )
                                              },
                                            },
                                          },
                                          [
                                            m.afterImg
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "pictrue",
                                                    staticStyle: {
                                                      margin: "0",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: m.afterImg,
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "upLoad" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-camera cameraIconfont",
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "attr-value-right" }, [
                              _c("div", { on: { click: _vm.addAttr } }, [
                                _c("i", {
                                  staticClass: "el-icon-plus",
                                  staticStyle: { "font-size": "20px" },
                                }),
                              ]),
                              _vm._v(" "),
                              i > 0
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeAttr(i)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-minus",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              i > 0
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.goUpAttrValAction(i)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-top",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt20",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogEditAttrVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
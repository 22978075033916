var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.goRouter("AuctionCountdownAdd")
                },
              },
            },
            [_vm._v("创建倒计时抽奖")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.goRouter("AuctionRandomAdd")
                },
              },
            },
            [_vm._v("创建随机抽奖")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.searchForm.type,
            callback: function ($$v) {
              _vm.$set(_vm.searchForm, "type", $$v)
            },
            expression: "searchForm.type",
          },
        },
        _vm._l(_vm.tabs, function (item) {
          return _c("el-tab-pane", {
            key: item.type,
            attrs: { label: item.name, name: item.type },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.currentTab.listLoading,
              expression: "currentTab.listLoading",
            },
          ],
          staticStyle: { width: "100%", color: "#000" },
          attrs: { data: _vm.currentTab.list, "highlight-current-row": "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "id",
              align: "center",
              "min-width": "130",
              fixed: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "活动名称",
              prop: "name",
              align: "center",
              "min-width": "120",
              fixed: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "藏品名称",
              prop: "productName",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "投放数量",
              prop: "score",
              align: "center",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "积分类型",
              prop: "scoreTypeName",
              align: "center",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "消耗积分",
              prop: "score",
              align: "center",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "开始时间",
              prop: "startTime",
              align: "center",
              "min-width": "160",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.getStatus(row.status)))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "160",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    (
                      _vm.currentTab.type == 1
                        ? scope.row.status === -1
                        : scope.row.status === 1 || scope.row.status === -1
                    )
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text", link: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          中奖名单\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text", link: "" },
                            on: {
                              click: function ($event) {
                                return _vm.goRouterSave(
                                  _vm.searchForm.type,
                                  {},
                                  { id: scope.row.id }
                                )
                              },
                            },
                          },
                          [_vm._v("编辑\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text", link: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCdAuction(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-box" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              layout: "total, sizes, prev, pager, next, jumper",
              "page-size": _vm.currentTab.listQuery.pageSize,
              "current-page": _vm.currentTab.listQuery.pageNum,
              total: _vm.currentTab.listQuery.total,
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.currentTab.listQuery, "pageNum", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.currentTab.listQuery, "pageNum", $event)
              },
              "current-change": function ($event) {
                return _vm.getList()
              },
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.dialogTab,
                callback: function ($$v) {
                  _vm.dialogTab = $$v
                },
                expression: "dialogTab",
              },
            },
            _vm._l(_vm.dialogTabs, function (item) {
              return _c("el-tab-pane", {
                key: item.type,
                attrs: { label: item.name, name: item.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.topLoading,
                  expression: "topLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.topList },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "昵称", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "number", label: "序号", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "参与时间",
                  "min-width": "170",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-box" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.dialogPage.pageSize,
                  "current-page": _vm.dialogPage.pageNum,
                  total: _vm.dialogPage.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.dialogPage, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.dialogPage, "pageNum", $event)
                  },
                  "current-change": function ($event) {
                    return _vm.getTopList()
                  },
                  "size-change": _vm.handleDialogSizeChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBalanceList = getBalanceList;
exports.getEntryList = getEntryList;
exports.getExport = getExport;
exports.getExportDetail = getExportDetail;
exports.getPay = getPay;
exports.getPayInfoApi = getPayInfoApi;
exports.getPayOrderList = getPayOrderList;
exports.getWithdrawalList = getWithdrawalList;
exports.getwithdrawal = getwithdrawal;
exports.updateInfoApi = updateInfoApi;
exports.withdrawalApproval = withdrawalApproval;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 获取提现记录 -- 列表
 */
function getWithdrawalList(data) {
  return _request.default.get("/pay/sys/withdrawal/list", data);
}

/**
 * @description 获取支付记录 -- 列表
 */
function getPayOrderList(data) {
  return _request.default.get("/pay/sys/pay/order/list", data);
}

/**
 * @description 余额明细 -- 列表
 */
function getBalanceList(data) {
  return _request.default.get("/member/sys/member/wallet/list", data);
}

/**
 * @description 入账明细 -- 列表
 */
function getEntryList(data) {
  return _request.default.get("/member/sys/member/wallet/account", data);
}

/**
 * @description 余额明细导出
 */
function getExport(data) {
  return _request.default.get("/member/sys/member/wallet/export", data);
}

/**
 * @description 出账入账明细
 */
function getExportDetail(data) {
  return _request.default.get("/member/sys/member/wallet/account/export", data);
}

/**
 * @description 提现明细
 */
function getwithdrawal(data) {
  return _request.default.get("/pay/sys/withdrawal/export", data);
}

/**
 * @description 支付明细
 */
function getPay(data) {
  return _request.default.get("/pay/sys/pay/order/export", data);
}

/**
 * @description 是否同意体现 /pay/sys/withdrawal/approval
 */
function withdrawalApproval(data) {
  return _request.default.post("pay/sys/withdrawal/approval", data);
}
//获取提现设置
function getPayInfoApi(data) {
  return _request.default.get("/pay/sys/withdrawal/config/info ", data);
}
//修改提现设置

function updateInfoApi(data) {
  return _request.default.put("/pay/sys/withdrawal/config/update", data);
}
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _animal = require("@/api/animal");
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AnimalDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      selectType: "",
      outPutData: [],
      fertilizerData: [],
      selectIndex: -1
    };
  },
  mounted: function mounted() {
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.getGuardInfoApi)().then(function (res) {
        console.log(res);
        var animalsProduceOrExchanges = res.data;
        var outPutData = animalsProduceOrExchanges.filter(function (item) {
          return item.type == 1;
        });
        var fertilizerData = animalsProduceOrExchanges.filter(function (item) {
          return item.type == 2;
        });
        _this.fertilizerData = fertilizerData;
        _this.outPutData = outPutData;
        _this.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    selectAnimal: function selectAnimal() {
      this.$refs["product_nft"].changeshow();
      this.selectType = "selectAnimal";
    },
    addOutPut: function addOutPut() {
      this.outPutData.push({
        type: 1,
        productId: "",
        productName: "",
        productImage: "",
        downOrUpNum: 1
      });
    },
    addFertilizer: function addFertilizer() {
      this.fertilizerData.push({
        type: 2,
        productId: "",
        productName: "",
        productImage: "",
        downOrUpNum: 1
      });
    },
    selectMessage: function selectMessage(data) {
      var _data$data$ = data.data[0],
        productId = _data$data$.productId,
        image = _data$data$.image,
        name = _data$data$.name;
      if (this.selectType === "addGuard") {
        var idx = this.outPutData.findIndex(function (item) {
          return item.productId == productId;
        });
        if (idx > -1) {
          return this.$message.error("已存在,请重新选择");
        } else {
          this.outPutData[this.selectIndex].productId = productId;
          this.outPutData[this.selectIndex].productImage = image;
          this.outPutData[this.selectIndex].productName = name;
        }
      } else if (this.selectType === "addFertilizer") {
        var _idx = this.fertilizerData.findIndex(function (item) {
          return item.productId == productId;
        });
        if (_idx > -1) {
          return this.$message.error("已存在,请重新选择");
        } else {
          this.fertilizerData[this.selectIndex].productId = productId;
          this.fertilizerData[this.selectIndex].productImage = image;
          this.fertilizerData[this.selectIndex].productName = name;
        }
      }
      this.selectType = "";
      this.selectIndex = -1;
    },
    selectOutPut: function selectOutPut(type, idx) {
      console.log(idx);
      if (idx > -1) {
        this.selectIndex = idx;
      }
      if (type === "guard") {
        this.selectType = "addGuard";
      } else {
        this.selectType = "addFertilizer";
      }
      this.$refs["product_nft"].changeshow();
    },
    deleteOutOut: function deleteOutOut(idx) {
      this.outPutData.splice(idx, 1);
    },
    deleteFertilizer: function deleteFertilizer(idx) {
      this.fertilizerData.splice(idx, 1);
    },
    submit: function submit() {
      var _this2 = this;
      var err = "";
      if (!this.outPutData.length) {
        err = "请添加产出";
      }
      // let idx = this.outPutData.findIndex(item => {
      //   return item.productId != "";
      // });
      // let fIdx = this.fertilizerData.findIndex(item => {
      //   return item.productId != "";
      // });
      // console.log(idx, fIdx);
      // if (idx == -1 || fIdx == -1) {
      //   err = "请补全信息";
      // }
      if (err) {
        return this.$message.error(err);
      }
      var data = {
        savageryGuards: [].concat((0, _toConsumableArray2.default)(this.outPutData), (0, _toConsumableArray2.default)(this.fertilizerData))
      };
      if (this.$route.query.id) {
        data.id = this.$route.query.id;
      }
      var loadingInstance = _elementUi.Loading.service();
      (0, _animal.createGuardApi)(data).then(function (res) {
        _this2.$message.success("添加成功");
        _this2.goBack();
        _this2.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (_) {
        _this2.$nextTick(function () {
          loadingInstance.close();
        });
      });
    }
  }
};
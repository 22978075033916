var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rule-table-height" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            data: _vm.list,
            "span-method": _vm.objectSpanMethod,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "规则设置", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "规则详情", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        scope.row.name == "积分抵现比例"
                          ? [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.ruleDescription + ",")),
                              ]),
                              _vm._v(" "),
                              scope.row.isOpen
                                ? _c("div", [_vm._v("已开启抵现")])
                                : _c("div", [_vm._v("已关闭抵现")]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.name == "积分获得比例"
                          ? [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.ruleDescription + ",")),
                              ]),
                              _vm._v(" "),
                              scope.row.isOpen
                                ? _c("div", [_vm._v("已开启获取")])
                                : _c("div", [_vm._v("已关闭获取")]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.name != "积分抵现比例" &&
                        scope.row.name != "积分获得比例"
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.ruleDescription)),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.updateTime || "-"))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == "switch"
                      ? [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#cccccc",
                              "active-value": "1",
                              "inactive-value": "0",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.editSwitchAction(
                                  scope.row.value,
                                  scope
                                )
                              },
                            },
                            model: {
                              value: scope.row.value,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "value", $$v)
                              },
                              expression: "scope.row.value",
                            },
                          }),
                        ]
                      : [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.settingAction(scope.row)
                                },
                              },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            data: _vm.ruleList,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "积分奖励规则", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "规则详情", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.ruleDescription))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.updateTime || "-"))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.settingAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("设置")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            "close-on-click-modal": false,
            visible: _vm.visible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(_vm._s(_vm.setData.title)),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.setData.desc))]),
          _vm._v(" "),
          _c("el-row", { staticClass: "mt10" }, [
            _vm.setData.setType > 1 && _vm.setData.setType < 8
              ? _c(
                  "div",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _vm.setData.setType == 2
                          ? _c("span", [_vm._v("自定义名称：")])
                          : _vm.setData.setType == 3
                          ? _c("span", [_vm._v("修改有效期：")])
                          : _vm.setData.setType > 3 && _vm.setData.setType < 8
                          ? _c("span", [_vm._v("获得积分后")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "w150",
                          attrs: {
                            "max-length": 12,
                            placeholder: _vm.setData.tiplaceholderValuele,
                          },
                          model: {
                            value: _vm.setData.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.setData, "value", $$v)
                            },
                            expression: "setData.value",
                          },
                        }),
                        _vm._v(" "),
                        _vm.setData.setType > 3 && _vm.setData.setType < 8
                          ? _c("span", [_vm._v("天后解锁")])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.setData.setType >= 8 && _vm.setData.setType <= 11
              ? _c(
                  "div",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("积分："),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w150",
                            attrs: {
                              "max-length": 12,
                              placeholder: _vm.setData.tiplaceholderValuele,
                            },
                            model: {
                              value: _vm.setData.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "value", $$v)
                              },
                              expression: "setData.value",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v("抵扣1元"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c mt10" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("抵扣积分类型："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "选择积分类型" },
                              model: {
                                value: _vm.setData.scoreTypeIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.setData, "scoreTypeIds", $$v)
                                },
                                expression: "setData.scoreTypeIds",
                              },
                            },
                            _vm._l(_vm.typeList, function (m, i) {
                              return _c("el-option", {
                                key: "type" + i,
                                attrs: { value: m.id, label: m.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c mt20" },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.setData.isOpen,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "isOpen", $$v)
                              },
                              expression: "setData.isOpen",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v("启用积分抵扣"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.setData.setType >= 12
              ? _c(
                  "div",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _vm.setData.setType == 16
                            ? _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("每邀请1人可得："),
                              ])
                            : _vm.setData.setType == 18
                            ? _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("下载APP获得："),
                              ])
                            : _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("每消费："),
                              ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w150",
                            attrs: {
                              "max-length": 12,
                              placeholder: _vm.setData.tiplaceholderValuele,
                            },
                            model: {
                              value: _vm.setData.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "value", $$v)
                              },
                              expression: "setData.value",
                            },
                          }),
                          _vm._v(" "),
                          _vm.setData.setType == 16
                            ? _c("span", { staticClass: "ml10" }, [
                                _vm._v("积分"),
                              ])
                            : _vm.setData.setType == 18
                            ? _c("span", { staticClass: "ml10" }, [
                                _vm._v("积分"),
                              ])
                            : _c("span", { staticClass: "ml10" }, [
                                _vm._v("元可获得1积分"),
                              ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.setData.setType != 18
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt10" },
                            [
                              _c("span", { staticStyle: { width: "130px" } }, [
                                _vm._v("每日上限："),
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "w150",
                                attrs: {
                                  "max-length": 12,
                                  placeholder: _vm.setData.placeholderUpLimit,
                                },
                                model: {
                                  value: _vm.setData.value2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setData, "value2", $$v)
                                  },
                                  expression: "setData.value2",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("积分"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c mt10" },
                        [
                          _c("span", { staticStyle: { width: "130px" } }, [
                            _vm._v("获得积分类型："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "选择积分类型" },
                              model: {
                                value: _vm.setData.scoreTypeIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.setData, "scoreTypeIds", $$v)
                                },
                                expression: "setData.scoreTypeIds",
                              },
                            },
                            _vm._l(_vm.typeList, function (m, i) {
                              return _c("el-option", {
                                key: "type" + i,
                                attrs: { value: m.id, label: m.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.setData.setType == 15
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt20" },
                            [
                              _c("span", { staticStyle: { width: "130px" } }),
                              _vm._v(" "),
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.setData.value3,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.setData, "value3", $$v)
                                    },
                                    expression: "setData.value3",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("服务费部分获得积分"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("消费全额获得积分"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.setData.setType <= 15
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt20" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#cccccc",
                                },
                                model: {
                                  value: _vm.setData.isOpen,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setData, "isOpen", $$v)
                                  },
                                  expression: "setData.isOpen",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("启用获得积分"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.setData.setType == 16
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c mt20" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#cccccc",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.setData.value3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setData, "value3", $$v)
                                  },
                                  expression: "setData.value3",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("实名后才可获得"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.saveAction },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t ft14 fWeight500 mt20" }, [
      _c("span", [_vm._v("积分规则设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "bg" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t ft14 fWeight500 mt40" }, [
      _c("span", [_vm._v("积分奖励规则设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "bg" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
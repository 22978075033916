"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWantBuyConfigApi = getWantBuyConfigApi;
exports.getWantBuyListApi = getWantBuyListApi;
exports.getWantBuyRecordListApi = getWantBuyRecordListApi;
exports.setWantBuyConfigApi = setWantBuyConfigApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 获取求购所有配置
 */
function getWantBuyConfigApi(data) {
  return _request.default.get("/market/sys/want/buy/config", data);
}
/**
 * @description 修改求购设置
 */
function setWantBuyConfigApi(data) {
  return _request.default.put("/market/sys/want/buy/config", data);
}

/**
 * @description 获取求购明细列表
 */
function getWantBuyRecordListApi(id) {
  return _request.default.get("/market/sys/want/buy/recordList/".concat(id));
}

/**
 * @description 获取求购列表
 */
function getWantBuyListApi(data) {
  return _request.default.get("/market/sys/want/buy/list", data);
}
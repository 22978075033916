var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", {}, [
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(1)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [_vm._v("用户隐私协议")]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(2)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [_vm._v("用户服务协议")]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(3)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [
              _vm._v("数字藏品用户协议"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(4)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [
              _vm._v("数字藏品隐私规则"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(5)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [
              _vm._v("连连支付服务协议-用户协议"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(6)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [
              _vm._v("连连支付隐私权政策"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(7)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [_vm._v("寄售交易协议")]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            on: {
              click: function ($event) {
                return _vm.setRuleAction(8)
              },
            },
          },
          [
            _c("div", { staticClass: "l flex-one" }, [_vm._v("求购协议")]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _product = require("@/api/product");
var _timing = require("@/api/timing.js");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TimingSetting',
  data: function data() {
    return {
      snapshotOptions: [],
      searchPages: {
        pageNum: 1,
        pageSize: 500
      },
      formData: {
        marketUserIds: [],
        // 可寄售用户
        scanWhiteList: [],
        // 扫描白名单
        lockMarketProbability: '',
        // 概率
        lockMarketWhiteList: [],
        // 锁单白名单
        lockMarketType: 1
      },
      config: {}
    };
  },
  created: function created() {
    this.getSnapshot();
    this.getScanConfig();
  },
  methods: {
    getSnapshot: function getSnapshot(reset) {
      var _this = this;
      if (reset) {
        this.searchPages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)({}, this.searchPages);
      (0, _product.snapshotListApi)(params).then(function (res) {
        _this.snapshotOptions = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 获取扫描寄售藏品
    getScanConfig: function getScanConfig() {
      var _this2 = this;
      (0, _timing.getScanConfigApi)().then(function (res) {
        if (res.code === 200) {
          var data = res.data || {};
          _this2.config = data;
          _this2.formData = _this2.config;
          var arrayList = ['marketUserIds', 'scanWhiteList', 'lockMarketWhiteList'];
          arrayList.forEach(function (e) {
            _this2.formData[e] = _this2.getLockorder(e);
          });
          _this2.formData.lockMarketProbability = _this2.config.lockMarketProbability;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    numInput: function numInput(name, piont, min, max) {
      this.formData[name] = (0, _index.numInput)(this.formData[name], piont, min, max);
    },
    getLockorder: function getLockorder(key) {
      var lock = [];
      if (this.config && this.config[key]) {
        try {
          lock = this.config[key].split(',');
        } catch (_unused) {
          lock = [];
        }
      }
      return lock;
    },
    submitForm: function submitForm() {
      var params = {};
      for (var i in this.formData) {
        var val = this.formData[i];
        if (Array.isArray(val)) {
          params[i] = val.join(',');
        } else {
          params[i] = val;
        }
      }
      this.setScanConfig((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.config), params));
    },
    // 修改扫描寄售设置
    setScanConfig: function setScanConfig(config, type) {
      var _this3 = this;
      if (this.btnLoading) {
        return;
      }
      var params = (0, _objectSpread2.default)({}, config);
      if (type === 1) {
        params.hasAutoLockMarket = !params.hasAutoLockMarket;
      }
      this.btnLoading = true;
      (0, _timing.setScanConfigApi)(params).then(function (res) {
        if (res.code === 200) {
          _this3.getScanConfig();
          if (_this3.marketDownVisible) {
            _this3.marketDownVisible = false;
          }
          _this3.btnLoading = false;
          _this3.$message.success(res.msg);
        } else {
          _this3.btnLoading = false;
          _this3.$message.error(res.msg);
        }
      }).catch(function (err) {
        _this3.btnLoading = false;
        console.log(err);
      });
    }
  }
};
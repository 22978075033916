"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find");
var _coundownauction = require("@/api/coundownauction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tabs: [{
        type: '1',
        name: '倒计时抽奖',
        getApi: _coundownauction.getCdAuctionListApi,
        deleteApi: _coundownauction.deleteCdAuctionApi,
        list: [],
        listLoading: true,
        listQuery: {
          pageNum: 1,
          pageSize: 10,
          total: 0
        }
      }, {
        type: '2',
        name: '随机抽奖',
        getApi: _coundownauction.getAuctionRandomListApi,
        deleteApi: _coundownauction.deleteAuctionRandomApi,
        list: [],
        listLoading: true,
        listQuery: {
          pageNum: 1,
          pageSize: 10,
          total: 0
        }
      }],
      searchForm: {
        type: '1'
      },
      dialogVisible: false,
      currentId: '',
      topList: [],
      dialogPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dialogTab: '1',
      topLoading: false,
      dialogTabs: [{
        name: '中奖名单',
        type: '1'
      }, {
        name: '未中奖名单',
        type: '2'
      }]
    };
  },
  computed: {
    currentTab: function currentTab() {
      var _this = this;
      return this.tabs.find(function (e) {
        return e.type === _this.searchForm.type;
      });
    }
  },
  watch: {
    'searchForm.type': function searchFormType(val) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          type: val
        }
      });
      this.getList();
    },
    dialogTab: function dialogTab(val) {
      this.getTopList(1);
    }
  },
  created: function created() {
    this.init();
    this.getList();
  },
  methods: {
    init: function init() {
      var _this2 = this;
      if (this.$route.query.type) {
        var item = this.tabs.find(function (e) {
          return Number(e.type) === Number(_this2.$route.query.type);
        });
        if (item) {
          this.searchForm.type = item.type;
        }
      }
    },
    getList: function getList(reset) {
      var _this3 = this;
      var tab = this.tabs.find(function (e) {
        return e.type === _this3.searchForm.type;
      });
      if (reset) {
        tab.listQuery.pageNum = 1;
      }
      tab.listLoading = true;
      tab.getApi((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), tab.listQuery)).then(function (res) {
        tab.list = res.rows;
        tab.listQuery.total = res.total;
        tab.listLoading = false;
      }).catch(function (err) {
        console.log(err);
      });
    },
    getStatus: function getStatus(status) {
      var statusObj = {
        '1': '进行中',
        '0': '未开始',
        '-1': '已结束'
      };
      console.log(statusObj[status]);
      return statusObj[status];
    },
    openDialog: function openDialog(id) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      this.currentId = id;
      this.dialogTab = type + '';
      this.getTopList(1);
      this.dialogVisible = true;
    },
    getTopList: function getTopList(reset) {
      var _this4 = this;
      this.topLoading = true;
      if (reset) {
        this.dialogPage.pageNum = 1;
      }
      var statusApi = this.searchForm.type === '1' ? _coundownauction.getStatusListApi : _coundownauction.getRandomStatusListApi;
      statusApi((0, _objectSpread2.default)({
        id: this.currentId,
        type: this.dialogTab
      }, this.dialogPage)).then(function (res) {
        if (res.code === 200) {
          _this4.topList = res.rows;
          _this4.dialogPage.total = res.total;
        } else {
          _this4.$message.error(res.msg);
        }
        _this4.topLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this4.$message.error(err.message);
        _this4.topLoading = false;
      });
    },
    deleteCdAuction: function deleteCdAuction(id) {
      var _this5 = this;
      this.$confirm('确认删除此抽奖活动么?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var tab = _this5.tabs.find(function (e) {
          return e.type === _this5.searchForm.type;
        });
        tab.deleteApi(id).then(function (res) {
          if (res.code === 200) {
            _this5.getList();
          }
          _this5.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.currentTab.listQuery.pageSize = val;
      this.getList(1);
    },
    handleDialogSizeChange: function handleDialogSizeChange(val) {
      this.dialogPage.pageSize = val;
      this.getTopList(1);
    },
    goRouter: function goRouter(name) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.$router.push({
        name: name,
        query: query,
        params: params
      });
    },
    goRouterSave: function goRouterSave(type) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var name;
      if (type === '1') {
        name = 'AuctionCountdownInfo';
      } else {
        name = 'AuctionRandomInfo';
      }
      this.$router.push({
        name: name,
        query: query,
        params: params
      });
    }
  }
};
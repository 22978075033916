var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c("ul", { staticClass: "search-box" }, [
        _c(
          "li",
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.searchForm.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "status", $$v)
                  },
                  expression: "searchForm.status",
                },
              },
              _vm._l(_vm.statusList, function (item, index) {
                return _c(
                  "el-radio-button",
                  { key: index, attrs: { label: item.value } },
                  [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "w150" },
          [
            _c("el-input", {
              attrs: { placeholder: "藏品名称", size: "small", clearable: "" },
              on: { input: _vm.searchInput },
              model: {
                value: _vm.searchForm.productName,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "productName", $$v)
                },
                expression: "searchForm.productName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "w150" },
          [
            _c("el-input", {
              attrs: { placeholder: "用户名", size: "small", clearable: "" },
              on: { input: _vm.searchInput },
              model: {
                value: _vm.searchForm.nickName,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "nickName", $$v)
                },
                expression: "searchForm.nickName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("el-date-picker", {
              attrs: {
                "value-format": "yyyy-MM-dd",
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                size: "small",
              },
              on: { change: _vm.changeTime },
              model: {
                value: _vm.time,
                callback: function ($$v) {
                  _vm.time = $$v
                },
                expression: "time",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%", color: "#000" },
          attrs: { data: _vm.list, "highlight-current-row": "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "用户名",
              prop: "id",
              align: "center",
              "min-width": "130",
              fixed: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.memberId
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", link: "" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(row.nickName) +
                                " " +
                                _vm._s(row.phone) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "求购藏品",
              prop: "productName",
              align: "center",
              "min-width": "120",
              fixed: "left",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "求购价格",
              prop: "wantBuyPrice",
              align: "center",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "已购/求购数量",
              prop: "score",
              align: "center",
              "min-width": "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", link: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openDialog(row.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.buyCount) +
                            "/" +
                            _vm._s(row.wantBuyCount) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              prop: "createTime",
              align: "center",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.getStatus(row.status)))]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-box" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              layout: "total, sizes, prev, pager, next, jumper",
              "page-size": _vm.listQuery.pageSize,
              "current-page": _vm.listQuery.pageNum,
              total: _vm.listQuery.total,
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNum", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNum", $event)
              },
              "current-change": function ($event) {
                return _vm.getList()
              },
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.topLoading,
                  expression: "topLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.topList },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "卖出用户",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.memberId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", link: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.nickName) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "number", label: "编号", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "购买时间",
                  "min-width": "100",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var financeRouter = {
  path: "".concat(_settings.roterPre, "/finance"),
  name: 'finance',
  meta: {
    icon: 'dashboard',
    title: '财务管理'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'balance',
    name: 'Balance',
    meta: {
      title: '站内余额'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/balance/index.vue'));
      });
    }
  }, {
    path: 'withdrawal_details',
    name: 'WithdrawalDetails',
    meta: {
      title: '提现明细'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/withdrawalDetails/index.vue'));
      });
    }
  }, {
    path: 'payment_order',
    name: 'PaymentOrder',
    meta: {
      title: '支付订单'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/paymentOrder/index.vue'));
      });
    }
  }, {
    path: 'separate',
    name: 'separate',
    meta: {
      title: '分账配置'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/finance/separate.vue'));
      });
    }
  }]
};
var _default = exports.default = financeRouter;
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import colour from "@/components/customPage/colour.vue";
var _default = exports.default = {
  name: "plantConfig",
  // components: {
  //   colour
  // },
  data: function data() {
    return {
      formState: {
        isConfig: false,
        //是否启用配置：
        growBackGround: '',
        //土地列表背景
        goBackGround: '',
        //返回按钮
        sowBackground: '',
        //种子背景
        seedBackGround: '',
        //播种按钮
        harvestBackGround: '',
        //收获按钮
        leaseBackGround: '',
        //租赁按钮
        closeBackGround: '',
        //关闭按钮
        confirmBackGround: '' //确定按钮
      }
    };
  },
  created: function created() {
    var _this = this;
    (0, _animal.getSetSkinApi)().then(function (res) {
      console.log('1---', res);
      if (res.code == 200) {
        _this.formState = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this.formState), res.data);
      }
    });
  },
  methods: {
    modalPicTap: function modalPicTap(file) {
      var that = this;
      this.$modalUpload(function (img) {
        that.formState[file] = img[0];
      }, 1);
    },
    onSubmit: function onSubmit() {
      (0, _animal.updateSetSkinApi)(this.formState).then(function (res) {
        console.log(res);
      });
    }
  }
};
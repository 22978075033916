"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _nft = require("@/api/nft");
var _product = require("@/api/product");
var _index = require("@/utils/index");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "addBlindBox",
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      currentTab: 0,
      fullscreenLoading: false,
      formValidate: {
        image: "",
        leftimage: "",
        rightimage: "",
        topimage: "",
        title: "",
        box_image: "",
        start_time: "",
        issuer_num: "",
        reserved_number: "",
        buy_limit_number: "",
        album_id: ""
      },
      ruleValidate: {
        title: [{
          required: true,
          message: "请输入盲盒名称",
          trigger: "blur"
        }],
        start_time: [{
          required: true,
          message: "请选择发售时间",
          trigger: "change"
        }],
        sale_price: [{
          required: true,
          message: "请输入发售价格",
          trigger: "blur"
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error("请输入正确的发售价格"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }],
        issuer_num: [{
          required: true,
          message: "请输入发售数量",
          trigger: "blur"
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error("请输入正确的发售数量"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }]
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 20
      },
      orderidArr: [],
      orderArr: [],
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      nftid: '',
      priceList: [{
        price_name: '',
        price: '',
        box_count: '1'
      }],
      type: '0',
      checkedLevel: [],
      levelList: [],
      boxConsigneeData: [],
      levelProduct: [],
      selectedLerverPid: '',
      selectedLerverPidIndex: '0',
      albumlist: []
    };
  },
  created: function created() {
    var _this2 = this;
    this.getList(1);
    (0, _product.blindboxSeriesListApi)().then(function (res) {
      _this2.albumlist = res.data.list;
    });
    // .replace(
    //           /https:\/\/saas.cdn.yunzongbu.cn/g,
    //           "https://saas.cdn.yunzongbu.cn"
    //         )
    // this.$nextTick(() => {
    //   var canvas = document.getElementById("canvas");
    //   var context = canvas.getContext("2d");
    //   var img = new Image();
    //   img.src = "https://saas.cdn.yunzongbu.cn/merchant/23/2022518/image/1652879927398736631.png!120a";
    //   // img.crossOrigin = "Anonymous";

    //   // context.transform(a,b,c,d,e,f);
    //   // a	水平缩放绘图 b	水平倾斜绘图 c	垂直倾斜绘图 d	垂直缩放绘图 e	水平移动绘图 f	垂直移动绘图
    //   img.onload = function () {
    //     // Top left 
    //     // context.rotate(0.7854);
    //     // context.transform(1,0.65,-0.5,1,30,10)

    //     context.transform(1,0,.5,.5,0,0);
    //     context.drawImage(img, 0, 0, 100, 100);
    //     context.setTransform(1,0,0,1,0,0);
    //     context.transform(.5,.5,0,1,0,0);
    //     context.drawImage(img, 0, 0, 100, 100);
    //     context.setTransform(1,0,0,1,0,0);
    //     context.transform(1,0,0,1,50,50);
    //     context.drawImage(img, 0, 0, 100, 100);
    //     //  var deg  =  Math.PI / 180 ;
    //     // context.transform(1,0,Math.sin(105*deg),1,0,0);
    //     // context.drawImage(img, 0, 0, 100, 60);
    //     // img.src = 'https://saas.cdn.yunzongbu.cn/merchant/23/2022518/image/1652854654212116396.png!120a'
    //     // img.onload = function () {
    //     //   context.setTransform(1,0,0,1,0,0);
    //     //   context.transform(1,Math.sin(75*deg),0,1,0,0);
    //     //   context.drawImage(img, 0, 0, 60, 100);
    //     //   img.src = 'https://saas.cdn.yunzongbu.cn/merchant/23/2022513/image/1652420141581904054.jpeg!120a'
    //     //   img.onload=function () {
    //     //       context.setTransform(1,0,0,1,0,0);
    //     //       context.transform(1,0,0,1,60,60);
    //     //       context.drawImage(img, 0, 0, 100, 100);
    //     //   }

    //     // }
    //     // console.log(canvas.toDataURL("image/png"));
    //   };
    // });
    this.blindboxLevelList().then(function (res) {
      if (_this2.$route.query.id) {
        //   this.currentTab = 1;
        _this2.nftid = _this2.$route.query.id;
        _this2.getBoxDetail(_this2.$route.query.id);
        _this2.blindboxGoodsList(_this2.$route.query.id);
      }
    });
  },
  methods: {
    blindboxLevelList: function blindboxLevelList() {
      var _this3 = this;
      return (0, _product.blindboxLevelListApi)().then(function (res) {
        _this3.levelList = res.data.filter(function (item) {
          return item.is_all != 1;
        });
        var json = {};
        _this3.levelList.map(function (item) {
          json[item.id] = item;
        });
        _this3.levelJson = json;
        console.log(_this3.levelJson);
      });
    },
    getBoxDetail: function getBoxDetail(id) {
      var _this4 = this;
      (0, _product.blindboxDetailApi)(id).then(function (res) {
        var data = res.data;
        var imagearr = data.box_image.split(",");
        _this4.formValidate = {
          image: data.image || imagearr[0],
          leftimage: [imagearr[0]],
          rightimage: imagearr[1] ? [imagearr[1]] : '',
          topimage: imagearr[2] ? [imagearr[2]] : '',
          title: data.product.store_name,
          box_image: data.box_image,
          start_time: data.start_time,
          issuer_num: data.issuer_num,
          // sale_number: data.sale_number,
          reserved_number: data.reserved_number,
          buy_limit_number: data.product.buy_limit_num,
          consignee_content: data.consignee_content,
          album_id: data.album_id
        };
        _this4.$refs['editor'].setContent(data.consignee_content);
        _this4.priceList = data.blindBoxPrice;
        data.blindBoxConsignee.forEach(function (item) {
          item.level_name = item.boxLevel.level_name;
        });
        _this4.boxConsigneeData = data.blindBoxConsignee;
        _this4.checkedLevel = data.blindBoxConsignee.map(function (item) {
          return item.box_level_id;
        });
        // data.boxProducts.forEach(item=>{
        //     this.orderidArr.push(item.product_id);
        //     item.issale = item.is_show == 1 ? "0" : "1";
        //     item.is_hide = item.is_hide+'';
        //     item.product = {
        //         image: item.image,
        //         stock: item.stock,
        //         store_name: item.store_name,
        //     }
        // });
        // console.log(data.boxProducts)
        // this.orderArr = data.boxProducts
      });
    },
    blindboxGoodsList: function blindboxGoodsList(id) {
      var _this5 = this;
      var that = this;
      (0, _product.blindboxGoodsListApi)({
        box_id: id
      }).then(function (res) {
        var data = res.data;
        var arr = [];
        var orderlist = [];
        for (var key in data) {
          var json = {};
          json.id = key;
          json.level_name = that.levelJson[key].level_name;
          json.level_id = key;
          json.count = data[key].length;
          json.product = data[key].map(function (item) {
            var itemjson = item;
            itemjson.issale = item.product.mer_status == 1 ? "0" : "1";
            itemjson.is_hide = item.is_hide + '';
            itemjson.store_name = item.product.store_name;
            itemjson.image = item.product.image;
            itemjson.stock = item.product.stock;
            return itemjson;
          });
          orderlist = orderlist.concat(data[key]);
          arr.push(json);
        }
        _this5.levelProduct = arr;
        _this5.selectedLerverPidIndex = 0;
        _this5.orderArr = JSON.parse(JSON.stringify(arr[0].product));
        _this5.orderidArr = orderlist.map(function (item) {
          return item.product_id;
        });
      });
    },
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      // nftCollectionListApi
      (0, _product.productLstApi)(this.queryParams).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
    },
    selectLevelProduct: function selectLevelProduct(e) {
      console.log(e.index);
      this.levelProduct[this.selectedLerverPidIndex].product = JSON.parse(JSON.stringify(this.orderArr));
      this.selectedLerverPidIndex = e.index;
      this.orderArr = JSON.parse(JSON.stringify(this.levelProduct[e.index].product)) || [];
    },
    changeafterc: function changeafterc() {
      if (this.boxConsigneeData.length > 0) {
        var allnum = 100;
        this.boxConsigneeData.forEach(function (item, index) {
          if (index > 0) {
            allnum = allnum - item.consignee_after;
          }
        });
        this.boxConsigneeData[0].consignee_after = Number(allnum.toFixed(3));
      }
    },
    handleCheckedLevelChange: function handleCheckedLevelChange(e) {
      // console.log(this.checkedLevel);
      // console.log(e);
      var newlevelPrdoct = JSON.parse(JSON.stringify(this.levelProduct));
      var newlevelPrdoctJson = {};
      newlevelPrdoct.forEach(function (item) {
        newlevelPrdoctJson[item.id] = item;
      });
      var newboxConsignee = JSON.parse(JSON.stringify(this.boxConsigneeData));
      var newboxConsigneeJson = {};
      newboxConsignee.forEach(function (item) {
        newboxConsigneeJson[item.id] = item;
      });
      var arr = [];
      var that = this;
      arr = this.levelList.filter(function (item) {
        return that.checkedLevel.indexOf(item.id) > -1;
      });
      this.boxConsigneeData = arr.map(function (item, index) {
        if (newboxConsigneeJson[item.id]) {
          return newboxConsigneeJson[item.id];
        } else {
          return {
            id: item.id,
            box_level_id: item.id,
            consignee_before: '',
            consignee_after: 0,
            need_buy_number: '',
            level_name: item.level_name
          };
        }
      });
      this.levelProduct = arr.map(function (item) {
        if (newlevelPrdoctJson[item.id]) {
          return newlevelPrdoctJson[item.id];
        } else {
          return {
            id: item.id,
            level_name: item.level_name,
            count: 0,
            product: []
          };
        }
      });
      var orderlist = [];
      this.levelProduct.forEach(function (item) {
        orderlist = orderlist.concat(item.product);
      });
      this.orderidArr = orderlist.map(function (item) {
        return item.product_id;
      });
      if (this.levelProduct && this.levelProduct[this.selectedLerverPidIndex]) {
        this.orderArr = JSON.parse(JSON.stringify(this.levelProduct[this.selectedLerverPidIndex].product)) || [];
      }
      this.changeafterc();
    },
    addPrice: function addPrice() {
      this.priceList.push({
        price_name: '',
        price: '',
        box_count: ''
      });
    },
    delPrice: function delPrice(index) {
      this.priceList.splice(index, 1);
    },
    atteLeavetooltip: function atteLeavetooltip(h, _ref) {
      var column = _ref.column,
        $index = _ref.$index;
      return [column.label, h("el-tooltip", {
        props: {
          effect: "dark",
          placement: "top",
          content: "开关打开后，此商品在商品列表中不显示。"
        }
      }, [h("span", {
        class: {
          "el-icon-question": true
        }
      })])];
    },
    submitForm: function submitForm() {
      var _this7 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          // if (!this.formValidate.leftimage) {
          //   this.$message.error("请上传左侧图片");
          //   return;
          // }
          if (!_this7.formValidate.image) {
            _this7.$message.error("请上传封面图片");
            return;
          }
          if (!_this7.formValidate.leftimage) {
            _this7.formValidate.leftimage = [_this7.formValidate.image];
          }
          if (_this7.formValidate.rightimage || _this7.formValidate.topimage) {
            if (!_this7.formValidate.rightimage) {
              _this7.$message.error("请上传右侧图片");
              return;
            }
            if (!_this7.formValidate.topimage) {
              _this7.$message.error("请上传顶部图片");
              return;
            }
            _this7.formValidate.box_image = _this7.formValidate.leftimage[0] + ',' + _this7.formValidate.rightimage[0] + ',' + _this7.formValidate.topimage[0];
          } else {
            _this7.formValidate.box_image = _this7.formValidate.leftimage[0];
          }
          if (!_this7.orderidArr.length) {
            _this7.$message.error("请选择商品");
            return;
          }
          // this.formValidate.image = this.formValidate.leftimage[0];
          _this7.levelProduct[_this7.selectedLerverPidIndex].product = JSON.parse(JSON.stringify(_this7.orderArr));
          _this7.formValidate.boxPriceData = _this7.priceList;
          _this7.formValidate.boxConsigneeData = _this7.boxConsigneeData;
          _this7.formValidate.price = _this7.priceList[0].price;
          var product = [];
          _this7.levelProduct.forEach(function (item) {
            product = product.concat(item.product);
          });
          _this7.formValidate.boxProductData = product.map(function (item) {
            console.log(item);
            return {
              product_id: item.product_id,
              op_mer_status: item.issale == 1 ? 0 : 1,
              issuer_num: item.issuer_num,
              is_hide: item.is_hide,
              hide_image: item.hide_image,
              box_level_id: item.box_level_id
            };
          });
          var loading = _this7.$loading({
            lock: true,
            text: "保存中……",
            spinner: "el-icon-loading"
          });
          if (_this7.$route.query.id) {
            (0, _product.blindboxUpdateApi)(_this7.$route.query.id, _this7.formValidate).then(function () {
              loading.close();
              _this7.$message.success("保存成功");
              _this7.$router.back();
            });
            return;
          }
          (0, _product.blindboxCreateApi)(_this7.formValidate).then(function (res) {
            loading.close();
            _this7.$message({
              message: "保存成功",
              type: "success"
            });
            _this7.$router.back();
            _this7.$emit("close");
          }).catch(function (res) {
            loading.close();
            // this.$message.error(res.message);
          });
        }
      });
    },
    backAction: function backAction() {
      this.$router.go(-1);
    },
    selectinver: function selectinver(row, index) {
      if (this.orderidArr.indexOf(row.product_id) > -1) {
        return;
      }
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      newrow.issuer_num = newrow.stock;
      newrow.issale = false;
      newrow.is_hide = false;
      newrow.hide_image = '';
      newrow.box_level_id = this.levelProduct[this.selectedLerverPidIndex].id;
      this.levelProduct[this.selectedLerverPidIndex].product.push(newrow);
      this.levelProduct[this.selectedLerverPidIndex].count++;
      this.orderArr.push(newrow);
      this.orderidArr.push(row.product_id);
    },
    del: function del(index, row) {
      var ids = this.levelProduct[this.selectedLerverPidIndex].product.map(function (row) {
        return row.product_id;
      });
      var idx = ids.indexOf(row.product_id);
      this.levelProduct[this.selectedLerverPidIndex].product.splice(idx, 1);
      this.orderArr.splice(idx, 1);
      this.levelProduct[this.selectedLerverPidIndex].count--;
      // let nindex = this.orderidArr.indexOf(row.product_id);
      // this.orderidArr.splice(nindex, 1);
      // this.orderArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      var ids = this.levelProduct[this.selectedLerverPidIndex].product.map(function (item) {
        return item.product_id;
      });
      var idx = ids.indexOf(row.product_id);
      this.levelProduct[this.selectedLerverPidIndex].product.splice(idx, 1);
      this.orderArr.splice(idx, 1);
      this.levelProduct[this.selectedLerverPidIndex].count--;
      this.$forceUpdate();
      // row.selected = false;
      var nindex = this.orderidArr.indexOf(row.product_id);
      this.orderidArr.splice(nindex, 1);
      this.orderArr.splice(nindex, 1);
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          if (num == "top") {
            _this.formValidate.topimage = img;
          } else if (num == "left") {
            _this.formValidate.leftimage = img;
          } else if (num == "right") {
            _this.formValidate.rightimage = img;
          } else if (num == "product") {
            _this.orderArr[i].hide_image = img[0];
          } else if (num == "showimage") {
            _this.formValidate.image = img[0];
          }
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = '';
    }
  }
};
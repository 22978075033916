"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _nft = require("@/api/nft");
var _index4 = require("@/utils/index");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _user = require("@/api/user");
var _nft2 = _interopRequireDefault(require("@/components/customChildren/nft"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "blockchainList",
  components: {
    fileList: _fileList.default,
    Nft: _nft2.default
  },
  data: function data() {
    return {
      visibleDisplace: false,
      selectCollection: [],
      displaceType: "",
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        status: "",
        pageNum: 1,
        pageSize: 20
      },
      chkName: "",
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      saleStatusList: [{
        id: 0,
        name: "正常"
      }, {
        id: 1,
        name: "寄售中"
      }],
      visibleRecord: false,
      queryRecord: {
        remarks: "",
        pwd: ""
      },
      timeVal: [],
      visibleDestroy: false,
      queryDestroy: {
        remarks: "",
        pwd: ""
      },
      labelList: [],
      memberGroupIds: [],
      shareData: []
    };
  },
  created: function created() {
    this.queryParams.status = this.$route.query.status || "";
    this.getList(1);
    this.getLabelList();
  },
  methods: {
    confirmDisplace: function confirmDisplace() {
      var _this = this;
      if (this.displaceType) {
        var data = {
          displaceType: this.displaceType,
          displaceProducts: [],
          memberNftIds: this.checkedIds
        };
        this.selectCollection.forEach(function (item) {
          data.displaceProducts.push({
            objectId: item.id,
            productId: item.productId,
            blockchainTypeId: item.blockchainTypeId,
            number: item.number
          });
        });
        (0, _nft.displaceApi)(data).then(function (res) {
          console.log(res);
          _this.$message.success("添加成功");
          _this.visibleDisplace = false;
          _this.closeDisplace();
          _this.getList();
        });
      }
    },
    closeDisplace: function closeDisplace() {
      this.selectCollection = [];
      this.displaceType = "";
      this.checkedIds = [];
      // this.visibleDisplace = false
    },
    deleteCollection: function deleteCollection(id) {
      this.selectCollection = this.selectCollection.filter(function (item) {
        return item.id !== id;
      });
    },
    addCollection: function addCollection() {
      this.$refs["product_nft"].changeshow();
    },
    selectMessage: function selectMessage(data) {
      // 选完处理选中数据
      var _data$data = data.data[data.data.length - 1],
        id = _data$data.id,
        productId = _data$data.productId,
        image = _data$data.image,
        name = _data$data.name,
        blockchainTypeId = _data$data.blockchainTypeId,
        reserved_number = _data$data.reserved_number;
      this.selectCollection.push({
        productId: productId,
        image: image,
        name: name,
        id: id,
        reserved_number: reserved_number,
        number: 1,
        blockchainTypeId: blockchainTypeId
      });
    },
    getLabelList: function getLabelList() {
      var _this2 = this;
      (0, _user.labelLstApi)({
        pageNum: 1,
        pageSize: 100
      }).then(function (res) {
        _this2.labelList = res.rows;
      });
    },
    exportOrder: function exportOrder() {
      var _this3 = this;
      (0, _nft.productMembernftExportApi)(this.queryParams).then(function (res) {
        _this3.$message.success("操作成功");
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(10);
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? "" : name;
      this.changeType(!(this.chkName === ""));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = "dan";
        }
      } else {
        this.chkName = "";
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.pageNum);
      if (this.chkName === "dan") {
        this.checkedPage.push(this.queryParams.pageNum);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this4 = this;
      var ids = [];
      var shareData = [];
      this.tableData.data.forEach(function (v) {
        // 寄售中 || 上链中 不可批量操作
        if (!(v.nftStatus || !v.status)) {
          ids.push(v.id);
          shareData.push({
            id: v.id,
            linkName: v.linkName
          });
        }
      });
      if (this.chkName === "duo") {
        this.checkedIds = [];
        this.shareData = [];
        this.allCheck = true;
      } else if (this.chkName === "dan") {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this4.checkedIds.indexOf(id);
          if (index === -1) {
            _this4.checkedIds.push(id);
          }
        });
        shareData.forEach(function (item) {
          var index = _this4.shareData.findIndex(function (i) {
            return i.id == item.id;
          });
          if (index === -1) {
            _this4.shareData.push(item);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this4.checkedIds.indexOf(id);
          if (index > -1) {
            _this4.checkedIds.splice(index, 1);
          }
        });
        shareData.forEach(function (item) {
          var index = _this4.shareData.findIndex(function (i) {
            return i.id == item.id;
          });
          if (index > -1) {
            _this4.shareData.splice(index, 1);
          }
        });
      }
    },
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === "duo") {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
          var idx = this.shareData.findIndex(function (i) {
            return i.id == user.id;
          });
          if (idx === -1) {
            this.shareData.push({
              id: user.id,
              linkName: user.linkName
            });
          }
        }
      } else {
        if (this.chkName === "duo") {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
          var _idx = this.shareData.findIndex(function (i) {
            return i.id == user.id;
          });
          if (_idx > -1) {
            this.shareData.splice(_idx, 1);
          }
        }
      }
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index4.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.queryParams.memberGroupIds = this.memberGroupIds.toString(",");
      this.setTypeAction();
      (0, _nft.productMembernftApi)(this.queryParams).then(function (res) {
        _this5.tableData.data = res.rows;
        _this5.tableData.total = res.total;
        _this5.tableData.statistical = res.statistical;
        _this5.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.listLoading = false;
      });
    },
    onchangeTime: function onchangeTime(val) {
      if (val) {
        this.queryParams.startTime = val[0];
        this.queryParams.endTime = val[1];
        this.getList(1);
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
        this.getList(1);
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    saleStatusChange: function saleStatusChange() {
      this.getList(1);
    },
    visibleChange: function visibleChange(val) {
      if (!val) {
        this.getList(1);
      }
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    batchRecover: function batchRecover() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择上链");
      } else {
        this.visibleRecord = true;
      }
    },
    productRecoverMethod: function productRecoverMethod(remarks, pwd) {
      var _this6 = this;
      if (!pwd) {
        this.$message({
          type: "warning",
          message: "请输入密码"
        });
        return;
      }
      this.$confirm("\u786E\u8BA4\u56DE\u6536\u6240\u9009\u4E0A\u94FE?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _nft.productRecoverApi)({
          memberNftIds: _this6.checkedIds,
          remarks: remarks,
          pwd: pwd
        }).then(function () {
          _this6.$message.success("操作成功");
          _this6.checkedIds = [];
          _this6.visibleRecord = false;
          _this6.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    batchDestroy: function batchDestroy() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择上链");
      } else {
        this.visibleDestroy = true;
      }
    },
    batchDisplace: function batchDisplace() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择上链");
      } else {
        this.visibleDisplace = true;
        // this.$refs["product_nft"].changeshow();
      }
    },
    downloadShare: function downloadShare() {
      var _this7 = this;
      if (this.checkedIds.length === 0) {
        return this.$message.warning("请先选择上链");
      } else {
        console.log(this.shareData);
        var loading = this.$loading();
        (0, _nft.downloadShareApi)({
          sharePosters: this.shareData
        }).then(function (res) {
          _this7.$message.success("下载成功");
          _this7.shareData = [];
          _this7.checkedIds = [];
          loading.close();
          _this7.getList();
        }).catch(function (err) {
          _this7.$message.err(err);
          loading.close();
        });
      }
    },
    productDestroyMethod: function productDestroyMethod(remarks, pwd) {
      var _this8 = this;
      if (!pwd) {
        this.$message({
          type: "warning",
          message: "请输入密码"
        });
        return;
      }
      this.$confirm("\u786E\u8BA4\u9500\u6BC1\u6240\u9009\u4E0A\u94FE?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _nft.productDestroyApi)({
          memberNftIds: _this8.checkedIds,
          remarks: remarks,
          pwd: pwd
        }).then(function () {
          _this8.$message.success("操作成功");
          _this8.checkedIds = [];
          _this8.visibleDestroy = false;
          _this8.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 3 || _vm.content.style == 4
      ? _c(
          "div",
          {
            staticClass: "content-box flex",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(_vm.content.style == 4 ? 3 : 2, function (m, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "l flex-one",
                staticStyle: { overflow: "hidden" },
                style:
                  i > 0
                    ? "padding-left: " + _vm.facade.item_margin / 2 + "px;"
                    : "",
              },
              _vm._l(2, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "one-list",
                    class: { mt0: index == 0 },
                    style:
                      "width: 100%;margin-top:" +
                      _vm.facade.item_margin / 2 +
                      "px;border-top-right-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-top-left-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-bottom-left-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;border-bottom-right-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;background: " +
                      _vm.facade.status_background +
                      " url(" +
                      _vm.facade.status_bg_image +
                      ") repeat left center;",
                  },
                  [
                    _vm.content.style !== 4
                      ? _c(
                          "div",
                          {
                            staticClass: "time",
                            style:
                              "color: " +
                              _vm.facade.status_btn_color +
                              ";font-size: " +
                              _vm.facade.status_font_size / 2 +
                              "px;background: " +
                              _vm.facade.status_btn_bg_color +
                              ";",
                          },
                          [_vm._v("4.12 09:00 开售")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        staticStyle: { background: "#999" },
                        style:
                          _vm.getHeight +
                          "border-bottom-left-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;border-bottom-right-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;overflow: hidden;",
                      },
                      [
                        _vm.facade.img_style === 1
                          ? _c("img", {
                              staticStyle: { display: "block" },
                              attrs: {
                                src:
                                  index == 0 && i == 0
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                              },
                            })
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src:
                                  index == 0 && i == 0
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                fit:
                                  _vm.facade.img_style === 2
                                    ? "contain"
                                    : "cover",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "text color-black line1",
                        staticStyle: { padding: "5px 10px 0 10px" },
                        style:
                          "color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [_vm._v("刺杀罪恶之文化侵蚀")]
                    ),
                    _vm._v(" "),
                    _vm.facade.sub_area == "left" && _vm.content.style !== 4
                      ? _c(
                          "div",
                          {
                            staticClass: "r-address line1",
                            staticStyle: { padding: "0 10px 0 10px" },
                            style:
                              "color: " +
                              _vm.facade.sub_color +
                              ";font-size: " +
                              _vm.facade.sub_font_size / 2 +
                              "px;",
                          },
                          [_vm._v("北京复兴文化创意产业平台（发行方）")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bottom",
                        staticStyle: { padding: "0 10px 0 10px" },
                      },
                      [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l-price flex-one",
                              style: "color: " + _vm.facade.price_color + ";",
                            },
                            [
                              _vm._v("￥"),
                              _c(
                                "span",
                                {
                                  style:
                                    "font-size: " +
                                    _vm.facade.price_font_size / 2 +
                                    "px;",
                                },
                                [_vm._v("100")]
                              ),
                              _vm._v("起"),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.content.style !== 4
                            ? _c(
                                "div",
                                {
                                  staticClass: "num",
                                  style:
                                    "color: " +
                                    _vm.facade.stock_main_color +
                                    ";font-size: " +
                                    _vm.facade.stock_font_size / 2 +
                                    "px;",
                                },
                                [_vm._v("限量500份")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.facade.sub_area != "left" && _vm.content.style !== 4
                      ? _c(
                          "div",
                          {
                            staticClass: "r-address line1",
                            staticStyle: { padding: "0 10px 0 10px" },
                            style:
                              "color: " +
                              _vm.facade.sub_color +
                              ";font-size: " +
                              _vm.facade.sub_font_size / 2 +
                              "px;",
                          },
                          [_vm._v("北京复兴文化创意产业平台（发行方）")]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      : _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          [
            _c(
              "div",
              { class: { focus: _vm.content.style == 5 } },
              [
                _vm.content.style == 5
                  ? _c(
                      "div",
                      {
                        staticClass: "nft-list",
                        style:
                          "border-top-right-radius: " +
                          _vm.facade.img_radius / 2 +
                          "px;border-bottom-right-radius: " +
                          _vm.facade.img_radius / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "list" },
                          _vm._l(6, function (m) {
                            return _c(
                              "div",
                              { key: "num" + m, staticClass: "item" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640",
                                    fit: "cover",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.content.style == 5 ? 1 : 2, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "one-list",
                      class: { mt0: index == 0 },
                      style:
                        "width: 100%;margin-top:" +
                        _vm.facade.item_margin / 2 +
                        "px;border-top-right-radius: " +
                        _vm.facade.img_radius / 2 +
                        "px;border-top-left-radius: " +
                        _vm.facade.img_radius / 2 +
                        "px;border-bottom-left-radius: " +
                        _vm.facade.status_radius / 2 +
                        "px;border-bottom-right-radius: " +
                        _vm.facade.status_radius / 2 +
                        "px;background: " +
                        _vm.facade.status_background +
                        " url(" +
                        _vm.facade.status_bg_image +
                        ") repeat left center;",
                    },
                    [
                      _vm.content.style !== 4
                        ? _c(
                            "div",
                            {
                              staticClass: "time flex align-items-c",
                              style:
                                "color: " +
                                _vm.facade.status_color +
                                ";font-size: " +
                                _vm.facade.status_fontSize / 2 +
                                "px;" +
                                (_vm.facade.status_bg_color &&
                                _vm.facade.status_bg_color_gradient
                                  ? "background: linear-gradient(90deg, " +
                                    _vm.facade.status_bg_color +
                                    " 0%, " +
                                    _vm.facade.status_bg_color_gradient +
                                    " 100%)"
                                  : "background: " +
                                    _vm.facade.status_bg_color) +
                                ";",
                            },
                            [
                              _vm.facade.status_not_started
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.facade.status_not_started,
                                    },
                                  })
                                : _vm._e(),
                              _c("span", [_vm._v("4.12 09:00 开售")]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          style:
                            (_vm.content.style == 5
                              ? "width: " +
                                (375 - _vm.facade.page_margin - 70) +
                                "px;height: " +
                                (375 - _vm.facade.page_margin - 70) +
                                "px;"
                              : "") +
                            ("border-bottom-left-radius: " +
                              _vm.facade.img_bottom_radius / 2 +
                              "px;border-bottom-right-radius: " +
                              _vm.facade.img_bottom_radius / 2 +
                              "px;overflow: hidden;"),
                        },
                        [
                          _vm.content.style == 1
                            ? _c("img", {
                                staticStyle: { display: "block" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                },
                              })
                            : _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640",
                                  fit: "cover",
                                },
                              }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex align-items-c",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex-one",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text color-black line1",
                                  style:
                                    "color: " +
                                    _vm.facade.title_color +
                                    ";font-size: " +
                                    _vm.facade.title_font_size / 2 +
                                    "px;font-weight: " +
                                    _vm.facade.title_font_weight +
                                    ";",
                                },
                                [_vm._v("刺杀罪恶之文化侵蚀")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tags flex align-items-c flex-wrap",
                                  style:
                                    "font-size: " +
                                    _vm.facade.btn_font_size / 2 +
                                    "px;",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "num",
                                      style:
                                        "overlfow: hidden;margin-right: 5px;height: 21px;border-radius: " +
                                        _vm.facade.stock_radius / 2 +
                                        "px;font-size: " +
                                        _vm.facade.stock_font_size / 2 +
                                        "px;" +
                                        (_vm.facade.stock_border_gradient_color
                                          ? "background: linear-gradient(" +
                                            (_vm.facade.gradient_direction ||
                                              "to right") +
                                            ", " +
                                            _vm.facade.stock_border_color +
                                            ", " +
                                            _vm.facade
                                              .stock_border_gradient_color +
                                            ");"
                                          : "background:" +
                                            _vm.facade.stock_border_color +
                                            ";"),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "stock flex",
                                          staticStyle: {
                                            margin: "1px 1px 1px 1px",
                                          },
                                          style:
                                            "border-radius: " +
                                            _vm.facade.stock_radius / 2 +
                                            "px;",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "l",
                                              style:
                                                "border-radius: " +
                                                _vm.facade.stock_radius / 2 +
                                                "px 0 0 " +
                                                _vm.facade.stock_radius / 2 +
                                                "px;color: " +
                                                _vm.facade.stock_main_color +
                                                ";" +
                                                (_vm.facade
                                                  .stock_main_background_gradient
                                                  ? "background: linear-gradient(" +
                                                    (_vm.facade
                                                      .gradient_direction ||
                                                      "to right") +
                                                    ", " +
                                                    _vm.facade
                                                      .stock_main_background +
                                                    ", " +
                                                    _vm.facade
                                                      .stock_main_background_gradient +
                                                    ");"
                                                  : "background:" +
                                                    _vm.facade
                                                      .stock_main_background +
                                                    ";"),
                                            },
                                            [_vm._v("限量")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "r",
                                              style:
                                                "border-radius: 0 " +
                                                _vm.facade.stock_radius / 2 +
                                                "px " +
                                                _vm.facade.stock_radius / 2 +
                                                "px 0;color: " +
                                                _vm.facade.stock_sub_color +
                                                ";" +
                                                (_vm.facade
                                                  .stock_sub_background_gradient
                                                  ? "background: linear-gradient(" +
                                                    (_vm.facade
                                                      .gradient_direction ||
                                                      "to right") +
                                                    ", " +
                                                    _vm.facade
                                                      .stock_sub_background +
                                                    ", " +
                                                    _vm.facade
                                                      .stock_sub_background_gradient +
                                                    ");"
                                                  : "background:" +
                                                    _vm.facade
                                                      .stock_sub_background +
                                                    ";"),
                                            },
                                            [_vm._v("500份")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.facade.hideBtn
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tag-bor",
                                            style:
                                              "padding: 1px;border-radius: " +
                                              _vm.facade.btn_radius / 2 +
                                              "px;" +
                                              (_vm.facade
                                                .main_btn_border_gradient
                                                ? "background: linear-gradient(" +
                                                  (_vm.facade
                                                    .gradient_direction ||
                                                    "to right") +
                                                  ", " +
                                                  _vm.facade.main_btn_border +
                                                  ", " +
                                                  _vm.facade
                                                    .main_btn_border_gradient +
                                                  ");"
                                                : "background:" +
                                                  _vm.facade.main_btn_border +
                                                  ";"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "tag",
                                                style:
                                                  "padding: 2px 4px;font-size: " +
                                                  _vm.facade.btn_font_size / 2 +
                                                  "px;color: " +
                                                  _vm.facade.main_btn_color +
                                                  ";background: " +
                                                  _vm.facade.main_btn_bg_color +
                                                  ";border-radius: " +
                                                  _vm.facade.btn_radius / 2 +
                                                  "px;" +
                                                  (_vm.facade
                                                    .main_btn_bg_color_gradient
                                                    ? "background: linear-gradient(" +
                                                      (_vm.facade
                                                        .gradient_direction ||
                                                        "to right") +
                                                      ", " +
                                                      _vm.facade
                                                        .main_btn_bg_color +
                                                      ", " +
                                                      _vm.facade
                                                        .main_btn_bg_color_gradient +
                                                      ");"
                                                    : "background:" +
                                                      _vm.facade
                                                        .main_btn_bg_color +
                                                      ";"),
                                              },
                                              [_vm._v("红色文律")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tag-bor",
                                            style:
                                              "padding: 1px;border-radius: " +
                                              _vm.facade.btn_radius / 2 +
                                              "px;" +
                                              (_vm.facade
                                                .main_btn_border_gradient
                                                ? "background: linear-gradient(" +
                                                  (_vm.facade
                                                    .gradient_direction ||
                                                    "to right") +
                                                  ", " +
                                                  _vm.facade.main_btn_border +
                                                  ", " +
                                                  _vm.facade
                                                    .main_btn_border_gradient +
                                                  ");"
                                                : "background:" +
                                                  _vm.facade.main_btn_border +
                                                  ";"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "tag",
                                                style:
                                                  "padding: 2px 4px;font-size: " +
                                                  _vm.facade.btn_font_size / 2 +
                                                  "px;color: " +
                                                  _vm.facade.main_btn_color +
                                                  ";background: " +
                                                  _vm.facade.main_btn_bg_color +
                                                  ";border-radius: " +
                                                  _vm.facade.btn_radius / 2 +
                                                  "px;" +
                                                  (_vm.facade
                                                    .main_btn_bg_color_gradient
                                                    ? "background: linear-gradient(" +
                                                      (_vm.facade
                                                        .gradient_direction ||
                                                        "to right") +
                                                      ", " +
                                                      _vm.facade
                                                        .main_btn_bg_color +
                                                      ", " +
                                                      _vm.facade
                                                        .main_btn_bg_color_gradient +
                                                      ");"
                                                    : "background:" +
                                                      _vm.facade
                                                        .main_btn_bg_color +
                                                      ";"),
                                              },
                                              [_vm._v("文化复兴")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "surplus" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "h",
                                    style:
                                      "background: " +
                                      _vm.facade.surplus_desc_color +
                                      ";",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "x",
                                      style:
                                        "background: " +
                                        _vm.facade.surplus_color +
                                        ";width: 20%;",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "txt",
                                    style:
                                      "color: " +
                                      _vm.facade.surplus_text_color +
                                      ";",
                                  },
                                  [_vm._v("剩余220")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bottom bottom1 flex align-items-c",
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "author flex-one flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.sub_color +
                                      ";font-size: " +
                                      _vm.facade.sub_font_size / 2 +
                                      "px;line-height: 20px;",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "l-price",
                                      style:
                                        "color: " +
                                        _vm.facade.price_color +
                                        ";",
                                    },
                                    [
                                      _vm._v("￥"),
                                      _c(
                                        "span",
                                        {
                                          style:
                                            "font-size: " +
                                            _vm.facade.price_font_size / 2 +
                                            "px;",
                                        },
                                        [_vm._v("100")]
                                      ),
                                      _vm._v("起"),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(3, function (item, index) {
            return _c("div", { key: index, staticClass: "list flex" }, [
              _c("div", { staticClass: "l-time" }, [
                _c("div", {
                  staticClass: "t-xian",
                  class: { pt0: index == 0 },
                  style:
                    "padding-top:" +
                    _vm.facade.item_margin / 2 +
                    "px;background-color: " +
                    _vm.facade.xian_color +
                    ";",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "item-back",
                    style: "background: " + _vm.facade.background_color + ";",
                  },
                  [
                    index == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "day",
                            style:
                              "color: " +
                              _vm.facade.month_color +
                              ";font-size: " +
                              _vm.facade.month_font_size / 2 +
                              "px;font-weight: " +
                              _vm.facade.month_font_weight +
                              ";",
                          },
                          [_vm._v("04:30")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "time",
                        style:
                          "color: " +
                          _vm.facade.time_color +
                          ";font-size: " +
                          _vm.facade.time_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.time_font_weight +
                          ";",
                      },
                      [_vm._v(_vm._s(index == 0 ? "12:00" : "18:00"))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dian item-flex-center" }, [
                      _c("div", {
                        style: "background: " + _vm.facade.dian_color,
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "b-xian",
                  style: "background-color: " + _vm.facade.xian_color,
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r-view flex",
                  class: { mt0: index == 0 },
                  style:
                    "margin-top:" +
                    _vm.facade.item_margin / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.status_bg_image +
                    ") repeat left center;background-size: 100%;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src:
                            index == 0
                              ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                              : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                          fit: _vm.facade.img_style === 2 ? "contain" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "img-r" }, [
                    _c(
                      "div",
                      {
                        staticClass: "name line1",
                        style:
                          "color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [_vm._v("坠落·光在前方照亮的地方")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "count",
                        style:
                          "color: " +
                          _vm.facade.stock_main_color +
                          ";font-size: " +
                          _vm.facade.stock_font_size / 2 +
                          "px;",
                      },
                      [_vm._v("限量500份")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "price",
                        style: "color: " + _vm.facade.price_color + ";",
                      },
                      [
                        _vm._v("￥"),
                        _c(
                          "span",
                          {
                            style:
                              "font-size: " +
                              _vm.facade.price_font_size / 2 +
                              "px;",
                          },
                          [_vm._v("100")]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
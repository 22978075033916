var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c("div", [_vm._v("倒计时抽奖")]),
      _vm._v(" "),
      _c(
        "baseForm",
        {
          attrs: { form: _vm.formData, "init-form": _vm.initForm },
          on: { submit: _vm.submitForm, change: _vm.changeForm },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "白名单设置：" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.hasLimitation,
                  callback: function ($$v) {
                    _vm.hasLimitation = $$v
                  },
                  expression: "hasLimitation",
                },
              }),
              _vm._v(" "),
              _vm.hasLimitation
                ? [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "400px" },
                        attrs: {
                          multiple: "",
                          placeholder: "选择关联快照名单",
                        },
                        model: {
                          value: _vm.switchWhiteList,
                          callback: function ($$v) {
                            _vm.switchWhiteList = $$v
                          },
                          expression: "switchWhiteList",
                        },
                      },
                      _vm._l(_vm.whitelist, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖品设置：" } },
            [
              _c(
                "el-descriptions",
                {
                  attrs: {
                    title: "",
                    column: 5,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "藏品名称" } }, [
                    _c(
                      "div",
                      [
                        _vm.formData.productId
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.formData.productName)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", link: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdditem()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.formData.productId ? "修改" : "选择") +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "投放数量" } }, [
                    _vm._v("\n          1\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "出价倒计时" } },
                    [
                      _c("el-input", {
                        scopedSlots: _vm._u([
                          {
                            key: "suffix",
                            fn: function () {
                              return [_vm._v("分钟")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.formData.limitationTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "limitationTime", $$v)
                          },
                          expression: "formData.limitationTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "积分类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "选择积分类型" },
                          model: {
                            value: _vm.formData.scoreTypeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "scoreTypeId", $$v)
                            },
                            expression: "formData.scoreTypeId",
                          },
                        },
                        _vm._l(_vm.typeList, function (m, i) {
                          return _c("el-option", {
                            key: "type" + i,
                            attrs: { value: m.id, label: m.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "消耗积分" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.score,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "score", $$v)
                          },
                          expression: "formData.score",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.visibleTitle,
            visible: _vm.visible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: "名称搜索",
                  },
                  on: { change: _vm.nftCollectionListApiMethod },
                  model: {
                    value: _vm.materialForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.materialForm, "name", $$v)
                    },
                    expression: "materialForm.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.nftCollectionListApiMethod },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  key: Math.random(),
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.materialList.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "头像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.coverPicture },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseRow(scope.row, 1)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.chooseList.findIndex(function (res) {
                                        return (
                                          res.productId === scope.row.productId
                                        )
                                      }) != -1
                                        ? "取消选择"
                                        : "选择"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticClass: "mt10",
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.materialForm.pageSize,
                  "current-page": _vm.materialForm.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.materialList.total,
                },
                on: {
                  "size-change": _vm.materialFormSizeChange,
                  "current-change": _vm.materialFormpageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
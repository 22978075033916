var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c("div", { staticClass: "item flex align-items-c h50" }, [
      _c("div", { staticClass: "flex-one" }, [
        _vm._v("\n      求购下单白名单\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "small" },
              model: {
                value: _vm.formData.hasLimitationWantBuy,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "hasLimitationWantBuy", $$v)
                },
                expression: "formData.hasLimitationWantBuy",
              },
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("不限")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: true } }, [
                _vm._v("指定白名单"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formData.hasLimitationWantBuy
            ? _c(
                "el-select",
                {
                  staticClass: "ml10",
                  staticStyle: { width: "200px" },
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "选择关联快照名单",
                  },
                  model: {
                    value: _vm.formData.limitationWantBuyWhiteList,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "limitationWantBuyWhiteList", $$v)
                    },
                    expression: "formData.limitationWantBuyWhiteList",
                  },
                },
                _vm._l(_vm.snapshotOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item flex align-items-c h50" },
      [
        _c("div", { staticClass: "flex-one" }, [_vm._v("最大求购数量")]),
        _vm._v(" "),
        _c("el-input", {
          staticClass: "w200",
          attrs: { placeholder: "请输入数量" },
          model: {
            value: _vm.formData.maxCount,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "maxCount", $$v)
            },
            expression: "formData.maxCount",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item flex align-items-c h50" },
      [
        _c("div", { staticClass: "flex-one" }, [
          _vm._v("是否显示自动买入挂单"),
        ]),
        _vm._v(" "),
        _c("el-switch", {
          attrs: { "active-color": "#13ce66", "inactive-color": "#ccc" },
          model: {
            value: _vm.formData.hasShowAutoBuy,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "hasShowAutoBuy", $$v)
            },
            expression: "formData.hasShowAutoBuy",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "item flex align-items-c h50" }, [
      _c("div", { staticClass: "flex-one" }, [
        _vm._v("\n      是否开启自动买入限制\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "small" },
              model: {
                value: _vm.formData.hasLimitationAutoWantBuy,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "hasLimitationAutoWantBuy", $$v)
                },
                expression: "formData.hasLimitationAutoWantBuy",
              },
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("不限")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: true } }, [
                _vm._v("指定白名单"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formData.hasLimitationAutoWantBuy
            ? _c(
                "el-select",
                {
                  staticClass: "ml10",
                  staticStyle: { width: "200px" },
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "选择关联快照名单",
                  },
                  on: { change: _vm.onChange },
                  model: {
                    value: _vm.formData.limitationAutoWantBuyWhiteList,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "limitationAutoWantBuyWhiteList",
                        $$v
                      )
                    },
                    expression: "formData.limitationAutoWantBuyWhiteList",
                  },
                },
                _vm._l(_vm.snapshotOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item flex align-items-c h50" },
      [
        _c("div", { staticClass: "flex-one" }, [_vm._v("置顶白名单")]),
        _vm._v(" "),
        _c(
          "el-select",
          {
            staticClass: "ml10",
            staticStyle: { width: "200px" },
            attrs: {
              multiple: "",
              "collapse-tags": "",
              placeholder: "选择置顶白名单",
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.formData.toppingShopList,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "toppingShopList", $$v)
              },
              expression: "formData.toppingShopList",
            },
          },
          _vm._l(_vm.snapshotOptions, function (item) {
            return _c("el-option", {
              key: item.id,
              attrs: { label: item.name, value: item.id },
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "submit-btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.submitForm()
              },
            },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { attrs: { label: "寄售账户名单" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", "collapse-tags": "", size: "small" },
                  model: {
                    value: _vm.formData.marketUserIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "marketUserIds", $$v)
                    },
                    expression: "formData.marketUserIds",
                  },
                },
                _vm._l(_vm.snapshotOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _vm._v(
              "\n      自动寄售功能开启后会自动从名单内账户搜索符合条件的藏品自动寄售\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "寄售监控白名单" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", "collapse-tags": "", size: "small" },
                  model: {
                    value: _vm.formData.scanWhiteList,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "scanWhiteList", $$v)
                    },
                    expression: "formData.scanWhiteList",
                  },
                },
                _vm._l(_vm.snapshotOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _vm._v("\n      白名单用户的寄售最低价格不会被监控\n    "),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "锁单概率" } }, [
            _c(
              "div",
              { staticClass: "form-input" },
              [
                _c("el-input", {
                  attrs: { size: "small" },
                  on: {
                    input: function ($event) {
                      return _vm.numInput("lockMarketProbability", 0, 0, 100)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "suffix",
                      fn: function () {
                        return [_vm._v("\n            %\n          ")]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.formData.lockMarketProbability,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "lockMarketProbability", $$v)
                    },
                    expression: "formData.lockMarketProbability",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _vm._v(
              "\n      未设置时，默认符合条件的寄售将全部被锁单，设计后会按照设置的百分比进行锁单\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "flex-item", attrs: { label: "锁单白名单" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", "collapse-tags": "", size: "small" },
                  model: {
                    value: _vm.formData.lockMarketWhiteList,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "lockMarketWhiteList", $$v)
                    },
                    expression: "formData.lockMarketWhiteList",
                  },
                },
                _vm._l(_vm.snapshotOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml10" },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 2, "inactive-value": 1 },
                    model: {
                      value: _vm.formData.lockMarketType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "lockMarketType", $$v)
                      },
                      expression: "formData.lockMarketType",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "（开关关闭则白名单用户的寄售不会被锁单，只锁定白名单之外的用户。打开开关则反之）"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _vm._v("\n      白名单用户的寄售不会被锁单\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
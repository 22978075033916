"use strict";

var _interopRequireWildcard = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("/root/workspace/java_admin_Rm9R/node_modules/core-js/modules/es6.array.iterator.js");
require("/root/workspace/java_admin_Rm9R/node_modules/core-js/modules/es6.promise.js");
require("/root/workspace/java_admin_Rm9R/node_modules/core-js/modules/es6.object.assign.js");
require("/root/workspace/java_admin_Rm9R/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _moment = _interopRequireDefault(require("moment"));
var _vuePrintNb = _interopRequireDefault(require("vue-print-nb"));
var _cascader = _interopRequireDefault(require("element-ui/lib/cascader"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _vueLazyload = _interopRequireDefault(require("vue-lazyload"));
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _elementUi2 = _interopRequireDefault(require("@form-create/element-ui"));
var _uploadFrom = _interopRequireDefault(require("./components/uploadPicture/uploadFrom"));
var _vueUeditorWrap = _interopRequireDefault(require("vue-ueditor-wrap"));
var _vueJsonp = require("vue-jsonp");
var _attrFrom = _interopRequireDefault(require("./components/attrFrom"));
var _templatesFrom = _interopRequireDefault(require("./components/templatesFrom"));
var _couponList = _interopRequireDefault(require("./components/couponList"));
require("./icons");
require("./permission");
var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));
require("swiper/dist/css/swiper.css");
var _modalForm = _interopRequireDefault(require("@/libs/modal-form"));
var _modalAttr = _interopRequireDefault(require("@/libs/modal-attr"));
var _modalTemplates = _interopRequireDefault(require("@/libs/modal-templates"));
var _videoCloud = _interopRequireDefault(require("@/utils/videoCloud"));
var _modalCoupon = _interopRequireDefault(require("@/libs/modal-coupon"));
var _public = require("@/libs/public");
var _ChangeMap = _interopRequireDefault(require("@/components/ChangeMap"));
var _albumSelect = _interopRequireDefault(require("@/components/albumSelect"));
var echarts = _interopRequireWildcard(require("echarts"));
var _system = require("@/api/system");
var _request = require("@/api/request");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var filters = _interopRequireWildcard(require("./filters"));
var _auth = require("./utils/auth");
// a modern alternative to CSS resets

// global css

// 懒加载

// icon
// permission control
// swiper

_elementUi2.default.component("ChangeMap", _ChangeMap.default);
_elementUi2.default.component("albumSelect", _albumSelect.default);

// global filters modalTemplates
// import notice from '@/libs/notice'; // global filters

_vue.default.use(_uploadFrom.default);
_vue.default.use(_vuePrintNb.default);
_vue.default.use(_elementUi2.default);
_vue.default.use(_vueJsonp.VueJsonp);
_vue.default.use(_vueAwesomeSwiper.default);
_vue.default.use(_vueLazyload.default, {
  preLoad: 1.3,
  error: require("./assets/images/no.png"),
  loading: require("./assets/images/moren.jpg"),
  attempt: 1,
  listenEvents: ["scroll", "wheel", "mousewheel", "resize", "animationend", "transitionend", "touchmove"]
});
_vue.default.component("vue-ueditor-wrap", _vueUeditorWrap.default);
_vue.default.component("attrFrom", _attrFrom.default);
_vue.default.component("templatesFrom", _templatesFrom.default);
_vue.default.component("couponList", _couponList.default);
_vue.default.prototype.$modalForm = _modalForm.default;
_vue.default.prototype.$modalSure = _public.modalSure;
_vue.default.prototype.$videoCloud = _videoCloud.default;
_vue.default.prototype.$modalSureDelete = _public.modalSureDelete;
_vue.default.prototype.$modalAttr = _modalAttr.default;
_vue.default.prototype.$modalTemplates = _modalTemplates.default;
_vue.default.prototype.$modalCoupon = _modalCoupon.default;
_vue.default.prototype.moment = _moment.default;
_vue.default.prototype.$echarts = echarts;

// Vue.prototype.process.env.XCX_APP_APPURL = 'https://mnft.magicchair.net';
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === "production") {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get("size") || "medium" // set element-ui default size
});
_vue.default.use(_cascader.default);
var formatString = function formatString(v) {
  return v.toString().length === 1 ? "0".concat(v) : v;
};
_vue.default.prototype.$formatData = function (time, noTime) {
  var d = new Date(time);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  var returnD = "";
  if (noTime) {
    if (noTime === 2) {
      returnD = "".concat(year, "\u5E74").concat(formatString(month), "\u6708").concat(formatString(day), "\u65E5");
    } else if (noTime === 3) {
      returnD = "".concat(formatString(day), "\u65E5");
    } else if (noTime === 4) {
      returnD = "".concat(year, "-").concat(formatString(month));
    } else if (noTime === 5) {
      returnD = "".concat(year).concat(formatString(month)).concat(formatString(day)).concat(formatString(hours)).concat(formatString(minutes)).concat(formatString(seconds));
    } else if (noTime === 6) {
      returnD = "".concat(year, "-").concat(formatString(month), "-").concat(formatString(day), " ").concat(formatString(hours), ":").concat(formatString(minutes));
    } else {
      returnD = "".concat(year, "-").concat(formatString(month), "-").concat(formatString(day));
    }
  } else {
    returnD = "".concat(year, "-").concat(formatString(month), "-").concat(formatString(day), " ").concat(formatString(hours), ":").concat(formatString(minutes), ":").concat(formatString(seconds));
  }
  return returnD;
};
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_router.default.beforeEach(function (to, from, next) {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // document.title = to.meta.title + '-' + JSON.parse(Cookies.get('MerInfo')).login_title
  }
  next();
});
var token = (0, _auth.getToken)();
if ((0, _auth.getUrlKey)("token")) {
  token = (0, _auth.getUrlKey)("token");
  _jsCookie.default.set("merchantToken", token);
}
// let _notice;
// if (token) {
//   _notice = notice(token);
// }

_vue.default.directive("fo", {
  inserted: function inserted(el, binding, vnode) {
    // 聚焦元素
    // el.querySelector('input').focus()
    el.querySelector("textarea").focus();
  }
});
var loadVue = function loadVue(data) {
  _settingMer.default.httpUrl = "".concat(location.protocol, "//").concat(data.api_url, "/");
  _settingMer.default.https = "".concat(location.protocol, "//").concat(data.api_url, "/");
  // SettingMer.https = `https://java-api-test.yunzongbu.cn/`;

  _settingMer.default.appUrl = "https://".concat(data.h5_url, "/#");
  (0, _request.setApi)();
  _vue.default.config.productionTip = false;
  new _vue.default({
    el: "#app",
    data: {
      // notice: _notice
    },
    methods: {
      // closeNotice() {
      //   this.notice && this.notice();
      // }
    },
    router: _router.default,
    store: _store.default,
    render: function render(h) {
      return h(_App.default);
    }
  });
};
var href = location.hostname;
var getApiFun = function getApiFun() {
  (0, _system.getApi)({
    domain_url: href
  }).then(function (res) {
    localStorage.setItem("apiLocal", JSON.stringify((0, _objectSpread2.default)({
      server_time: res.server_time
    }, res.data)));
    loadVue(res.data);
  }).catch(function () {});
};
var apiLocal = localStorage.getItem("apiLocal");
if (apiLocal) {
  var local = JSON.parse(apiLocal);
  if (Date.parse(new Date()) / 1000 < local.server_time + 604800) {
    loadVue(local);
  } else {
    getApiFun();
  }
} else {
  getApiFun();
}

// export default
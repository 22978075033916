"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _nft = require("@/api/nft");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      showImage: false,
      imagesKey: "",
      ruleData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _nft.nftRuleConfigInfoApi)().then(function (res) {
      _this.ruleData = (0, _objectSpread2.default)({
        hasCirculation: res.data.hasCirculation || false,
        noShowUnpackingRecord: res.data.noShowUnpackingRecord || false,
        showHoldingTime: res.data.showHoldingTime || false,
        hasWechatPop: res.data.hasWechatPop || false,
        productBuyExperience: res.data.productBuyExperience || false,
        myProductUrl: res.data.myProductUrl || "",
        myProductFloatUrl: res.data.myProductFloatUrl || "",
        purchaseNotes: res.data.purchaseNotes || "",
        numberSuffix: res.data.numberSuffix || "",
        id: res.data.id || ""
      }, res.data);
    }).catch(function () {});
  },
  methods: {
    delImgAction: function delImgAction(key) {
      this.ruleData[key] = "";
    },
    showImageAction: function showImageAction(tit, key, key1) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this2.ruleData[key1][key] = img[0];
        } else {
          _this2.ruleData[key] = img[0];
        }
        _this2.$forceUpdate();
      }, tit);
    },
    changeImg: function changeImg(key) {
      var _this3 = this;
      // if (this.status === 1) {
      //   this.$message.error('已发行不可修改图片');
      //   return false;
      // }
      this.$modalUpload(function (img) {
        _this3.ruleData[key] = img[0];
      }, "1", "1");
    },
    saveAction: function saveAction() {
      var _this4 = this;
      (0, _nft.nftRuleConfigUpdateApi)(this.ruleData).then(function (res) {
        _this4.$message.success("设置成功");
      }).catch(function () {});
    }
  }
};
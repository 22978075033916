var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "rule-form",
      attrs: { model: _vm.ruleForm, "label-width": "120px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "排行榜显示条数", prop: "pageSize" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleForm.pageSize,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "pageSize", $$v)
                },
                expression: "ruleForm.pageSize",
              },
            },
            _vm._l(_vm.pageSizeList, function (item) {
              return _c("el-radio", { key: item, attrs: { label: item } }, [
                _vm._v(_vm._s(item) + "条"),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "标签显示名称" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { offset: 4, span: 8 } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.profitListName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "profitListName", $$v)
                      },
                      expression: "ruleForm.profitListName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { offset: 4, span: 8 } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.buyListName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "buyListName", $$v)
                      },
                      expression: "ruleForm.buyListName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户标签名称" } },
        _vm._l(_vm.tabName, function (tags, index) {
          return _c(
            "el-row",
            { key: index, staticClass: "tab-row" },
            [
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { offset: 1, span: 2 } },
                [_vm._v(_vm._s(index))]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { offset: 1, span: 8 } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm[tags[0]],
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, tags[0], $$v)
                      },
                      expression: "ruleForm[tags[0]]",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { offset: 1, span: 2 } },
                [_vm._v(_vm._s(index))]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { offset: 1, span: 8 } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm[tags[1]],
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, tags[1], $$v)
                      },
                      expression: "ruleForm[tags[1]]",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "名人堂黑名单" } },
        [
          _c(
            "el-select",
            {
              attrs: {
                multiple: "",
                "collapse-tags": "",
                size: "small",
                placeholder: "选择名单",
                clearable: "",
              },
              model: {
                value: _vm.blacklist,
                callback: function ($$v) {
                  _vm.blacklist = $$v
                },
                expression: "blacklist",
              },
            },
            _vm._l(_vm.snapshotOptions, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      showdetail: false,
      uid: '',
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      listLoading: true,
      queryParams: {
        status: '',
        type: '2',
        timeType: '',
        pageNum: 1,
        pageSize: 20
      },
      pointsDeductionList: [{
        value: null,
        label: '不限积分抵扣'
      }, {
        value: true,
        label: '使用积分'
      }, {
        value: false,
        label: '未使用积分'
      }],
      couponDeductionList: [{
        value: null,
        label: '不限优惠券抵扣'
      }, {
        value: true,
        label: '使用优惠券'
      }, {
        value: false,
        label: '未使用优惠券'
      }],
      timeType: [],
      timeList: [{
        text: '全部',
        val: ''
      }, {
        text: '今天',
        val: 'today'
      }, {
        text: '昨天',
        val: 'yesterday'
      }, {
        text: '最近7天',
        val: 'lately7'
      }, {
        text: '最近30天',
        val: 'lately30'
      }, {
        text: '本月',
        val: 'month'
      }, {
        text: '本年',
        val: 'year'
      }],
      payList: [],
      visibleRefund: false,
      refundForm: {
        orderId: '',
        refundPrice: ''
      },
      refundRules: {
        refundPrice: [{
          required: true,
          message: '请输入退款金额',
          trigger: 'blur'
        }]
      },
      refundRow: null
    };
  },
  filters: {
    formatStatus: function formatStatus(val) {
      var res = '';
      switch (val) {
        case -2:
          res = '已退款';
          break;
        case -1:
          res = '已关闭';
          break;
        case 0:
          res = '待付款';
          break;
        case 1:
          res = '待发货';
          break;
        case 2:
          res = '待收货';
          break;
        case 3:
          res = '已收货，待评价';
          break;
        case 4:
          res = '已完成';
          break;
        default:
          break;
      }
      return res;
    }
  },
  mounted: function mounted() {
    this.getList(1);
    this.getPayTypeList();
  },
  methods: {
    confirmRefundAction: function confirmRefundAction(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.refundForm.refundPrice = Number(_this.refundForm.refundPrice);
          (0, _order.refundOrderApi)(_this.refundForm).then(function () {
            _this.$message.success('操作成功');
            _this.visibleRefund = false;
            _this.getList(1);
          }).catch(function (msg) {
            // loading.close();
          });
        } else {
          return false;
        }
      });
    },
    exportOrder: function exportOrder() {
      var _this2 = this;
      console.log(this.queryParams);
      (0, _order.orderExportApi)(this.queryParams).then(function (res) {
        _this2.$message.success('操作成功');
      });
    },
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList(3);
    },
    getPayTypeList: function getPayTypeList() {
      var _this3 = this;
      (0, _order.getPayType)().then(function (res) {
        // console.log(res)
        _this3.payList = res.data;
      }).catch(function (res) {});
    },
    // 取消订单
    cancelOrderAction: function cancelOrderAction(id) {
      var _this4 = this;
      this.$confirm('确定取消此订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.cancelOrderApi)(id).then(function (res) {
          _this4.$message.success(res.message);
          _this4.getList('');
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      }).catch(function () {});
    },
    // 退款
    refundAction: function refundAction(row) {
      this.refundRow = row;
      this.refundForm.orderId = row.id;
      this.visibleRefund = true;
    },
    selectChange: function selectChange(tab) {
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeType = e;
      this.queryParams.timeType = e ? this.timeType.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _order.orderListApi)(this.queryParams).then(function (res) {
        _this5.tableData.data = res.rows;
        _this5.tableData.total = res.total;
        _this5.tableData.statistical = res.statistical;
        if (_this5.tableData.statistical !== null) {
          _this5.tableData.statistical['all'] = _this5.tableData.statistical.overCount + _this5.tableData.statistical.closeCount + _this5.tableData.statistical.waitCount;
        }
        _this5.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList('');
    },
    showDetail: function showDetail(uid) {
      this.uid = uid;
      this.showdetail = true;
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteAuctionRandomApi = deleteAuctionRandomApi;
exports.deleteCdAuctionApi = deleteCdAuctionApi;
exports.getAuctionRandomInfoApi = getAuctionRandomInfoApi;
exports.getAuctionRandomListApi = getAuctionRandomListApi;
exports.getCdAuctionInfoApi = getCdAuctionInfoApi;
exports.getCdAuctionListApi = getCdAuctionListApi;
exports.getRandomStatusListApi = getRandomStatusListApi;
exports.getStatusListApi = getStatusListApi;
exports.setAuctionRandomApi = setAuctionRandomApi;
exports.setCdAuctionApi = setCdAuctionApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * 创建或编辑倒计时活动
 */
function setCdAuctionApi(data) {
  return _request.default.post('/activity/sys/countdown/auction', data);
}
/**
 * 获取倒计时活动详情
 */
function getCdAuctionInfoApi(id) {
  return _request.default.get("/activity/sys/countdown/auction/".concat(id));
}
/**
 * 删除倒计时活动
 */
function deleteCdAuctionApi(id) {
  return _request.default.delete("/activity/sys/countdown/auction/".concat(id));
}
/**
 * 获取倒计时活动列表
 */
function getCdAuctionListApi(data) {
  return _request.default.get('/activity/sys/countdown/auction/list', data);
}

/**
 * 随机数创建或编辑活动
 */
function setAuctionRandomApi(data) {
  return _request.default.post('/activity/sys/random/auction', data);
}
/**
 * 随机数获取活动详情
 */
function getAuctionRandomInfoApi(id) {
  return _request.default.get("/activity/sys/random/auction/".concat(id));
}
/**
 * 随机数删除活动
 */
function deleteAuctionRandomApi(id) {
  return _request.default.delete("/activity/sys/random/auction/".concat(id));
}
/**
 * 随机数活动列表
 */
function getAuctionRandomListApi(data) {
  return _request.default.get("/activity/sys/random/auction/list", data);
}
/**
 * 倒计时获取中奖列表
 */
function getStatusListApi(data) {
  return _request.default.get("/activity/sys/countdown/auction/memberList", data);
}

/**
 * 随机获取中奖列表
 */
function getRandomStatusListApi(data) {
  return _request.default.get("/activity/sys/random/auction/memberList", data);
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container help-detail",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { position: "relative" },
        },
        [
          _c(
            "div",
            { staticClass: "remove-control form-view " },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm ",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：", prop: "title" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入活动名称" },
                            model: {
                              value: _vm.ruleForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "title", $$v)
                              },
                              expression: "ruleForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间：", prop: "dateTime" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "value-format": "timestamp",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.ruleForm.dateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "dateTime", $$v)
                              },
                              expression: "ruleForm.dateTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片上传：", prop: "coverImage" } },
                    [
                      _c("div", { staticClass: "flex align-items-c" }, [
                        _c("div", { staticClass: "img-list" }, [
                          _c("div", [
                            _vm.ruleForm.coverImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg("", "coverImage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.ruleForm.coverImage + "!120a",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg("", "coverImage")
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-picture" })]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-n" }, [
                            _vm._v("活动生成海报"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-b" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-list" }, [
                          _c("div", [
                            _vm.ruleForm.backgroundImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg(
                                          "",
                                          "backgroundImage"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.ruleForm.backgroundImage +
                                          "!120a",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "del-upload-img",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg("backgroundImage")
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg(
                                          "",
                                          "backgroundImage"
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-picture" })]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-n" }, [
                            _vm._v("主视觉图"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-b" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-list" }, [
                          _c("div", [
                            _vm.ruleForm.contentStyle.backgroundImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg(
                                          "",
                                          "backgroundImage",
                                          "contentStyle"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.ruleForm.contentStyle
                                            .backgroundImage + "!120a",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "del-upload-img",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg(
                                              "backgroundImage",
                                              "contentStyle"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg(
                                          "",
                                          "backgroundImage",
                                          "contentStyle"
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-picture" })]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-n" }, [
                            _vm._v("版块背景图"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-b" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-list" }, [
                          _c("div", [
                            _vm.ruleForm.contentStyle.shareImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg(
                                          "",
                                          "shareImage",
                                          "contentStyle"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.ruleForm.contentStyle.shareImage +
                                          "!120a",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "del-upload-img",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg(
                                              "shareImage",
                                              "contentStyle"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-image flex align-items-c justify-c",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeImg(
                                          "",
                                          "shareImage",
                                          "contentStyle"
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-picture" })]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-n" }, [
                            _vm._v("生成海报按钮"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-b" }, [
                            _vm._v("建议100×100"),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "页面背景色：" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "flex align-items-c" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "160px", height: "36px" } },
                          [
                            _c("colour", {
                              attrs: {
                                colour: "#FFFFFF",
                                "no-alpha": "1",
                                "no-btn": "1",
                              },
                              model: {
                                value: _vm.ruleForm.backgroundColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "backgroundColor", $$v)
                                },
                                expression: "ruleForm.backgroundColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex align-items-c" }, [
                        _c("span", [_vm._v("版块背景色：")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "160px", height: "36px" } },
                          [
                            _c("colour", {
                              attrs: {
                                colour: "#FFFFFF",
                                "no-alpha": "1",
                                "no-btn": "1",
                              },
                              model: {
                                value:
                                  _vm.ruleForm.contentStyle.backgroundColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.contentStyle,
                                    "backgroundColor",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.contentStyle.backgroundColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex align-items-c" }, [
                        _c("span", [_vm._v("版块标题色：")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "160px", height: "36px" } },
                          [
                            _c("colour", {
                              attrs: {
                                colour: "#FFFFFF",
                                "no-alpha": "1",
                                "no-btn": "1",
                              },
                              model: {
                                value: _vm.ruleForm.contentStyle.titleColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.contentStyle,
                                    "titleColor",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.contentStyle.titleColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex align-items-c" }, [
                        _c("span", [_vm._v("正文题色：")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "160px", height: "36px" } },
                          [
                            _c("colour", {
                              attrs: {
                                colour: "#FFFFFF",
                                "no-alpha": "1",
                                "no-btn": "1",
                              },
                              model: {
                                value: _vm.ruleForm.contentStyle.textColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.contentStyle,
                                    "textColor",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.contentStyle.textColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "版块距离：" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("外边距：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.contentStyle.viewMargin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.contentStyle,
                                  "viewMargin",
                                  $$v
                                )
                              },
                              expression: "ruleForm.contentStyle.viewMargin",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("内边距："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.contentStyle.viewPadding,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.contentStyle,
                                  "viewPadding",
                                  $$v
                                )
                              },
                              expression: "ruleForm.contentStyle.viewPadding",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [_vm._v("圆角：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.contentStyle.viewRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.contentStyle,
                                  "viewRadius",
                                  $$v
                                )
                              },
                              expression: "ruleForm.contentStyle.viewRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "字号：" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("标题字号：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.contentStyle.titleFontSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.contentStyle,
                                  "titleFontSize",
                                  $$v
                                )
                              },
                              expression: "ruleForm.contentStyle.titleFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("内容字号："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.contentStyle.textFontSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.contentStyle,
                                  "textFontSize",
                                  $$v
                                )
                              },
                              expression: "ruleForm.contentStyle.textFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "助力成功提示：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: {
                            maxlength: "30",
                            placeholder: "可不填，最多30个字",
                          },
                          model: {
                            value: _vm.ruleForm.helpRuleData.helpSuccessTips,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.helpRuleData,
                                "helpSuccessTips",
                                $$v
                              )
                            },
                            expression: "ruleForm.helpRuleData.helpSuccessTips",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10" }, [
                          _vm._v("不填默认提示”助力成功“"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "自定义跳转地址：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "500px" },
                          attrs: { placeholder: "可不填" },
                          model: {
                            value: _vm.ruleForm.helpRuleData.customizeUrl,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.helpRuleData,
                                "customizeUrl",
                                $$v
                              )
                            },
                            expression: "ruleForm.helpRuleData.customizeUrl",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10" }, [
                          _vm._v("如不填则跳到本次活动页面"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "助力规则：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("div", { staticClass: "l" }, [
                          _vm._v("仅新用户可助力："),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#eee",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: _vm.ruleForm.helpRuleData.isNewUser,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.helpRuleData,
                                "isNewUser",
                                $$v
                              )
                            },
                            expression: "ruleForm.helpRuleData.isNewUser",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "l ml20" }, [
                          _vm._v("需实名可助力："),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#eee",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: _vm.ruleForm.helpRuleData.reservedNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.helpRuleData,
                                "reservedNumber",
                                $$v
                              )
                            },
                            expression: "ruleForm.helpRuleData.reservedNumber",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "l ml20" }, [
                          _vm._v("需开通钱包可助力："),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#eee",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: _vm.ruleForm.helpRuleData.isOpenWallet,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.helpRuleData,
                                "isOpenWallet",
                                $$v
                              )
                            },
                            expression: "ruleForm.helpRuleData.isOpenWallet",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "奖品设置：" } }, [
                    _c(
                      "div",
                      { staticClass: "tab" },
                      [
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "10%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n                奖品类型\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "10%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n                奖品图片\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "20%",
                                "text-align": "left",
                              },
                            },
                            [_vm._v("名称")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "10%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n                按每*人助力\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "40%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n                助力设置\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "10%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n                奖品总数\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("操作")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.prizeData, function (m, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "tr flex align-items-c" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "td flex",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.editTypeAction(i)
                                        },
                                      },
                                      model: {
                                        value: m.prizeType,
                                        callback: function ($$v) {
                                          _vm.$set(m, "prizeType", $$v)
                                        },
                                        expression: "m.prizeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft",
                                          label: "作品",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft_blind",
                                          label: "盲盒",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "integral",
                                          label: "积分",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "coupon",
                                          label: "优惠券",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td flex",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  m.prizeType == "product_nft" ||
                                  m.prizeType == "product_nft_blind"
                                    ? [
                                        m.prizeImage
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "upload-image flex align-items-c justify-c",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: m.prizeImage + "!120a",
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "upload-image flex align-items-c justify-c",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-picture",
                                                }),
                                              ]
                                            ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td flex",
                                  staticStyle: { width: "20%" },
                                },
                                [
                                  m.prizeType == "integral"
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "w100 mr10" },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "积分类型",
                                                },
                                                model: {
                                                  value: m.scoreTypeId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      m,
                                                      "scoreTypeId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "m.scoreTypeId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.typeList,
                                                function (m, i) {
                                                  return _c("el-option", {
                                                    key: "type" + i,
                                                    attrs: {
                                                      value: m.id,
                                                      label: m.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("el-input-number", {
                                          staticStyle: { width: "50px" },
                                          attrs: { controls: false },
                                          model: {
                                            value: m.getIntegral,
                                            callback: function ($$v) {
                                              _vm.$set(m, "getIntegral", $$v)
                                            },
                                            expression: "m.getIntegral",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml5" }, [
                                          _vm._v("积分"),
                                        ]),
                                      ]
                                    : [_vm._v(_vm._s(m.prizeName))],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "td flex flex-direction align-items-s",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#cccccc",
                                      "inactive-value": 1,
                                      "active-value": 2,
                                      disabled: !!m.prizeId,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeReceiveType(m)
                                      },
                                    },
                                    model: {
                                      value: m.receiveType,
                                      callback: function ($$v) {
                                        _vm.$set(m, "receiveType", $$v)
                                      },
                                      expression: "m.receiveType",
                                    },
                                  }),
                                  _vm._v(" "),
                                  m.receiveType === 2
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66",
                                              "inactive-color": "#eee",
                                              "active-value": 1,
                                              "inactive-value": 0,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeMulti(m)
                                              },
                                            },
                                            model: {
                                              value: m.isMultipleBuy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  m,
                                                  "isMultipleBuy",
                                                  $$v
                                                )
                                              },
                                              expression: "m.isMultipleBuy",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("只看购买数")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "40%" },
                                },
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "div",
                                      [
                                        _c("span", { staticClass: "mr5" }, [
                                          _vm._v(
                                            _vm._s(
                                              m.receiveType == 2 ? "每" : "需"
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-input-number", {
                                          staticStyle: { width: "50px" },
                                          attrs: {
                                            controls: false,
                                            disabled: m.isMultipleBuy === 1,
                                          },
                                          model: {
                                            value: m.helpCount,
                                            callback: function ($$v) {
                                              _vm.$set(m, "helpCount", $$v)
                                            },
                                            expression: "m.helpCount",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml5" }, [
                                          _vm._v("人助力"),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml5 mr5" }, [
                                          _vm._v(
                                            _vm._s(
                                              m.receiveType == 2 ? "每" : "需"
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-input-number", {
                                          staticStyle: { width: "50px" },
                                          attrs: { controls: false },
                                          model: {
                                            value: m.buyNum,
                                            callback: function ($$v) {
                                              _vm.$set(m, "buyNum", $$v)
                                            },
                                            expression: "m.buyNum",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml5" }, [
                                          _vm._v("次购买"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    m.receiveType == 2
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "ml5 mr5" },
                                              [_vm._v("可领1个，最多")]
                                            ),
                                            _vm._v(" "),
                                            _c("el-input-number", {
                                              staticStyle: { width: "60px" },
                                              attrs: { controls: false },
                                              model: {
                                                value: m.maxReceiveNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    m,
                                                    "maxReceiveNum",
                                                    $$v
                                                  )
                                                },
                                                expression: "m.maxReceiveNum",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v("个"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "reward-list" },
                                    [
                                      _c("span", [_vm._v("自定义奖励数量：")]),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            size: "small",
                                            placeholder:
                                              "请选择快照名单,可多选",
                                            clearable: "",
                                            multiple: "",
                                          },
                                          model: {
                                            value: m.snapshotIds,
                                            callback: function ($$v) {
                                              _vm.$set(m, "snapshotIds", $$v)
                                            },
                                            expression: "m.snapshotIds",
                                          },
                                        },
                                        _vm._l(
                                          _vm.snapshotList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                value: item.id,
                                                label: item.name,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("el-input-number", {
                                        attrs: {
                                          controls: false,
                                          precision: 0,
                                          placeholder: "自定义奖励数量",
                                        },
                                        model: {
                                          value: m.bonusTimes,
                                          callback: function ($$v) {
                                            _vm.$set(m, "bonusTimes", $$v)
                                          },
                                          expression: "m.bonusTimes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td flex",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: { controls: false },
                                    model: {
                                      value: m.prizeNum,
                                      callback: function ($$v) {
                                        _vm.$set(m, "prizeNum", $$v)
                                      },
                                      expression: "m.prizeNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  m.prizeType != "integral"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAdditem(
                                                m.prizeType,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择\n                ")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delPrizeAction(i)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.addPrizeAction },
                                },
                                [_vm._v("新增奖品")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "购买任务指定：" } }, [
                    _c(
                      "div",
                      { staticClass: "tab" },
                      [
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("类型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("图片")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "60%",
                                "text-align": "left",
                              },
                            },
                            [_vm._v("名称")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "20%" },
                            },
                            [_vm._v("操作")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.buyProductData, function (m, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "tr flex align-items-c" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.editBuyTypeAction(i)
                                        },
                                      },
                                      model: {
                                        value: m.prizeType,
                                        callback: function ($$v) {
                                          _vm.$set(m, "prizeType", $$v)
                                        },
                                        expression: "m.prizeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft",
                                          label: "作品",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft_blind",
                                          label: "盲盒",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td item-flex-center",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  m.image
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-image flex align-items-c justify-c",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: m.image + "!120a",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-image flex align-items-c justify-c",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-picture",
                                          }),
                                        ]
                                      ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: {
                                    width: "60%",
                                    "text-align": "left",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(m.name || "") +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "20%" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleBuyAdditem(
                                            m.prizeType,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delBuyAction(i)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.addBuyAction },
                                },
                                [_vm._v("新增指定购买")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("Tinymce", {
            ref: "editor",
            attrs: { height: 400 },
            model: {
              value: _vm.ruleForm.ruleMessage,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "ruleMessage", $$v)
              },
              expression: "ruleForm.ruleMessage",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "common-btns",
          class: { left0: _vm.device === "mobile" },
          style: { left: _vm.sidebar.opened ? "230px" : "74px" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAction("ruleForm")
                },
              },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1", "show-select": "" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1", "show-select": "" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
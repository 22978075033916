var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c(
      "div",
      { staticClass: "item flex align-items-c h50" },
      [
        _c("div", { staticClass: "flex-one" }, [
          _vm._v("自己种植土地数量上限"),
        ]),
        _vm._v(" "),
        _c("el-input", {
          staticClass: "inpStyle",
          attrs: { placeholder: "请输入数量" },
          model: {
            value: _vm.formState.selfPlantNum,
            callback: function ($$v) {
              _vm.$set(_vm.formState, "selfPlantNum", $$v)
            },
            expression: "formState.selfPlantNum",
          },
        }),
        _vm._v("块\n  "),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item flex align-items-c h50" },
      [
        _c("div", { staticClass: "flex-one" }, [
          _vm._v("邀请好友种植土地上限"),
        ]),
        _vm._v(" "),
        _c("el-input", {
          staticClass: "inpStyle",
          attrs: { placeholder: "请输入数量" },
          model: {
            value: _vm.formState.invitePlantNum,
            callback: function ($$v) {
              _vm.$set(_vm.formState, "invitePlantNum", $$v)
            },
            expression: "formState.invitePlantNum",
          },
        }),
        _vm._v("块\n  "),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item flex align-items-c h50" },
      [
        _c("div", { staticClass: "flex-one" }, [_vm._v("最多可租赁地块上限")]),
        _vm._v(" "),
        _c("el-input", {
          staticClass: "inpStyle",
          attrs: { placeholder: "请输入数量" },
          model: {
            value: _vm.formState.inviteMaxNum,
            callback: function ($$v) {
              _vm.$set(_vm.formState, "inviteMaxNum", $$v)
            },
            expression: "formState.inviteMaxNum",
          },
        }),
        _vm._v("块\n  "),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("div", { staticClass: "flex-one itemTit" }, [_vm._v("规则说明：")]),
        _vm._v(" "),
        _c("Tinymce", {
          ref: "editor",
          attrs: { height: 400 },
          model: {
            value: _vm.formState.ruleSet,
            callback: function ($$v) {
              _vm.$set(_vm.formState, "ruleSet", $$v)
            },
            expression: "formState.ruleSet",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "submit-btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.onSubmit()
              },
            },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _animal = require("@/api/animal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Setting",
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      formState: {
        selfPlantNum: 0,
        invitePlantNum: 0,
        inviteMaxNum: 0,
        ruleSet: ''
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _animal.getInviteSetApi)().then(function (res) {
      // console.log('1---------------------',res);
      _this.formState = res.data;
      _this.$refs.editor.setContent(res.data.rulesExplain);
    });
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this2 = this;
      (0, _animal.updateInviteSetApi)(this.formState).then(function (res) {
        // console.log(res);
        if (res.code == 200) {
          _this2.$message.success("操作成功");
        }
      });
    }
  }
};
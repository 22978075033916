var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("div", [_vm._v("当前轮次")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "轮次", width: "1000" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "80px",
                            "margin-right": "10px",
                          },
                          model: {
                            value: row.battleNum,
                            callback: function ($$v) {
                              _vm.$set(row, "battleNum", $$v)
                            },
                            expression: "row.battleNum",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: row.type,
                              callback: function ($$v) {
                                _vm.$set(row, "type", $$v)
                              },
                              expression: "row.type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("最少宝石房间"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("最多宝石房间"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("上一轮结果房间"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("指定房间"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "150px",
                              "margin-left": "10px",
                            },
                            model: {
                              value: row.roomId,
                              callback: function ($$v) {
                                _vm.$set(row, "roomId", $$v)
                              },
                              expression: "row.roomId",
                            },
                          },
                          _vm._l(_vm.albumList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.id, label: m.roomName },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return undefined
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onSubmit(row)
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onDele(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "submit-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onAdd()
                },
              },
            },
            [_vm._v("添加规则")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      activeName: '',
      tabList: [{
        name: 'orderScan',
        label: '寄售监控'
      }, {
        name: 'orderLock',
        label: '锁单监控'
      }]
    };
  },
  watch: {
    $route: {
      handler: function handler(val) {
        var tabNames = this.tabList.map(function (e) {
          return e.name;
        });
        if (!tabNames.includes(val.name)) {
          this.activeName = tabNames[0];
        } else {
          if (val.name !== this.activeName) {
            this.activeName = val.name;
          }
        }
      },
      immediate: true
    },
    activeName: {
      handler: function handler(val) {
        if (val && val !== this.$route.name) {
          this.$router.replace({
            name: val
          });
        }
      },
      immediate: true
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formRef",
      attrs: {
        model: _vm.formData,
        rules: _vm.formRules,
        "label-width": "120px",
        "label-position": "right",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "name", label: "活动名称" } },
        [
          _c("el-input", {
            staticClass: "w300",
            attrs: { placeholder: "请输入活动名称" },
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { attrs: { prop: "image", label: "活动封面图" } }, [
        _c(
          "div",
          {
            staticClass: "upLoadPicBox",
            attrs: { title: "正方形图片" },
            on: {
              click: function ($event) {
                return _vm.modalPicTap("1", "image")
              },
            },
          },
          [
            _vm.formData.image
              ? _c("div", { staticClass: "pictrue" }, [
                  _c("img", { attrs: { src: _vm.formData.image + "!120a" } }),
                ])
              : _c("div", { staticClass: "upLoad" }, [
                  _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.timeType === "daterange"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "活动时间",
                prop: "endTime",
                rules: [
                  {
                    required: true,
                    message: "请选择活动时间",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-date-picker", {
                staticClass: "w300",
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "picker-options": _vm.pickerOptions,
                },
                on: { change: _vm.handleChangeTime },
                model: {
                  value: _vm.currentTime,
                  callback: function ($$v) {
                    _vm.currentTime = $$v
                  },
                  expression: "currentTime",
                },
              }),
            ],
            1
          )
        : _c(
            "el-form-item",
            {
              attrs: {
                label: "活动开始时间",
                prop: "startTime",
                rules: [
                  {
                    required: true,
                    message: "请选择活动开始时间",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-date-picker", {
                staticClass: "w300",
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期时间",
                  "picker-options": _vm.pickerOptions,
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.formData.startTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "startTime", $$v)
                  },
                  expression: "formData.startTime",
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "活动规则", prop: "activities" } },
        [
          _c("el-input", {
            staticClass: "w300",
            attrs: {
              type: "textarea",
              placeholder: "请输入活动规则",
              rows: "5",
              maxlength: "300",
            },
            model: {
              value: _vm.formData.activities,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "activities", $$v)
              },
              expression: "formData.activities",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-row",
            { staticClass: "base-row", attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { prop: "backgroundImage" } }, [
                    _c("div", [_vm._v("页面背景图")]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "backgroundImage")
                            },
                          },
                        },
                        [
                          _vm.formData.backgroundImage
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.formData.backgroundImage + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { prop: "backGroundColor" } }, [
                    _c("div", [_vm._v("版块背景")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("colour", {
                          attrs: { colour: _vm.initForm.backGroundColor },
                          model: {
                            value: _vm.formData.backGroundColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "backGroundColor", $$v)
                            },
                            expression: "formData.backGroundColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { prop: "titleMasterColor" } }, [
                    _c("div", [_vm._v("版块主文字")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("colour", {
                          attrs: { colour: _vm.initForm.titleMasterColor },
                          model: {
                            value: _vm.formData.titleMasterColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "titleMasterColor", $$v)
                            },
                            expression: "formData.titleMasterColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "titleAssistantColor" } },
                    [
                      _c("div", [_vm._v("版块副文本")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("colour", {
                            attrs: { colour: _vm.initForm.titleAssistantColor },
                            model: {
                              value: _vm.formData.titleAssistantColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "titleAssistantColor",
                                  $$v
                                )
                              },
                              expression: "formData.titleAssistantColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "timingBackgroundColor" } },
                    [
                      _c("div", [_vm._v("计时背景")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("colour", {
                            attrs: {
                              colour: _vm.initForm.timingBackgroundColor,
                            },
                            model: {
                              value: _vm.formData.timingBackgroundColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "timingBackgroundColor",
                                  $$v
                                )
                              },
                              expression: "formData.timingBackgroundColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { prop: "timingTitleColor" } }, [
                    _c("div", [_vm._v("计时文字")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("colour", {
                          attrs: { colour: _vm.initForm.timingTitleColor },
                          model: {
                            value: _vm.formData.timingTitleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "timingTitleColor", $$v)
                            },
                            expression: "formData.timingTitleColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { prop: "cardRadius" } }, [
                    _c("div", [_vm._v("版块圆角")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          staticClass: "w80",
                          scopedSlots: _vm._u([
                            {
                              key: "suffix",
                              fn: function () {
                                return [_vm._v("px")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.formData.cardRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "cardRadius", $$v)
                            },
                            expression: "formData.cardRadius",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { prop: "buttonImage" } }, [
                    _c("div", [_vm._v("按钮背景图")]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "buttonImage")
                            },
                          },
                        },
                        [
                          _vm.formData.buttonImage
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.formData.buttonImage + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { prop: "buttonTitleColor" } }, [
                    _c("div", [_vm._v("按钮文字")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("colour", {
                          attrs: { colour: _vm.initForm.buttonTitleColor },
                          model: {
                            value: _vm.formData.buttonTitleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "buttonTitleColor", $$v)
                            },
                            expression: "formData.buttonTitleColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formRef")
                },
              },
            },
            [_vm._v(_vm._s(_vm.formData.id ? "确认修改" : "确认创建"))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _marketing = require("@/api/marketing");
var _vuex = require("vuex");
var _uuid = require("uuid");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _coupon = _interopRequireDefault(require("@/components/customChildren/coupon"));
var _nftblind = _interopRequireDefault(require("@/components/customChildren/nftblind.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Nft: _nft.default,
    Tinymce: _Tinymce.default,
    slider: _slider.default,
    colour: _colour.default,
    Coupon: _coupon.default,
    Nftblind: _nftblind.default
  },
  data: function data() {
    return {
      snapshotList: [],
      ruleForm: {
        title: "",
        coverImage: "",
        dateTime: [],
        backgroundImage: "",
        backgroundColor: "#FFFFFF",
        contentStyle: {
          backgroundImage: "",
          backgroundColor: "#FFFFFF",
          shareImage: "",
          viewMargin: 30,
          viewPadding: 50,
          viewRadius: 16,
          titleColor: "#000000",
          titleFontSize: 36,
          textColor: "#000000",
          textFontSize: 28
        },
        helpRuleData: {
          isNewUser: 1,
          reservedNumber: 1,
          isOpenWallet: 1,
          helpSuccessTips: "",
          customizeUrl: ""
        },
        ruleMessage: ""
      },
      prizeData: [{
        prizeType: "product_nft",
        prizeName: "",
        helpCount: 10,
        buyNum: 0,
        prizeNum: "",
        receiveLimitNum: "",
        prizeImage: "",
        prizeRelationId: "",
        getIntegral: 100,
        snapshotIds: [],
        bonusTimes: 1,
        isMultipleBuy: 0,
        uuid: (0, _uuid.v4)(),
        maxReceiveNum: 0
      }],
      buyProductData: [],
      rules: {
        coverImage: {
          required: true,
          message: "请上传活动生成海报",
          trigger: "change"
        },
        title: {
          required: true,
          message: "请输入活动名称",
          trigger: "blur"
        },
        dateTime: [{
          required: true,
          message: "请选择活动时间",
          trigger: "change"
        }]
      },
      typeList: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  created: function created() {
    var _this = this;
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.loadDetail();
    }
    (0, _marketing.integralTypeListApi)().then(function (res) {
      _this.typeList = res.data;
    });
    (0, _marketing.marketGetSnapshotApi)().then(function (res) {
      _this.snapshotList = res.data;
    });
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this2 = this;
      (0, _marketing.helpDetailApi)(this.id).then(function (res) {
        var data = res.data;
        _this2.ruleForm = {
          title: data.title,
          coverImage: data.coverImage,
          dateTime: [Date.parse(data.startTime), Date.parse(data.endTime)],
          backgroundImage: data.backgroundImage,
          backgroundColor: data.backgroundColor,
          helpRuleData: {
            isNewUser: data.helpRuleData.isNewUser,
            reservedNumber: data.helpRuleData.reservedNumber,
            isOpenWallet: data.helpRuleData.isOpenWallet,
            helpSuccessTips: data.helpRuleData.helpSuccessTips,
            customizeUrl: data.helpRuleData.customizeUrl
          },
          ruleMessage: data.ruleMessage,
          contentStyle: data.contentStyle ? data.contentStyle : {
            backgroundImage: "",
            backgroundColor: "#FFFFFF",
            shareImage: "",
            viewMargin: 30,
            viewPadding: 50,
            viewRadius: 16,
            titleColor: "#000000",
            titleFontSize: 36,
            textColor: "#000000",
            textFontSize: 28
          }
        };
        if (data.ruleMessage) {
          _this2.$refs["editor"].setContent(data.ruleMessage);
        }
        if (data.buyProductData && data.buyProductData.length > 0) {
          _this2.buyProductData = data.buyProductData.map(function (m) {
            return {
              prizeType: m.prizeType,
              name: m.productName,
              id: m.id,
              prizeRelationId: m.prizeRelationId,
              productId: m.productId,
              image: m.image
            };
          });
        }
        _this2.prizeData = data.prizeData;
        _this2.prizeData.forEach(function (item) {
          item.uuid = (0, _uuid.v4)();
          if (item.snapshotIds) {
            item.snapshotIds = item.snapshotIds.split(",");
          } else {
            item.snapshotIds = [];
          }
          item.bonusTimes = item.bonusTimes ? item.bonusTimes : 1;
        });
      }).catch(function (_ref) {
        var message = _ref.message;
        _this2.$message.error(message);
        _this2.$router.back();
      });
    },
    changeImg: function changeImg(i, key, otherKey) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        if (key) {
          if (otherKey) {
            _this3.ruleForm[otherKey][key] = img[0];
          } else {
            _this3.ruleForm[key] = img[0];
          }
          if (key === "coverImage") {
            _this3.$refs.ruleForm.validateField([key], function (Error) {
              if (!Error) {
                // 验证通过
                console.log("通过了");
              } else {
                console.log("验证不通过");
              }
            });
          }
        } else {
          _this3.prizeData[i].prizeImage = img[0];
        }
      }, 1, "1");
    },
    backAction: function backAction() {
      this.$router.back();
    },
    handleAdditem: function handleAdditem(view, i) {
      this.changeType = "";
      this.changeIndex = i;
      this.$refs[view].changeshow();
    },
    handleBuyAdditem: function handleBuyAdditem(view, i) {
      this.changeType = "buy";
      this.changeIndex = i;
      this.$refs[view].changeshow();
    },
    addPrizeAction: function addPrizeAction() {
      this.prizeData.push({
        prizeType: "product_nft",
        prizeName: "",
        receiveType: 1,
        helpCount: 10,
        maxReceiveNum: 0,
        buyNum: 0,
        prizeNum: "",
        receiveLimitNum: "",
        prizeImage: "",
        prizeRelationId: "",
        getIntegral: 100,
        snapshotIds: [],
        bonusTimes: 1,
        isMultipleBuy: 0,
        uuid: (0, _uuid.v4)()
      });
    },
    delPrizeAction: function delPrizeAction(i) {
      this.prizeData.splice(i, 1);
    },
    editTypeAction: function editTypeAction(i) {
      var d = this.prizeData[i];
      d.prizeImage = "";
      d.prizeName = "";
      d.getIntegral = "";
      d.prizeRelationId = "";
      this.prizeData[i] = d;
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      if (data.data && data.data[0]) {
        var val = data.data[0];
        if (this.changeType === "buy") {
          var d = this.buyProductData[this.changeIndex];
          d.image = val.image.split("!")[0];
          d.name = val.name;
          d.productId = val.p_id;
          d.prizeRelationId = val.id;
          this.buyProductData[this.changeIndex] = d;
        } else {
          var _d = this.prizeData[this.changeIndex];
          if (val.image) {
            _d.prizeImage = val.image.split("!")[0];
          }
          _d.prizeName = val.name;
          _d.productId = val.p_id;
          _d.prizeRelationId = val.id;
          this.prizeData[this.changeIndex] = _d;
        }
      }
    },
    saveAction: function saveAction(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var prizeData = [];
          var error = "";
          _this4.prizeData.forEach(function (m) {
            if (m.receiveType === 2 && m.maxReceiveNum <= 0) {
              error = "请设置奖品最多可领取数量";
            }
            if (m.isMultipleBuy) {
              if (!m.buyNum) {
                error = "请设置奖品最多可领取数量";
              }
            }
            if (m.prizeType === "product_nft" || m.prizeType === "product_nft_blind") {
              if (!m.prizeRelationId || !m.prizeNum || !m.helpCount && !m.buyNum) {
                error = "请正确设置奖品，奖品必须设置数量与领奖条件";
                prizeData.push(m);
              }
              if (m.prizeNum > m.stock) {
                error = "奖品总数超过奖品库存";
              }
            } else {
              if (!m.prizeNum || !m.helpCount && !m.buyNum) {
                error = "请正确设置奖品，奖品必须设置数量与领奖条件";
                prizeData.push(m);
              }
            }
            if (m.prizeType === "integral" && !m.scoreTypeId) {
              error = "请选择积分类型";
            }
          });
          if (error) {
            _this4.$message.error(error);
            return false;
          }
          // const buyProductData = [];
          // this.buyProductData.forEach(m => {
          //   if (m.id) {
          //     buyProductData.push(m);
          //   }
          // });
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));
          if (!data.coverImage) {
            _this4.$message.error("请上传活动生成海报");
            return false;
          }
          data.prizeData = _this4.prizeData;
          data.prizeData.forEach(function (item) {
            item.snapshotIds = item.snapshotIds.length > 0 ? item.snapshotIds.toString() : "";
          });
          if (_this4.buyProductData.length > 0) {
            data.buyProductData = _this4.buyProductData;
          } else {
            data.buyProductData = [];
          }
          data.startTime = data.dateTime[0];
          data.endTime = data.dateTime[1];
          console.log(data);
          var loading = _this4.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          var action = _marketing.helpCreatedApi;
          if (_this4.id) {
            data.id = _this4.id;
            action = _marketing.helpEditApi;
          }
          action(data).then(function () {
            loading.close();
            _this4.$message.success("操作成功");
            _this4.$router.back();
          }).catch(function (msg) {
            loading.close();
            _this4.$message.error(msg);
          });
        } else {
          _this4.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    addBuyAction: function addBuyAction() {
      this.buyProductData.push({
        prizeType: "product_nft",
        name: "",
        image: "",
        prizeRelationId: "",
        productId: ""
      });
    },
    editBuyTypeAction: function editBuyTypeAction(i) {
      var d = this.buyProductData[i];
      d.image = "";
      d.name = "";
      d.prizeRelationId = "";
      d.productId = "";
      this.buyProductData[i] = d;
    },
    delBuyAction: function delBuyAction(i) {
      this.buyProductData.splice(i, 1);
    },
    delImg: function delImg(key, otherKey) {
      if (otherKey) {
        this.ruleForm[otherKey][key] = "";
      } else {
        this.ruleForm[key] = "";
      }
    },
    changeMulti: function changeMulti(m) {
      var isMultipleBuy = m.isMultipleBuy,
        uuid = m.uuid;
      if (isMultipleBuy) {
        var prizeDataSource = (0, _toConsumableArray2.default)(this.prizeData);
        var target = prizeDataSource.find(function (item) {
          return item.uuid === uuid;
        });
        if (target) {
          target.helpCount = 1;
          this.prizeData = prizeDataSource;
        }
      }
    },
    changeReceiveType: function changeReceiveType(m) {
      var receiveType = m.receiveType,
        uuid = m.uuid;
      console.log(receiveType);
      if (receiveType === 1) {
        var prizeDataSource = (0, _toConsumableArray2.default)(this.prizeData);
        var target = prizeDataSource.find(function (item) {
          return item.uuid === uuid;
        });
        if (target) {
          target.isMultipleBuy = 0;
          this.prizeData = prizeDataSource;
        }
      }
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _product = require("@/api/product");
var _wantBuy = require("@/api/wantBuy");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TimingSetting',
  data: function data() {
    return {
      snapshotOptions: [],
      searchPages: {
        pageNum: 1,
        pageSize: 500
      },
      formData: {},
      config: {},
      arrayList: ['limitationWantBuyWhiteList', 'limitationAutoWantBuyWhiteList', 'toppingShopList']
    };
  },
  created: function created() {
    this.getSnapshot();
    this.getConfig();
  },
  methods: {
    onChange: function onChange() {
      this.$forceUpdate();
    },
    getSnapshot: function getSnapshot(reset) {
      var _this = this;
      if (reset) {
        this.searchPages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)({}, this.searchPages);
      (0, _product.snapshotListApi)(params).then(function (res) {
        _this.snapshotOptions = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 获取扫描寄售藏品
    getConfig: function getConfig() {
      var _this2 = this;
      (0, _wantBuy.getWantBuyConfigApi)().then(function (res) {
        if (res.code === 200) {
          var data = res.data || {};
          _this2.formData = data;
          _this2.arrayList.forEach(function (e) {
            _this2.formData[e] = _this2.getLockorder(e);
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    numInput: function numInput(name, piont, min, max) {
      this.formData[name] = (0, _index.numInput)(this.formData[name], piont, min, max);
    },
    getLockorder: function getLockorder(key) {
      var config = this.formData;
      var lock = [];
      if (config && config[key]) {
        try {
          lock = config[key].split(',');
        } catch (_unused) {
          lock = [];
        }
      }
      return lock;
    },
    setLockorder: function setLockorder(key) {
      var config = this.formData;
      var lock = '';
      if (config && config[key]) {
        try {
          lock = config[key].join(',');
        } catch (_unused2) {
          lock = '';
        }
      }
      return lock;
    },
    submitForm: function submitForm() {
      var _this3 = this;
      var params = {};
      this.arrayList.forEach(function (e) {
        params[e] = _this3.setLockorder(e);
      });
      console.log(this.formData);
      console.log(params);
      // return
      this.setConfig((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.formData), params));
    },
    // 修改扫描寄售设置
    setConfig: function setConfig(config) {
      var _this4 = this;
      if (this.btnLoading) {
        return;
      }
      var params = {
        id: config.id,
        limitationWantBuyWhiteList: config.limitationWantBuyWhiteList,
        maxCount: config.maxCount,
        hasLimitationWantBuy: config.hasLimitationWantBuy,
        hasShowAutoBuy: config.hasShowAutoBuy,
        hasLimitationAutoWantBuy: config.hasLimitationAutoWantBuy,
        limitationAutoWantBuyWhiteList: config.limitationAutoWantBuyWhiteList,
        toppingShopList: config.toppingShopList
      };
      this.btnLoading = true;
      (0, _wantBuy.setWantBuyConfigApi)(params).then(function (res) {
        if (res.code === 200) {
          _this4.getConfig();
          _this4.$message.success(res.msg);
        } else {
          _this4.$message.error(res.msg);
        }
        _this4.btnLoading = false;
      }).catch(function (err) {
        _this4.btnLoading = false;
        console.log(err);
      });
    }
  }
};
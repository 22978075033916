var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-flex-center",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.facade.imgStatic ? "no-d3-wrap" : "d3-wrap",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "rotatef" },
                          [
                            _c("el-image", {
                              staticClass: "img",
                              style: {
                                borderWidth:
                                  (_vm.facade.borderWidth
                                    ? _vm.facade.borderWidth
                                    : 0) /
                                    2 +
                                  "px",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                fit: "contain",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "nameArea"
        ? [
            _c(
              "div",
              {
                staticClass: "top-name-view",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.facade.marginLeft / 2 +
                  "px 0 " +
                  _vm.facade.marginLeft / 2 +
                  "px;background: " +
                  _vm.facade.background +
                  ";padding: 0 " +
                  _vm.facade.paddingLeft / 2 +
                  "px " +
                  _vm.facade.paddingbottom / 2 +
                  "px " +
                  _vm.facade.paddingLeft / 2 +
                  "px;\n    border-radius:" +
                  _vm.facade.topRadius / 2 +
                  "px " +
                  _vm.facade.topRadius / 2 +
                  "px " +
                  _vm.facade.bottomRadius / 2 +
                  "px " +
                  _vm.facade.bottomRadius / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    class: {
                      "flex-direction item-flex-center":
                        _vm.facade.layout == "center",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-one",
                        staticStyle: { overflow: "hidden" },
                      },
                      [
                        _c(
                          "div",
                          {
                            style:
                              "padding-top: " +
                              _vm.facade.nameTop / 2 +
                              "px;font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;line-height: " +
                              (_vm.facade.nameFontSize + 14) / 2 +
                              "px;color: " +
                              _vm.facade.nameColor +
                              ";font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";text-align: " +
                              _vm.facade.layout +
                              ";",
                          },
                          [_vm._v("BELIEVERS 系列盲盒")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            class: {
                              "item-flex-center": _vm.facade.layout == "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "desc-bor",
                                style:
                                  "margin-top: " +
                                  _vm.facade.tabTop / 2 +
                                  "px;padding: 1px;border-radius: " +
                                  _vm.facade.stock_radius / 2 +
                                  "px;" +
                                  (_vm.facade.tabBorderGradient
                                    ? "background: linear-gradient(" +
                                      (_vm.facade.gradient_direction ||
                                        "to right") +
                                      ", " +
                                      _vm.facade.tabBorder +
                                      ", " +
                                      _vm.facade.tabBorderGradient +
                                      ");"
                                    : "background:" +
                                      _vm.facade.tabBorder +
                                      ";"),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "desc",
                                    style:
                                      "font-size: " +
                                      _vm.facade.tabFontSize / 2 +
                                      "px;color: " +
                                      _vm.facade.tabColor +
                                      ";font-weight: " +
                                      _vm.facade.tabFongWeight +
                                      ";border-radius: " +
                                      (_vm.facade.stock_radius - 4) / 2 +
                                      "px;" +
                                      (_vm.facade.tabBgColorGradient
                                        ? "background: linear-gradient(" +
                                          (_vm.facade.gradient_direction ||
                                            "to right") +
                                          ", " +
                                          _vm.facade.tabBgColor +
                                          ", " +
                                          _vm.facade.tabBgColorGradient +
                                          ");"
                                        : "background:" +
                                          _vm.facade.tabBgColor +
                                          ";"),
                                  },
                                  [_vm._v("ssr级系列")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.facade.btnText
                      ? _c(
                          "div",
                          {
                            staticClass: "btn flex align-items-c",
                            style:
                              "margin-top: " +
                              _vm.facade.btnTop / 2 +
                              "px;font-size: " +
                              _vm.facade.btnFontSize / 2 +
                              "px;color: " +
                              _vm.facade.btnColor +
                              ";font-weight: " +
                              _vm.facade.btnFongWeight +
                              ";background:" +
                              _vm.facade.btnBgColor +
                              ";border:1px solid " +
                              _vm.facade.btnBorder +
                              ";height: " +
                              _vm.facade.btnHeight / 2 +
                              "px;border-radius: " +
                              _vm.facade.btnRadius / 2 +
                              "px;",
                          },
                          [
                            _vm.facade.btnImg
                              ? _c("img", { attrs: { src: _vm.facade.btnImg } })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(_vm.facade.btnText))]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "desc",
                    style:
                      "margin-top: " +
                      _vm.facade.descTop / 2 +
                      "px;font-size: " +
                      _vm.facade.descFontSize / 2 +
                      "px;color: " +
                      _vm.facade.descColor +
                      ";font-weight: " +
                      _vm.facade.descFontWeight +
                      ";line-height:" +
                      _vm.facade.descLineHeight / 2 +
                      "px;",
                  },
                  [
                    _vm._v(
                      "未来，人类对数码设备，脑机接口的极度依赖，沉溺于虚拟世界元宇宙而抵触现实。由此产生的新宗教，统称为数字信徒。脑机接口的极度依赖。"
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "share"
        ? [
            _vm.pageInfo.show_share == 1 && _vm.pageInfo.share_area == 1
              ? _c(
                  "div",
                  {
                    staticClass: "top-img item-flex-center",
                    style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
                  },
                  [
                    _c("div", { staticClass: "share-icon" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.facade.share_icon + "!120a",
                          alt: "",
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        : _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "name",
                    style:
                      "color: " +
                      _vm.facade.color +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";",
                  },
                  [_vm._v("重负之下·文创之巅景远作品光在前方照亮你的路")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "price"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "price",
                    style:
                      "font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";text-align: center;",
                  },
                  [
                    _c(
                      "span",
                      {
                        style:
                          "font-size: " +
                          _vm.facade.symbol_font_size / 2 +
                          "px;",
                      },
                      [_vm._v("￥")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "NUM",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("39.00")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "stock"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "price",
                    style:
                      "font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";text-align: center;",
                  },
                  [_vm._v("限量5000份")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "coupon"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "coupon flex align-items-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _c("div", { staticClass: "flex align-items-c flex-one" }, [
                      _c("img", {
                        attrs: { src: _vm.facade.couponImg, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml10" }, [_vm._v("满500减30")]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "ml20",
                        attrs: { src: _vm.facade.couponImg, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml10" }, [_vm._v("9折券")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", [_vm._v("领券")]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: _vm.facade.receiveImg, alt: "" },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";",
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满14周岁的中国大陆用户购买。"
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type == "list"
        ? [
            _c(
              "div",
              {
                staticClass: "content-box",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "title-image",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%", display: "block" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "list-title item-flex-center",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.facade.color +
                          ";font-size: " +
                          _vm.facade.fontSize / 2 +
                          "px;",
                      },
                      [
                        _c("div", {
                          staticClass: "heng",
                          style: "background: " + _vm.facade.color,
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "t",
                            style: "font-weight: " + _vm.facade.fontWeight,
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.facade.title.replace("${num}", "2"))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heng",
                          style: "background: " + _vm.facade.color,
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(2, function (item, index) {
                  return _c("div", { key: index, staticClass: "list flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "r-view flex",
                        style:
                          "margin-top:" +
                          _vm.facade.item_margin / 2 +
                          "px;border-radius: " +
                          _vm.pageInfo.borderRadius / 2 +
                          "px;background: " +
                          _vm.facade.status_background +
                          " url(" +
                          _vm.facade.status_bg_image +
                          ") repeat right bottom;background-size: 100%;",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "img" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src:
                                  index == 0
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                fit: "cover",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-r" }, [
                          _c(
                            "div",
                            {
                              staticClass: "name line2",
                              style:
                                "color: " +
                                _vm.facade.title_color +
                                ";font-size: " +
                                _vm.facade.title_font_size / 2 +
                                "px;font-weight: " +
                                _vm.facade.title_font_weight +
                                ";",
                            },
                            [_vm._v("坠落·光在前方照亮的地方")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "tags flex align-items-c flex-wrap",
                              style:
                                "font-size: " +
                                _vm.facade.btn_font_size / 2 +
                                "px;",
                            },
                            [
                              !_vm.facade.hideBtn
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tag",
                                        style:
                                          "color: " +
                                          _vm.facade.main_btn_color +
                                          ";background: " +
                                          _vm.facade.main_btn_bg_color +
                                          ";border-radius: " +
                                          _vm.facade.btn_radius / 2 +
                                          "px",
                                      },
                                      [_vm._v("艺术大师")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tag",
                                        style:
                                          "color: " +
                                          _vm.facade.sub_btn_color +
                                          ";background: " +
                                          _vm.facade.sub_btn_bg_color +
                                          ";border-radius: " +
                                          _vm.facade.btn_radius / 2 +
                                          "px",
                                      },
                                      [_vm._v("潮流")]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
          ]
        : _vm.content.type === "notice"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";",
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满14周岁的中国大陆用户购买。数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。本商品一经售出，不支持退换。本商品源文件不支持本地下载。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用1。"
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type == "composemeta"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "compose-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style: "color:" + _vm.facade.nameColor,
                              },
                              [_vm._v("A 商品标题")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "r-change flex align-items-c",
                                style: "color:" + _vm.facade.changeColor,
                              },
                              [
                                _c("span", [_vm._v("选择")]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "change-img",
                                  attrs: { src: _vm.facade.addImage },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "need flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [_vm._v("中奖概率")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [_vm._v("24.22%")]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style: "color:" + _vm.facade.nameColor,
                              },
                              [_vm._v("B 商品标题")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "r-change flex align-items-c",
                                style: "color:" + _vm.facade.changeColor,
                              },
                              [
                                _c("span", [_vm._v("已选择2个")]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "change-img",
                                  attrs: { src: _vm.facade.addImage },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "need flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [_vm._v("A+B+C需要500")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [_vm._v("持有614")]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style: "color:" + _vm.facade.nameColor,
                              },
                              [_vm._v("C 商品标题")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "need flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [_vm._v("A+B+C需要500")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [_vm._v("持有614")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "go-buy" }, [
                              _c(
                                "div",
                                { style: "color: " + _vm.facade.btnPopColor },
                                [_vm._v("数量不足")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  style:
                                    "background: " +
                                    _vm.facade.btnBgColor +
                                    ";color: " +
                                    _vm.facade.btnColor,
                                },
                                [_vm._v("去购买")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-pop",
                      style: "color:" + _vm.facade.popTitleColor,
                    },
                    [_vm._v("已选2件/条件已满足可立即合成")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-pop-desc",
                      style: "color:" + _vm.facade.popColor,
                    },
                    [_vm._v("可随机合成列表中的一项")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "compose-resultimage" }, [
                    _c("img", {
                      attrs: { src: _vm.facade.resultImage, alt: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "compose-list",
                      style:
                        "border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;background: " +
                        _vm.facade.listBackground +
                        ";padding: " +
                        _vm.facade.listPadding / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compose-left",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://java-test-cdn.yunzongbu.cn/merchant/000000/20221122/image/1669047881340194450.jpg!120a",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-right",
                          style:
                            "margin-left: " +
                            _vm.facade.listPadding / 2 +
                            "px;",
                        },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "compose-title flex-one line1",
                                style:
                                  "color:" +
                                  _vm.facade.nameColor +
                                  ";padding-top: 5px;",
                              },
                              [_vm._v("商品标题")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "count flex" }, [
                            _c(
                              "div",
                              { style: "color: " + _vm.facade.txtColor + ";" },
                              [
                                _vm._v("剩余"),
                                _c(
                                  "span",
                                  {
                                    style:
                                      "color: " + _vm.facade.numColor + ";",
                                  },
                                  [_vm._v("15699")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { style: "color: " + _vm.facade.txtColor + ";" },
                              [
                                _vm._v("单次合成"),
                                _c(
                                  "span",
                                  {
                                    style:
                                      "color: " + _vm.facade.numColor + ";",
                                  },
                                  [_vm._v("2")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { style: "color: " + _vm.facade.txtColor + ";" },
                              [
                                _vm._v("可合成数量"),
                                _c(
                                  "span",
                                  {
                                    style:
                                      "color: " + _vm.facade.numColor + ";",
                                  },
                                  [_vm._v("2/2")]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "syntheticConditions"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title) + "\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "synthetic-conditions" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          style:
                            "border-radius: " +
                            _vm.facade.imgRadius / 2 +
                            "px;",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "name line1",
                          style:
                            "font-weight: " +
                            _vm.facade.nameFontWeight +
                            ";font-size: " +
                            _vm.facade.nameFontSize / 2 +
                            "px;color: " +
                            _vm.facade.nameColor +
                            ";margin-top: " +
                            _vm.facade.nameMarginTop / 2 +
                            "px;",
                        },
                        [_vm._v("A Digital Belivevers")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "need flex",
                          style:
                            "margin-top: " +
                            _vm.facade.needMarginTop / 2 +
                            "px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "need-view flex",
                              style:
                                "border-color: " +
                                _vm.facade.needBorderColor +
                                ";background: " +
                                _vm.facade.needBgColor +
                                ";color: " +
                                _vm.facade.needColor,
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "l",
                                  style:
                                    "border-color: " +
                                    _vm.facade.needBorderColor +
                                    ";",
                                },
                                [_vm._v("中奖概率")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "r",
                                  style:
                                    "border-color: " +
                                    _vm.facade.needBorderColor +
                                    ";",
                                },
                                [_vm._v("24.4%")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        style:
                          "margin-left: " +
                          _vm.pageInfo.item_padding / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style:
                              "border-radius: " +
                              _vm.facade.imgRadius / 2 +
                              "px;",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style:
                              "font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;color: " +
                              _vm.facade.nameColor +
                              ";margin-top: " +
                              _vm.facade.nameMarginTop / 2 +
                              "px;",
                          },
                          [_vm._v("B Digital Belivevers")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "need flex",
                            style:
                              "margin-top: " +
                              _vm.facade.needMarginTop / 2 +
                              "px;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [_vm._v("中奖概率")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "r" }, [
                                  _vm._v("24.4%"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        style:
                          "margin-left: " +
                          _vm.pageInfo.item_padding / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style:
                              "border-radius: " +
                              _vm.facade.imgRadius / 2 +
                              "px;",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style:
                              "font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;color: " +
                              _vm.facade.nameColor +
                              ";margin-top: " +
                              _vm.facade.nameMarginTop / 2 +
                              "px;",
                          },
                          [_vm._v("C Digital Belivevers")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "need flex",
                            style:
                              "margin-top: " +
                              _vm.facade.needMarginTop / 2 +
                              "px;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "need-view flex",
                                style:
                                  "border-color: " +
                                  _vm.facade.needBorderColor +
                                  ";background: " +
                                  _vm.facade.needBgColor +
                                  ";color: " +
                                  _vm.facade.needColor,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "l",
                                    style:
                                      "border-color: " +
                                      _vm.facade.needBorderColor +
                                      ";",
                                  },
                                  [_vm._v("中奖概率")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "r",
                                    style:
                                      "background: " +
                                      _vm.facade.needReachBgColor +
                                      ";",
                                  },
                                  [_vm._v("22.24%")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "openresault"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title) + "\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "openresault-list",
                    style: "margin-top:" + _vm.facade.listMarginTop / 2 + "px",
                  },
                  [
                    _c("div", { staticClass: "openresault-left" }, [
                      _c(
                        "div",
                        {
                          staticClass: "openresault-title",
                          style:
                            "color: " +
                            _vm.facade.listTitleColor +
                            ";font-size: " +
                            _vm.facade.listTitleFontSize / 2 +
                            "px;line-height: " +
                            _vm.facade.listTitleLineHeight / 2 +
                            "px;",
                        },
                        [_vm._v("数字信徒A")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "openresault-desc",
                          style:
                            "color: " +
                            _vm.facade.listTimeColor +
                            ";font-size: " +
                            _vm.facade.listTimeFontSize / 2 +
                            "px;line-height: " +
                            _vm.facade.listTimeLineHeight / 2 +
                            "px;",
                        },
                        [_vm._v("2022-10-12 13:09:12")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "openresult-right",
                        style:
                          "color: " +
                          _vm.facade.listRightColor +
                          ";font-size: " +
                          _vm.facade.listRightFontSize / 2 +
                          "px;line-height: " +
                          _vm.facade.listRightLineHeight / 2 +
                          "px;",
                      },
                      [_vm._v("BELIEVERS-A")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import OSS from 'ali-oss';
var _default = exports.default = {
  name: "UserList",
  data: function data() {
    return {
      userType: "authentication",
      realNameAuthentication: {
        show: false,
        idCard: "",
        idCardReverseUrl: "",
        idCardFacadeUrl: "",
        name: "",
        result: 1,
        reason: ""
      },
      authenticationData: {
        show: false,
        id: "",
        type: 1,
        idCard: "",
        idCardReverseUrl: "",
        idCardFacadeUrl: "",
        name: "",
        user: null
      },
      moren: require("@/assets/images/f.png"),
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      userFrom: {
        pageNum: 1,
        pageSize: 20,
        status: ""
      },
      // ossClient: null,

      frontImgLoading: false,
      backImgLoading: false,
      searchLoading: false,
      userList: []
    };
  },
  mounted: function mounted() {
    this.getList("");
  },
  methods: {
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "all") {
        this.$router.push("/merchant/user/list");
        return false;
      } else if (tab.name === "label") {
        this.$router.push("/merchant/user/label");
        return false;
      } else if (tab.name === "authentication") {
        this.$router.push("/merchant/user/verify");
        return false;
      } else if (tab.name === "canel") {
        this.$router.push("/merchant/user/cancellation");
        return false;
      } else if (tab.name === 'regiest_set') {
        this.$router.push('/merchant/user/regiest_set');
        return false;
      } else if (tab.name === 'new_user_set') {
        this.$router.push('/merchant/user/new_user_set');
        return false;
      }
    },
    saveVerifyAction: function saveVerifyAction() {
      var _this = this;
      if (this.realNameAuthentication.result === -1 && !this.realNameAuthentication.reason) {
        this.$message.error("请输入拒绝理由");
        return false;
      }
      this.$confirm("\u786E\u8BA4".concat(this.realNameAuthentication.result === 2 ? "同意" : "拒绝", "\u5BA1\u6838\uFF1F"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var data = {
          id: _this.realNameAuthentication.id,
          status: _this.realNameAuthentication.result
        };
        if (data.status === -1) {
          data.reason = _this.realNameAuthentication.reason;
        }
        (0, _user.userVerifyApi)(data).then(function () {
          _this.$message.success("操作成功");
          _this.closeAction();
          _this.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    closeAction: function closeAction() {
      this.realNameAuthentication = {
        show: false,
        id: "",
        idCard: "",
        idCardReverseUrl: "",
        idCardFacadeUrl: "",
        type: "",
        name: "",
        result: "",
        reason: ""
      };
    },
    showRealNameAuthentication: function showRealNameAuthentication(row) {
      this.realNameAuthentication = {
        id: row.id,
        show: true,
        idCard: row.idCard,
        idCardReverseUrl: row.idCardReverseUrl,
        idCardFacadeUrl: row.idCardFacadeUrl,
        type: row.type,
        name: row.name,
        result: 1,
        reason: ""
      };
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.userFrom.pageNum = num || this.userFrom.pageNum;
      (0, _user.userVerifyListApi)(this.userFrom).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.pageSize = val;
      this.getList("");
    },
    uploadImg: function uploadImg(file, key) {
      var _this3 = this;
      this.authenticationData[key] = "";
      if (key === "idcard_just_photo") {
        this.frontImgLoading = true;
      } else {
        this.backImgLoading = true;
      }
      this.multipartUpload(file.file, function (url) {
        checkUserIdCardImage({
          idCardImage: url,
          idCardSide: key === "idcard_just_photo" ? "front" : "back"
        }).then(function (res) {
          if (Number(_this3.authenticationData.idcard_type) === 1) {
            if (key === "idcard_just_photo") {
              _this3.authenticationData.real_name = res.data.name;
              _this3.authenticationData.idcard_no = res.data.idCard_num;
            }
          }
          if (key === "idcard_just_photo") {
            _this3.frontImgLoading = false;
          } else {
            _this3.backImgLoading = false;
          }
          _this3.authenticationData[key] = url;
        }).catch(function () {
          if (key === "idcard_just_photo") {
            _this3.frontImgLoading = false;
          } else {
            _this3.backImgLoading = false;
          }
        });
      });
    },
    // uploadImg(file, key) {
    //   this.authenticationData[key] = '';
    //   if (key === 'idCardFacadeUrl') {
    //     this.frontImgLoading = true;
    //   } else {
    //     this.backImgLoading = true;
    //   }
    //   this.multipartUpload(file.file, url => {
    //     checkUserIdCardImage({
    //       idCardImage: url,
    //       idCardSide: key === 'idCardFacadeUrl' ? 'front' : 'back'
    //     })
    //       .then(res => {
    //         if (
    //           Number(this.authenticationData.type) === 1
    //         ) {
    //           if (key === 'idCardFacadeUrl') {
    //             this.authenticationData.name = res.data.name;
    //             this.authenticationData.idCard = res.data.idCard_num;
    //           }
    //         }
    //         if (key === 'idCardFacadeUrl') {
    //           this.frontImgLoading = false;
    //         } else {
    //           this.backImgLoading = false;
    //         }
    //         this.authenticationData[key] = url;
    //       })
    //       .catch(() => {
    //         if (key === 'idCardFacadeUrl') {
    //           this.frontImgLoading = false;
    //         } else {
    //           this.backImgLoading = false;
    //         }
    //       });
    //   });
    // },
    // async multipartUpload(m, cb) {
    //   try {
    //     const mer_id = localStorage.getItem('mer_id');
    //     const myDate = new Date();
    //     let imgT = 'png';
    //     imgT = imgT.toLowerCase();
    //     const imgName =
    //       myDate.getTime().toString() +
    //       parseInt(Math.random() * 1000000, 10).toString();
    //     const imgUrl = `${imgName}.${imgT}`;
    //     const result = await this.ossClient.put(
    //       `merchant/${mer_id || '000000'}/id_image/${imgUrl}`,
    //       m
    //     );
    //     if (result && result.url) {
    //       if (cb) cb(`https://saas.cdn.yunzongbu.cn/${result.name}`);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     this.$Message.error('身份证上传失败，请刷新后重新扫描身份证');
    //   }
    // },
    searchUserAction: function searchUserAction(data) {
      var _this4 = this;
      var params = {
        source: 2,
        hasVerified: 0,
        userStatus: 2,
        nameOrPhone: data,
        pageNum: 1,
        pageSize: 20
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this4.userList = res.rows;
      });
    },
    saveAuthAction: function saveAuthAction() {
      var _this5 = this;
      var data = {
        memberId: this.authenticationData.memberId,
        idCard: this.authenticationData.idCard,
        name: this.authenticationData.name,
        type: this.authenticationData.type,
        idCardFacadeUrl: this.authenticationData.idCardFacadeUrl,
        idCardReverseUrl: this.authenticationData.idCardReverseUrl,
        id: this.authenticationData.id
      };
      if (!data.memberId) {
        this.$message.error("请先选择用户");
        return false;
      }
      // if (!data.idCardFacadeUrl) {
      //   this.$message.error('请上传身份证正面照片');
      //   return false;
      // }
      // if (!data.idCardReverseUrl) {
      //   this.$message.error('请上传身份证反面照片');
      //   return false;
      // }
      if (!data.name) {
        this.$message.error("请输入真实姓名");
        return false;
      }
      if (!data.idCard) {
        this.$message.error("请输入身份证号");
        return false;
      }
      this.$confirm("\u786E\u8BA4".concat(this.authenticationData.id ? "修改" : "创建", "\u5B9E\u540D\u8BA4\u8BC1\uFF1F"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var action = _user.createVerifyApi;
        if (_this5.authenticationData.id) {
          action = _user.createVerifyApi;
        }
        action(data).then(function () {
          _this5.$message.success("操作成功");
          _this5.authenticationData.show = false;
          _this5.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    addAuthAction: function addAuthAction() {
      this.authenticationData = {
        show: true,
        id: "",
        type: 1,
        idCard: "",
        idCardReverseUrl: "",
        idCardFacadeUrl: "",
        name: "",
        user: null
      };
    },
    editAuthAction: function editAuthAction(row) {
      console.log(row, 546);
      this.authenticationData = {
        show: true,
        id: row.id,
        type: row.type,
        idCard: row.idCard,
        idCardReverseUrl: row.idCardReverseUrl,
        idCardFacadeUrl: row.idCardFacadeUrl,
        name: row.name,
        memberId: row.memberId,
        nickName: row.nickName
      };
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _wantBuy = require("@/api/wantBuy");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      searchForm: {
        productName: '',
        status: '',
        startTime: '',
        endTime: '',
        nickName: ''
      },
      time: ['', ''],
      statusList: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '求购中'
      }, {
        value: '2',
        label: '已完成'
      }, {
        value: '-1',
        label: '已取消'
      }],
      dialogVisible: false,
      currentId: '',
      topList: [],
      topLoading: false,
      listLoading: false,
      list: [],
      listQuery: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      showdetail: false,
      uid: ''
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getList(1);
    },
    getList: function getList(reset) {
      var _this = this;
      if (reset) {
        this.listQuery.pageNum = 1;
      }
      this.listLoading = true;
      (0, _wantBuy.getWantBuyListApi)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), this.listQuery)).then(function (res) {
        _this.list = res.rows;
        _this.listQuery.total = res.total;
        _this.listLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this.listLoading = false;
      });
    },
    searchInput: (0, _index.debounce)(function () {
      this.getList();
    }, 200, false),
    changeTime: function changeTime(val) {
      if (val && val.length) {
        this.searchForm.startTime = val[0] || '';
        this.searchForm.endTime = val[1] || '';
      }
      this.getList(1);
    },
    getStatus: function getStatus(status) {
      var item = this.statusList.find(function (e) {
        return Number(e.value) === status;
      }) || {};
      return item.label || status;
    },
    openDialog: function openDialog(id) {
      this.currentId = id;
      this.getTopList();
      this.dialogVisible = true;
    },
    getTopList: function getTopList() {
      var _this2 = this;
      this.topLoading = true;
      (0, _wantBuy.getWantBuyRecordListApi)(this.currentId).then(function (res) {
        if (res.code === 200) {
          _this2.topList = res.data;
        } else {
          _this2.$message.error(res.msg);
        }
        _this2.topLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err.message);
        _this2.topLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getList(1);
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.memberId;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    goRouter: function goRouter(name) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.$router.push({
        name: name,
        query: query,
        params: params
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  data: function data() {
    return {
      // menuList: []
      auctionMenu: [],
      notify: ""
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["permission_routes", "sidebar", "menuList"])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    defaultOpen: function defaultOpen() {
      if (this.menuList) {
        var list = this.menuList.map(function (m) {
          return m.route;
        });
        return list;
      }
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      console.log(this.sidebar.opened, 68);
      if (this.sidebar.opened) {
        this.setOpen();
      } else {
        if (this.timeout) clearTimeout(this.timeout);
      }
      return !this.sidebar.opened;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.getMenus();
    this.getBalance();
    this.timer = setInterval(function () {
      _this.getBalance();
      // 1800000
    }, 1800000);
  },
  methods: {
    getBalance: function getBalance() {
      var _this2 = this;
      (0, _setting.getBalanceApi)().then(function (res) {
        if (_this2.notify) {
          _this2.notify.close();
        }
        var _res$data = res.data,
          balance = _res$data.balance,
          sms_surplus_count = _res$data.sms_surplus_count,
          verify_balance = _res$data.verify_balance;
        //您的$name的费用已预警不足，为了不影响正常使用，请及时充值！
        var pay = [];
        if (balance * 1 < 200) {
          pay.push("上链费");
        }
        if (sms_surplus_count * 1 < 50) {
          pay.push("短信费");
        }
        if (verify_balance * 1 < 50) {
          pay.push("实名费");
        }
        if (pay.length) {
          _this2.notify = _this2.$notify({
            title: "",
            message: "\u60A8\u7684".concat(pay, "\u7684\u8D39\u7528\u5DF2\u9884\u8B66\u4E0D\u8DB3\uFF0C\u4E3A\u4E86\u4E0D\u5F71\u54CD\u6B63\u5E38\u4F7F\u7528\uFF0C\u8BF7\u53CA\u65F6\u5145\u503C\uFF01"),
            position: "bottom-right",
            type: "warning",
            duration: 0
          });
        }
      });
    },
    getMenus: function getMenus() {
      if (["accountManage", "sweepGoods", "orderCollectionList"].includes(this.$route.name)) {
        this.$store.dispatch("user/getMenus", 1);
      } else if (["UserManagePlanB", "BatchScan", "PurchasedCollection"].includes(this.$route.name)) {
        this.$store.dispatch("user/getMenus", 2);
      } else {
        this.$store.dispatch("user/getMenus");
      }
      // console.log(this.$route.name);
      // this.$store.dispatch("user/getMenus");
    },
    closeMenuAction: function closeMenuAction(index) {
      this.$refs.menuEL.open(index);
    },
    setOpen: function setOpen() {
      var _this3 = this;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        console.log(_this3.menuList);
        var list = _this3.menuList.map(function (m) {
          return m.route;
        });
        list.forEach(function (m) {
          _this3.$refs.menuEL.open(m);
        });
      }, 100);
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.array.find");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _objectSpread3 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _timing = require("@/api/timing.js");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'OrderScan',
  data: function data() {
    return {
      marketList: [],
      marketPages: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      multipleSelection: [],
      listDialogLoading: false,
      dialogVisible: false,
      listLoading: false,
      scanList: [],
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      config: {
        marketDownPrice: '',
        hasAutoLockMarket: false
      },
      btnLoading: false,
      countVisible: false,
      marketDownVisible: false,
      consignVisible: false,
      formData: {
        marketDownPrice: '',
        everyTimeMarketCount: '',
        maxMarketCount: '',
        id: '',
        type: 1
      },
      downPrice: '',
      upPrice: '',
      types: [{
        title: '修改寄售规则',
        key: 'marketDownPrice'
      }, {
        title: '每次寄售数量',
        key: 'everyTimeMarketCount'
      }, {
        title: '最大寄售数量',
        key: 'maxMarketCount'
      }, {
        title: '',
        key: ''
      }],
      currentproduct: {
        id: '',
        name: ''
      },
      productDialog: false,
      productList: [],
      productPages: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      collectionName: ''
    };
  },
  computed: {
    getMarketDownPrice: function getMarketDownPrice() {
      var marketDownPrice = '-';
      if (this.config && this.config.id) {
        try {
          marketDownPrice = Number(this.config.marketDownPrice);
        } catch (_unused) {
          marketDownPrice = '-';
        }
      }
      return marketDownPrice;
    }
  },
  created: function created() {
    // this.getScanConfig();
    this.getList(1);
    this.getmarketList(1);
  },
  methods: {
    // 获取列表
    getList: function getList(reset) {
      var _this = this;
      this.listLoading = true;
      if (reset) {
        this.pages.pageNum = 1;
      }
      var params = (0, _objectSpread3.default)({}, this.pages);
      (0, _timing.getScanProductApi)(params).then(function (res) {
        _this.scanList = res.rows;
        _this.pages.total = res.total;
        _this.listLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this.listLoading = false;
      });
    },
    numInput: function numInput(name, piont, min, max) {
      this.formData[name] = (0, _index.numInput)(this.formData[name], piont, min, max);
    },
    openDialog: function openDialog() {
      this.collectionName = '';
      this.getmarketList();
      this.dialogVisible = true;
    },
    // type 0 修改寄售规则 1 每次寄售数量 2 最大寄售数量
    editDialog: function editDialog(dialogName, type, row) {
      Object.assign(this.formData, (0, _objectSpread3.default)({
        type: type
      }, row));
      this[dialogName] = true;
    },
    editOneDialog: function editOneDialog(row) {
      this.formData = (0, _objectSpread3.default)({
        type: 3
      }, row);
      if (row.marketPriceType == 3) {
        this.downPrice = row.marketDownPrice;
        this.upPrice = row.marketUpPrice;
        this.formData.marketDownPrice = '';
        this.formData.marketUpPrice = '';
      }
      // console.log(this.formData);
      // Object.assign(this.formData, { id,marketPriceType: 3 });
      this.consignVisible = true;
    },
    handleClose: function handleClose(formEl) {
      this.$refs[formEl].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // 修改配置
          var key = _this2.types[_this2.formData.type].key;
          if (_this2.formData.type === 0) {
            var params = (0, _objectSpread3.default)((0, _objectSpread3.default)({}, _this2.config), {}, (0, _defineProperty2.default)({}, key, _this2.formData[key]));
            _this2.setScanConfig(params);
          } else if (_this2.formData.type === 3) {
            var _params = _this2.scanList.find(function (e) {
              return e.id === _this2.formData.id;
            });
            if (_params) {
              _params[key] = _this2.formData[key];
              _this2.updateScanProduct(_params);
            }
          } else {
            // 修改寄售内容
            var _params2 = _this2.scanList.find(function (e) {
              return e.id === _this2.formData.id;
            });
            if (_params2) {
              _params2[key] = _this2.formData[key];
              // this.updateScanProduct(params);
              (0, _timing.updateScanProductApi)(_params2).then(function (res) {
                if (res.code === 200) {
                  _this2.countVisible = false;
                  _this2.consignVisible = false;
                  _this2.getList();
                }
              }).catch(function (err) {
                console.log(err);
              });
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 修改寄售数量
    updateScanProduct: function updateScanProduct(row) {
      var _this3 = this;
      var params;
      if (this.formData.marketPriceType) {
        if (this.formData.marketPriceType == 3) {
          params = {
            id: this.formData.id,
            marketDownPrice: Number(this.downPrice),
            marketUpPrice: Number(this.upPrice)
          };
        }
        if (this.formData.marketPriceType == 1) {
          params = {
            id: this.formData.id,
            marketDownPrice: Number(this.formData.marketDownPrice)
          };
        }
        if (this.formData.marketPriceType == 2) {
          params = {
            id: this.formData.id,
            marketUpPrice: Number(this.formData.marketUpPrice)
          };
        }
        params.marketPriceType = this.formData.marketPriceType;
      } else {
        params = (0, _objectSpread3.default)({}, row);
      }
      // console.log(params,this.formData);
      // return
      (0, _timing.updateScanProductApi)(params).then(function (res) {
        if (res.code === 200) {
          _this3.countVisible = false;
          _this3.consignVisible = false;
          _this3.getList();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.marketPages.pageSize = val;
      this.getmarketList(1);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    updateSelection: function updateSelection(row) {
      if (this.multipleSelection && Array.isArray(this.multipleSelection)) {
        var itemIndex = this.multipleSelection.findIndex(function (e) {
          return e.id === row.id;
        });
        if (itemIndex === -1) {
          this.$refs.multipleTable.toggleRowSelection(row);
        } else {
          // this.$refs.multipleTable.toggleRowSelection(row, true);
        }
      }
    },
    formatCheck: function formatCheck() {
      var parmas = [];
      if (this.multipleSelection && this.multipleSelection.length) {
        parmas = this.multipleSelection.map(function (e) {
          return {
            productId: e.productId,
            marketProductId: e.id,
            productName: e.name,
            image: e.image,
            everyTimeMarketCount: 0,
            maxMarketCount: 0
          };
        });
      }
      return parmas;
    },
    // 添加扫描寄售藏品
    createScan: function createScan() {
      var _this4 = this;
      var params = this.formatCheck();
      params.length && (0, _timing.createScanProductApi)(params).then(function (res) {
        if (res.code === 200) {
          _this4.getList();
          _this4.$message.success(res.msg);
          _this4.dialogVisible = false;
        } else {
          _this4.$message.error(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    //
    onHandStatus: function onHandStatus(row) {
      var _this5 = this;
      (0, _timing.updateScanProductApi)({
        id: row.id,
        hasAutoMarket: !row.hasAutoMarket
      }).then(function (res) {
        // console.log(res);
        if (res.code === 200) {
          _this5.getList();
        }
      });
    },
    // 下架扫描寄售商品列表
    cancelScan: function cancelScan(id) {
      var _this6 = this;
      this.$confirm("\u786E\u8BA4\u4E0B\u67B6\u8BE5\u5BC4\u552E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (response) {
        if (response === 'confirm') {
          (0, _timing.cancelScanProductApi)(id).then(function (res) {
            if (res.code === 200) {
              _this6.$message.success(res.msg);
              _this6.getList();
            } else {
              _this6.$message.error(res.msg);
            }
          }).catch(function (err) {
            console.log(err);
          });
        }
      }).catch(function () {});
    },
    // 删除扫描寄售藏品
    deleteScan: function deleteScan(id) {
      var _this7 = this;
      (0, _timing.deleteScanProductApi)(id).then(function (res) {
        if (res.code === 200) {
          _this7.getList();
          _this7.$message.success(res.msg);
        } else {
          _this7.$message.success(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 修改扫描寄售设置
    setScanConfig: function setScanConfig(config, type) {
      var _this8 = this;
      if (this.btnLoading) {
        return;
      }
      var params = (0, _objectSpread3.default)({}, config);
      if (type === 1) {
        params.hasAutoMarket = !params.hasAutoMarket;
      }
      this.btnLoading = true;
      (0, _timing.setScanConfigApi)(params).then(function (res) {
        if (res.code === 200) {
          _this8.getList();
          // this.getScanConfig();
          if (_this8.marketDownVisible) {
            _this8.marketDownVisible = false;
          }
          _this8.btnLoading = false;
          _this8.$message.success(res.msg);
        } else {
          _this8.btnLoading = false;
          _this8.$message.error(res.msg);
        }
      }).catch(function (err) {
        _this8.btnLoading = false;
        console.log(err);
      });
    },
    // 获取扫描寄售藏品
    getScanConfig: function getScanConfig() {
      var _this9 = this;
      (0, _timing.getScanConfigApi)().then(function (res) {
        if (res.code === 200) {
          var data = res.data || {};
          _this9.config = data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    collectionInput: (0, _index.debounce)(function () {
      this.getmarketList();
    }, 200, false),
    // 获取藏品列表
    getmarketList: function getmarketList(reset) {
      var _this10 = this;
      if (reset) {
        this.marketPages.pageNum = 1;
      }
      var params = (0, _objectSpread3.default)((0, _objectSpread3.default)({
        type: 1,
        status: true
      }, this.marketPages), {}, {
        name: this.collectionName,
        hasSpecial: true
      });
      (0, _marketing.marketProductListApi)(params).then(function (res) {
        _this10.marketList = res.rows;
        _this10.marketPages.total = res.total;
        console.log(res, 'eeeee');
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.productPages.pageSize = val;
      this.getProductList(1);
    },
    getProduct: function getProduct(productId, name, image, id) {
      this.currentproduct = {
        scanMarketId: id,
        id: productId,
        name: name,
        image: image
      };
      this.getProductList(1);
      this.productDialog = true;
    },
    getProductList: function getProductList(reset) {
      var _this11 = this;
      this.productPages.pageNum = reset ? reset : 1;
      var params = (0, _objectSpread3.default)((0, _objectSpread3.default)({
        scanMarketId: this.currentproduct.scanMarketId,
        productId: this.currentproduct.id
      }, this.productPages), {}, {
        hasSpecial: true
      });
      this.listDialogLoading = true;
      (0, _marketing.marketUserListApi)(params).then(function (res) {
        if (res.code === 200) {
          _this11.productList = res.rows;
          _this11.productPages.total = res.total;
          _this11.listDialogLoading = false;
        }
      }).catch(function (err) {
        _this11.listDialogLoading = false;
        console.log(err);
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.userType,
                callback: function ($$v) {
                  _vm.userType = $$v
                },
                expression: "userType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "用户列表", name: "all" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "实名认证", name: "authentication" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "用户标签", name: "label" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注销申请", name: "canel" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "注册配置", name: "regiest_set" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "设置", name: "new_user_set" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", "label-width": "80px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.userFrom.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFrom, "status", $$v)
                            },
                            expression: "userFrom.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("待审核"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("已注销"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "-1" } }, [
                            _vm._v("已驳回"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200 ml20",
                          attrs: {
                            placeholder: "客户名称/手机号",
                            size: "small",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.userFrom.nameOrPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFrom, "nameOrPhone", $$v)
                            },
                            expression: "userFrom.nameOrPhone",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image
                                  ? scope.row.image
                                  : _vm.moren,
                                "preview-src-list": [
                                  scope.row.image || _vm.moren,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", {
                            domProps: { textContent: _vm._s(row.nickName) },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "真实姓名", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.realName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", prop: "phone", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "等级", prop: "levelName", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "成长值", prop: "growth", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "数字藏品持仓",
                  prop: "nftCount",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "数字盲盒持仓",
                  prop: "boxCount",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "积分", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.score) + "分")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "未使用优惠券", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(row.noUseCouponCount) + "张"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "钱包余额", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.balance) + "元")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "未提现佣金", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [_vm._v(_vm._s(row.withDraw))]),
                        _vm._v("元\n        "),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 0
                          ? _c("div", [_vm._v("待审核")])
                          : row.status == 1
                          ? _c("div", [_vm._v("已注销")])
                          : row.status == -1
                          ? _c("div", [_vm._v("已驳回")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmCancel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("确认注销\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.beRejected(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            驳回申请\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.userFrom.pageSize,
                  "current-page": _vm.userFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.realNameAuthentication.show,
            width: "40%",
            title: "确认注销",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.realNameAuthentication, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "cdialog-con", staticStyle: { margin: "20px 0" } },
            [
              _c("p", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "注销后该用户将不再持有相关资产和数据，请确认已与用户达成一致！以免出现纠纷和法律责任"
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cdialog-con" }, [
            _c("p", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("用户数据"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("当前等级：" + _vm._s(_vm.userObj.levelName || "无")),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("成长值：" + _vm._s(_vm.userObj.growth || 0))]),
            _vm._v(" "),
            _c("p", [
              _vm._v("数字藏品持仓：" + _vm._s(_vm.userObj.nftCount || 0)),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("数字盲盒持仓：" + _vm._s(_vm.userObj.boxCount || 0)),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("积分：" + _vm._s(_vm.userObj.score || 0) + "分")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "未用优惠券：" +
                  _vm._s(_vm.userObj.noUseCouponCount || 0) +
                  "张"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "钱包余额：" + _vm._s(_vm.userObj.noUseCouponCount || 0) + "元"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("未提现佣金：" + _vm._s(_vm.userObj.withDraw || 0) + "元"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "20px 0" } },
            [
              _vm._v("\n      输入操作密码： "),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { type: "password" },
                model: {
                  value: _vm.pwd,
                  callback: function ($$v) {
                    _vm.pwd = $$v
                  },
                  expression: "pwd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
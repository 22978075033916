"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBoxGoods = addBoxGoods;
exports.addBoxGoodsNum = addBoxGoodsNum;
exports.batchDestroyApi = batchDestroyApi;
exports.batchRecoveryApi = batchRecoveryApi;
exports.blockchainEditGas = blockchainEditGas;
exports.blockchainList = blockchainList;
exports.clearDrawLotsApi = clearDrawLotsApi;
exports.createBox = createBox;
exports.dataListApi = dataListApi;
exports.delBox = delBox;
exports.delBoxGoods = delBoxGoods;
exports.destroyListApi = destroyListApi;
exports.displaceApi = displaceApi;
exports.downloadShareApi = downloadShareApi;
exports.drawLotsApi = drawLotsApi;
exports.drawLotsExporApi = drawLotsExporApi;
exports.editBox = editBox;
exports.editBoxGoods = editBoxGoods;
exports.editBoxGoodsStatus = editBoxGoodsStatus;
exports.editBoxIssueStatus = editBoxIssueStatus;
exports.editBoxStatus = editBoxStatus;
exports.editOrderNumApi = editOrderNumApi;
exports.getBoxDetail = getBoxDetail;
exports.getBoxGoodsList = getBoxGoodsList;
exports.getBoxList = getBoxList;
exports.getProductSplitListApi = getProductSplitListApi;
exports.importAssetsApi = importAssetsApi;
exports.memberBoxListApi = memberBoxListApi;
exports.nftAddIssueNum = nftAddIssueNum;
exports.nftCollectionAlbumCreateApi = nftCollectionAlbumCreateApi;
exports.nftCollectionAlbumDelApi = nftCollectionAlbumDelApi;
exports.nftCollectionAlbumDetailApi = nftCollectionAlbumDetailApi;
exports.nftCollectionAlbumFilterApi = nftCollectionAlbumFilterApi;
exports.nftCollectionAlbumListApi = nftCollectionAlbumListApi;
exports.nftCollectionAlbumUpdataApi = nftCollectionAlbumUpdataApi;
exports.nftCollectionAlbumUpdataCheckApi = nftCollectionAlbumUpdataCheckApi;
exports.nftCollectionAlbumUpdateApi = nftCollectionAlbumUpdateApi;
exports.nftCollectionBatchIssueApi = nftCollectionBatchIssueApi;
exports.nftCollectionCreateApi = nftCollectionCreateApi;
exports.nftCollectionDelApi = nftCollectionDelApi;
exports.nftCollectionDetailApi = nftCollectionDetailApi;
exports.nftCollectionInfoApi = nftCollectionInfoApi;
exports.nftCollectionIssueApi = nftCollectionIssueApi;
exports.nftCollectionListApi = nftCollectionListApi;
exports.nftCollectionListCheckApi = nftCollectionListCheckApi;
exports.nftCollectionUpdateApi = nftCollectionUpdateApi;
exports.nftCollectionUpdateSortApi = nftCollectionUpdateSortApi;
exports.nftRuleConfigInfoApi = nftRuleConfigInfoApi;
exports.nftRuleConfigUpdateApi = nftRuleConfigUpdateApi;
exports.participateExportApi = participateExportApi;
exports.participateListApi = participateListApi;
exports.productBoxAddStockApi = productBoxAddStockApi;
exports.productBoxDeleteApi = productBoxDeleteApi;
exports.productBoxFreedStockApi = productBoxFreedStockApi;
exports.productBoxInfoApi = productBoxInfoApi;
exports.productBoxIssueApi = productBoxIssueApi;
exports.productBoxListApi = productBoxListApi;
exports.productBoxSaveApi = productBoxSaveApi;
exports.productBoxShowUpdateApi = productBoxShowUpdateApi;
exports.productBoxUpdateApi = productBoxUpdateApi;
exports.productDestroyApi = productDestroyApi;
exports.productDestroyListApi = productDestroyListApi;
exports.productDestroyListExportApi = productDestroyListExportApi;
exports.productGiventoApi = productGiventoApi;
exports.productGiventoExportApi = productGiventoExportApi;
exports.productMemberBoxListApi = productMemberBoxListApi;
exports.productMemberBoxReclaimApi = productMemberBoxReclaimApi;
exports.productMembernftApi = productMembernftApi;
exports.productMembernftExportApi = productMembernftExportApi;
exports.productNftRecoverApi = productNftRecoverApi;
exports.productNftRecoverExportApi = productNftRecoverExportApi;
exports.productReclaimRecordListApi = productReclaimRecordListApi;
exports.productRecordListApi = productRecordListApi;
exports.productRecordListExportApi = productRecordListExportApi;
exports.productRecoverApi = productRecoverApi;
exports.recoverToDestroy = recoverToDestroy;
exports.recoveryListApi = recoveryListApi;
exports.recoveryNftTransfersApi = recoveryNftTransfersApi;
exports.splitUpdateApi = splitUpdateApi;
exports.transferBlindNftApi = transferBlindNftApi;
exports.transferCreateApi = transferCreateApi;
exports.transferDelApi = transferDelApi;
exports.transferListApi = transferListApi;
exports.transferListExportApi = transferListExportApi;
exports.transferUpdateApi = transferUpdateApi;
exports.transfersListApi = transfersListApi;
exports.updateVirtualLotteryCountApi = updateVirtualLotteryCountApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 列表
 */
function nftCollectionListApi(data) {
  return _request.default.get("product/sys/product/nft/list", data);
}
/**
 * @description 列表
 */
function splitUpdateApi(data) {
  return _request.default.post("/product/sys/product/nft/splitUpdate", data);
}
/**
 * @description 中签列表
 */
function drawLotsApi(data) {
  return _request.default.get("/activity/sys/draw/lots/list", data);
}
/**
 * @description 用户签数据详情
 */
function participateListApi(data) {
  return _request.default.get("/activity/sys/draw/lots/participateList", data);
}
/**
 * @description 导出中签列表
 */
function drawLotsExporApi(data) {
  return _request.default.get("/activity/sys/draw/lots/export", data);
}
/**
 * @description 导出参与列表
 */
function participateExportApi(data) {
  return _request.default.get("/activity/sys/draw/lots/participateExport", data);
}
/**
 * @description 修改中签数
 */
function updateVirtualLotteryCountApi(data) {
  return _request.default.put("/product/sys/product/nft/updateVirtualLotteryCount", data);
}
/**
 * @description 批量操作检查
 */
function nftCollectionListCheckApi(data) {
  return _request.default.post("product/sys/product/nft", data);
}
/**
 * @description 修改数字藏品
 */
function nftCollectionDetailApi(data) {
  return _request.default.put("product/sys/product/nft/update", data);
}
/**
 * @description 修改数字藏品排序
 */
function nftCollectionUpdateSortApi(data) {
  return _request.default.put("product/sys/product/nft/updateSort", data);
}
/**
 * @description 系列列表
 */
function nftCollectionAlbumListApi(data) {
  return _request.default.get("product/sys/series/list", data);
}

/**
 * @description 批量新增商品
 */
function nftCollectionAlbumUpdataApi(data, opts) {
  return _request.default.post("product/sys/product/nft/batchSaveV2", data, opts);
}

/**
 * @description 批量新增商品检查
 */
function nftCollectionAlbumUpdataCheckApi(data) {
  return _request.default.post("product/sys/product/nft/batchSaveCheckoutV2", data);
}

/**
 * @description 系列类型nft数量
 */
function nftCollectionAlbumFilterApi(data) {
  return _request.default.get("store/nftCollection/album/statusFilter", data);
}
/**
 * @description 系列详情
 */
function nftCollectionAlbumDetailApi(data) {
  return _request.default.get("product/sys/series/info", data);
}

/**
 * @description 创建系列
 */
function nftCollectionAlbumCreateApi(data) {
  return _request.default.post("product/sys/series/save", data);
}

/**
 * @description 修改系列
 */
function nftCollectionAlbumUpdateApi(data) {
  return _request.default.put("product/sys/series/update", data);
}

/**
 * @description 删除系列
 */
function nftCollectionAlbumDelApi(id) {
  return _request.default.delete("product/sys/series/delete/".concat(id));
}

/**
 * @description nft上链数据列表
 */
function dataListApi(data) {
  return _request.default.get("nft/dataList", data);
}

/**
 * @description 获取持仓明细列表
 */
function memberBoxListApi(data) {
  return _request.default.get("product/sys/member/box/list", data);
}

/**
 * @description 赠送列表
 */
function transfersListApi(data) {
  return _request.default.get("nft/transfersList", data);
}

/**
 * @description 详情
 */
function nftCollectionInfoApi(id) {
  return _request.default.get("product/sys/product/nft/info", id);
}

/**
 * @description 修改排序
 */
function editOrderNumApi(id, data) {
  return _request.default.post("store/nftCollection/editOrderNum/".concat(id), data);
}

/**
 * @description 创建
 */
function nftCollectionCreateApi(data) {
  return _request.default.post("product/sys/product/nft/save", data);
}

/**
 * @description 修改
 */
function nftCollectionUpdateApi(data) {
  return _request.default.put("product/sys/product/nft/update", data);
}

/**
 * @description 发行
 */
function nftCollectionIssueApi(id) {
  return _request.default.post("product/sys/product/nft/issued/".concat(id));
}
/**
 * @description 删除
 */
function nftCollectionDelApi(id) {
  return _request.default.delete("product/sys/product/nft/delete/".concat(id));
}
/**
 * @description 批量发行
 */
function nftCollectionBatchIssueApi(data) {
  return _request.default.post("product/sys/product/nft/batchIssued", data);
}
// 获取盲盒详情
function getBoxDetail(id) {
  return _request.default.get("activity/nft/blindBox/detail/".concat(id));
}
// 获取盲盒列表
function getBoxList(data) {
  return _request.default.get("activity/nft/blindBox/lst", data);
}
// 创建盲盒
function createBox(data) {
  return _request.default.post("activity/nft/blindBox/create", data);
}
// 编辑盲盒
function editBox(id, data) {
  return _request.default.post("activity/nft/blindBox/update/".concat(id), data);
}
// 删除盲盒
function delBox(id) {
  return _request.default.delete("activity/nft/blindBox/delete/".concat(id));
}
// 修改盲盒状态
function editBoxStatus(id, data) {
  return _request.default.post("activity/nft/blindBox/switchStatus/".concat(id), data);
}
// 修改盲盒发行状态
function editBoxIssueStatus(id, data) {
  return _request.default.post("activity/nft/blindBox/switchIssueStatus/".concat(id), data);
}
// 盲盒藏品列表
function getBoxGoodsList(data) {
  return _request.default.get("activity/nft/blindBox/product/lst", data);
}
// 数字盲盒添加藏品
function addBoxGoods(data) {
  return _request.default.post("activity/nft/blindBox/product/create", data);
}
// 数字盲盒修改藏品
function editBoxGoods(data) {
  return _request.default.post("activity/nft/blindBox/product/update", data);
}
// 批量修改原商品的状态
function editBoxGoodsStatus(data) {
  return _request.default.post("activity/nft/blindBox/product/batchSwitchMerStatus", data);
}
// 删除盲盒藏品
function delBoxGoods(data) {
  return _request.default.post("activity/nft/blindBox/product/delete", data);
}
// 数字藏品增发
function nftAddIssueNum(data) {
  return _request.default.put("product/sys/product/nft/additionalIssuance", data);
}

// nft回收记录列表
function recoveryListApi(data) {
  return _request.default.get("nft/recoveryList", data);
}

// nft销毁记录列表
function destroyListApi(data) {
  return _request.default.get("nft/destroyList", data);
}

// 批量回收nft
function batchRecoveryApi(data) {
  return _request.default.post("nft/batchRecovery", data);
}

// 批量销毁nft
function batchDestroyApi(data) {
  return _request.default.post("nft/batchDestroy", data);
}
// 数字盲盒藏品增加数量
function addBoxGoodsNum(data) {
  return _request.default.post("activity/nft/blindBox/product/addIssueNum", data);
}
// 数字藏品重新投放
function recoveryNftTransfersApi(data) {
  return _request.default.post("/product/sys/nftrecover/rollOut", data);
}
// 转增盲盒
function transferBlindNftApi(data) {
  return _request.default.get("activity/nft/blindBox/transfersList", data);
}

/**
 * nft规则设置
 */
function nftRuleConfigInfoApi() {
  return _request.default.get("product/sys/config/info");
}

/**
 * nft规则设置
 */
function nftRuleConfigUpdateApi(data) {
  return _request.default.post("product/sys/config/update", data);
}

// 查询转赠规则
function transferListApi(data) {
  return _request.default.get("product/sys/transfers/config/list", data);
}
// 导出转赠规则
function transferListExportApi(data) {
  return _request.default.get("product/sys/transfers/config/list", data);
}
// 添加转赠规则
function transferCreateApi(data) {
  return _request.default.post("product/sys/transfers/config/save", data);
}
// 修改转赠规则
function transferUpdateApi(data) {
  return _request.default.put("product/sys/transfers/config/update", data);
}
// 删除转赠规则
function transferDelApi(id) {
  return _request.default.delete("product/sys/transfers/config/delete/".concat(id));
}
// 获取作品分账列表
function getProductSplitListApi(data) {
  return _request.default.get("product/sys/product/nft/getProductSplitList", data);
}

/**
 * @description 盲盒列表
 */
function productBoxListApi(data) {
  return _request.default.get("product/sys/product/box/list", data);
}

/**
 * @description 释放盲盒
 */
function productBoxFreedStockApi(id) {
  return _request.default.post("product/sys/product/box/freedStock/".concat(id));
}

/**
 * @description 发行盲盒
 */
function productBoxIssueApi(id) {
  return _request.default.post("product/sys/product/box/issued/".concat(id));
}

/**
 * @description 删除商品-盲盒表
 */
function productBoxDeleteApi(id) {
  return _request.default.post("product/sys/product/box/delete/".concat(id));
}

/**
 * @description 新增商品-盲盒表
 */
function productBoxSaveApi(data) {
  return _request.default.post("product/sys/product/box/save", data);
}

/**
 * @description 上下架商品-盲盒
 */
function productBoxShowUpdateApi(data) {
  return _request.default.post("product/sys/product/box/showUpdate", data);
}

/**
 * @description 修改商品-盲盒表
 */
function productBoxUpdateApi(data) {
  return _request.default.post("product/sys/product/box/update", data);
}

/**
 * @description 查询商品-盲盒表信息
 */
function productBoxInfoApi(data) {
  return _request.default.get("product/sys/product/box/info", data);
}

/**
 * @description 获取持仓明细列表
 */
function productMemberBoxListApi(data) {
  return _request.default.get("product/sys/member/box/list", data);
}

/**
 * @description 持仓明细列表 - 回收盲盒
 */
function productMemberBoxReclaimApi(data) {
  return _request.default.post("product/sys/member/box/reclaim", data);
}

/**
 * @description 获取回收记录列表
 */
function productReclaimRecordListApi(data) {
  return _request.default.get("product/sys/reclaim/record/list", data);
}

/**
 * @description 获取转赠记录列表
 */
function productRecordListApi(data) {
  return _request.default.get("product/sys/transfer/record/list", data);
}

/**
 * @description 盲盒-导出转赠记录列表
 */
function productRecordListExportApi(data) {
  return _request.default.get("product/sys/transfer/record/export", data);
}

/**
 * @description 查询上链列表
 */
function productMembernftApi(data) {
  return _request.default.get("product/sys/membernft/list", data);
}

/**
 * @description 导出上链列表
 */
function productMembernftExportApi(data) {
  return _request.default.get("product/sys/membernft/export", data);
}

/**
 * @description 查询回收列表
 */
function productNftRecoverApi(data) {
  return _request.default.get("product/sys/nftrecover/list", data);
}

/**
 * @description 导出回收列表
 */
function productNftRecoverExportApi(data) {
  return _request.default.get("product/sys/nftrecover/recoverExport", data);
}

/**
 * @description 查询销毁列表
 */
function productDestroyListApi(data) {
  return _request.default.get("product/sys/productnft/destroy/list", data);
}

/**
 * @description 导出销毁列表
 */
function productDestroyListExportApi(data) {
  return _request.default.get("product/sys/productnft/destroy/destroyExport", data);
}

/**
 * @description 查询转赠列表
 */
function productGiventoApi(data) {
  return _request.default.get("product/sys/productnft/givento/list", data);
}

/**
 * @description 导出转赠列表
 */
function productGiventoExportApi(data) {
  return _request.default.get("product/sys/productnft/givento/givenToExport", data);
}

/**
 * @description 回收
 */
function productRecoverApi(data) {
  return _request.default.put("product/sys/membernft/recover", data);
}

/**
 * @description 销毁
 */
function productDestroyApi(data) {
  return _request.default.put("product/sys/membernft/destroy", data);
}

/**
 * @description 盲盒创建 - 追加投放
 */
function productBoxAddStockApi(data) {
  return _request.default.post("product/sys/product/box/addStock", data);
}

/**
 * 回收销毁 /product/sys/nftrecover/recoverToDestroy
 */
function recoverToDestroy(data) {
  return _request.default.post("product/sys/nftrecover/recoverToDestroy", data);
}

/**
 * 区块链列表
 */
function blockchainList(data) {
  return _request.default.get("product/sys/blockchain/list", data);
}
/**
 * 修改gas费用
 */
function blockchainEditGas(data) {
  return _request.default.post("product/sys/blockchain/update", data);
}

// 批量置换
function displaceApi(data) {
  return _request.default.post("product/sys/membernft/displace", data);
}

// 批量压缩
function downloadShareApi(data) {
  return _request.default.post("product/sys/membernft/downloadShare", data);
}

// 删除导入的抽签数据
function clearDrawLotsApi(id) {
  return _request.default.delete("product/sys/product/nft/clearDrawLots/".concat(id));
}
// 导入版权链资产id数组
function importAssetsApi(data, opts) {
  return _request.default.post("/product/sys/product/nft/importAssets", data, opts);
}
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.payTosplitExportApi = payTosplitExportApi;
exports.payTosplitListApi = payTosplitListApi;
exports.payTosplitToSplitApi = payTosplitToSplitApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 分账列表
 */
function payTosplitListApi(data) {
  return _request.default.get("/pay/sys/pay/tosplit/list", data);
}
/**
 * @description 导出
 */
function payTosplitExportApi(data) {
  return _request.default.post("/pay/sys/pay/tosplit/export", data);
}
/**
 * @description 创建分账
 */
function payTosplitToSplitApi(data) {
  return _request.default.post("/pay/sys/pay/tosplit/toSplit", data);
}
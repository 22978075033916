"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var escapeRouter = {
  path: "".concat(_settings.roterPre, "/escape"),
  name: "escape",
  meta: {
    icon: "",
    title: "大逃杀"
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: "manageMent",
    name: "EscapeManageMent",
    meta: {
      title: "大逃杀管理"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/escape/index"));
      });
    }
  }]
};
var _default = exports.default = escapeRouter;
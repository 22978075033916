"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _timing = require("@/api/timing.js");
var _marketing = require("@/api/marketing");
var _product = require("@/api/product");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'OrderScan',
  data: function data() {
    return {
      marketList: [],
      marketPages: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      multipleSelection: [],
      listDialogLoading: false,
      dialogVisible: false,
      listLoading: false,
      scanList: [],
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      config: {
        marketDownPrice: '',
        hasAutoLockMarket: false
      },
      btnLoading: false,
      snapshotOptions: [],
      searchPages: {
        pageNum: 1,
        pageSize: 500
      },
      lockordermember: [],
      formData: {
        maxPrice: ''
      },
      countVisible: false,
      searchName: '',
      collectionName: ''
    };
  },
  computed: {
    getMarketDownPrice: function getMarketDownPrice() {
      var marketDownPrice = '-';
      if (this.config && this.config.id) {
        try {
          marketDownPrice = Number(this.config.marketDownPrice);
        } catch (_unused) {
          marketDownPrice = '-';
        }
      }
      return marketDownPrice;
    }
  },
  created: function created() {
    this.getScanConfig();
    this.getList(1);
    this.getmarketList(1);
    this.getSnapshot();
  },
  methods: {
    // 获取列表
    getList: function getList(reset) {
      var _this = this;
      this.listLoading = true;
      if (reset) {
        this.pages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pages), {}, {
        name: this.searchName
      });
      (0, _timing.getLockOrderv2ListApi)(params).then(function (res) {
        _this.scanList = res.rows;
        _this.pages.total = res.total;
        _this.listLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this.listLoading = false;
      });
    },
    searchInput: (0, _index.debounce)(function () {
      this.getList();
    }, 200, false),
    getSnapshot: function getSnapshot(reset) {
      var _this2 = this;
      if (reset) {
        this.searchPages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)({}, this.searchPages);
      (0, _product.snapshotListApi)(params).then(function (res) {
        _this2.snapshotOptions = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    },
    openDialog: function openDialog() {
      this.collectionName = '';
      this.getmarketList();
      this.dialogVisible = true;
    },
    numInput: function numInput(name, piont, min, max) {
      this.formData[name] = (0, _index.numInput)(this.formData[name], piont, min, max);
    },
    editDialog: function editDialog(dialogName, row) {
      Object.assign(this.formData, (0, _objectSpread2.default)({}, row));
      this[dialogName] = true;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var params = _this3.formData;
          delete params.params;
          _this3.updateScanProduct(_this3.formData);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 修改寄售数量
    updateScanProduct: function updateScanProduct(row) {
      var _this4 = this;
      var params = (0, _objectSpread2.default)({}, row);
      (0, _timing.createLockOrderv2Api)([params]).then(function (res) {
        if (res.code === 200) {
          _this4.getList();
          _this4.countVisible = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.marketPages.pageSize = val;
      this.getmarketList(1);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    updateSelection: function updateSelection(row) {
      if (this.multipleSelection && Array.isArray(this.multipleSelection)) {
        var itemIndex = this.multipleSelection.findIndex(function (e) {
          return e.id === row.id;
        });
        if (itemIndex === -1) {
          this.$refs.multipleTable.toggleRowSelection(row);
        } else {
          // this.$refs.multipleTable.toggleRowSelection(row, true);
        }
      }
    },
    formatCheck: function formatCheck() {
      var parmas = [];
      if (this.multipleSelection && this.multipleSelection.length) {
        parmas = this.multipleSelection.map(function (e) {
          return {
            productId: e.productId,
            marketProductId: e.id,
            productName: e.name,
            image: e.image,
            everyTimeMarketCount: 0,
            maxMarketCount: 0,
            maxPrice: 0
          };
        });
      }
      return parmas;
    },
    // 添加扫描寄售藏品
    createScan: function createScan() {
      var _this5 = this;
      var params = this.formatCheck();
      params.length && (0, _timing.createLockOrderv2Api)(params).then(function (res) {
        if (res.code === 200) {
          _this5.getList();
          _this5.$message.success(res.msg);
          _this5.dialogVisible = false;
        } else {
          _this5.$message.error(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 删除扫描寄售藏品
    deleteScan: function deleteScan(id) {
      var _this6 = this;
      (0, _timing.deleteLockOrderv2Api)(id).then(function (res) {
        if (res.code === 200) {
          _this6.getList();
          _this6.$message.success(res.msg);
        } else {
          _this6.$message.success(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 修改扫描寄售设置
    setScanConfig: function setScanConfig(config, type) {
      var _this7 = this;
      if (this.btnLoading) {
        return;
      }
      var params = (0, _objectSpread2.default)({}, config);
      if (type === 1) {
        params.hasAutoLockMarket = !params.hasAutoLockMarket;
      }
      this.btnLoading = true;
      (0, _timing.setScanConfigApi)(params).then(function (res) {
        if (res.code === 200) {
          _this7.getScanConfig();
          if (_this7.marketDownVisible) {
            _this7.marketDownVisible = false;
          }
          _this7.btnLoading = false;
          _this7.$message.success(res.msg);
        } else {
          _this7.btnLoading = false;
          _this7.$message.error(res.msg);
        }
      }).catch(function (err) {
        _this7.btnLoading = false;
        console.log(err);
      });
    },
    changeSelect: function changeSelect(val) {
      var lockMarkets = val.join(',');
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.config), {}, {
        lockMarkets: lockMarkets
      });
      this.setScanConfig(params);
    },
    // 扫描参数变化
    // 获取扫描寄售藏品
    getScanConfig: function getScanConfig() {
      var _this8 = this;
      (0, _timing.getScanConfigApi)().then(function (res) {
        if (res.code === 200) {
          var data = res.data || {};
          _this8.config = data;
          _this8.lockordermember = _this8.getLockordermember();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getLockordermember: function getLockordermember() {
      var lock = [];
      if (this.config && this.config.lockMarkets) {
        try {
          lock = this.config.lockMarkets.split(',');
        } catch (_unused2) {
          lock = [];
        }
      }
      return lock;
    },
    collectionInput: (0, _index.debounce)(function () {
      this.getmarketList();
    }, 200, false),
    // 获取藏品列表
    getmarketList: function getmarketList(reset) {
      var _this9 = this;
      if (reset) {
        this.marketPages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
        type: 1,
        status: true
      }, this.marketPages), {}, {
        name: this.collectionName
      });
      (0, _marketing.marketProductListApi)(params).then(function (res) {
        _this9.marketList = res.rows;
        _this9.marketPages.total = res.total;
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.list.data },
        },
        [
          _c("el-table-column", { attrs: { label: "排序", prop: "sort" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "卡槽名称", prop: "slotName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "解锁条件" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.productName) + "*" + _vm._s(row.needNum)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.editSlot(row.id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-link",
        {
          attrs: { underline: false, type: "primary" },
          on: { click: _vm.addSlot },
        },
        [_vm._v("+添加卡槽")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              "page-size": _vm.pageConfig.pageSize,
              "current-page": _vm.pageConfig.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.list.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加卡槽", visible: _vm.addSlotDialog },
          on: {
            "update:visible": function ($event) {
              _vm.addSlotDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("ul", { staticClass: "slot-wrapper" }, [
            _c(
              "li",
              [
                _c("span", [_vm._v("*卡槽名称")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w200",
                  attrs: { type: "text" },
                  model: {
                    value: _vm.form.slotName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "slotName", $$v)
                    },
                    expression: "form.slotName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("span", [_vm._v("*排序")]),
                _vm._v(" "),
                _c("el-input-number", {
                  attrs: { min: 1, controls: false },
                  model: {
                    value: _vm.form.sort,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sort", $$v)
                    },
                    expression: "form.sort",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("*解锁条件")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("span", [_vm._v("需消耗")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w100 ml10",
                    attrs: { controls: false, min: 0 },
                    model: {
                      value: _vm.form.needNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "needNum", $$v)
                      },
                      expression: "form.needNum",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "ml10 mr10",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeProduct },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "产出项", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "数字藏品", value: 2 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.form.productName
                    ? _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "primary" },
                          on: { click: _vm.selectProduct },
                        },
                        [_vm._v("\n            选择\n          ")]
                      )
                    : _c(
                        "div",
                        { staticClass: "flex align-items-c ml10" },
                        [
                          _c("el-image", {
                            staticClass: "w50 h50 mr5",
                            attrs: { src: _vm.form.productImage, alt: "" },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.form.productName))]),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "primary" },
                              on: { click: _vm.selectProduct },
                            },
                            [_vm._v("更改")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-c align-items-c " },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                title: "选择产出项",
                visible: _vm.produceDialog,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.produceDialog = $event
                },
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.produceData } },
                [
                  _c("el-table-column", { attrs: { label: "Id", prop: "id" } }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-image", {
                              attrs: { src: scope.row.image, alt: "" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "名称", prop: "name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "用户持有" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              { attrs: { underline: false, type: "primary" } },
                              [_vm._v(_vm._s(scope.row.allNum))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "未领取上限", prop: "maxAccount" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectProduce(scope.row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: {
          "current-data": [],
          size: "1",
          "show-select": true,
          needPointsDeductionType: false,
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
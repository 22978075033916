var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "top-view" }, [
          _c(
            "div",
            { staticStyle: { "margin-top": "-10px" } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs",
                  attrs: { "default-active": _vm.type, mode: "horizontal" },
                  on: { select: _vm.changeAllTypeAction },
                },
                [
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _vm._v("短信群发"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "3" } }, [
                    _vm._v("短信签名"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "2" } }, [
                    _vm._v("短信内容模板"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "4" } }, [
                    _vm._v("站内群发"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addAction },
                },
                [_vm._v("创建群发")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "order-table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "推送标题", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.titleName))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发送内容", "min-width": "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.messageContent))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发送人数", prop: "count" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "创建时间", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发送时间", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.sendTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "状态", align: "center", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.status == 0
                            ? _c("div", { staticStyle: { color: "#f4a800" } }, [
                                _vm._v("待发送"),
                              ])
                            : row.status == 1
                            ? _c("div", { staticStyle: { color: "#ff0000" } }, [
                                _vm._v("\n              发送中\n            "),
                              ])
                            : row.status == 2
                            ? _c("div", { staticStyle: { color: "#5db741" } }, [
                                _vm._v("\n              已发送\n            "),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "22",
                  attrs: { label: "操作", align: "center", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.status < 2
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editAction(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.delAction(row.id)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt20 flex justify-e" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-size": _vm.queryParams.pageSize,
                    "current-page": _vm.queryParams.pageNum,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: { "current-change": _vm.getList },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.newDeliverDetail.id ? "修改内容" : "创建新内容",
            visible: _vm.newDeliverDetail.show,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.newDeliverDetail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.newDeliverDetail,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推送标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容标题" },
                    model: {
                      value: _vm.newDeliverDetail.titleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "titleName", $$v)
                      },
                      expression: "newDeliverDetail.titleName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送对象" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newDeliverDetail.objectType,
                        callback: function ($$v) {
                          _vm.$set(_vm.newDeliverDetail, "objectType", $$v)
                        },
                        expression: "newDeliverDetail.objectType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(" 全部用户 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v(" 快照名单 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.newDeliverDetail.objectType == 2
                ? _c("el-form-item", { attrs: { label: "快照名单" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              placeholder: "请选择快照名单",
                              clearable: "",
                              multiple: "",
                            },
                            on: { change: _vm.snapshotIdsChange },
                            model: {
                              value: _vm.newDeliverDetail.snapshotIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newDeliverDetail,
                                  "snapshotIds",
                                  $$v
                                )
                              },
                              expression: "newDeliverDetail.snapshotIds",
                            },
                          },
                          _vm._l(_vm.snapshotList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { value: item.id, label: item.name },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml20" }, [
                          _vm._v(
                            "总人数：共" +
                              _vm._s(_vm.checkSnapshot.totalNum || 0) +
                              "条记录/共" +
                              _vm._s(_vm.checkSnapshot.removalNum || 0) +
                              "人"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送时间" } },
                [
                  _c("el-date-picker", {
                    staticClass: "ml10",
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择发送时间",
                    },
                    model: {
                      value: _vm.newDeliverDetail.sendTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "sendTime", $$v)
                      },
                      expression: "newDeliverDetail.sendTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newDeliverDetail.messageType,
                        callback: function ($$v) {
                          _vm.$set(_vm.newDeliverDetail, "messageType", $$v)
                        },
                        expression: "newDeliverDetail.messageType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(" 输入内容 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(" 关联文章 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.newDeliverDetail.messageType == 0
                ? _c("el-form-item", { attrs: { label: "图文封面：" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("1")
                          },
                        },
                      },
                      [
                        _vm.newDeliverDetail.pictureUrl
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", {
                                attrs: { src: _vm.newDeliverDetail.pictureUrl },
                              }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.newDeliverDetail.messageType == 1
                ? _c("el-form-item", { attrs: { label: "文章内容" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择文章",
                              filterable: "",
                              remote: "",
                              "remote-method": _vm.searchAction,
                              loading: _vm.searchLoading,
                            },
                            model: {
                              value: _vm.newDeliverDetail.articleId,
                              callback: function ($$v) {
                                _vm.$set(_vm.newDeliverDetail, "articleId", $$v)
                              },
                              expression: "newDeliverDetail.articleId",
                            },
                          },
                          _vm._l(_vm.cmslist, function (m, i) {
                            return _c("el-option", {
                              key: "user" + i,
                              attrs: {
                                value: m.noticeId,
                                label: m.noticeTitle,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.newDeliverDetail.messageType == 0
                ? _c("el-form-item", { attrs: { label: "推送内容" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "txt-textarea",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            resize: "none",
                            placeholder: "输入推送内容",
                            rows: "4",
                          },
                          model: {
                            value: _vm.newDeliverDetail.messageContent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.newDeliverDetail,
                                "messageContent",
                                $$v
                              )
                            },
                            expression: "newDeliverDetail.messageContent",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.newDeliverDetail.messageType == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接地址", prop: "url" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "链接地址" },
                        model: {
                          value: _vm.newDeliverDetail.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.newDeliverDetail, "url", $$v)
                          },
                          expression: "newDeliverDetail.url",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "推送范围" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.newDeliverDetail.sendRange,
                        callback: function ($$v) {
                          _vm.$set(_vm.newDeliverDetail, "sendRange", $$v)
                        },
                        expression: "newDeliverDetail.sendRange",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "0" } }, [
                        _vm._v("Android客户端"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("iOS客户端"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.newDeliverDetail.show = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
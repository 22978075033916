var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scan-box" },
    [
      _c(
        "div",
        { attrs: { clas: "table-search" } },
        [
          _c(
            "el-row",
            { staticClass: "mb15", attrs: { align: "middle", type: "flex" } },
            [
              _c(
                "el-col",
                { staticClass: "flex align-items-c", attrs: { span: 8 } },
                [
                  _c("span", [_vm._v("锁单用户：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        size: "small",
                        placeholder: "选择白名单",
                        clearable: "",
                      },
                      on: { change: _vm.changeSelect },
                      model: {
                        value: _vm.lockordermember,
                        callback: function ($$v) {
                          _vm.lockordermember = $$v
                        },
                        expression: "lockordermember",
                      },
                    },
                    _vm._l(_vm.snapshotOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("span", [_vm._v("自动锁单:")]),
                  _vm._v(" "),
                  _vm.config && _vm.config.id
                    ? [
                        _vm.config.hasAutoLockMarket
                          ? _c("span", [
                              _c("i", { staticClass: "el-icon-loading" }),
                              _vm._v("扫货中"),
                            ])
                          : _c("span", [_vm._v("已暂停")]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    on: { input: _vm.searchInput },
                    model: {
                      value: _vm.searchName,
                      callback: function ($$v) {
                        _vm.searchName = $$v
                      },
                      expression: "searchName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "text-right", attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog()
                        },
                      },
                    },
                    [_vm._v("添加藏品")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.scanList, stripe: "", size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "图片", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticClass: "w50 h50",
                      attrs: { src: scope.row.image },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "数藏名称",
              prop: "productName",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "扫货价格", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.editDialog("countVisible", scope.row)
                          },
                        },
                      },
                      [_vm._v("<" + _vm._s(scope.row.maxPrice))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "80", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteScan(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "status-btn" },
        [
          _vm.config.id
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setScanConfig(_vm.config, 1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.config.hasAutoLockMarket ? "暂停" : "开始"))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "添加藏品",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticClass: "search-input",
            attrs: { size: "small", placeholder: "输入藏品名称搜索" },
            on: { input: _vm.collectionInput },
            model: {
              value: _vm.collectionName,
              callback: function ($$v) {
                _vm.collectionName = $$v
              },
              expression: "collectionName",
            },
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.marketList,
                loading: _vm.listDialogLoading,
                size: "small",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "image", label: "头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "w30 h30",
                          attrs: { src: scope.row.image },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "60", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.updateSelection(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-box" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.marketPages.pageSize,
                  "current-page": _vm.marketPages.pageNum,
                  total: _vm.marketPages.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.marketPages, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.marketPages, "pageNum", $event)
                  },
                  "current-change": _vm.getmarketList,
                  "size-change": _vm.handleUserSizeChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.createScan()
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.countVisible,
            title: "修改扫货价格",
            width: "400px",
            align: "center",
          },
          on: {
            "update:visible": function ($event) {
              _vm.countVisible = $event
            },
            close: function ($event) {
              return _vm.handleClose("formData")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "form-custom",
              attrs: { model: _vm.formData },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "扫货价格",
                    prop: "maxPrice",
                    rules: [
                      {
                        required: true,
                        message: "扫货价格不能为空",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    on: {
                      input: function ($event) {
                        return _vm.numInput("maxPrice", 0, 0, 99999999999999)
                      },
                    },
                    model: {
                      value: _vm.formData.maxPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "maxPrice", $$v)
                      },
                      expression: "formData.maxPrice",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      return (_vm.countVisible = false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponUser',
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      issueData: {
        data: [],
        total: 0
      },
      showDetailData: {
        show: false,
        name: '',
        image: ''
      }
    };
  },
  mounted: function mounted() {
    this.getIssueList();
  },
  methods: {
    delImg: function delImg(key) {
      this.showDetailData[key] = '';
    },
    modalPicTap: function modalPicTap(key) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.showDetailData[key] = img[0];
      }, 1);
    },
    createdTypeAction: function createdTypeAction() {
      this.showDetailData = {
        show: true,
        name: '',
        image: ''
      };
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this2 = this;
      this.Loading = true;
      (0, _marketing.integralTypeListApi)().then(function (res) {
        _this2.issueData.data = res.data;
        _this2.Loading = false;
      }).catch(function (res) {
        _this2.Loading = false;
        // this.$message.error(res.message)
      });
    },
    editAction: function editAction(m) {
      this.showDetailData = {
        show: true,
        id: m.id,
        name: m.name,
        image: m.image,
        isTransfer: m.isTransfer,
        transferScale: m.transferScale
      };
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (this.lock) return false;
      this.lock = true;
      (0, _marketing.integralSaveApi)(this.showDetailData).then(function () {
        _this3.lock = false;
        _this3.$message.success('操作成功');
        _this3.showDetailData.show = false;
        _this3.getIssueList();
        _this3.$emit('setTypeInfo');
      }).catch(function (_ref) {
        var message = _ref.message;
        _this3.lock = false;
      });
    },
    delAction: function delAction(m) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u7C7B\u578B?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.integralDelApi)(m.id).then(function () {
          _this4.$message.success('删除成功');
          _this4.getIssueList();
          _this4.$emit('setTypeInfo');
        }).catch(function () {});
      }).catch(function () {});
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      !_vm.listData.length || _vm.listData.length < 4
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addProduce } },
            [_vm._v("添加产出项")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.listData } },
        [
          _c("el-table-column", { attrs: { label: "Id", prop: "id" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      attrs: { src: scope.row.image, alt: "" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户持有" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      { attrs: { underline: false, type: "primary" } },
                      [_vm._v(_vm._s(scope.row.allNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "未领取上线", prop: "maxAccount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.editProduce(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加产出项",
            visible: _vm.addProduceDialog,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addProduceDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "内丹图片", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1")
                        },
                      },
                    },
                    [
                      _vm.ruleForm.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", { attrs: { src: _vm.ruleForm.image } }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称：", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "未领取上限：", prop: "maxAccount" } },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, min: 1, precision: 0 },
                    model: {
                      value: _vm.ruleForm.maxAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "maxAccount", $$v)
                      },
                      expression: "ruleForm.maxAccount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "交易手续费：", prop: "commission" } },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, min: 1, precision: 0 },
                    model: {
                      value: _vm.ruleForm.commission,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "commission", $$v)
                      },
                      expression: "ruleForm.commission",
                    },
                  }),
                  _vm._v("%\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "交易限价：" } },
                [
                  _vm._v("\n        最低"),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: { min: 0, controls: false, precision: 0 },
                    on: {
                      blur: function (e, name) {
                        _vm.blurNumber(e, "minPrice")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.minPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "minPrice", $$v)
                      },
                      expression: "ruleForm.minPrice",
                    },
                  }),
                  _vm._v("元 最高"),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: { controls: false, min: 0, precision: 0 },
                    on: {
                      blur: function (e, name) {
                        _vm.blurNumber(e, "maxPrice")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.maxPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "maxPrice", $$v)
                      },
                      expression: "ruleForm.maxPrice",
                    },
                  }),
                  _vm._v("元\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最低挂售数量", prop: "minNumber" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.minNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "minNumber", $$v)
                      },
                      expression: "ruleForm.minNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "序号：", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "序号小的在前" },
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sort", $$v)
                      },
                      expression: "ruleForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
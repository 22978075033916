"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _product = require("@/api/product");
var _marketing = require("@/api/marketing");
var _cms = require("@/api/cms");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      newDeliverDetail: {
        titleName: "",
        objectType: 0,
        snapshotIds: [],
        sendTime: "",
        messageType: 0,
        pictureUrl: "",
        articleId: "",
        messageContent: "",
        url: "",
        sendRange: [],
        show: false
      },
      rules: {
        name: [{
          required: true,
          message: "请输入内容标题",
          trigger: "blur"
        }],
        context: [{
          required: true,
          message: "请输入推送内容",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "请输入申请说明",
          trigger: "blur"
        }],
        signId: [{
          required: true,
          message: "请选择签名",
          trigger: "change"
        }]
      },
      type: "4",
      signList: [],
      snapshotList: [],
      checkSnapshot: {
        totalNum: "",
        removalNum: ""
      },
      cmslist: [],
      searchLoading: false
    };
  },
  computed: {},
  created: function created() {
    var _this2 = this;
    (0, _marketing.marketGetSnapshotApi)().then(function (res) {
      _this2.snapshotList = res.data;
    });
    this.getList();
  },
  methods: {
    searchAction: function searchAction(data) {
      var _this3 = this;
      var params = {
        noticeTitle: data,
        pageNum: 1,
        pageSize: 20
      };
      (0, _cms.articleLstApi)(params).then(function (res) {
        _this3.cmslist = res.rows;
      });
    },
    changeAllTypeAction: function changeAllTypeAction(tab) {
      if (tab === "1") {
        this.$router.push("/merchant/marketing/sms/task");
        return false;
      } else if (tab === "2") {
        this.$router.push("/merchant/marketing/sms/template");
        return false;
      } else if (tab === "3") {
        this.$router.push("/merchant/marketing/sms/sign");
        return false;
      }
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _marketing.noticeList)(this.queryParams).then(function (res) {
        _this4.tableData.data = res.rows;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    addAction: function addAction() {
      this.newDeliverDetail = {
        titleName: "",
        objectType: 0,
        snapshotIds: [],
        sendTime: "",
        messageType: 0,
        pictureUrl: "",
        articleId: "",
        messageContent: "",
        url: "",
        sendRange: [],
        show: true
      };
    },
    editAction: function editAction(item) {
      var _this5 = this;
      //   this.newDeliverDetail = {
      //     show: true,
      //     id: item.id,
      //     title: item.title,
      //     context: item.context,
      //     remark: item.remark,
      //     signId: item.signId,
      //   };
      (0, _marketing.getNoticeDetails)(item.id).then(function (res) {
        var data = res.data;
        data.show = true;
        _this5.newDeliverDetail = data;
      });
    },
    submitForm: function submitForm(formName, isSave) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.$confirm("\u786E\u8BA4".concat(isSave ? "保存" : "保存", "?"), "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            var data = JSON.parse(JSON.stringify(_this6.newDeliverDetail));
            var subData = {
              titleName: data.titleName,
              objectType: data.objectType,
              snapshotIds: data.snapshotIds,
              sendTime: data.sendTime,
              messageType: data.messageType,
              pictureUrl: data.pictureUrl,
              articleId: data.articleId,
              messageContent: data.messageContent,
              url: data.url,
              sendRange: data.sendRange
            };
            var action = _marketing.createNotice;
            if (data.id) {
              subData.id = data.id;
              action = _marketing.editNotice;
            }
            action(subData).then(function () {
              _this6.$message.success("操作成功");
              _this6.newDeliverDetail.show = false;
              _this6.getList();
            });
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    delAction: function delAction(id) {
      var _this7 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u6D88\u606F?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _marketing.deleteNotice)(id).then(function () {
          _this7.$message.success("删除成功");
          _this7.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    signChange: function signChange(val) {
      var item = this.signList.find(function (item) {
        return item.id == val;
      });
      this.newDeliverDetail.name = item.name;
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.newDeliverDetail.pictureUrl = img[0];
      }, tit);
    },
    snapshotIdsChange: function snapshotIdsChange(val) {
      var _this8 = this;
      if (val.length) {
        (0, _product.snapshotCheckSnapshotApi)({
          snapshotIds: val.join(",")
        }).then(function (res) {
          _this8.checkSnapshot = res.data;
        });
      }
    }
  }
};
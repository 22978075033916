"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _nftblind = _interopRequireDefault(require("@/components/customChildren/nftblind.vue"));
var _searchAlbum = _interopRequireDefault(require("@/components/searchDataView/searchAlbum.vue"));
var _marketing = require("@/api/marketing");
var _popoverTable = _interopRequireDefault(require("./popoverTable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CreatCoupon',
  components: {
    Nft: _nft.default,
    Nftblind: _nftblind.default,
    PopoverTable: _popoverTable.default,
    SearchAlbum: _searchAlbum.default
  },
  data: function data() {
    var _this = this;
    var failureTypeValidator = function failureTypeValidator(rule, value, callback, txt) {
      if (_this.ruleForm.failureType === 1 && (!_this.ruleForm.times[0] || !_this.ruleForm.times[1])) {
        callback('请选择有效期');
      } else if (_this.ruleForm.failureType === 2 && (!_this.ruleForm.effectiveDay || isNaN(_this.ruleForm.effectiveDay))) {
        callback('请输入领取天数');
      } else {
        callback();
      }
    };
    // 判断规则
    var changeInput = function changeInput(value) {
      if (value <= 0) {
        _this.ruleForm.effectiveDay = '';
      }
      _this.ruleForm.effectiveDay = value.replace(/[^\d+]/, '');
    };
    var typeRuleValidator = function typeRuleValidator(rule, value, callback, txt) {
      if (_this.ruleForm.type === 1 && (_this.ruleForm.quota === '' || Number(_this.ruleForm.quota) === 0 || isNaN(_this.ruleForm.quota))) {
        callback('请正确输入需要满足的金额');
      } else if (_this.ruleForm.base === '' || Number(_this.ruleForm.base) === 0 || isNaN(_this.ruleForm.base)) {
        callback("\u8BF7\u6B63\u786E\u8F93\u5165".concat(_this.ruleForm.type === 3 ? '折扣百分比' : '优惠金额'));
      } else if (_this.ruleForm.type === 3 && (_this.ruleForm.base < 0 || _this.ruleForm.base > 100)) {
        callback("\u6298\u6263\u767E\u5206\u6BD4\u533A\u95F4\u4E3A0-100");
      } else if (_this.ruleForm.type === 1 && Number(_this.ruleForm.base) >= Number(_this.ruleForm.quota)) {
        callback('优惠金额必须小于需要满足的条件');
      } else {
        callback();
      }
    };
    return {
      id: '',
      changeInput: changeInput,
      ruleForm: {
        name: '',
        count: '',
        type: 1,
        quota: '',
        base: '',
        failureType: 1,
        times: [],
        effectiveDay: 0,
        getLimit: 0,
        mutex: [],
        crowdLimit: 1,
        remark: '',
        snapshotIds: [],
        hasAutoSend: false
      },
      use_range: {
        // 2 数字藏品  3数字盲盒
        '2': {
          type: 1,
          keyName: '数字藏品',
          data: []
        },
        '3': {
          type: 1,
          keyName: '数字盲盒',
          data: []
        },
        '1': {
          type: 1,
          keyName: '专辑',
          data: []
        }
      },
      rules: {
        name: {
          required: true,
          message: '请输入优惠券名称',
          trigger: 'blur'
        },
        count: [{
          required: true,
          message: '请输入发放数量',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '预留数量必须为数字',
          trigger: 'blur'
        }],
        type: {
          required: true,
          message: '请选择优惠券类型',
          trigger: 'change'
        },
        base: [{
          required: true,
          message: '请输入优惠方式',
          trigger: 'blur'
        }, {
          required: true,
          validator: typeRuleValidator,
          trigger: 'blur'
        }],
        failureType: [{
          required: true,
          trigger: 'change'
        }, {
          validator: failureTypeValidator,
          trigger: 'change'
        }]
      },
      loading: false,
      changeIndex: 0,
      status: 0,
      nameListsList: []
    };
  },
  created: function created() {
    var _this2 = this;
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.load();
    }
    (0, _marketing.marketGetSnapshotApi)().then(function (res) {
      _this2.nameListsList = res.data;
    });
  },
  methods: {
    load: function load() {
      var _this3 = this;
      (0, _marketing.couponDetailApi)({
        id: this.id
      }).then(function (res) {
        var data = res.data;
        _this3.status = data.status;
        _this3.ruleForm = {
          name: data.name,
          count: data.count,
          type: data.type,
          quota: data.rule.quota || '',
          base: data.rule.base,
          failureType: data.failureType,
          times: data.startTime && data.endTime ? [data.startTime, data.endTime] : [],
          mutex: [],
          effectiveDay: data.effectiveDay,
          getLimit: data.getLimit,
          crowdLimit: Number(data.crowdLimit),
          remark: data.remark
        };
        if (data.isNoUseMemberCard) {
          _this3.ruleForm.mutex.push(1);
        }
        if (data.isNoUseintegral) {
          _this3.ruleForm.mutex.push(2);
        }
        for (var i in _this3.use_range) {
          var m = _this3.use_range[i];
          if (data.couponRanges[i] && data.couponRanges[i][0] && data.couponRanges[i][0].productId !== 0) {
            m.data = data.couponRanges[i];
            m.type = 2;
          } else if (data.couponRanges[i] && data.couponRanges[i][0].productId === 0) {
            m.type = 1;
          } else {
            m.type = 0;
          }
        }
      });
    },
    changeTypeAction: function changeTypeAction(val, i) {
      if (val === 2) {
        this.showChangeAction(i);
      }
    },
    showChangeAction: function showChangeAction(i) {
      this.changeIndex = i;
      var view = '';
      switch (Number(i)) {
        case 1:
          view = 'SearchAlbum';
          break;
        case 2:
          view = 'nft';
          break;
        case 3:
          view = 'nft_box';
          break;
      }
      this.$refs[view].changeshow();
    },
    selectMessage: function selectMessage(data) {
      if (this.use_range[this.changeIndex]) {
        this.use_range[this.changeIndex].data = data.data;
      }
    },
    editAction: function editAction(key) {
      this.$refs.ruleForm.validateField([key], function (Error) {
        if (!Error) {
          // 验证通过
          console.log('通过了');
        } else {
          console.log('验证不通过');
        }
      });
    },
    saveAction: function saveAction(formName) {
      var _this4 = this;
      console.log(this.ruleForm, 412);
      // if (this.status != 0 && this.ruleForm.getLimit === 0) {
      //   // this.ruleForm.count = ''
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     messgae: '请填写数量'
      //   })
      //   return;
      // }
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));
          if (!data.getLimit) {
            data.getLimit = 0;
          }
          if (_this4.ruleForm.failureType === 1) {
            data.startTime = data.times[0];
            data.endTime = data.times[1];
          }
          data.rule = {
            quota: _this4.ruleForm.quota,
            base: _this4.ruleForm.base
          };
          // data.mutex = this.ruleForm.mutex.join(',');
          if (_this4.ruleForm.mutex.indexOf(1) !== -1) {
            data.isNoUseMemberCard = true;
          }
          if (_this4.ruleForm.mutex.indexOf(2) !== -1) {
            data.isNoUseintegral = true;
          }
          var useR = {};
          var couponRanges = [];
          var _loop = function _loop(key) {
            var item = _this4.use_range[key];
            if (item.type === 1) {
              // 选择全部
              // useR[key] = [0];
              couponRanges.push({
                productId: 0,
                type: key
              });
            } else if (item.type === 0) {
              // useR[key] = [];
            } else if (item.data && item.data.length > 0) {
              item.data.forEach(function (m) {
                couponRanges.push({
                  productId: m.p_id,
                  type: key
                });
              });
              // if (Number(key) === 3) {
              //   useR[key] = item.data.map(m => m.p_id);
              // } else {
              //   useR[key] = item.data.map(m => m.id);
              // }
            }
          };
          for (var key in _this4.use_range) {
            _loop(key);
          }
          // data.use_range = useR;
          data.couponRanges = couponRanges;
          console.log(data);
          var loading = _this4.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _marketing.couponCreatedApi;
          if (_this4.id) {
            data.id = _this4.id;
            action = _marketing.couponUpdateApi;
          }
          action(data).then(function () {
            loading.close();
            _this4.$message.success('操作成功');
            _this4.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          _this4.$message.error('请填写完整信息');
          return false;
        }
      });
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      setData: {},
      visible: false,
      list: [],
      listLoading: true,
      ruleList: [],
      typeList: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _marketing.integralTypeListApi)().then(function (res) {
      _this.typeList = res.data;
    }).catch(function () {});
    this.load();
  },
  methods: {
    inputLimit: function inputLimit(val, num) {
      console.log(val, num);
      this.setData.upLimit = Number(num.placeholderUpLimit);
      if (val > this.setData.upLimit) {
        return num.upLimit;
      }
    },
    load: function load() {
      var _this2 = this;
      (0, _marketing.integralConfGetInfoV2Api)(this.queryParams).then(function (res) {
        if (res.data) {
          res.data.scoreRuleSets.forEach(function (m, i) {
            m.setType = i + 1;
          });
          res.data.scoreAwardRuleSets.forEach(function (m, i) {
            m.setType = i + 1 + res.data.scoreRuleSets.length;
          });
          _this2.list = res.data.scoreRuleSets;
          if (_this2.list.length) {
            _this2.list[0].type = 'switch';
          }
          _this2.ruleList = res.data.scoreAwardRuleSets;
        }
        _this2.listLoading = false;
      }).catch(function (message) {
        _this2.listLoading = false;
      });
    },
    clearData: function clearData() {
      this.setData = {};
    },
    settingAction: function settingAction(row) {
      console.log(row);
      this.clearData();
      this.visible = true;
      this.setData = {
        title: row.name,
        id: row.id,
        desc: row.ruleDescription,
        tiplaceholderValuele: row.value,
        placeholderUpLimit: row.value2,
        isOpen: row.isOpen,
        setType: row.setType,
        consignmentRule: String(row.consignmentRule),
        value: row.value,
        value2: row.value2,
        value3: row.value3,
        scoreTypeIds: row.scoreTypeIds
      };
    },
    saveAction: function saveAction() {
      var _this3 = this;
      // console.log(this.setData.upLimit, this.setData.value, 207);
      // if (this.setData.upLimit) {
      //   if (Number(this.setData.value) > Number(this.setData.upLimit)) {
      //     this.$message({
      //       type: 'warning',
      //       message: '修改值不能大于上限'
      //     });
      //     return;
      //   }
      // }
      if (this.setData.title.indexOf('积分抵现比例-') !== -1 && !Number(this.setData.value)) {
        this.$message.error('请输入正确的积分数，积分不能为0');
        return false;
      }
      if (this.setData.title.indexOf('积分获得比例-') !== -1 && !Number(this.setData.value)) {
        this.$message.error('请输入正确的金额，金额不能为0');
        return false;
      }
      (0, _marketing.integralConfUpdateV2Api)(this.setData).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.visible = false;
        _this3.load();
      }).catch(function () {});
    },
    editSwitchAction: function editSwitchAction(val, scope) {
      var _this4 = this;
      (0, _marketing.integralConfUpdateV2Api)({
        value: val,
        valueType: 2,
        id: scope.row.id
      }).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.load();
      }).catch(function () {});
    },
    flitterData: function flitterData(arr) {
      var spanOneArr = [];
      var concatOne = 0;
      arr.forEach(function (item, index) {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          if (item.name === arr[index - 1].name) {
            // 第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr
      };
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex === 0) {
        // 判断哪一列
        var _row = this.flitterData(this.list).one[rowIndex];
        var _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    }
  }
};
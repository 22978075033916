"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      uid: '',
      showUser: false,
      keyword: '',
      userFrom: {
        source: 2,
        hasVerified: 2,
        userStatus: 0,
        nameOrPhone: '',
        pageNum: 1,
        pageSize: 10
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  methods: {
    init: function init(id) {
      this.uid = id;
      this.keyword = '';
      this.userFrom = {
        source: 2,
        hasVerified: 2,
        nameOrPhone: '',
        userStatus: 0,
        pageNum: 1,
        pageSize: 10
      };
      this.tableData = {
        data: [],
        total: 0
      };
      this.showUser = true;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.userFrom.pageNum = num || this.userFrom.pageNum;
      (0, _user.userLstApi)(this.userFrom).then(function (res) {
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.pageNum = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.pageSize = val;
      this.getList('');
    },
    handleClick: function handleClick() {
      this.userFrom.pageNum = 1;
      this.userFrom.nameOrPhone = this.keyword;
      this.getList();
    },
    changeUserAction: function changeUserAction(row) {
      this.$emit('changeUid', {
        userId: row.userId,
        avatar: row.avatar,
        userPhone: row.userPhone,
        nickName: row.nickName,
        realName: row.realName
      });
      this.showUser = false;
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
var _escape = require("@/api/escape");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      infoData: {}
    };
  },
  watch: {
    'options': {
      handler: function handler(val) {
        console.log(val);
        if (val.id) {
          var arr = val.chipNum ? val.chipNum.split(',') : [];
          val.tableData = [{
            chipName: val.chipName,
            chipImage: val.chipImage,
            chipNum: arr.map(function (item) {
              return {
                label: item
              };
            })
          }];
          this.infoData = val;
        } else {
          this.infoData = {
            roomList: [{
              roomName: ''
            }, {
              roomName: ''
            }, {
              roomName: ''
            }, {
              roomName: ''
            }, {
              roomName: ''
            }, {
              roomName: ''
            }, {
              roomName: ''
            }, {
              roomName: ''
            }],
            tableData: [{
              chipName: '',
              chipImage: '',
              chipNum: [{
                label: ''
              }]
            }],
            unInjury: [{
              min: '',
              max: '',
              scale: ''
            }, {
              min: '',
              max: '',
              scale: ''
            }, {
              min: '',
              max: '',
              scale: ''
            }]
          };
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {},
  methods: {
    onAddNum: function onAddNum(row) {
      row.chipNum.push({
        label: ''
      });
    },
    onDeleNum: function onDeleNum(row, index) {
      row.chipNum.splice(index, 1);
    },
    onSubmit: function onSubmit() {
      var _this = this;
      // console.log(this.infoData.id);
      // return
      if (this.infoData.id) {
        var arr = this.infoData.tableData[0].chipNum.map(function (item) {
          return item.label;
        });
        var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.infoData), {}, {
          chipNum: arr.join(',')
        });
        (0, _escape.battleEdit)(model).then(function (res) {
          console.log(res);
          if (res.code == 200) {
            _this.$message.success("保存成功");
          }
        });
      } else {
        var _arr = this.infoData.tableData[0].chipNum.map(function (item) {
          return item.label;
        });
        var _model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.infoData), {}, {
          chipNum: _arr.join(',')
        });
        (0, _escape.getBattleCreate)(_model).then(function (res) {
          console.log(res);
          _this.$message.success("保存成功");
          _this.$emit('succ');
        });
      }
    }
  }
};
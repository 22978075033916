"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "marketUser",
  props: {
    visibleHolder: {
      type: Boolean,
      default: false
    },
    productId: {
      type: String,
      default: null
    },
    types: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      listLoading: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: null
      },
      visibleHolderData: {
        data: [],
        total: 0
      },
      multipleSelection: [],
      transferForm: {
        userId: "",
        pwd: ""
      },
      transferRules: {
        pwd: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }],
        userId: [{
          required: true,
          message: "请选择转移人",
          trigger: "change"
        }]
      },
      userList: {},
      userLoading: false,
      transferDialog: false,
      labelList: [],
      memberGroupIds: []
    };
  },
  created: function created() {
    this.getList(1);
    this.getLabelList();
  },
  methods: {
    getLabelList: function getLabelList() {
      var _this = this;
      (0, _user.labelLstApi)({
        pageNum: 1,
        pageSize: 100
      }).then(function (res) {
        _this.labelList = res.rows;
      });
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      this.queryParams.type = this.types;
      this.queryParams.productId = this.productId;
      this.queryParams.memberGroupIds = this.memberGroupIds.toString(",");
      (0, _marketing.marketHoldersListApi)(this.queryParams).then(function (res) {
        _this2.visibleHolderData.data = res.rows;
        _this2.visibleHolderData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    sizeChange: function sizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList("");
    },
    visibleChange: function visibleChange(val) {
      if (!val) {
        this.getList(1);
      }
    },
    goSearch: function goSearch() {
      this.getList(1);
    },
    holderClose: function holderClose() {
      this.$emit("holderClose");
    },
    flitterData: function flitterData(arr) {
      var spanOneArr = [];
      var concatOne = 0;
      arr.forEach(function (item, index) {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          if (item.userId === arr[index - 1].userId) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr
      };
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (column.type === "selection") {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
      if (columnIndex === 1 || columnIndex === 1) {
        // 判断哪一列
        var _row = this.flitterData(this.visibleHolderData.data).one[rowIndex];
        var _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val.map(function (item) {
        return item.memberNftId;
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this3 = this;
      console.log(data);
      this.userLoading = true;
      var params = {
        phone: data
      };
      (0, _user.getAllList)(params).then(function (res) {
        _this3.userLoading = false;
        _this3.userList = res.data ? res.data : {};
      });
    },
    batchTransfer: function batchTransfer(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.$confirm("确认转移吗?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            var data = {
              userId: _this4.transferForm.userId,
              memberNftIds: _this4.multipleSelection,
              productId: _this4.productId,
              pwd: _this4.transferForm.pwd
            };
            (0, _marketing.pointToPointApi)(data).then(function (_) {
              _this4.closeTransferDialog();
              _this4.$message.success("转移成功");
            });
          }).catch(function () {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeTransferDialog: function closeTransferDialog() {
      this.transferForm = {
        userId: "",
        pwd: ""
      };
      this.getList(1);
      this.transferDialog = false;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scan-box" },
    [
      _c(
        "div",
        { attrs: { clas: "table-search" } },
        [
          _c(
            "el-row",
            { staticClass: "mb15", attrs: { align: "middle", type: "flex" } },
            [
              _c(
                "el-col",
                { staticClass: "text-right", attrs: { offset: 21, span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog()
                        },
                      },
                    },
                    [_vm._v("添加藏品")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.scanList, stripe: "", size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "图片", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticClass: "w50 h50",
                      attrs: { src: scope.row.productImage },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "数藏名称",
              prop: "productName",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "当前最低寄售价格",
              "min-width": "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("p", [_vm._v(_vm._s(scope.row.minMarketPrice))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "每次寄售数量",
              "min-width": "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "p",
                      [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.everyTimeMarketCount)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editDialog("countVisible", 1, {
                                  everyTimeMarketCount:
                                    scope.row.everyTimeMarketCount,
                                  id: scope.row.id,
                                })
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "最大寄售数量",
              "min-width": "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "p",
                      [
                        _c("span", [_vm._v(_vm._s(scope.row.maxMarketCount))]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editDialog("countVisible", 2, {
                                  maxMarketCount: scope.row.maxMarketCount,
                                  id: scope.row.id,
                                })
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "寄售价格", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "p",
                      [
                        scope.row.marketPriceType == 1
                          ? _c("span", [
                              _vm._v(
                                "低于市场价" +
                                  _vm._s(
                                    scope.row.marketDownPrice
                                      ? scope.row.marketDownPrice
                                      : 0
                                  ) +
                                  "元寄售"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.marketPriceType == 2
                          ? _c("span", [
                              _vm._v(
                                "高于市场价" +
                                  _vm._s(
                                    scope.row.marketUpPrice
                                      ? scope.row.marketUpPrice
                                      : 0
                                  ) +
                                  "元寄售"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.marketPriceType == 3
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.marketDownPrice
                                    ? scope.row.marketDownPrice
                                    : 0
                                ) +
                                  "-" +
                                  _vm._s(
                                    scope.row.marketUpPrice
                                      ? scope.row.marketUpPrice
                                      : 0
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editOneDialog(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "已上架寄售数量",
              "min-width": "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.getProduct(
                              scope.row.productId,
                              scope.row.productName,
                              scope.row.productImage,
                              scope.row.id
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.saleOnCount))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.hasAutoMarket ? "进行中" : "暂停")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "150", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.id
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandStatus(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.hasAutoMarket ? "暂停" : "开始")
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.cancelScan(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("一键下架")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteScan(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.consignVisible,
            title: "修改寄售价格",
            width: "400px",
            align: "center",
          },
          on: {
            "update:visible": function ($event) {
              _vm.consignVisible = $event
            },
            close: function ($event) {
              return _vm.handleClose("formData")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "form-custom",
              attrs: { model: _vm.formData },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "marketPriceType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.marketPriceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "marketPriceType", $$v)
                        },
                        expression: "formData.marketPriceType",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 3 } },
                        [
                          _vm._v("\n            按价格区间\n            "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.downPrice,
                              callback: function ($$v) {
                                _vm.downPrice = $$v
                              },
                              expression: "downPrice",
                            },
                          }),
                          _vm._v("\n            -\n            "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.upPrice,
                              callback: function ($$v) {
                                _vm.upPrice = $$v
                              },
                              expression: "upPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _c("span", [_vm._v("低于市场价格")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.formData.marketDownPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "marketDownPrice", $$v)
                              },
                              expression: "formData.marketDownPrice",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("元自动寄售")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: 2 } },
                        [
                          _c("span", [_vm._v("高于市场价格")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "input-price",
                            model: {
                              value: _vm.formData.marketUpPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "marketUpPrice", $$v)
                              },
                              expression: "formData.marketUpPrice",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("元自动寄售")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      return (_vm.consignVisible = false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "添加藏品",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticClass: "search-input",
            attrs: { size: "small", placeholder: "输入藏品名称搜索" },
            on: { input: _vm.collectionInput },
            model: {
              value: _vm.collectionName,
              callback: function ($$v) {
                _vm.collectionName = $$v
              },
              expression: "collectionName",
            },
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.marketList,
                loading: _vm.listDialogLoading,
                size: "small",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "image", label: "头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "w30 h30",
                          attrs: { src: scope.row.image },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "60", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.updateSelection(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-box" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.marketPages.pageSize,
                  "current-page": _vm.marketPages.pageNum,
                  total: _vm.marketPages.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.marketPages, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.marketPages, "pageNum", $event)
                  },
                  "current-change": _vm.getmarketList,
                  "size-change": _vm.handleUserSizeChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.createScan()
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.marketDownVisible,
            title: _vm.types[_vm.formData.type].title,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.marketDownVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "form-custom",
              attrs: { model: _vm.formData },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("span", [_vm._v("使用低于市场价格")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "input-price",
                    on: {
                      blur: function ($event) {
                        return _vm.numInput(
                          "marketDownPrice",
                          2,
                          0,
                          99999999999999
                        )
                      },
                    },
                    model: {
                      value: _vm.formData.marketDownPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "marketDownPrice", $$v)
                      },
                      expression: "formData.marketDownPrice",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("元自动寄售")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      return (_vm.marketDownVisible = false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.countVisible,
            title: _vm.types[_vm.formData.type].title,
            width: "400px",
            align: "center",
          },
          on: {
            "update:visible": function ($event) {
              _vm.countVisible = $event
            },
            close: function ($event) {
              return _vm.handleClose("formData")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "form-custom",
              attrs: { model: _vm.formData },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.formData.type === 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.types[_vm.formData.type].title,
                        prop: "everyTimeMarketCount",
                        rules: [
                          {
                            required: true,
                            message:
                              _vm.types[_vm.formData.type].title + "不能为空",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        on: {
                          input: function ($event) {
                            return _vm.numInput(
                              "everyTimeMarketCount",
                              0,
                              0,
                              99999999999999
                            )
                          },
                        },
                        model: {
                          value: _vm.formData.everyTimeMarketCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "everyTimeMarketCount", $$v)
                          },
                          expression: "formData.everyTimeMarketCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formData.type === 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.types[_vm.formData.type].title,
                        prop: "maxMarketCount",
                        rules: [
                          {
                            required: true,
                            message:
                              _vm.types[_vm.formData.type].title + "不能为空",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        on: {
                          input: function ($event) {
                            return _vm.numInput(
                              "maxMarketCount",
                              0,
                              0,
                              99999999999999
                            )
                          },
                        },
                        model: {
                          value: _vm.formData.maxMarketCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "maxMarketCount", $$v)
                          },
                          expression: "formData.maxMarketCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      return (_vm.countVisible = false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.productDialog,
            title: "寄售列表",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.productDialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.productList,
                loading: _vm.listDialogLoading,
                size: "small",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { prop: "image", label: "头像" } },
                [
                  [
                    _vm.currentproduct && _vm.currentproduct.image
                      ? _c("el-image", {
                          staticClass: "w30 h30",
                          attrs: { src: _vm.currentproduct.image },
                        })
                      : _vm._e(),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "名称" } },
                [
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.currentproduct && _vm.currentproduct.name) +
                        "\n        "
                    ),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          #" +
                            _vm._s(scope.row.number) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.price) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-box" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.productPages.pageSize,
                  "current-page": _vm.productPages.pageNum,
                  total: _vm.productPages.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.productPages, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.productPages, "pageNum", $event)
                  },
                  "current-change": _vm.getProductList,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
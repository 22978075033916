"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSet = addSet;
exports.allRoom = allRoom;
exports.battleEdit = battleEdit;
exports.deleteSet = deleteSet;
exports.getBattleCreate = getBattleCreate;
exports.getBattleRecords = getBattleRecords;
exports.getInfoList = getInfoList;
exports.getRanking = getRanking;
exports.getSetList = getSetList;
exports.getSuccessRecords = getSuccessRecords;
var _request = _interopRequireDefault(require("./request"));
// 所有的场景集合
function getInfoList(data) {
  return _request.default.get("/activity/sys/battle/getInfoList", data);
}
// 进攻数据
function getBattleRecords(data) {
  return _request.default.get("/activity/sys/battle/getBattleRecords", data);
}
// 排行榜
function getRanking(data) {
  return _request.default.get("/activity/sys/battle/getRanking", data);
}
// 分摊宝石
function getSuccessRecords(data) {
  return _request.default.get("/activity/sys/battle/getSuccessRecords", data);
}
// 房间列表
function allRoom(id) {
  return _request.default.get("/activity/sys/battle/allRoom/".concat(id));
}
// 场景集合编辑
function battleEdit(data) {
  return _request.default.post("/activity/sys/battle/edit", data);
}
// 设置查询
function getSetList(data) {
  return _request.default.get("/activity/sys/battle/getSetList", data);
}
// 添加新场景
function getBattleCreate(data) {
  return _request.default.post("/activity/sys/battle/create", data);
}
// 添加规则
function addSet(data) {
  return _request.default.post("/activity/sys/battle/addSet", data);
}
// 删除规则
function deleteSet(id) {
  return _request.default.delete("/activity/sys/battle/deleteSet/".concat(id));
}
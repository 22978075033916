"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _marketing = require("@/api/marketing");
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      newDeliverDetail: {
        show: false,
        title: '',
        context: '',
        remark: '',
        name: '',
        signId: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入内容标题',
          trigger: 'blur'
        }],
        context: [{
          required: true,
          message: '请输入推送内容',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请输入申请说明',
          trigger: 'blur'
        }],
        signId: [{
          required: true,
          message: '请选择签名',
          trigger: 'change'
        }]
      },
      type: '2',
      signList: []
    };
  },
  computed: {
    textareaLength: function textareaLength() {
      return this.newDeliverDetail.context.length;
    }
  },
  created: function created() {
    var _this = this;
    (0, _marketing.smsSignListApi)({
      status: 1,
      pageNum: 1,
      pageSize: 50
    }).then(function (res) {
      _this.signList = res.rows;
    });
    this.getList();
  },
  methods: {
    changeAllTypeAction: function changeAllTypeAction(tab) {
      if (tab === '1') {
        this.$router.push('/merchant/marketing/sms/task');
        return false;
      } else if (tab === '3') {
        this.$router.push('/merchant/marketing/sms/sign');
        return false;
      } else if (tab === '4') {
        this.$router.push('/merchant/marketing/sms/notice');
        return false;
      }
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _marketing.getSmsTempApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    addAction: function addAction() {
      var _this3 = this;
      (0, _accounts.SMSInfo)().then(function (res) {
        _this3.smsCount = (res.data.sms_count || 0) - (res.data.send_count || 0);
      });
      this.newDeliverDetail = {
        show: true,
        title: '',
        context: '',
        remark: '',
        name: '',
        signId: ''
      };
    },
    editAction: function editAction(item) {
      this.newDeliverDetail = {
        show: true,
        id: item.id,
        title: item.title,
        context: item.context,
        remark: item.remark,
        signId: item.signId
      };
      var val = this.signList.find(function (val) {
        return val.id == item.signId;
      });
      this.newDeliverDetail.name = val.name;
    },
    submitForm: function submitForm(formName, isSave) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.$confirm("\u786E\u8BA4".concat(isSave ? '保存并提交审核' : '保存', "?"), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            var data = JSON.parse(JSON.stringify(_this4.newDeliverDetail));
            var subData = {
              title: data.title,
              context: data.context,
              remark: data.remark,
              signId: data.signId
            };
            var action = _marketing.smsTempCreatedApi;
            if (data.id) {
              subData.id = data.id;
              action = _marketing.smsTempUpdateApi;
            }
            if (isSave) {
              action = _marketing.smsTempcreateAndSubmitApi;
            }
            action(subData).then(function () {
              _this4.$message.success('操作成功');
              _this4.newDeliverDetail.show = false;
              _this4.getList();
            });
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    delAction: function delAction(id) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u6A21\u677F?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.smsTempDelApi)(id).then(function () {
          _this5.$message.success('删除成功');
          _this5.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    smsTempSubmitAction: function smsTempSubmitAction(id) {
      var _this6 = this;
      this.$confirm("\u786E\u8BA4\u63D0\u4EA4\u5BA1\u6838?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.smsTempSubmitApi)(id).then(function () {
          _this6.$message.success('操作成功');
          _this6.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    signChange: function signChange(val) {
      var item = this.signList.find(function (item) {
        return item.id == val;
      });
      this.newDeliverDetail.name = item.name;
    }
  }
};
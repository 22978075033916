"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
var _gameCon = _interopRequireDefault(require("./gameCon.vue"));
var _attack = _interopRequireDefault(require("./attack.vue"));
var _rank = _interopRequireDefault(require("./rank.vue"));
var _setting = _interopRequireDefault(require("./setting.vue"));
var _escape = require("@/api/escape");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Escape",
  components: {
    Gamecon: _gameCon.default,
    Attack: _attack.default,
    Rank: _rank.default,
    Setting: _setting.default
  },
  data: function data() {
    return {
      infoList: [],
      infoData: {},
      activeName: "",
      activeTab: '1'
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _escape.getInfoList)().then(function (res) {
        console.log(res);
        _this.infoList = res.data;
        _this.activeName = res.data[0].battleName;
        _this.infoData = res.data[0];
      });
    },
    onAdd: function onAdd() {
      this.infoData = {};
      this.activeName = '';
      this.activeTab = '1';
    },
    handleClick: function handleClick(_ref) {
      var name = _ref.name;
      var row = this.infoList.find(function (item) {
        return item.battleName == name;
      });
      this.infoData = row;
      this.activeTab = '1';
    }
  }
};
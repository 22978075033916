"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
var _hallFame = require("@/api/hallFame");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {},
      pageSizeList: [30, 50, 100],
      tabName: {
        1: ['profitListFirstName', 'buyListFirstName'],
        2: ['profitListSecondName', 'buyListSecondName'],
        3: ['profitListThirdName', 'buyListThirdName'],
        '4以下': ['profitListFourthName', 'buyListFourthName']
      },
      searchPages: {
        pageNum: 1,
        pageSize: 500
      },
      blacklist: [],
      snapshotOptions: []
    };
  },
  created: function created() {
    this.getConfig();
    this.getSnapshot();
  },
  methods: {
    // 获取配置
    getConfig: function getConfig() {
      var _this = this;
      (0, _hallFame.getHallfameConfigApi)().then(function (res) {
        if (res.code === 200) {
          _this.ruleForm = res.data;
          _this.blacklist = _this.ruleForm.blacklist ? _this.ruleForm.blacklist.split(',') : [];
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.setConfig(_this2.ruleForm);
        } else {
          return false;
        }
      });
    },
    setConfig: function setConfig(form) {
      var _this3 = this;
      var params = (0, _objectSpread2.default)({}, form);
      params.blacklist = this.blacklist && this.blacklist.length ? this.blacklist.join(',') : '';
      delete params.params;
      (0, _hallFame.updateHallfameConfigApi)(params).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.msg);
        } else {
          _this3.$message.error(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
        _this3.$message.error(err.massage);
      });
    },
    getSnapshot: function getSnapshot(reset) {
      var _this4 = this;
      if (reset) {
        this.searchPages.pageNum = 1;
      }
      var params = (0, _objectSpread2.default)({}, this.searchPages);
      (0, _product.snapshotListApi)(params).then(function (res) {
        _this4.snapshotOptions = res.rows;
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};
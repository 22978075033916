"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _base = _interopRequireDefault(require("./components/base.vue"));
var _coundownauction = require("@/api/coundownauction");
var _nft = require("@/api/nft");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Random',
  components: {
    baseForm: _base.default
  },
  data: function data() {
    return {
      formData: {
        ruleType: 1,
        winningNumbers: []
      },
      currentNumber: '',
      initForm: {
        cardRadius: 16,
        titleMasterColor: '#000000',
        titleAssistantColor: '#999999',
        timingTitleColor: '#ffffff',
        timingBackgroundColor: '#FF9325',
        buttonTitleColor: '#ffffff',
        buttonBackgroundColor: '#F76262',
        backGroundColor: '#ffffff'
      },
      whitelist: [],
      hasLimitation: false,
      switchWhiteList: [],
      typeList: [],
      tableLoading: false,
      tableData: [],
      tablePages: {
        pageNum: 1,
        pageSize: 10
      },
      visible: false,
      visibleTitle: '',
      materialList: {
        data: [],
        total: 0
      },
      allMaterial: [],
      materialForm: {
        pageNum: 1,
        pageSize: 10,
        status: 1
      },
      chooseList: []
    };
  },
  created: function created() {
    this.init();
    this.nftCollectionListApiMethod('all');
    this.getType();
    this.getList();
  },
  methods: {
    init: function init() {
      if (this.$route.params.id) {
        this.getCdAuctionInfo();
      } else {
        Object.assign(this.formData, this.initForm);
      }
    },
    getList: function getList() {
      var _this = this;
      (0, _product.snapshotListApi)({
        pageNum: 1,
        pageSize: 200
      }).then(function (res) {
        _this.whitelist = res.rows;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getCdAuctionInfo: function getCdAuctionInfo() {
      var _this2 = this;
      (0, _coundownauction.getAuctionRandomInfoApi)(this.$route.params.id).then(function (res) {
        if (res.code === 200) {
          _this2.formData = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), res.data.ruleType === 1 ? {
            winningNumbers: res.data.winningNumbers.split(',')
          } : []), {}, {
            scoreTypeId: res.data.scoreTypeId.toString()
          });
          if (_this2.formData.hasLimitation) {
            _this2.switchWhiteList = _this2.formData.snapshotIds.split(',') || [];
          }
          _this2.hasLimitation = _this2.formData.hasLimitation;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getType: function getType() {
      var _this3 = this;
      (0, _marketing.integralTypeListApi)().then(function (res) {
        _this3.typeList = res.data;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    validateNum: function validateNum(rule, value, callback) {
      if (!value || !value.length) {
        callback(new Error('请添加中奖号码'));
      } else if (value.length < this.formData.putCount) {
        callback(new Error('中奖号码数量需和投放数量保持一致'));
      } else {
        callback();
      }
    },
    // changeType(val) {
    //   if (val === 1) {
    //     this.formData.winningNumber = '';
    //   } else {
    //     this.formData.winningNumbers = [];
    //   }
    // },
    changeForm: function changeForm(form) {
      Object.assign(this.formData, form);
    },
    // 提交form
    submitForm: function submitForm(form) {
      Object.assign(this.formData, form);
      this.formData.snapshotIds = this.switchWhiteList.toString();
      this.setCdAuction(this.formData);
    },
    // 设置倒计时信息
    setCdAuction: function setCdAuction(formData) {
      var _this4 = this;
      if (formData.ruleType === 1 && (!formData.winningNumbers || !formData.winningNumbers.length)) {
        this.$message.error('请输入中奖序号');
        return;
      }
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, formData), {}, {
        winningNumbers: formData.winningNumbers ? formData.winningNumbers.join(',') : '',
        snapshotIds: this.switchWhiteList.toString(),
        scoreTypeId: formData.scoreTypeId.toString(),
        hasLimitation: this.hasLimitation
      });
      (0, _coundownauction.setAuctionRandomApi)(params).then(function (res) {
        if (res.code === 200) {
          _this4.$router.push({
            name: 'cdAuctionList',
            query: {
              type: 2
            }
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    numInput: function numInput(name, point, min, max) {
      this.formData[name] = (0, _index.numInput)(this.formData[name], point, min, max);
    },
    // 弹出框相关
    handleAdditem: function handleAdditem() {
      this.visibleTitle = '选择藏品';
      this.nftCollectionListApiMethod();
      this.visible = true;
    },
    nftCollectionListApiMethod: function nftCollectionListApiMethod(type) {
      var _this5 = this;
      this.tableLoading = true;
      var params = (0, _objectSpread2.default)({}, this.materialForm);
      if (type === 'all') {
        params.pageSize = 500;
      }
      (0, _nft.nftCollectionListApi)(params).then(function (res) {
        _this5.tableLoading = false;
        res.rows.forEach(function (m) {
          m.choose = false;
        });
        if (type === 'all') {
          _this5.allMaterial = res.rows;
        } else {
          _this5.materialList.data = res.rows;
          _this5.materialList.total = res.total;
          _this5.materialList.data.forEach(function (element) {
            element.composeType = 1;
            element.choose = false;
            element.productName = element.name;
          });
          if (_this5.formData.productId) {
            var item = _this5.materialList.data.find(function (item) {
              return item.productId === _this5.formData.productId;
            });
            if (item) {
              _this5.chooseList = [item];
            }
          }
        }
      }).catch(function (res) {
        _this5.tableLoading = false;
      });
    },
    chooseRow: function chooseRow(row, max) {
      var obj = (0, _objectSpread2.default)({}, row);
      var index = this.chooseList.findIndex(function (res) {
        return res.productId === row.productId;
      });
      if (index !== -1) {
        this.chooseList.splice(index, 1);
      } else {
        console.log(this.chooseList.length < max);
        if (max > this.chooseList.length) {
          this.chooseList.push(obj);
        }
      }
    },
    materialFormSizeChange: function materialFormSizeChange(val) {
      this.materialForm.pageSize = val;
      this.goSearch();
    },
    materialFormpageChange: function materialFormpageChange(val) {
      this.materialForm.pageNum = val;
      this.goSearch();
    },
    goSearch: function goSearch() {
      this.nftCollectionListApiMethod();
    },
    confirmAction: function confirmAction() {
      if (this.chooseList.length && this.chooseList[0].productId) {
        var _this$chooseList$ = this.chooseList[0],
          productId = _this$chooseList$.productId,
          productName = _this$chooseList$.productName;
        this.formData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.formData), {}, {
          productId: productId,
          productName: productName
        });
      }
      this.visible = false;
    },
    onConfirm: function onConfirm() {
      if (this.formData.winningNumbers.length >= this.formData.putCount) {
        this.$message.error('中奖序号个数需和投放数量一致');
        return;
      }
      if (this.currentNumber) {
        if (!(!isNaN(parseFloat(this.currentNumber)) && isFinite(this.currentNumber))) {
          this.$message.error('请添加正确的中奖号码');
          return;
        }
        if (this.formData.winningNumbers.indexOf(this.currentNumber) !== -1) {
          this.$message.error('请勿重复添加中奖号码');
          return;
        }
        this.formData.winningNumbers.push(this.currentNumber);
        this.currentNumber = '';
      }
    },
    deleteIcon: function deleteIcon(item) {
      var index = this.formData.winningNumbers.findIndex(function (e) {
        return e === item;
      });
      if (index !== -1) {
        this.formData.winningNumbers.splice(index, 1);
      }
    },
    changePut: function changePut() {
      if (!this.formData.putCount) {
        // this.$message.error('请输入参数有误');
        return false;
      } else if (isNaN(this.formData.putCount)) {
        // this.$message.error('参数有误');
        return false;
      } else if (this.formData.putCount > this.getProductReserveStock()) {
        this.formData.putCount = '';
        this.$message.error('投放数量大于库存');
      }
    },
    getProductReserveStock: function getProductReserveStock() {
      var _this6 = this;
      var reserveStock = '';
      if (this.formData.productId && this.allMaterial.length) {
        var item = this.allMaterial.find(function (item) {
          return item.productId === _this6.formData.productId;
        }) || {};
        reserveStock = item.reserveStock || '';
      }
      return reserveStock;
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var hallFameRouter = {
  path: "".concat(_settings.roterPre, "/hallFame"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/hallFame/"),
  name: 'HallFame',
  meta: {
    title: '名人堂'
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/hallFame/index.vue'));
      });
    },
    name: 'HallFameIndex',
    redirect: "".concat(_settings.roterPre, "/hallFame/list"),
    meta: {
      title: '名人堂'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/hallFame/list.vue'));
        });
      },
      name: 'HallFameList',
      meta: {
        title: '名人堂'
      }
    }, {
      path: 'config',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/hallFame/config.vue'));
        });
      },
      name: 'HallFameConfig',
      meta: {
        title: '名人堂设置'
      }
    }, {
      path: 'tosplit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/tosplit/index'));
        });
      },
      name: 'HallFameTosplit',
      meta: {
        title: '分账列表',
        noCache: true
      }
    }]
  }]
};
var _default = exports.default = hallFameRouter;
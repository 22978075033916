"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
var _uploadPDF = _interopRequireDefault(require("../uploadPDF"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Distribution',
  components: {
    uploadPDF: _uploadPDF.default
  },
  data: function data() {
    return {
      selectData1: {},
      selectData2: {},
      classifyData1: [{
        name: '店铺首页',
        child: [],
        link: '/'
      }, {
        name: '详情页',
        child: [{
          name: '自定义页面详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入自定义页面ID',
            required: true
          }],
          link: '/pages/home/home?'
        }, {
          name: '文章详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入文章ID',
            required: true
          }],
          link: '/pages/word/index?'
        }, {
          name: '数字藏品详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入藏品ID',
            required: true
          }],
          link: '/pages/nftCollection/index?'
        }, {
          name: '合成详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入合成ID',
            required: true
          }],
          link: '/pages/marketing/compose/compose?'
        }, {
          name: '助力拉新详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入助力拉新ID',
            required: true
          }],
          link: '/pages/marketing/helpActivity/helpActivity?'
        }, {
          name: 'NFT盲盒详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入NFT盲盒ID',
            required: true
          }],
          link: '/pages/nftCollection/nftbox?'
        }, {
          name: '抽奖活动详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入抽奖活动ID',
            required: true
          }],
          link: '/pages/marketing/draw/draw?'
        }, {
          name: '投票详情',
          child: [{
            key: 'id',
            value: '',
            placeholder: '输入投票ID',
            required: true
          }],
          link: '/pages/marketing/vote/vote?'
        }, {
          name: '签到',
          child: [],
          link: '/pages/marketing/signIn/signIn'
        }, {
          name: '随机积分拍卖活动',
          child: [],
          link: '/pages/marketing/newdraw/list?type=2'
        }, {
          name: '倒计时积分拍卖活动',
          child: [],
          link: '/pages/marketing/newdraw/list?type=1'
        }],
        link: ''
      }, {
        name: '寄售',
        child: [],
        link: '/nft_market/home'
      }, {
        name: '我的藏品',
        child: [],
        link: '/users/my_nft'
      }, {
        name: '文章公告页面',
        child: [],
        link: '/pages/notice'
      }, {
        name: '个人中心',
        child: [],
        link: '/pages/users/index'
      }, {
        name: '其他',
        child: [{
          name: '自定义链接',
          child: [{
            key: 'link',
            value: '',
            placeholder: '输入跳转地址'
          }],
          link: ''
        }, {
          name: '公众号链接',
          child: [{
            key: 'official_account',
            value: '',
            placeholder: '输入跳转地址'
          }],
          link: ''
        }, {
          name: '电话',
          child: [{
            key: 'phone',
            value: '',
            placeholder: '输入电话',
            required: true
          }],
          link: 'phone?'
        }, {
          name: '打开PDF文件',
          child: [{
            key: 'url',
            value: '',
            placeholder: '输入电话',
            uploadPdf: true
          }],
          link: 'pdf?',
          link_h5: ''
        }, {
          name: 'Y积分排行榜',
          child: [],
          link: '/users/yPoint'
        }],
        link: ''
      }],
      classifyData2: [],
      classifyData3: [],
      showSet: false,
      ossClient: null,
      statusText: false,
      authProgress: 0,
      fileUrl: ''
    };
  },
  methods: {
    changeshow: function changeshow(data) {
      var _this = this;
      if (data.name || data.name1) {
        for (var key in this.classifyData1) {
          var val = this.classifyData1[key];
          if (val.name === data.name) {
            this.selectData1 = val;
            this.classifyData2 = val.child;
          }
        }
        if (data.name1) {
          var _loop = function _loop() {
            var val = _this.classifyData2[_key];
            if (val.name === data.name1) {
              _this.selectData2 = val;
              _this.classifyData3 = val.child;
              if (data.name1 === '自定义链接') {
                _this.classifyData3[0].value = data.link;
              } else if (data.name1 === '公众号链接') {
                _this.classifyData3[0].value = data.link;
              } else if (_this.classifyData3.length > 0) {
                var query = data.link.split('?')[1];
                var qList = query.split('&');
                _this.classifyData3.forEach(function (m) {
                  qList.forEach(function (cc) {
                    var hasIndex = cc.indexOf("".concat(m.key, "="));
                    console.log(cc, m, hasIndex);
                    if (hasIndex !== -1) {
                      var param = cc.split('=');
                      m.value = param[1];
                    }
                  });
                });
              }
            }
          };
          for (var _key in this.classifyData2) {
            _loop();
          }
        }
      } else {
        this.selectData1 = {};
        this.selectData2 = {};
        this.classifyData2 = [];
        this.classifyData3 = [];
      }
      this.showSet = true;
    },
    handleChange1: function handleChange1(item) {
      this.classifyData2 = item.child;
      this.classifyData3 = [];
    },
    handleChange2: function handleChange2(item) {
      item.child.forEach(function (m) {
        if (m.value) {
          m.value = '';
        }
      });
      this.classifyData3 = item.child;
    },
    uploadPDF: function uploadPDF(url) {
      console.log(this.classifyData3, url);
      this.classifyData3[0].value = url;
    },
    submitAction: function submitAction() {
      var data = {
        link: '',
        name: '',
        name1: ''
      };
      if (!this.selectData1.name) {
        this.showSet = false;
        // 关闭
      } else {
        data.link = this.selectData1.link;
        data.name = this.selectData1.name;
        if (this.selectData1.child.length > 0) {
          if (!this.selectData2.name) {
            this.$message.error('请选择具体跳转链接');
            return false;
            // 关闭
          }
          data.link += this.selectData2.link;
          data.name1 = this.selectData2.name;
          if (this.selectData2.child.length > 0) {
            if (data.link) {
              var d = [];
              for (var key in this.classifyData3) {
                var m = this.classifyData3[key];
                if (m.required && !m.value) {
                  this.$message.error('请输入具体跳转内容ID');
                  return false;
                }
                d.push("".concat(m.key, "=").concat(m.value));
              }
              data.link += d.length > 0 ? d.join('&') : '';
            } else {
              data.link = this.classifyData3[0].value;
            }
          }
        }
      }
      this.$emit('selectMessage', {
        data: data
      });
      this.showSet = false;
    }
  }
};
import { render, staticRenderFns } from "./orderv2.vue?vue&type=template&id=d80bf0c2"
import script from "./orderv2.vue?vue&type=script&lang=js"
export * from "./orderv2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/java_admin_Rm9R/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d80bf0c2')) {
      api.createRecord('d80bf0c2', component.options)
    } else {
      api.reload('d80bf0c2', component.options)
    }
    module.hot.accept("./orderv2.vue?vue&type=template&id=d80bf0c2", function () {
      api.rerender('d80bf0c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/timing/orderv2.vue"
export default component.exports
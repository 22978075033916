var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "el-tabs",
            {
              staticStyle: { flex: "1" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.infoList, function (item) {
              return _c("el-tab-pane", {
                key: item.id,
                attrs: { label: item.battleName, name: item.battleName },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-link",
            {
              attrs: { underline: false, type: "primary", size: "mini" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("+添加新场景")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "游戏设置", name: "1" } },
                [
                  _c("Gamecon", {
                    attrs: { options: _vm.infoData },
                    on: { succ: _vm.init },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "进攻数据", name: "2" } },
                [_c("Attack", { attrs: { optionsID: _vm.infoData.id } })],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "排行榜", name: "3" } },
                [_c("Rank", { attrs: { optionsID: _vm.infoData.id } })],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "设置", name: "4" } },
                [_c("Setting", { attrs: { optionsID: _vm.infoData.id } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
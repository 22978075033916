"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ProductClassify',
  data: function data() {
    return {
      moren: require('@/assets/images/bjt.png'),
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      editCateData: {
        show: false,
        parentId: [0],
        name: '',
        url: '',
        status: true,
        sort: 0,
        type: 1
      },
      cateLists: [],
      rules: {
        parentId: [{
          required: true,
          message: '请选择上级分类',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _product.storeCategoryListApi)().then(function (res) {
        var data = [{
          id: 0,
          label: '顶级分类',
          value: 0
        }];
        res.data.forEach(function (m) {
          var d_two = [];
          if (m.children && m.children.length > 0) {
            m.children.forEach(function (m1) {
              d_two.push({
                id: m1.id,
                label: m1.label,
                value: m1.id
              });
            });
          }
          var d = {
            id: m.id,
            label: m.label,
            value: m.id
          };
          if (d_two.length > 0) {
            d.children = d_two;
          }
          data.push(d);
        });
        _this2.tableData.data = res.data;
        _this2.cateLists = data;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    // 添加
    onAdd: function onAdd() {
      this.editCateData = {
        show: true,
        parentId: [0],
        name: '',
        url: '',
        status: true,
        sort: 0,
        type: 1
      };
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this3 = this;
      (0, _product.storeCategoryDetailApi)({
        id: id
      }).then(function (res) {
        _this3.editCateData = {
          show: true,
          parentId: [res.data.parentId ? res.data.parentId : 0],
          name: res.data.name,
          url: res.data.url,
          status: res.data.status,
          sort: res.data.sort,
          type: res.data.type,
          id: res.data.id
        };
      }).catch(function () {});
      // this.$modalForm(storeCategoryUpdateApi(id)).then(() => this.getList());
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _product.storeCategoryDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this4.$message.error(message);
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this5 = this;
      (0, _product.storeCategoryUpdateApi)({
        id: row.id,
        status: row.status
      }).then(function (_ref3) {
        var message = _ref3.message;
        _this5.$message.success('操作成功');
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this5.$message.error(message);
      });
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this6.editCateData));
          data.parentId = _this6.editCateData.parentId && _this6.editCateData.parentId[_this6.editCateData.parentId.length - 1] ? _this6.editCateData.parentId[_this6.editCateData.parentId.length - 1] : '0';
          _this6.$confirm("\u786E\u8BA4".concat(_this6.editCateData.id ? '修改' : '添加', "\u8BE5\u5206\u7C7B?"), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            var action = _product.storeCategoryCreateApi;
            if (_this6.editCateData.id) {
              action = _product.storeCategoryUpdateApi;
            }
            action(data).then(function () {
              _this6.$message.success('操作成功');
              _this6.getList();
              _this6.editCateData.show = false;
            }).catch(function (_ref5) {
              var message = _ref5.message;
            });
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.editCateData.url = img[0];
      }, tit);
    },
    handleRemove: function handleRemove() {
      this.editCateData.url = '';
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    facade: {
      type: [Object, Array]
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    getStyle: function getStyle(key) {
      var data = {};
      if (key === 'l') {
        data = {
          color: this.facade.frontColor,
          'font-weight': this.facade.frontFontWeight,
          'font-size': this.facade.frontFontSize / 2 + 'px',
          'border-radius': this.facade.frontRadius / 2 + 'px'
        };
        if (this.facade.frontBgImg) {
          data.background = "url(".concat(this.facade.frontBgImg, ") no-repeat;");
          data['background-size'] = '100% 100%';
          data['background-color'] = this.facade.frontBgColor;
          data['line-height'] = '50px';
        } else {
          data['background-color'] = this.facade.frontBgColor;
          if (this.facade.frontBorderColor) {
            data['border'] = "2px solid ".concat(this.facade.frontBorderColor);
            data['line-height'] = '46px';
          } else {
            data['line-height'] = '50px';
          }
        }
      } else if (key === 'r') {
        data = {
          color: this.facade.afterColor,
          'font-weight': this.facade.afterFontWeight,
          'font-size': this.facade.afterFontSize / 2 + 'px',
          'border-radius': this.facade.afterRadius / 2 + 'px'
        };
        if (this.facade.widthType === 2) {
          data.flex = 2;
        }
        if (this.facade.afterBgImg) {
          data.background = "url(".concat(this.facade.afterBgImg, ") no-repeat;");
          data['background-size'] = '100% 100%';
          data['background-color'] = this.facade.afterBgColor;
          data['line-height'] = '50px';
        } else {
          data['background-color'] = this.facade.afterBgColor;
          if (this.facade.afterBorderColor) {
            data['border'] = "2px solid ".concat(this.facade.afterBorderColor);
            data['line-height'] = '46px';
          } else {
            data['line-height'] = '50px';
          }
        }
      }
      var style = [];
      for (var _key in data) {
        style.push("".concat(_key, ":").concat(data[_key]));
      }
      return style.join(';');
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt20" },
        [
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { size: "small", placeholder: "按轮次" },
              on: {
                change: function ($event) {
                  return _vm.getList(1)
                },
              },
              model: {
                value: _vm.queryParams.recordsNum,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "recordsNum", $$v)
                },
                expression: "queryParams.recordsNum",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: {
                  click: function ($event) {
                    return _vm.getList(1)
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml20 align-items-c flex" },
            [
              _c("el-date-picker", {
                staticClass: "w200",
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  size: "small",
                  "start-placeholder": "选择开始时间",
                  "end-placeholder": "选择结束时间",
                  type: "datetimerange",
                },
                on: { change: _vm.onchangeTime },
                model: {
                  value: _vm.queryParams.timeVal,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "timeVal", $$v)
                  },
                  expression: "queryParams.timeVal",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("el-button", { attrs: { type: "primary" } }, [_vm._v("导出")]),
          _vm._v(" "),
          _c("el-link", { attrs: { type: "primary", underline: false } }, [
            _vm._v("查看导出列表"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "轮次", prop: "battleNum" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分摊宝石" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(1, row.battleRecordsId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(Number(row.shareNum)))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "失败人数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(2, row.battleRecordsId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(Number(row.failPeopleNum)))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", prop: "createTime" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex justify-e" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              "page-size": _vm.queryParams.pageSize,
              "current-page": _vm.queryParams.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.shareVisible,
            "close-on-click-modal": false,
            title: this.diaFrom.type == 1 ? "分摊宝石" : "失败人数",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "tab flex align-items-c mt20",
              staticStyle: { "justify-content": "space-between" },
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { size: "small", placeholder: "搜索用户" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.diaFrom.nameOrPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.diaFrom, "nameOrPhone", $$v)
                    },
                    expression: "diaFrom.nameOrPhone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("导出"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    { attrs: { type: "primary", underline: false } },
                    [_vm._v("查看导出列表")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.diaTableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "用户名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              underline: false,
                              type: "primary",
                              size: "mini",
                            },
                          },
                          [_vm._v(_vm._s(row.nickName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "投入情况" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.roomName) +
                            "-" +
                            _vm._s(row.productName) +
                            "*" +
                            _vm._s(row.inPutNum) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              this.diaFrom.type == 1
                ? _c("el-table-column", {
                    attrs: { label: "分摊宝石", prop: "getNum" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "投入时间", prop: "createTime" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  "page-size": _vm.diaFrom.pageSize,
                  "current-page": _vm.diaFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.diaTableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeOne,
                  "current-change": _vm.pageChangeOne,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
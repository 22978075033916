var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "120px" },
            },
            [
              _vm._l(_vm.ruleForm.imgConfig, function (imgConfig, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "imgconfig-item" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "添加产出皮肤", required: "" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: _vm.ruleForm.imgConfig[index].activeId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.imgConfig[index],
                                      "activeId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.imgConfig[index].activeId",
                                },
                              },
                              _vm._l(_vm.listData, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.name,
                                    value: item.id,
                                    disabled:
                                      _vm.ruleForm.imgConfig[index].activeId !==
                                        item.id &&
                                      _vm.activeIds.includes(item.id),
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-switch", {
                              model: {
                                value: imgConfig.custom,
                                callback: function ($$v) {
                                  _vm.$set(imgConfig, "custom", $$v)
                                },
                                expression: "imgConfig.custom",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("是否为自定义皮肤")]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "图片上传：", required: "" } },
                      [
                        _c("div", { staticClass: "flex flex-wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item flex align-items-c flex-direction mr20",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "stashBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.stashBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: imgConfig.stashBg + "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("仓库背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item flex align-items-c flex-direction mr20",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "stashInfoBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.stashInfoBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.stashInfoBg + "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("仓库信息背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item flex align-items-c flex-direction mr20",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationListBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationListBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationListBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("孵化列表产品背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationBg + "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("孵化背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationBack",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationBack
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationBack +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("孵化返回按钮"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationProgress",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationProgress
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationProgress +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("孵化进度"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotList",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotList
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotList +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽列表"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotListNoWrapper",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotListNoWrapper
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotListNoWrapper +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽列表无神兽占位背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotAdd",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotAdd
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotAdd +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽列表添加"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotUnlock",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotUnlock
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotUnlock +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽列表解锁"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotBtnBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotBtnBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotBtnBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽底部按钮背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotUnlockBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotUnlockBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotUnlockBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽解锁背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotInfoBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotInfoBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotInfoBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽详情背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotDelist",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotDelist
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotDelist +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽详情下架"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationSlotSupplement",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationSlotSupplement
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationSlotSupplement +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("卡槽详情补充"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationPopup",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationPopup
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationPopup +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("弹窗底部按钮"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationClose",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationClose
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationClose +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("弹窗关闭按钮"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationTitleBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationTitleBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationTitleBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("弹窗title背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationRuleBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationRuleBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationRuleBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("规则背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationRule",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationRule
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationRule +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("规则图片"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "incubationRecordBg",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.incubationRecordBg
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.incubationRecordBg +
                                              "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("孵化记录背景"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-item mr20 flex align-items-c flex-direction",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  attrs: { title: "正方形图片" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "animalEmpty",
                                        "",
                                        imgConfig
                                      )
                                    },
                                  },
                                },
                                [
                                  imgConfig.animalEmpty
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              imgConfig.animalEmpty + "!120a",
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "txt" }, [
                                _vm._v("为空占位图"),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-form-item", [
                      _c(
                        "ul",
                        {
                          staticClass: "colour-box",
                          staticStyle: { margin: "0" },
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化列表文字颜色：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationListText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationListText",
                                      $$v
                                    )
                                  },
                                  expression: "imgConfig.incubationListText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("li", { staticClass: "flex flex-wrap" }, [
                            _c("span", [_vm._v("孵化列表文字背景颜色：")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w300 flex" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "whitespace-nowrap" },
                                  [_vm._v("开始：")]
                                ),
                                _vm._v(" "),
                                _c("colour", {
                                  attrs: { colour: "#FFFFFF" },
                                  model: {
                                    value: imgConfig.incubationListStartText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        imgConfig,
                                        "incubationListStartText",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "imgConfig.incubationListStartText",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w300 flex" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "whitespace-nowrap" },
                                  [_vm._v("中间(可不设置)：")]
                                ),
                                _vm._v(" "),
                                _c("colour", {
                                  attrs: { colour: "#FFFFFF" },
                                  model: {
                                    value: imgConfig.incubationListMiddleText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        imgConfig,
                                        "incubationListMiddleText",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "imgConfig.incubationListMiddleText",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w300 flex" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "whitespace-nowrap" },
                                  [_vm._v("结束：")]
                                ),
                                _vm._v(" "),
                                _c("colour", {
                                  attrs: { colour: "#FFFFFF" },
                                  model: {
                                    value: imgConfig.incubationListEndText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        imgConfig,
                                        "incubationListEndText",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "imgConfig.incubationListEndText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情标题颜色：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationTitleStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationTitleStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationTitleStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationTitleEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationTitleEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationTitleEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情标题背景：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationTitleBgStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationTitleBgStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationTitleBgStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationTitleBgEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationTitleBgEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationTitleBgEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情列表背景：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationBottomStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationBottomStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationBottomStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationBottomEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationBottomEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationBottomEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情弹窗标题颜色：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value:
                                    imgConfig.incubationPopupTitleStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupTitleStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupTitleStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationPopupTitleEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupTitleEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupTitleEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情弹窗背景：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationPopupBgStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupBgStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupBgStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationPopupBgEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupBgEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupBgEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情弹窗边框：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value:
                                    imgConfig.incubationPopupBorderStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupBorderStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupBorderStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationPopupBorderEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupBorderEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupBorderEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情弹窗头部背景：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value:
                                    imgConfig.incubationPopupNavBgStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupNavBgStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupNavBgStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationPopupNavBgEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupNavBgEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupNavBgEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [
                                _vm._v("孵化详情弹窗关闭按钮颜色："),
                              ]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value:
                                    imgConfig.incubationPopupCloseStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupCloseStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupCloseStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationPopupCloseEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationPopupCloseEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationPopupCloseEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情按钮文字颜色：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationButtonStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationButtonStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationButtonStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationButtonEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationButtonEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationButtonEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化详情按钮背景颜色：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationButtonBgStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationButtonBgStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationButtonBgStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationButtonBgEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationButtonBgEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationButtonBgEndText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "flex" },
                            [
                              _c("span", [_vm._v("孵化记录列表背景：")]),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationRecordsStartText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationRecordsStartText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationRecordsStartText",
                                },
                              }),
                              _vm._v(" "),
                              _c("colour", {
                                staticStyle: { width: "25%" },
                                attrs: { colour: "#FFFFFF" },
                                model: {
                                  value: imgConfig.incubationRecordsEndText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      imgConfig,
                                      "incubationRecordsEndText",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "imgConfig.incubationRecordsEndText",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.activeIds.length < _vm.listData.length
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addImgConfig()
                            },
                          },
                        },
                        [_vm._v("添加配置")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item", [
                _c("div", { staticClass: "base-config" }, [_vm._v("公共样式")]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "公共上传图片" } }, [
                _c("div", { staticClass: "flex flex-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item flex align-items-c flex-direction mr20",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("homeBg", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.homeBg
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.ruleForm.homeBg + "!120a" },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("首页背景")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("homeTop", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.homeTop
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.homeTop + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("首页顶部")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(
                                "homeTopArrowRight",
                                "",
                                _vm.imgConfig
                              )
                            },
                          },
                        },
                        [
                          _vm.ruleForm.homeTopArrowRight
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.ruleForm.homeTopArrowRight + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("首页顶部右箭头"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("saleTop", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.saleTop
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.saleTop + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("售卖上箭头")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("saleBottom", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.saleBottom
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.saleBottom + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("售卖下箭头")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("saleSelectedTop", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.saleSelectedTop
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.saleSelectedTop + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("售卖选中上箭头"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("saleSelectedBottom", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.saleSelectedBottom
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.ruleForm.saleSelectedBottom + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("售卖选中下箭头"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("saleClose", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.saleClose
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.saleClose + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("售卖关闭按钮"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("saleNumberSub", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.saleNumberSub
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.saleNumberSub + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("售卖数量减")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("saleNumberAdd", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.saleNumberAdd
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.saleNumberAdd + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("售卖数量加")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("innerBg", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.innerBg
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.innerBg + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("内丹详情背景"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("innerDecoration", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.innerDecoration
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.innerDecoration + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("内丹详情装饰图"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item mr20 flex align-items-c flex-direction",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("innerBtn", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.innerBtn
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.innerBtn + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("内丹详情按钮背景"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item flex align-items-c flex-direction mr20",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(
                                "incubationWarehouseBg",
                                ""
                              )
                            },
                          },
                        },
                        [
                          _vm.ruleForm.incubationWarehouseBg
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.ruleForm.incubationWarehouseBg +
                                      "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("孵化列表总背景"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "img-item flex align-items-c flex-direction mr20",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("consignBg", "")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.consignBg
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.consignBg + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("寄售列表产出背景"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: { label: "颜色渐变：", required: "" },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "colour-box", staticStyle: { margin: "0" } },
                    [
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("首页tab渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeTabStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "homeTabStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.homeTabStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeTabEndLinear,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "homeTabEndLinear", $$v)
                              },
                              expression: "ruleForm.homeTabEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("首页tabs渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeTabsStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "homeTabsStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.homeTabsStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeTabsEndLinear,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "homeTabsEndLinear", $$v)
                              },
                              expression: "ruleForm.homeTabsEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("首页列表状态渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeListStatusStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "homeListStatusStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.homeListStatusStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeListStatusEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "homeListStatusEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.homeListStatusEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("首页列表底部渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeListBottomStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "homeListBottomStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.homeListBottomStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.homeListBottomEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "homeListBottomEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.homeListBottomEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("内丹仓库按钮渐变左侧：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerBtnStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerBtnStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerBtnStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerBtnEndLinear,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "innerBtnEndLinear", $$v)
                              },
                              expression: "ruleForm.innerBtnEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("内丹仓库按钮渐变右侧：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value:
                                _vm.ruleForm.otherConfig.innerBtnStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.otherConfig,
                                  "innerBtnStartLinear",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.otherConfig.innerBtnStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.otherConfig.innerBtnEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.otherConfig,
                                  "innerBtnEndLinear",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.otherConfig.innerBtnEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("内丹仓库弹窗渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerPopupStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerPopupStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerPopupStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerPopupEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerPopupEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerPopupEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("出售内丹按钮渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerSaleBtnStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerSaleBtnStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerSaleBtnStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerSaleBtnEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerSaleBtnEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerSaleBtnEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("出售内丹输入框渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerSaleIptStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerSaleIptStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerSaleIptStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerSaleIptEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerSaleIptEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerSaleIptEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("在售内丹列表渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerSaleListStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerSaleListStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerSaleListStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.innerSaleListEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "innerSaleListEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.innerSaleListEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("确认购买弹窗渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.confirmBuyStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "confirmBuyStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.confirmBuyStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.confirmBuyEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "confirmBuyEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.confirmBuyEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("确认购买弹窗按钮渐变：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.confirmBuyBtnStartLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "confirmBuyBtnStartLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.confirmBuyBtnStartLinear",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.confirmBuyBtnEndLinear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "confirmBuyBtnEndLinear",
                                  $$v
                                )
                              },
                              expression: "ruleForm.confirmBuyBtnEndLinear",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("弹窗文字主颜色：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.otherConfig.popupTitleColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.otherConfig,
                                  "popupTitleColor",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.otherConfig.popupTitleColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("弹窗文字副颜色：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.otherConfig.popupBaseColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.otherConfig,
                                  "popupBaseColor",
                                  $$v
                                )
                              },
                              expression: "ruleForm.otherConfig.popupBaseColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("主要按钮颜色：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.activeColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "activeColor", $$v)
                              },
                              expression: "ruleForm.activeColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("副文本颜色：")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.baseColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "baseColor", $$v)
                              },
                              expression: "ruleForm.baseColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("寄售产出背景颜色")]),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.otherConfig.consignStartColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.otherConfig,
                                  "consignStartColor",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.otherConfig.consignStartColor",
                            },
                          }),
                          _vm._v(" "),
                          _c("colour", {
                            staticStyle: { width: "25%" },
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.ruleForm.otherConfig.consignEndColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.otherConfig,
                                  "consignEndColor",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.otherConfig.consignEndColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转卖协议名称" } },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.ruleForm.noticeOnResale,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "noticeOnResale", $$v)
                      },
                      expression: "ruleForm.noticeOnResale",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.split");
var _marketing = require("@/api/marketing");
var _product = require("@/api/product");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      ruleData: {
        homeLableShow: [],
        snapshotIds: []
      },
      sort: ["全部", "推荐", "我的关注"],
      whitelist: [],
      restriction: "1",
      fastBuy: "1"
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      (0, _marketing.marketConfigListApi)().then(function (res) {
        _this.ruleData = res.data;
        var s = [];
        s[_this.ruleData.allSort] = "全部";
        s[_this.ruleData.recommendSort] = "推荐";
        s[_this.ruleData.myFollowSort] = "我的关注";
        _this.sort = s;
        _this.ruleData.listNumberFormat = "".concat(res.data.listNumberFormat);
        _this.ruleData.homeLableShow = res.data.homeLableShow ? res.data.homeLableShow.split(",") : ["1"];
        _this.ruleData.id = res.data.id;
        if (res.data.snapshotIds.length) {
          _this.restriction = "2";
          _this.ruleData.snapshotIds = _this.ruleData.snapshotIds.split(",") || [];
        } else {
          _this.restriction = "1";
        }
        if (res.data.quickSnapshotIds.length) {
          _this.fastBuy = "2";
          _this.ruleData.quickSnapshotIds = _this.ruleData.quickSnapshotIds.split(",") || [];
        } else {
          _this.fastBuy = "1";
        }
        // this.MarketWhite = res.data.marketWhiteList ? '2' : '1';
        _this.ruleData.marketWhiteList = res.data.marketWhiteList ? res.data.marketWhiteList.split(',') : '';
      }).catch(function () {});
    },
    getList: function getList() {
      var _this2 = this;
      (0, _product.snapshotListApi)({
        pageNum: 1,
        pageSize: 200
      }).then(function (res) {
        _this2.whitelist = res.rows;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (this.ruleData.hasMarketWhiteList) {
        if (!this.ruleData.marketWhiteList.length) {
          this.$message.error('请选择寄售挂单白名单');
          return;
        }
        if (!this.ruleData.restrictivePrompts) {
          this.$message.error('请填写寄售挂单白名单提示语');
          return;
        }
      }
      (0, _marketing.marketConfigUpdateListApi)({
        isNotShowHash: this.ruleData.isNotShowHash,
        isNotShowDate: this.ruleData.isNotShowDate,
        isNotCurrentOwn: this.ruleData.isNotCurrentOwn,
        isNotShowRecord: this.ruleData.isNotShowRecord,
        isNotShowSearch: this.ruleData.isNotShowSearch,
        isNotShowAuthor: this.ruleData.isNotShowAuthor,
        id: this.ruleData.id,
        listNumberFormat: this.ruleData.listNumberFormat,
        hasLimit: this.ruleData.hasLimit,
        hasCirculate: this.ruleData.hasCirculate,
        hasCirculateIsCount: this.ruleData.hasCirculateIsCount,
        marketFreezeTime: this.ruleData.marketFreezeTime,
        hasFirstMarket: this.ruleData.hasFirstMarket,
        noBuyHas: this.ruleData.noBuyHas,
        noBuyImage: this.ruleData.noBuyImage,
        starLevelImage: this.ruleData.starLevelImage,
        homeLableShow: this.ruleData.homeLableShow.join(","),
        limitName: this.ruleData.limitName,
        circulateName: this.ruleData.circulateName,
        circulateIsCountName: this.ruleData.circulateIsCountName,
        allSort: this.sort.indexOf("全部"),
        recommendSort: this.sort.indexOf("推荐"),
        myFollowSort: this.sort.indexOf("我的关注"),
        registrationInterval: this.ruleData.registrationInterval,
        nickNameStyle: this.ruleData.nickNameStyle,
        snapshotIds: this.restriction == "1" ? "" : this.ruleData.snapshotIds.toString(),
        hasBatch: this.ruleData.hasBatch,
        hasQuick: this.ruleData.hasQuick,
        quickSnapshotIds: this.fastBuy == "1" ? "" : this.ruleData.quickSnapshotIds.toString(),
        hasSignAgree: this.ruleData.hasSignAgree,
        hasRetrySignAgree: this.ruleData.hasRetrySignAgree,
        hasTransaction: this.ruleData.hasTransaction,
        hasTransactionRollingRecord: this.ruleData.hasTransactionRollingRecord,
        currentMarket: this.ruleData.currentMarket,
        hasMarketWhiteList: this.ruleData.hasMarketWhiteList,
        marketWhiteList: this.ruleData.hasMarketWhiteList ? this.ruleData.marketWhiteList.join(',') : '',
        restrictivePrompts: this.ruleData.hasMarketWhiteList ? this.ruleData.restrictivePrompts : ''
      }).then(function (res) {
        _this3.$message.success("设置成功");
        _this3.getInfo();
      }).catch(function () {});
    },
    checkboxChange: function checkboxChange(val) {
      console.log(val);
    },
    delImgAction: function delImgAction(key) {
      this.ruleData[key] = "";
    },
    showImageAction: function showImageAction(tit, key, key1) {
      var _this4 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this4.ruleData[key1][key] = img[0];
        } else {
          _this4.ruleData[key] = img[0];
        }
        _this4.$forceUpdate();
      }, tit);
    },
    changeRestriction: function changeRestriction() {
      this.ruleData.snapshotIds = [];
    },
    changeFastBuy: function changeFastBuy() {
      this.ruleData.quickSnapshotIds = [];
    },
    changeMarketWhite: function changeMarketWhite() {
      // this.ruleData.marketWhiteList = [];
    },
    settingContent: function settingContent() {
      this.$router.push("/merchant/systemForm/systemAgreement/detail?t=7");
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "tab flex align-items-c" },
          [
            _c(
              "el-form",
              {
                attrs: { size: "small", inline: "", "label-position": "left" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "状态：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { type: "button" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.queryParams.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "status", $$v)
                          },
                          expression: "queryParams.status",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "" } }, [
                          _vm._v("全部\n              "),
                          _vm.tableData.statistical
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    "(" + _vm.tableData.statistical.linkAll
                                      ? _vm.tableData.statistical.linkAll
                                      : 0 + ")"
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("已上链\n              "),
                          _vm.tableData.statistical
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    "(" + _vm.tableData.statistical.linkComplete
                                      ? _vm.tableData.statistical.linkComplete
                                      : 0 + ")"
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "0" } }, [
                          _vm._v("上链中\n              "),
                          _vm.tableData.statistical
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    "(" + _vm.tableData.statistical.linkIng
                                      ? _vm.tableData.statistical.linkIng
                                      : 0 + ")"
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "作品名称：" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "w200",
                        attrs: { size: "small", placeholder: "作品名称" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.queryParams.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "productName", $$v)
                          },
                          expression: "queryParams.productName",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            icon: "el-icon-search",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "专辑名称：" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "w200",
                        attrs: { size: "small", placeholder: "专辑名称" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.queryParams.seriesName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "seriesName", $$v)
                          },
                          expression: "queryParams.seriesName",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            icon: "el-icon-search",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "编号：" } },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: {
                        type: "number",
                        size: "small",
                        placeholder: "起始编号",
                      },
                      model: {
                        value: _vm.queryParams.startNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "startNum", $$v)
                        },
                        expression: "queryParams.startNum",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(" 至 ")]),
                    _vm._v(" "),
                    _c(
                      "el-input",
                      {
                        staticClass: "w200",
                        attrs: {
                          type: "number",
                          size: "small",
                          placeholder: "结尾编号",
                        },
                        model: {
                          value: _vm.queryParams.endNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "endNum", $$v)
                          },
                          expression: "queryParams.endNum",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            icon: "el-icon-search",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "提交时间：" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        format: "yyyy-MM-dd HH:mm:ss",
                        "start-placeholder": "选择开始时间",
                        "end-placeholder": "选择结束时间",
                        size: "small",
                        type: "datetimerange",
                      },
                      on: { change: _vm.onchangeTime },
                      model: {
                        value: _vm.timeVal,
                        callback: function ($$v) {
                          _vm.timeVal = $$v
                        },
                        expression: "timeVal",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "当前归属：" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "w200",
                        attrs: { size: "small", placeholder: "当前归属" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.queryParams.nickName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "nickName", $$v)
                          },
                          expression: "queryParams.nickName",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            icon: "el-icon-search",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "寄售状态：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择寄售状态", clearable: "" },
                        on: { change: _vm.saleStatusChange },
                        model: {
                          value: _vm.queryParams.saleStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "saleStatus", $$v)
                          },
                          expression: "queryParams.saleStatus",
                        },
                      },
                      _vm._l(_vm.saleStatusList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "用户标签" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { multiple: "", placeholder: "请选择用户标签" },
                        on: {
                          "visible-change": _vm.visibleChange,
                          "remove-tag": _vm.goSearch,
                        },
                        model: {
                          value: _vm.memberGroupIds,
                          callback: function ($$v) {
                            _vm.memberGroupIds = $$v
                          },
                          expression: "memberGroupIds",
                        },
                      },
                      _vm._l(_vm.labelList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt10 mb10" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.exportOrder },
              },
              [_vm._v("导出")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "text" },
                on: { click: _vm.getExportFileList },
              },
              [_vm._v("查看导出记录")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData.data, size: "small" },
              },
              [
                _c("el-table-column", {
                  attrs: { width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popover",
                            {
                              staticClass: "tabPop",
                              attrs: {
                                placement: "top-start",
                                width: "100",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "spBlock onHand",
                                    class: { check: _vm.chkName === "dan" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandle("dan", scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("选中本页")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                attrs: {
                                  slot: "reference",
                                  value:
                                    (_vm.chkName === "dan" &&
                                      _vm.checkedPage.indexOf(
                                        _vm.queryParams.pageNum
                                      ) > -1) ||
                                    _vm.chkName === "duo",
                                },
                                on: { change: _vm.changeType },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            attrs: {
                              disabled: !!(
                                scope.row.nftStatus || !scope.row.status
                              ),
                              value:
                                _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                                (_vm.chkName === "duo" &&
                                  _vm.noChecked.indexOf(scope.row.id) === -1),
                            },
                            on: {
                              change: function (v) {
                                return _vm.changeOne(v, scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作品图片" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: { src: row.coverPicture },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作品名称" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.name))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作品编号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.productNumber))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "专辑名称" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.seriesName))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "链类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.linkName))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "当前归属" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(row.nickName) + " " + _vm._s(row.userPhone)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作品哈希" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.fileHash))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "流转次数" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.memberPhone))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发行价" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.price))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "寄售状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(_vm._s(row.nftStatus ? "寄售中" : "正常")),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "上链状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(_vm._s(row.status ? "上链完成" : "上链中")),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "提交时间", "min-width": "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  row.successTime
                                    ? row.successTime
                                    : row.createTime
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block flex justify-e" },
              [
                _c(
                  "div",
                  { staticClass: "flex-one mt20 align-items-c flex" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.batchRecover },
                      },
                      [_vm._v("\n            批量回收\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mr10",
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.batchDestroy },
                      },
                      [_vm._v("\n            批量销毁\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mr10",
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.batchDisplace },
                      },
                      [_vm._v("\n            批量置换\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mr10",
                        attrs: {
                          type: "primary",
                          icon: "ios-search",
                          label: "default",
                          size: "small",
                        },
                        on: { click: _vm.downloadShare },
                      },
                      [_vm._v("\n            下载证书\n          ")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                    "page-size": _vm.queryParams.pageSize,
                    "current-page": _vm.queryParams.pageNum,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.pageChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.visibleRecord
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleRecord,
                "close-on-click-modal": false,
                center: "",
                title: "回收",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleRecord = $event
                },
              },
            },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "备注" },
                model: {
                  value: _vm.queryRecord.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryRecord, "remarks", $$v)
                  },
                  expression: "queryRecord.remarks",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { margin: "20px 0" } },
                [
                  _vm._v("\n      输入操作密码："),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "password", placeholder: "请输入密码" },
                    model: {
                      value: _vm.queryRecord.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryRecord, "pwd", $$v)
                      },
                      expression: "queryRecord.pwd",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.productRecoverMethod(
                            _vm.queryRecord.remarks,
                            _vm.queryRecord.pwd
                          )
                        },
                      },
                    },
                    [_vm._v("确认回收")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleDestroy
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleDestroy,
                "close-on-click-modal": false,
                center: "",
                title: "销毁",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleDestroy = $event
                },
              },
            },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "备注" },
                model: {
                  value: _vm.queryDestroy.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryDestroy, "remarks", $$v)
                  },
                  expression: "queryDestroy.remarks",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { margin: "20px 0" } },
                [
                  _vm._v("\n      输入操作密码："),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "password", placeholder: "请输入密码" },
                    model: {
                      value: _vm.queryRecord.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryRecord, "pwd", $$v)
                      },
                      expression: "queryRecord.pwd",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.productDestroyMethod(
                            _vm.queryDestroy.remarks,
                            _vm.queryRecord.pwd
                          )
                        },
                      },
                    },
                    [_vm._v("确认销毁")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleDisplace
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleDisplace,
                "close-on-click-modal": false,
                title: "置换",
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleDisplace = $event
                },
                close: _vm.closeDisplace,
              },
            },
            [
              _c(
                "div",
                [
                  _c("span", [_vm._v("置换后数藏处理方式")]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.displaceType,
                        callback: function ($$v) {
                          _vm.displaceType = $$v
                        },
                        expression: "displaceType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("回收")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("销毁")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("保留")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.selectCollection.length
                    ? _c(
                        "el-table",
                        { attrs: { data: _vm.selectCollection } },
                        [
                          _c("el-table-column", {
                            attrs: { label: "图片" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("img", {
                                        staticClass: "w50 h50",
                                        attrs: {
                                          src: scope.row.image,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3706139615
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "数藏名称", prop: "name" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "预留库存", prop: "number" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.reserved_number)
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2862076606
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "置换数量(不能大于库存)" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input-number", {
                                        staticClass: "w50",
                                        attrs: {
                                          min: 1,
                                          controls: false,
                                          precision: 0,
                                        },
                                        model: {
                                          value: scope.row.number,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "number", $$v)
                                          },
                                          expression: "scope.row.number",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              884309608
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            underline: false,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteCollection(
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1345710291
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "el-icon-plus",
                      staticStyle: { display: "block" },
                      attrs: { type: "text" },
                      on: { click: _vm.addCollection },
                    },
                    [_vm._v("\n        添加数藏\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: !_vm.selectCollection.length,
                      },
                      on: { click: _vm.confirmDisplace },
                    },
                    [_vm._v("确认置换")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": _vm.selectCollection, size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
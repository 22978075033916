"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _escape = require("@/api/escape");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    optionsID: {
      type: String | Number
    }
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        status: ''
      },
      listLoading: false,
      shareVisible: false,
      diaTableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      diaFrom: {
        pageNum: 1,
        pageSize: 20,
        nameOrPhone: ''
      }
    };
  },
  watch: {
    'optionsID': {
      handler: function handler(val) {
        this.queryParams.id = val;
        this.getList(1);
      },
      deep: true
    }
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.pageNum = num || this.queryParams.pageNum;
      (0, _escape.getBattleRecords)(this.queryParams).then(function (res) {
        console.log('1', res);
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.tableData.statistical = res.statistical;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    onDetails: function onDetails(type, id) {
      this.diaFrom.type = type;
      this.diaFrom.id = id;
      this.diaInit();
    },
    diaInit: function diaInit() {
      var _this2 = this;
      (0, _escape.getSuccessRecords)(this.diaFrom).then(function (res) {
        console.log(res);
        _this2.diaTableData.data = res.rows;
        _this2.diaTableData.total = res.total;
        _this2.diaTableData.statistical = res.statistical;
        _this2.shareVisible = true;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.queryParams.pageNum = page;
      this.getList();
    },
    handleSizeChangeOne: function handleSizeChangeOne(val) {
      this.diaFrom.pageSize = val;
      this.diaInit();
    },
    pageChangeOne: function pageChangeOne(page) {
      this.diaFrom.pageNum = page;
      this.diaInit();
    },
    onchangeTime: function onchangeTime(val) {
      if (val) {
        this.queryParams.startTime = val[0];
        this.queryParams.endTime = val[1];
        this.getList(1);
      } else {
        this.queryParams.startTime = '';
        this.queryParams.endTime = '';
        this.getList(1);
      }
    }
  }
};
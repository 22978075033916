"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _escape = require("@/api/escape");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    optionsID: {
      type: String | Number
    }
  },
  data: function data() {
    return {
      queryId: '',
      tableData: {
        data: [],
        total: 0,
        statistical: {}
      },
      albumList: []
    };
  },
  watch: {
    'optionsID': {
      handler: function handler(val) {
        this.queryId = val;
        this.getList();
      },
      deep: true
    }
  },
  mounted: function mounted() {},
  methods: {
    onAdd: function onAdd() {
      this.tableData.data.push({
        type: 1
      });
    },
    getList: function getList() {
      var _this = this;
      (0, _escape.getSetList)({
        id: this.queryId
      }).then(function (res) {
        console.log('3', res);
        _this.tableData.data = res.rows;
        _this.tableData.total = res.total;
        _this.tableData.statistical = res.statistical;
      });
      (0, _escape.allRoom)(this.queryId).then(function (res) {
        console.log(res);
        _this.albumList = res.data;
      });
    },
    onSubmit: function onSubmit(row) {
      (0, _escape.addSet)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        battleId: this.queryId
      })).then(function (res) {
        console.log(row);
      });
    },
    onDele: function onDele(row) {
      console.log(row);
    }
  }
};